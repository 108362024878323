import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { priceToString } from '../../../utils/utils';

export const MainRealty = ({dwellingList, commercialList}) => {

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-rent-homestay.png`;
  }

  return (
    <>
      <MainRentSection>
        <Header>
          <h2>지금 핫한 부동산 매물을<br/>확인하세요</h2>
          <span><Link to={`/realty-sale`}>전체보기</Link></span>
        </Header>
        <ul>
          {dwellingList?.map((row) => (
            <li key={row.id}>
              <Link to={`/dwelling-sale/${row.id}`}>
                <div className='imgWrapper'>
                  <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading='lazy' />
                  <div className="type">주거용</div>
                </div>
                <div className='title'>{row.title}</div>
                <div className='name'>{row.name}</div>
                <div className='price'>
                  {0 < row.price ? priceToString(row.price) : '가격문의'}
                </div>
              </Link>
            </li>
          ))}
          {commercialList?.map((row) => (
            <li key={row.id}>
              <Link to={`/commercial-sale/${row.id}`}>
                <div className='imgWrapper'>
                  <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading='lazy' />
                  <div className="type">비즈니스</div>
                </div>
                <div className='title'>{row.title}</div>
                <div className='name'>{row.name}</div>
                <div className='price'>
                  {0 < row.price ? priceToString(row.price) : '가격문의'}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </MainRentSection>
    </>
  )
};

const MainRentSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding-bottom: 2.4rem;
  width: auto;
  height: auto;
  background-color: #ffffff;
  & ul {
    margin: 0;
    padding: 0 1.6rem;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & li {
      position: relative;
      margin: 0;
      padding: 0;
      width: 48.5%;
      border-bottom: 0 solid #dddddd;
      & a {
        width: 16.7rem;
        color: #000;
        text-decoration: none;
        & div.imgWrapper {
          position: relative;
          width: 100%;
          height: 10.7rem;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.4rem;
          }
          & div.type {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0.2rem 0.8rem;
            width: auto;
            height: 2.1rem;

            font-size: 12px;
            font-weight: 500;
            line-height: 2.1rem;
            letter-spacing: -0.04em;
            color: #fd6258;

            background: #ffeae9;
            border-radius: 0 0.4rem;
          }
        }
        & div.title {
          margin-top: 0.6rem;
          height: 2rem;
          font-size: 14px;
          font-weight: 500;
          line-height: 2rem;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & div.name {
          margin-top: 0.2rem;
          height: 1.7rem;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.7rem;
          letter-spacing: -0.04em;
          color: #9e9e9e;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & div.price {
          margin-top: 0.8rem;
          height: 2.3rem;
          font-size: 16px;
          font-weight: 700;
          line-height: 2.3rem;
          letter-spacing: -0.04em;
          color: #000000;
        }
      }
    }
  }
`;

const Header = styled.div`
  position: relative;
  padding: 2.4rem 1.6rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.04em;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;