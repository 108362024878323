import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";

export class TutorApi {

  fetchTutorList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/tutor`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchTutorById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/tutor/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchTutorEditById(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/tutor/${id}/edit`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  checkTutorPermission(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/tutor/${id}/check-permission`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createTutorForStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/tutor`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createTutorForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/tutor/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveTutorForStep1(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/tutor/${id}/step1`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveTutorForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/tutor/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteTutor(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/tutor/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createTutorImageFile(id, formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/tutor/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteTutorImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/tutor/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveTutorNewPassword(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/tutor/${id}/password`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}