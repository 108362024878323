import React from 'react';
import styled from 'styled-components';

export const CommunityNoticeListComponent = ({ list, link, startPoint, perView, postOnClick }) => {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const renderNotices = (startPoint, perView, list) => {
    let result = [];
    if(list.length > startPoint) {
      const loopStart = startPoint;
      const loopEnd = (list.length < (perView + startPoint))? list.length: perView + startPoint;
      for(let i=loopStart; i<loopEnd; i++) {
        let row = list[i];
        result.push (
          <NoticeCard key={row.id} onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
              📢[공지] {row.title}
          </NoticeCard>
        )
      }
    }
    return result;
  }

  return (
    <>
      {0 < list.length && (
        <NoticeSection>
          {renderNotices(startPoint, perView, list)}
        </NoticeSection>
      )}
    </>
  );
};

const NoticeSection = styled.ul`
  margin: 0;
  padding: 0;
  width: auto;
  list-style: none;
  box-sizing: border-box;
`;

const NoticeCard = styled.li`
  margin: 0;
  padding: 0 1.6rem;
  height: 4.6rem;

  font-size: 14px;
  font-weight: 500;
  line-height: 4.6rem;
  letter-spacing: -0.04em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: 0.1rem solid #F5F5F5;
  cursor: pointer;
`;
