import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { elapsedText, isEmptyObj, numberToString, priceToString } from '../../../utils/utils';
import { CarApi } from '../api/CarApi';
import { ImageGalleryModal } from '../component/ImageGalleryModal';
import {
  CarAdditionalOptions,
  CarBrand,
  CarColors,
  CarIncludedOptions,
} from '../../../__mocks__/PostData';
import { RealtyAccordionComponent } from '../realty/component/RealtyAccordionComponent';
import { ImageSlideComponent } from '../component/ImageSlideComponent';

export const CarDetail = ({setDialog}) => {
  const NO_IMAGE = '/img/no-image-car.png';
  const carApi = new CarApi();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showImageModal, setShowImageModal] = useState(false);
  const [article, setArticle] = useState({
    id: '',
    boardId: 2,
    active: '',
    name: '',
    phone: '',
    email: '',
    title: '',
    description: '',
    type: '',
    flagType: '',
    price: '',
    brand: '',
    model: '',
    ageRange: '',
    mileage: '',
    gasType: '',
    transmission: '',
    drivingSystem: '',
    includedOptions: '',
    additionalOptions: '',
    color: '',
    hitCount: 0,
    updatedDate:  '',
    isSoldOut: false,
    images: {},
  });

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    carApi.fetchCarById(
      id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        // setDialog({isOpen: true, message: "현재 등록된 게시물이 없습니다.", type: "alert"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate("/car", {state: {isBack: true, type: 'car'}});
  }

  const handleStoreOwnerImageError = (e) => {
    e.target.src=`/img/no-store-owner-image.png`;
  }

  const handlerToggleShowModal = () => {
    if(isEmptyObj(article?.images)) return;
    setShowImageModal(!showImageModal);
  }

  const isIncludedOptionsChecked = (value) => {
    return 0<=article.includedOptions?.indexOf(value);
  }

  const isAdditionalOptionsChecked = (value) => {
    return 0<=article.additionalOptions?.indexOf(value);
  }

  // render -------------------------------------------------
  const renderCategory = ({type}) => {
    return (
      <>
        <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
        <span className='categoryLv2'>{type}</span>
      </>
    );
  }

  const addCarFlagTypeTag = ({flagType, isSoldOut}) => {
    if(!flagType && !isSoldOut) return;
    let backgroundColor = '';
    if("급매" === flagType) {
      backgroundColor = '#F77B21';
    }
    return (
      <ArticleTag>
        {isSoldOut ? (
          <span style={{background: '#DE2B36'}}>SOLD</span>
        ) : flagType && (
          <span style={{background: backgroundColor}}>{flagType}</span>
        )}
      </ArticleTag>
    );
  }

  const addLocationNContactTag = ({updatedDate, hitCount}) => {
    return (<span>{elapsedText(updatedDate)} · 조회수 {hitCount}</span>);
  }

  const addIncludedOptionsTag = (id, label) => {
    if(isIncludedOptionsChecked(id)) {
      return (
        <div className='checkbox-checked' >
          <img src='/icon/checkbox-checked-icon.png' alt='checked' />
          <label>{label}</label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-unchecked'>
          <img src='/icon/checkbox-unchecked-icon.png' alt='unchecked' />
          <label>{label}</label>
        </div>
      );
    }
  }

  const addAdditionalOptionsTag = (id, label) => {
    if(isAdditionalOptionsChecked(id)) {
      return (
        <div className='checkbox-checked' >
          <img src='/icon/checkbox-checked-icon.png' alt='checked' />
          <label>{label}</label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-unchecked'>
          <img src='/icon/checkbox-unchecked-icon.png' alt='unchecked' />
          <label>{label}</label>
        </div>
      );
    }
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <ImageSlideComponent
          images={article.images}
          noImgUrl={NO_IMAGE}
          onClick={()=>handlerToggleShowModal()}
        />
        <CategoryGroup>
          <div className='category'>
            <span className='categoryLv0'>전체</span>
            {renderCategory(article)}
          </div>
          <span className='postId'>{`게시글번호 ${article.id}`}</span>
        </CategoryGroup>

        <ContentWrapper>
          {addCarFlagTypeTag(article)}
          <Title>{article.title}</Title>
          <PriceInfo>
            {0 < article?.price ? `${priceToString(article?.price)}` : `가격문의`}
          </PriceInfo>
          <UpdateInfo>
            {addLocationNContactTag(article)}
          </UpdateInfo>
          {article?.storeId ? (
            <StoreInfo>
              <StoreTitle>
                <span>DEALER INFO</span>
                {article.storeTitle}
              </StoreTitle>
              <StoreDetail>
                <img src={`https://www.vanchosun.com/stores/File_images_stores/${article.storeImageFile}`} onError={(e) => handleStoreOwnerImageError(e)} alt={article.storeTitle} />
                <div className='storeDetails'>
                  <div className='storeOwner'>{article.storeName}</div>
                  <div>{article.storePhone}</div>
                  <div>{article.storeEmail}</div>
                </div>
              </StoreDetail>
            </StoreInfo>
          ) : (
            <StoreInfo>
              <StoreTitle>
                <span>DEALER INFO</span>
              </StoreTitle>
              <StoreDetail>
                <img src={`/img/no-store-owner-image.png`} onError={(e) => handleStoreOwnerImageError(e)} alt={'개인셀러'} />
                <div className='storeDetails'>
                  <div className='storeOwner'>{article.name}</div>
                  <div>{article.phone}</div>
                  <div>{article.email}</div>
                </div>
              </StoreDetail>
            </StoreInfo>
          )}
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <SubTitle>한 눈에 보는 매물</SubTitle>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-brand-icon.png' alt='Brand'/>
                      </div>
                      <div className='label'>브랜드</div>
                      <div className='value'>
                        {(CarBrand[article.brand]?.label) ? CarBrand[article.brand]?.label : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-ageRange-icon.png' alt='Age-range'/>
                      </div>
                      <div className='label'>연식</div>
                      <div className='value'>
                        {(0<article.ageRange) ? `${article.ageRange}년` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-color-icon.png' alt='Color'/>
                      </div>
                      <div className='label'>색상</div>
                      <div className='value'>
                        {(article.color) ? CarColors[article?.color]?.label : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-gas-type-icon.png' alt='Gas-type'/>
                      </div>
                      <div className='label'>연료</div>
                      <div className='value'>
                        {(article.gasType) ? article.gasType : '-'}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
          <Separator height={'0.2rem'}/>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-mileage-icon.png' alt='Mileage'/>
                      </div>
                      <div className='label'>주행거리</div>
                      <div className='value'>
                        {(0<article.mileage) ? `${numberToString(article.mileage)}km` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-transmission-icon.png' alt='Transmission'/>
                      </div>
                      <div className='label'>변속기</div>
                      <div className='value'>
                        {(article.transmission) ? article.transmission : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/car-driving-system-icon.png' alt='Driving-system'/>
                      </div>
                      <div className='label'>구동</div>
                      <div className='value'>
                        {(article.drivingSystem) ? article.drivingSystem : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'></div>
                      <div className='label'></div>
                      <div className='value'></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <SubTitle>옵션정보</SubTitle>
          <div style={{marginTop: '2.6rem'}}>
            <RealtyAccordionComponent>
              <AdditionalInfo>
                {Object.keys(CarIncludedOptions).map((key)=>
                  <li key={key}>
                    {addIncludedOptionsTag(key, CarIncludedOptions[key].label)}
                  </li>
                )}
              </AdditionalInfo>
            </RealtyAccordionComponent>
          </div>
          <Separator height={'0.2rem'}/>
          <SubTitle>특이사항</SubTitle>
          <RealtyAccordionComponent>
            <AdditionalInfo>
              {Object.keys(CarAdditionalOptions).map((key)=>
                <li key={key}>
                  {addAdditionalOptionsTag(key, CarAdditionalOptions[key].label)}
                </li>
              )}
            </AdditionalInfo>
          </RealtyAccordionComponent>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <SubTitle>매물 설명</SubTitle>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
        </ContentWrapper>
        <ImageGalleryModal
          title='매물 사진'
          showModal={showImageModal}
          toggleShowModal={()=>handlerToggleShowModal()}
          imageList={article.images} />
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const CategoryGroup = styled.div`
  position: relative;
  padding-left: 1.4rem;
  & div.category {
    width: auto;
    height: 4rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #f5f5f5;
    & span.categoryLv0 {
      color: #424242;
    }
    & span.categoryLv1 {
      /* font-weight: 500; */
      color: #424242;
      /* color: #FD6258; */
    }
    & span.categoryLv2 {
      font-weight: 500;
      color: #FD6258;
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  & span.postId {
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    padding: 0.2rem 0.8rem;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 30rem;
  }
`;

const ArticleTag = styled.div`
  margin: 2.1rem 0 0 0;
  display: flex;
  align-items: center;
  & span {
    margin-right: 0.5rem;
    padding: 0 1.1rem;
    width: auto;
    height: 1.8rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
  }
`;
const Title = styled.h1`
  margin: 2.1rem 0 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  word-break: break-all;
`;
const SubTitle = styled.h1`
  margin: 2.1rem 0 2.6rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
`;
const PriceInfo = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 2.9rem;
  color: #000;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;

const StoreInfo = styled.div`
  margin: 3.7rem 0;
  width: auto;
  height: auto;
  background-color: #F7F7F7;
  border-radius: 0.4rem;
`;
const StoreTitle = styled.div`
  padding: 1.6rem;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;
  border-bottom: 0.2rem solid #fff;
  & span {
    padding: 0 0.4rem;
    width: 6.6rem;
    height: 1.8rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #FD6258;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #FD6258;
    border-radius: 0.2rem;
  }
`;
const StoreDetail = styled.div`
  padding: 1.6rem;
  height: auto;
  display: flex;
  align-items: center;
  & img {
    width: 7.6rem;
    height: 7.6rem;
    object-fit: cover;
    background: #FFFFFF;
    border-radius: 4px;
  }
  & div.storeDetails {
    margin-left: 2.1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;
    & div.storeOwner {
      margin-bottom: 0.6rem;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
  }
`;

const PreviewInfo = styled.div`
  margin: 0rem 0;
  border: 0rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 0rem 0rem;
    width: 100%;
    & tr {
      width: 100%;
      height: 12rem;
      & td {
        width: 8.3rem;
        & div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & div.icon {
            width: 4.2rem;
            height: 4.2rem;
            & img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
          & div.label {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #424242;
          }
          & div.value {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #9E9E9E;
          }
        }
      }
    }
  }
`;

const AdditionalInfo = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & li {
    margin: 0.4rem;
    width: 47.7%;
    height: 3.2rem;
    @media screen and (max-width:389px) {
      width: 46.7%;
    }
    & div.checkbox-checked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #fd6258;
      }
    }
    & div.checkbox-unchecked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #9E9E9E;
      }
    }
  }
`;

const Content = styled.div`
  margin: 0;
  padding: 1rem 0;
  width: auto;
  height: 100%;
  overflow: auto;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;

  clear: both;
`;
