import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { NewsApi } from '../api/NewsApi';
import { NewsSearch } from '../component/NewsSearch';
import { NewsListComponent } from '../component/NewsListComponent';
import { Helmet } from 'react-helmet-async';

export const SpecialFeatureArticleList = () => {
  const newsApi = new NewsApi();
  const navigate = useNavigate();
  const location = useLocation();
  const SAVED_SEARCH_NAME = "specialFA_search";
  const [loading, setLoading] = useState(false);
  const [specialFeatureArticleList, setSpecialFeatureArticleList] = useState({list:[]});
  const [searchData, setSearchData] = useState(() => {
    const isBack = location.state?.isBack;
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(isBack && saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: 18,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          filter: null,
          title: null,
          filterToggle: false,
          scrollY: 0,
          viewMode: 'card',
        };
      }
    }
  });

  const handleSearchClick = () => {
    setLoading(true);
    newsApi.fetchSpecialFeatureArticleList(searchData,
      (data) => {
        setSpecialFeatureArticleList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth",
      });
    }, 400);
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.filter, searchData.title]);

  return (
    <ListWrapper>
      <Helmet>
        <title>{`기획특집 뉴스 - 밴쿠버 조선일보`}</title>
      </Helmet>
      <NewsSearch
        placeholder={'기사를 검색하세요.'}
        searchData={searchData}
        changeSearchData={changeSearchData}
        handleSearchClick={handleSearchClick}
      />
      <div className='list'>
        <NewsListComponent
          viewMode={searchData.viewMode}
          list={specialFeatureArticleList.list}
          link={'special-feature-article'}
          isLoading={loading}
          itemOnClick={handlerItemCardOnClick}
        />
      </div>
      <Pagination
        currentPage={searchData.page}
        totalPage={searchData.totalPageCount}
        perPageSize={searchData.perPageSize}
        handlePageChange={handlePageChange}
      />
      {/* <Loader loading={loading} /> */}
    </ListWrapper>
  );
}

const ListWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    margin: 0.5rem;
  }
`;
