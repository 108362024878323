import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInterval } from '../hooks/useInterval';

export const ErrorPage = () => {
  const navigate = useNavigate();

  const handlerOnClick = () => {
    navigate('/', {replace : true});
  }

  useInterval(()=> {
    handlerOnClick();
  }, 3000);

  return (
    <ErrorWrapper>
      <img src='/img/404-page-img.png' alt='페이지를 찾을 수 없습니다'/>
      <h1>원하시는 페이지를 찾을 수 없습니다.</h1>
      <p>
        찾으려는 페이지의 주소가 잘못 입력되었거나,<br/>
        주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.<br/>
        입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
      </p>
      <MarketButton onClick={()=>handlerOnClick()}>
        밴마켓 홈 바로가기
        <img src='\icon\angle-right-gray2-icon.png' alt='밴마켓 홈 바로가기'/>
      </MarketButton>
    </ErrorWrapper>
  );
}

const ErrorWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: 100%;
  min-height: 40rem;
  height: 73vh;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  & img {
    padding: 5rem 0 2.4rem;
    height: 14.26rem;
    width: 15.2rem;
    -webkit-user-drag: none;
  }
  & h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & p {
    padding: 1.7rem 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    letter-spacing: -0.04em;
    color: #9E9E9E;
  }
`;

const MarketButton = styled.div`
  margin: 0 auto;
  padding: 0 0 0 1rem;
  width: 16.9rem;
  height: 3.6rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #616161;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  border: 0.1rem solid #E0E0E0;
  border-radius: 30rem;
  cursor: pointer;
  & img {
    margin: 0;
    padding: 0.2rem 0 0 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;