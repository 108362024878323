import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { RadioComponents } from '../../../common/RadioComponents';
import { RadioButtonComponents } from '../../../common/RadioButtonComponents';
import { RendMoveInDateComponent } from './component/RendMoveInDateComponent';
import { SelectComponent } from '../../../common/SelectComponents';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { CustomCheckbox } from './component/CustomCheckbox';
import { RentTypesModal } from './component/RentTypesModal';
import { RentRoomTypesModal } from './component/RentRoomTypesModal';
import { Loader } from '../../../common/Loader';
import { MarketEditor } from '../component/MarketEditor';
import { RentApi } from '../api/RentApi';
import { UserApi } from '../../user/api/UserApi';
import { addHyphenForPhoneNumber, autoAddHyphenForPhoneNumber, isBlankValue, isPositiveOrZero, isValidEmail } from '../../../utils/utils';
import {
  RealEstateCities,
  RealEstateProvinces,
  RentContacts,
  RentRoomTypes,
  RentTypes,
  RentBathroomNumbers,
  RentNeedDeposit,
  RentLeaseTerms,
  RentDetailedOptions,
  RentPaymentFrequency,
  RentStatus,
  RentBedroomNumberList,
} from '../../../__mocks__/PostData';

export const RentDetailEdit = ({setDialog}) => {
  const rentApi = new RentApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const [showCalender, setShowCalender] = useState(false);
  const [showRentTypeModal, setShowRentTypeModal] = useState(false);
  const [showRentRoomTypeModal, setShowRentRoomTypeModal] = useState(false);
  const [needDeposit, setNeedDeposit] = useState(false);
  const [noneSize, setNoneSize] = useState(false);
  const [noneBuiltIn, setNoneBuiltIn] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [isNewPost] = useState(location?.state?.id?false:true);
  const [article, setArticle] = useState({
    id: location?.state?.id?location?.state?.id:'',
    boardId: 21,
    active: 0,
    name: '',
    phone: '',
    email: '',
    contactCode: 'phone',
    title: '',
    description: '',
    locationId: '1',
    locationSubId: '1',
    address: '',
    postal: '',
    type: '',
    roomType:'',
    bedroomCount: 0,
    bathroomCount: 0,
    size: 0,
    builtIn: 0,
    paymentFrequency: '',
    price: 0,
    deposit: 0,
    moveInType: 'immediate-moveIn',
    moveInDate: '',
    leaseTerm: '1달 미만',
    detailedOptions: '',
    hitCount: 0,
    isSoldOut: false,
    linkUrl: '',
    isPremium: false,
    premiumStart: '',
    premiumEnd: '',
    postedDate: '',
    updateDate: '',
    payType: '410',
    images: {},
  });

  // == state 변경 ------------------------------------------------
  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeArticleImages = (index, imageData) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = {};
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleStepOnClick = (step, id='') => {
    const checkId = (id) ? id : article.id;
    if(2 === step && !checkId) {
      return;
    } else {
      setStep(step);
    }
  }

  const handleTitleOnChange = (value) => {
    changeArticleData("title", value);
  }

  const handleNameOnChange = (value) => {
    changeArticleData("name", value);
  }

  const handleEmailOnChange = (value) => {
    changeArticleData("email", value);
  }

  const handlePhoneNumberOnChange = (value) => {
    changeArticleData("phone", autoAddHyphenForPhoneNumber(value));
  };

  const handleLocationIdOnChange = ({target}) => {
    changeArticleData('locationId', target.value);
  }

  const handleLocationSubIdOnChange = ({target}) => {
    changeArticleData('locationSubId', target.value);
  }

  const handleAddressOnChange = ({target}) => {
    changeArticleData("address", target.value);
  };

  const handlePostalOnChange = ({target}) => {
    changeArticleData("postal", target.value);
  };

  const handleTypeOnChange = (value) => {
    changeArticleData('type', value);
    toggleRentTypeModal();
    toggleRentRoomTypeModal();
  };

  const handleRoomTypeOnChange = (value) => {
    changeArticleData('roomType', value);
    toggleRentRoomTypeModal();
  };

  const handleContactCodeOnChange = ({target}) => {
    changeArticleData('contactCode', target.value);
  };

  const handlePriceOnChange = (value) => {
    // changeArticleData("price", value.replace(/[^.0-9]/g, ""));
    changeArticleData("price", value);
  }

  const handleBedroomCountOnChange = ({target}) => {
    changeArticleData('bedroomCount', target.value);
  }

  const handleBathroomCountOnChange = ({target}) => {
    changeArticleData('bathroomCount', target.value);
  }

  const handleBuiltInOnChange = ({target}) => {
    if(0<target.value) setNoneBuiltIn(false);
    changeArticleData("builtIn", target.value);
  }

  const handleNoneBuiltInOnChange = () => {
    if(!noneBuiltIn) changeArticleData("builtIn", 0);
    setNoneBuiltIn(!noneBuiltIn);
  }

  const handleRentSizeOnChange = ({target}) => {
    if(0<target.value) setNoneSize(false);
    changeArticleData("size", target.value);
  }

  const handleNoneRentSizeOnChange = () => {
    if(!noneSize) changeArticleData("size", 0);
    setNoneSize(!noneSize);
  }

  const handleRentPaymentFrequencyOnChange = ({target}) => {
    if('' === target.value) {
      setPriceDisabled(true);
      handlePriceOnChange(0);
    } else {
      setPriceDisabled(false);
    }
    changeArticleData("paymentFrequency", target.value);
  }

  const handleDepositOnChange = (value) => {
    changeArticleData("deposit", value);
  }

  const handleMoveInTypeOnChange = (value) => {
    changeArticleData("moveInType", value);
  }

  const handleMoveInDateOnChange = (value) => {
    changeArticleData("moveInDate", value);
  }

  const handlerToggleShowCalender = () => {
    setShowCalender(!showCalender);
  }

  const handleLeaseTermOnChange = ({target}) => {
    changeArticleData("leaseTerm", target.value);
  }

  const handleDetailedOptionsOnChange = (value) => {
    changeArticleData("detailedOptions", value);
  }

  const handleRentStatusOnChange = (value) => {
    changeArticleData("isSoldOut", value);
  }

  const handleNeedDepositOnChange = () => {
    if(needDeposit) changeArticleData("deposit", 0);
    setNeedDeposit(!needDeposit);
  }

  const toggleRentTypeModal = () => {
    setShowRentTypeModal(!showRentTypeModal);
  }

  const toggleRentRoomTypeModal = () => {
    setShowRentRoomTypeModal(!showRentRoomTypeModal);
  }

  const handleSearchClick = () => {
    if(article.id) {
      setLoading(true);

      const formData = new FormData();
      formData.append('id', article.id);

      rentApi.fetchRentEditById(article.id, formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          setArticle(result);
        },
        (error) => {
          setLoading(false);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1, {state: {isBack: true}});
        }
      );
    } else {
      userApi.fetchUser(
        (data) => {
          const { result: {nickName, contactEmail, phone} } = data;
          changeArticleData("name", nickName);
          changeArticleData("phone", phone);
          changeArticleData("email", contactEmail);
        },
        (error) => {
          console.log(error);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              // setDialog({hasError: true, message: `${message}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              // setDialog({hasError: true, message: `${statusText}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const uploadImageFile = (file, index) => {
    if(article.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', article.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      rentApi.createRentImageFile(
        article.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(article.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': article.id,
      });

      rentApi.deleteRentImageFile(
        article.id
        , deleteImageFileData
        , () => {
          setLoading(false);
          deleteArticleImages(index);
        }
        , () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const handleSaveForStep1OnClick = () => {
    const id = article.id;
    if(id) {
      saveForStep1();
    } else {
      createForStep1();
    }
  }

  const createForStep1 = () => {
    if(validateArticleDataForStep1ForCreate()) {
      setLoading(true);

      const { id, boardId, active,
        name, phone, email, contactCode,
        title, locationId, locationSubId, postal, address,
        type, roomType, bedroomCount, bathroomCount,
        size, builtIn, paymentFrequency, price, deposit,
        moveInType, moveInDate, leaseTerm, detailedOptions,
      } = article;

      const createData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'locationId': locationId,
        'locationSubId': locationSubId,
        'postal': postal,
        'address': address,
        'type': type,
        'roomType': roomType,
        'bedroomCount': bedroomCount,
        'bathroomCount': bathroomCount,
        'size': size,
        'builtIn': builtIn,
        'paymentFrequency': paymentFrequency,
        'price': price,
        'deposit': deposit,
        'moveInType': moveInType,
        'moveInDate': moveInDate,
        'leaseTerm': leaseTerm,
        'detailedOptions': detailedOptions,
      });

      rentApi.createRentForStep1(createData,
        (data) => {
          setLoading(false);
          changeArticleData('id', data.id);
          handleStepOnClick(2, data.id);
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { message, errors } = data;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const saveForStep1 = () => {
    if(validateArticleDataForStep1() && window.confirm("Step1 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active,
        name, phone, email, contactCode,
        title, locationId, locationSubId, postal, address,
        type, roomType, bedroomCount, bathroomCount,
        size, builtIn, paymentFrequency, price, deposit,
        moveInType, moveInDate, leaseTerm, detailedOptions,
      } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'locationId': locationId,
        'locationSubId': locationSubId,
        'postal': postal,
        'address': address,
        'type': type,
        'roomType': roomType,
        'bedroomCount': bedroomCount,
        'bathroomCount': bathroomCount,
        'size': size,
        'builtIn': builtIn,
        'paymentFrequency': paymentFrequency,
        'price': price,
        'deposit': deposit,
        'moveInType': moveInType,
        'moveInDate': moveInDate,
        'leaseTerm': leaseTerm,
        'detailedOptions': detailedOptions,
      });

      rentApi.saveRentForStep1(id, updateData,
        (data) => {
          handleSearchClick();
          // setDialog({isOpen: true, message: "저장하였습니다.", type: "alert"});
          handleStepOnClick(2);
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { message, errors } = data;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const handleSaveForStep2OnClick = () => {
    if(window.confirm(isNewPost?"결제 하시겠습니까?":"Step2 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active, payType, isSoldOut } = article;
      const step2Data = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'description': editorRef.current.getContent(),
        'payType': payType,
        'isSoldOut': isSoldOut,
      });

      if(isNewPost) {
        rentApi.createRentForStep2(id, step2Data,
          (data) => {
            setLoading(false);
            setDialog({isOpen: true, message: "등록 하였습니다.", type: "alert"});
            if('410' === payType) {
              handleExtendPostOnClick();
            } else {
              navigate(`/rent-homestay`, {replace : true});
            }
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      } else {
        rentApi.saveRentForStep2(id, step2Data,
          (data) => {
            handleSearchClick();
            setDialog({isOpen: true, message: "저장하였습니다.", type: "alert"});
            navigate(`/rent/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      }
    }
  }

  const handleExtendPostOnClick = () => {
    const { id, boardId, payType } = article;
    navigate('/market-payment-step1',
      {state: {
        refType: 'rent',
        refPostId: id,
        refBoardId: boardId,
        itemId: payType,
        backLink: `/rent/${id}`,
        cancelLink: `/rent-homestay`,
      }}
    );
  }

  // ==== valid check ------------------------------------------------
  const isStep1 = () => {
    return (step === 1) ? true : false;
  }

  const isStep2 = () => {
    return (step === 2) ? true : false;
  }

  const validateArticleDataForStep1ForCreate = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "임대인은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "연락처는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.locationId)) {
      setDialog({isOpen: true, message: "주 or Province는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.locationSubId)) {
      setDialog({isOpen: true, message: "도시 or City는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.address)) {
      setDialog({isOpen: true, message: "주소는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.postal)) {
      setDialog({isOpen: true, message: "우편번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.type)) {
      setDialog({isOpen: true, message: "랜트 유형은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "렌트비는 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateArticleDataForStep1 = () => {
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "임대인은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "연락처는 필수입니다.", type: "alert"});
      return false;
    }

    if(isBlankValue(article.locationId)) {
      setDialog({isOpen: true, message: "주 or Province는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.locationSubId)) {
      setDialog({isOpen: true, message: "도시 or City는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.address)) {
      setDialog({isOpen: true, message: "주소는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.postal)) {
      setDialog({isOpen: true, message: "우편번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.type)) {
      setDialog({isOpen: true, message: "구분은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "렌트비는 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // render ------------------------------------------------
  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <StepGroup>
        <div className={`${isStep1() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(1)}>Step 1</div>
        <div className={`${isStep2() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(2)}>Step 2</div>
      </StepGroup>
      {isStep1() && (
        <>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>임대인 정보를 입력해주세요</div>
              <InputComponent
                label={'임대인'}
                id={'name'}
                type={'text'}
                checkType={'text'}
                value={article.name}
                onChange={handleNameOnChange} />
              <InputComponent
                label={'전화번호'}
                id={'phone'}
                type={'text'}
                checkType={'text'}
                value={addHyphenForPhoneNumber(article.phone)}
                maxLength={13}
                placeholder={'00*-00*-0000'}
                onChange={handlePhoneNumberOnChange} />
              <InputComponent
                label={'이메일'}
                id={'email'}
                type={'email'}
                checkType={'email'}
                value={article.email}
                placeholder={'입력하세요.'}
                onChange={handleEmailOnChange} />
              <RadioComponents
                label={'연락망 선택'}
                id={'contactCode'}
                value={article.contactCode||''}
                objectData={RentContacts}
                onChange={handleContactCodeOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>렌트 정보를 입력해주세요</div>
              <InputComponent
                label={'제목'}
                id={'title'}
                type={'text'}
                checkType={'text'}
                value={article.title}
                placeholder={'입력하세요.'}
                onChange={handleTitleOnChange} />

              <LocationComponent>
                <div className='title'>지역</div>
                <div className='locationGroup'>
                  <select
                    id={'locationId'}
                    value={article.locationId||''}
                    onChange={handleLocationIdOnChange}
                  >
                    {Object.keys(RealEstateProvinces).map((key) =>
                      <option key={key} value={RealEstateProvinces[key].value}>{RealEstateProvinces[key].label}</option>
                    )}
                  </select>
                  <select
                    id={'locationSubId'}
                    value={article.locationSubId||''}
                    onChange={handleLocationSubIdOnChange}
                  >
                    {Object.keys(RealEstateCities).map((key) =>
                      <option key={key} value={RealEstateCities[key].value}>{RealEstateCities[key].label}</option>
                    )}
                  </select>
                </div>
              </LocationComponent>
              <SelectComponent
                label={'거래지역'}
                id={'location'}
                value={article.location}
                objectData={RealEstateCities}
                placeholder={'거래지역 선택'}
                onChange={handleLocationSubIdOnChange} />

              <AddressComponent>
                <div className='title'>
                  상세주소
                </div>
                <div className='postalGroup'>
                  <div className='postalLabel'>
                    우편번호
                  </div>
                  <input className='postalInput'
                    id={'postal'}
                    type={'text'}
                    value={article.postal||''}
                    placeholder={'예) V3N 4R7'}
                    onChange={handlePostalOnChange}
                    autoComplete="off"
                    required/>
                </div>
                <div className='addressGroup'>
                  <input className='addressInput'
                    id={'address'}
                    type={'text'}
                    value={article.address||''}
                    placeholder={'예) 331A-4501 North Road'}
                    onChange={handleAddressOnChange}
                    autoComplete="off"
                    required/>
                </div>
              </AddressComponent>

              <RentTypeComponent>
                <label htmlFor='type'>렌트유형</label>
                <div className='rentTypeDisplay' onClick={()=>toggleRentTypeModal()}>
                  <span>전체</span>
                  <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
                  <span>{RentTypes[article.type]?.label}</span>
                  {RentRoomTypes[article.roomType]?.label && (
                    <>
                      <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
                      <span>{RentRoomTypes[article.roomType]?.label}</span>
                    </>
                  )}
                  <img className='rentTypeButton' src='/icon/angle-right-gray-icon.png' alt='arrow right' />
                </div>
                <RentTypesModal
                  title={"카테고리"}
                  types={RentTypes}
                  value={article.type}
                  showModal={showRentTypeModal}
                  toggleModal={toggleRentTypeModal}
                  handlerValueOnChange={(value)=>handleTypeOnChange(value)}
                />
                <RentRoomTypesModal
                  title={"카테고리"}
                  types={RentRoomTypes}
                  rentTypes={RentTypes}
                  rentTypeValue={article.type}
                  value={article.roomType}
                  showModal={showRentRoomTypeModal}
                  toggleModal={toggleRentRoomTypeModal}
                  handlerValueOnChange={(value)=>handleRoomTypeOnChange(value)}
                />
              </RentTypeComponent>

              <RoomComponent>
                <div className='title'>방/욕실 개수</div>
                <div className='roomGroup'>
                  <select
                    id={'bedroomCount'}
                    value={article.bedroomCount||0}
                    onChange={handleBedroomCountOnChange} >
                    {RentBedroomNumberList.map((rentBedroomNumberObj) =>
                      <option key={rentBedroomNumberObj.value} value={rentBedroomNumberObj.value}>
                        {rentBedroomNumberObj.label}
                      </option>
                    )}
                  </select>
                  <select
                    id={'bathroomCount'}
                    value={article.bathroomCount||0}
                    onChange={handleBathroomCountOnChange} >
                    {Object.keys(RentBathroomNumbers).map((key) =>
                      <option key={key} value={RentBathroomNumbers[key].value}>
                        {RentBathroomNumbers[key].label}
                      </option>
                    )}
                  </select>
                </div>
              </RoomComponent>

              <RentSizeComponent>
                <div className='title'>전용면적</div>
                <div className='rentSizeGroup'>
                  <input id='size'
                    type='number'
                    placeholder='예) 1500'
                    value={article.size}
                    onChange={handleRentSizeOnChange}
                    autoComplete="off"
                    required
                    disabled={noneSize} />
                  <div>Sqft</div>
                </div>
                <div className='noneCheck'>
                  <input
                    id='noneRentSize'
                    type='checkbox'
                    onChange={()=>handleNoneRentSizeOnChange()}
                    checked={noneSize} />
                  <label htmlFor='noneRentSize'>정보없음</label>
                </div>
              </RentSizeComponent>

              <BuiltInGroupComponent>
                <div className='title'>건축년도</div>
                <div className='builtInGroup'>
                  <input id='builtIn'
                    type='number'
                    placeholder='예) 2022'
                    value={article.builtIn}
                    onChange={handleBuiltInOnChange}
                    autoComplete="off"
                    required
                    disabled={noneBuiltIn} />
                  <div>년</div>
                </div>
                <div className='noneCheck'>
                  <input id='noneBuiltIn'
                    type='checkbox'
                    onChange={()=>handleNoneBuiltInOnChange()}
                    checked={noneBuiltIn} />
                  <label htmlFor='noneBuiltIn'>정보없음</label>
                </div>
              </BuiltInGroupComponent>

              <PriceGroupComponent>
                <div className='title'>가격</div>
                <div className='PriceGroup'>
                  <select
                    id={'paymentFrequency'}
                    value={article.paymentFrequency||''}
                    onChange={handleRentPaymentFrequencyOnChange}
                  >
                    {Object.keys(RentPaymentFrequency).map((key) =>
                      <option key={key} value={RentPaymentFrequency[key].value}>
                        {RentPaymentFrequency[key].label}
                      </option>
                    )}
                  </select>
                  <input
                    id='price'
                    type='number'
                    value={article.price||0}
                    onChange={(e) => handlePriceOnChange(e.target.value)}
                    autoComplete="off"
                    required
                    disabled={priceDisabled} />
                </div>
              </PriceGroupComponent>

              <DepositGroupComponent>
                <RadioButtonComponents
                  label={'디파짓'}
                  id={'needDeposit'}
                  value={needDeposit}
                  objectData={RentNeedDeposit}
                  width={'48%'}
                  onChange={() => handleNeedDepositOnChange()} />
                <input className='depositInput'
                  id='deposit'
                  type='number'
                  value={article.deposit||0}
                  onChange={(e) => handleDepositOnChange(e.target.value)}
                  autoComplete="off"
                  disabled={!needDeposit} />
              </DepositGroupComponent>
              <RendMoveInDateComponent
                label={'입주날짜'}
                moveInType={article.moveInType}
                moveInDate={article.moveInDate}
                showCalender={showCalender}
                handleMoveInTypeOnChange={handleMoveInTypeOnChange}
                handleMoveInDateOnClick={handlerToggleShowCalender}
                handleMoveInDateOnChange={handleMoveInDateOnChange} />
              <SelectComponent
                label={'계약기간'}
                id={'leaseTerm'}
                value={article.leaseTerm}
                objectData={RentLeaseTerms}
                placeholder={'계약기간을 선택하세요'}
                onChange={handleLeaseTermOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>상세 옵션을 선택해주세요</div>
              <AdditionalInfo>
                {Object.keys(RentDetailedOptions).map(key=>
                  <li key={key}>
                    <CustomCheckbox
                      label={RentDetailedOptions[key].label}
                      name={'detailedOptions'}
                      value={RentDetailedOptions[key].value}
                      valuesString={article.detailedOptions}
                      handleValueOnChange={handleDetailedOptionsOnChange} />
                  </li>
                )}
              </AdditionalInfo>
            </ArticleForm>
            <ArticleForm>
              <NextButton
                type='button'
                color="#fff"
                backgroundColor='#fd6258'
                onClick={() => handleSaveForStep1OnClick()}>
                {isNewPost? '다음' : '수정하기'}
              </NextButton>
            </ArticleForm>
          </ContentWrap>
        </>
      )}
      {isStep2() && (
        <ContentWrap>
          <ArticleForm>
            <ImageUploadComponent
              images={article.images}
              uploadProcess={uploadImageFile}
              deleteProcess={deleteImageFile}
              setDialog={setDialog}
            />
            <Label htmlFor='description'>
              상세내용
              <span>(선택)</span>
            </Label>
            <MarketEditor
              id={'description'}
              editorRef={editorRef}
              placeholder={'숙박 상세 내용을 입력해주세요. 예) 방 구조, 관리 상태, 교통, 부대시설, 주변 인프라 등'}
              description={article.description}
              disabled={false}
            />
            <Notification>
              * 렌트 광고글 외에 주제와 맞지 않는 내용을 기재하면 사전통지 없이 삭제됩니다.
            </Notification>
            <RadioButtonComponents
              label={'렌트상태'}
              subLabel={'(선택)'}
              id={'isSoldOut'}
              value={article.isSoldOut}
              objectData={RentStatus}
              width={'48%'}
              onChange={handleRentStatusOnChange}
              />
          </ArticleForm>
          <NextButton
            type='button'
            color="#fff"
            backgroundColor='#fd6258'
            onClick={() => handleSaveForStep2OnClick()}>
            {isNewPost? '결제하기' : '수정하기'}
          </NextButton>
          {(!isNewPost) && (
            <PostExtendGroup>
              <input type='text'
                id='PostExtend'
                value={`${article?.premiumStart ? new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(new Date(article?.premiumStart)) : '시작일'} ~ ${article?.premiumEnd ? new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(new Date(article?.premiumEnd)) : '종료일'}`}
                readOnly/>
              <button type='button'
                onClick={() => handleExtendPostOnClick()}>
                광고 연장신청
              </button>
            </PostExtendGroup>
          )}
        </ContentWrap>
      )}
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;
const StepGroup = styled.div`
  padding: 1.3rem 1.6rem 0;
  display: flex;
  justify-content: center;
  & div.step {
    width: 50%;
    height: 4.2rem;

    font-size: 14px;
    font-weight: 700;
    line-height: 4.2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  & div.step:first-child {
    border: 0.1rem solid #eee;
    border-radius: 0.4rem 0rem 0rem 0.4rem;
  }
  & div.step:last-child {
    border: 0.1rem solid #eee;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
  }
  & div.step.active {
    color: #fd6258;
    background-color: #ffeae9;
    border-color: #ffeae9;
  }
`;

const ContentWrap = styled.div`
  padding: 1.4rem;
  height: 77%;
`;

const ArticleForm = styled.div`
  margin-bottom: 0.5rem;
  & div.articleLabel {
    margin: 2rem 0rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const NextButton = styled.button`
  margin: 3.6rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const Label = styled.label`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 2.3rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const LocationComponent = styled.div`
  height: 6.5rem;

  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.locationGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & select {
      width: 16.8rem;
      height: 3.8rem;
      background: #FFFFFF;
      border: 0.1rem solid #EEEEEE;
      border-radius: 0.4rem;
    }
  }
`;

const AddressComponent = styled.div`
  margin: 2rem 0;
  height: 10.4rem;
  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.postalGroup {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & div.postalLabel {
      width: 12.8rem;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
    }
    & input.postalInput {
      margin-left: 1rem;
      width: 50%;
      height: 2.2rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;

      border: 0rem solid #F5F5F5;
      border-bottom: 0.1rem solid #F5F5F5;
    }
    & input.postalInput:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
  }
  & div.addressGroup {
    & input.addressInput {
      width: 100%;
      height: 2.2rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;

      border: 0rem solid #F5F5F5;
      border-bottom: 0.1rem solid #F5F5F5;
    }
  }
`;

const RentTypeComponent = styled.div`
  margin: 2rem 0;
  height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div.rentTypeDisplay {
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 0.1rem solid #F5F5F5;
    & span {
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
    & img.rentTypeButton {
      position: absolute;
      top: 0.8rem;
      right: 0rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const RoomComponent = styled.div`
  margin: 2rem 0;
  height: 8.5rem;

  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.roomGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & select {
      width: 16.8rem;
      height: 3.8rem;
      background: #FFFFFF;
      border: 0.1rem solid #EEEEEE;
      border-radius: 0.4rem;
    }
  }
`;

const RentSizeComponent = styled.div`
  margin: 2rem 0;
  height: 10.5rem;

  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.rentSizeGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & input {
      padding-right: 1rem;
      width: 16.8rem;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
      text-align: right;

      background: #FFFFFF;
      border: 0.1rem solid #EEEEEE;
      border-radius: 0.4rem;
      &:disabled {
        background-color: #eee;
      }
    }
    & div {
      margin-left: 0.8rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
  & div.noneCheck {
    height: 3.2rem;
    display: flex;
    align-items: center;
    & input[type=checkbox] {
      accent-color: #f86258;
    }
    & label {
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
`;

const BuiltInGroupComponent = styled.div`
  margin: 2rem 0;
  height: 10.5rem;

  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.builtInGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & input {
      padding-right: 1rem;
      width: 16.8rem;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
      text-align: right;

      border: 0.1rem solid #EEEEEE;
      border-radius: 0.4rem;
      &:disabled {
        background-color: #eee;
      }
    }
    & div {
      margin-left: 0.8rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
  & div.noneCheck {
    height: 3.2rem;
    display: flex;
    align-items: center;
    & input[type=checkbox] {
      accent-color: #f86258;
    }
    & label {
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
`;

const PriceGroupComponent = styled.div`
  margin: 2rem 0;
  height: 8.5rem;

  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & select {
      margin-right: 0.8rem;
      padding-left: 1.6rem;
      width: 35%;
      height: 4.15rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }

    & input {
      padding-right: 0.8rem;
      min-width: 21rem;
      width: 65%;
      height: 3.8rem;
      box-sizing: border-box;

      font-size: 14px;
      font-weight: 400;
      line-height: 3.8rem;
      letter-spacing: -0.04em;
      color: #424242;
      text-align: right;

      background-image: url('/icon/dollar-icon.png');
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position: 1.6rem center;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }
    & input:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.success {
      color: ${({ theme }) => theme.colors.success};
      border-color: ${({ theme }) => theme.colors.borderSuccess};
    }
    & input.success:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.error {
      border-color: ${({ theme }) => theme.colors.errorBorder};
    }
    & input.error:focus-visible {
      outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
    }
  }
`;

const DepositGroupComponent = styled.div`
  margin: 2rem 0;
  height: 13.5rem;
  width: auto;
  & div.title {
    margin-bottom: 1.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & input.depositInput {
    padding-right: 0.8rem;
    width: 100%;
    height: 3.8rem;
    box-sizing: border-box;

    font-size: 14px;
    font-weight: 400;
    line-height: 3.8rem;
    letter-spacing: -0.04em;
    color: #424242;
    text-align: right;

    background-image: url('/icon/dollar-icon.png');
    background-repeat: no-repeat;
    background-size: 0.7rem;
    background-position: 1.6rem center;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
  }
`;

const AdditionalInfo = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & li {
    margin: 1.4rem 0.3rem;
    padding: 0;
    width: 48%;
  }
`;

const Notification = styled.div`
  margin-top: 1.6rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #FD6258;
`;

const PostExtendGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    padding-right: 0.8rem;
    width: 60%;
    height: 4rem;

    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #616161;
    text-align: center;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }

  & button {
    width: 35%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    background: #F5F5F5;
    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
    cursor: pointer;
  }
`;
