import React from 'react';
import { YellowpageGridComponent } from './YellowpageGridComponent';
import { YellowpagePostListComponent } from './YellowpagePostListComponent';

export const YellowpageListComponent = ({ viewMode, list, link, isLoading=true, postOnClick}) => {

  return (
    <>
      {'grid' === viewMode ? (
        <YellowpageGridComponent list={list} link={link} isLoading={isLoading} postOnClick={postOnClick} />
      ) : (
        <YellowpagePostListComponent list={list} link={link} isLoading={isLoading} postOnClick={postOnClick} />
      )}
    </>
  );
}