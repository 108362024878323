import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { elapsedText, priceToString } from '../../../utils/utils';
import { Cities } from '../../../__mocks__/PostData';

export const MainBuySells = ({list}) => {
  const navigate = useNavigate();
  const [setSwiperRef] = useState(null);

  const handleButtonLinkOnClick = (e, url) => {
    navigate(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-buysell.png`;
  }

  const isPremium = (premium) => {
    return ("1" === premium) ? true : false;
  }

  const addPriceTag = ({premium, price, type}) => {
    if(isPremium(premium)) {
      return '';
    } else if('무료나눔' === type) {
      return type;
    } else {
      return (0 < price) ? priceToString(price) : '가격문의';
    }
  }

  return (
    <>
      <MainBuySellSection>
        <Header>
          <h2>가까운 이웃들과<br/>중고 거래를 해보세요</h2>
          <span><Link to={`/buysell`}>전체보기</Link></span>
        </Header>
        <MySwiper
          onSwiper={setSwiperRef}
          slidesPerView={2.4}
          spaceBetween={10}
          freeMode={true}
          >
          {list.map((row) => (
            <SwiperSlide key={row.id}>
              <Item onClick={(e)=>handleButtonLinkOnClick(e, `/buysell/${row.id}`)}>
                <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading='lazy' />
                <h3>{row.title}</h3>
                <div className='info'>
                  <span className='location'>{Cities[row.locationCode]?.label}</span>
                  <img className='period' src='/icon/period-icon.png' alt='period'/>
                  <span className='date'>{elapsedText(row.updatedDate)}</span>
                </div>
                <div className='price'>
                  {addPriceTag(row)}
                </div>
              </Item>
            </SwiperSlide>
          ))}
        </MySwiper>
      </MainBuySellSection>
    </>
  )
};

const MainBuySellSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding-bottom: 2.4rem;
  width: 100%;
  height: auto;
  background-color: #fff;
`;

const Header = styled.div`
  padding: 2.4rem 1.6rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;

const MySwiper = styled(Swiper)`
  padding-left: 1.6rem;
  width: auto;
`;

const Item = styled.div`
  width: 14.4rem;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  & img {
    width: 14.4rem;
    height: 10.4rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  & h3 {
    margin: 0;
    padding: 1rem 0 0.1rem 0;
    height: 2rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div.info {
    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & span.location {
      height: 1.7rem;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      color: #9E9E9E;
    }
    & span.date {
      height: 1.7rem;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      color: #9E9E9E;
    }
    & img.period {
      margin: 0 0.4rem;
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  & div.price {
    padding-top: 0.7rem;
    height: 2rem;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    color: #000000;
  }
`;