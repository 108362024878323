import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdsApi } from './api/AdsApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';

export const MarketPickAd = ({position, listSize=1}) => {
  SwiperCore.use([Autoplay, Pagination]);
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleAdsSearch = () => {
    adsApi.fetchRandomAdsList(position, listSize, (data)=> {
      setAds(data);
    })
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  useEffect(() => {
    handleAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <Header>
        <h2>밴마켓's PICK </h2>
      </Header>
      <MySwiper
        slidesPerView={1.8}
        autoplay={ {"delay": 6000} }
        modules={[Autoplay, Pagination]}
      >
        {ads?.list.map((row) => (
          <SwiperSlide key={row.id}>
            <ItemCard onClick={()=>handleOpenDetail(row)}>
              <ImageWrapper>
                <img src={`${row.imageFile1}`} alt={row?.title} onError={(e) => handleImageError(e)} />
              </ImageWrapper>
            </ItemCard>
          </SwiperSlide>
        ))}
      </MySwiper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  margin: 0 auto;
  padding-left: 1.6rem;
  padding-bottom: 2.4rem;
  width: auto;
  height: auto;
  background-color: #fff;
`;

const Header = styled.div`
  position: relative;
  padding: 2.4rem 0rem;
  display: flex;
  & h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
  }
`;

const MySwiper = styled(Swiper)`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const ItemCard = styled.div`
  position: relative;
  width: 18.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const ImageWrapper = styled.div`
  width: 18.8rem;
  height: 18.8rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;
