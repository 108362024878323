import React, { useState } from 'react';
import styled from 'styled-components';

export const CommunityReplyActionComponent = ({editOnClick, deleteOnClick, showMoreOnClick}) => {
  const [show, setShow] = useState(false);

  const handleToggleActionModal = () => {
    setShow(!show);
    showMoreOnClick?.();
  }

  const handlerEditOnClick = () => {
    editOnClick?.();
    // handleToggleActionModal();
  }

  const handlerDeleteOnClick = () => {
    deleteOnClick?.();
    // handleToggleActionModal();
  }

  return (
    <ReplyActionWrapper>
      <img src='/icon/ellipsis-icon.png' alt='생략' onClick={()=>handleToggleActionModal()} />
      <ReplyActionList show={show}>
        <ul>
          <li onClick={handlerEditOnClick}>
            <div>수정</div>
          </li>
          <li onClick={handlerDeleteOnClick}>
            <div>삭제</div>
          </li>
        </ul>
      </ReplyActionList>
    </ReplyActionWrapper>
  );
};

const ReplyActionWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const ReplyActionList = styled.div`
  display: ${props => props.show ? '':'none'};
  position: absolute;
  top: 3.4rem;
  right: 0;
  width: 9.6rem;
  z-index: 100;
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
    & li {
      margin: 0rem;
      display: flex;
      align-items: center;
      border-bottom: 0.1rem solid #eee;
      & div {
        margin-left: 0.8rem;
        height: 4.2rem;
        color: #747474;
        font-size: 1.4rem;
        line-height: 4.2rem;
        cursor: pointer;
      }
    }
    & li.active {
      & div {
        color: #FD6258;
        font-weight: bold;
        background-color: #FFEAE9;
      }
    }
    & li:last-child {
      border: 0;
    }
  }
`;