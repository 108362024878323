import React from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../../common/SkeletonComponent';
import { elapsedText } from '../../../../utils/utils';
import { CommunityBoards } from '../../../../__mocks__/PostData';

export const CommunityPostListComponent = ({ list, link, type, startPoint, perView, isLoading=true, postOnClick }) => {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const isNewPost = (strDate) => {
    const seconds = 1; // 초 (밀리초)
    const minute = seconds * 60; // 분
    const hour = minute * 60; // 시
    const day = hour * 24; // 일
    const newCountDays = 1; // New 일수
    const postDate = new Date(strDate.replace('/','-')).getTime();
    const toDay = new Date().getTime();
    const elapsedTime = Math.trunc((toDay - postDate) / 1000);
    if(elapsedTime < (day * newCountDays)) {
      return true;
    } else {
      return false;
    }
  };

  const isImmigrant = () => {
    if('immigrant' === type) {
      return true;
    }
    return false;
  }

  const renderPostItems = (startPoint, perView, list) => {
    let result = [];
    if(list.length > startPoint) {
      const loopStart = startPoint;
      const loopEnd = (list.length < (perView + startPoint))? list.length: perView + startPoint;
      for(let i=loopStart; i<loopEnd; i++) {
        let row = list[i];
        result.push (
          <PostItem key={row.id}>
            <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
              <BoardName>{CommunityBoards[row?.boardId]?.label ? CommunityBoards[row?.boardId]?.label : row?.boardId}</BoardName>
              <InfoWrapper width={isImmigrant()?'100%':'80%'}>
                <div className='infoHeader'>
                  <h2>{row.title}</h2>
                  {0<row.replyCount && (
                    <span className='replyCount'>{`(${row.replyCount})`}</span>
                  )}
                  {isNewPost(row?.postDate) && (
                    <img src='/icon/new-icon.png' alt='New post'/>
                  )}
                </div>
                <div>
                  {row.name && (
                    <span>{row.name}</span>
                  )}
                  <span className='postedDate'>{elapsedText(row?.postDate)}</span>
                  <span>조회수 {row.hitCount}</span>
                </div>
              </InfoWrapper>
              {!isImmigrant() && row.thumbnailImageFile && (
                <ImageWrapper>
                  <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                </ImageWrapper>
              )}
            </div>
          </PostItem>
        )
      }
    }
    return result;
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <BoardName style={{marginBottom: '0.4rem', width: '1.8rem', height: '1.3rem', backgroundColor: '#F5F5F5'}}></BoardName>
                <InfoWrapper width={'80%'}>
                  <div className='infoHeader'>
                    <h2 style={{width: '70%', height: '2rem', backgroundColor: '#F5F5F5'}}> </h2>
                  </div>
                  <div style={{marginTop: '0.4rem', width: '80%', height: '1.7rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
                <ImageWrapper></ImageWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <BoardName style={{marginBottom: '0.4rem', width: '1.8rem', height: '1.3rem', backgroundColor: '#F5F5F5'}}></BoardName>
                <InfoWrapper width={'80%'}>
                  <div className='infoHeader'>
                    <h2 style={{width: '70%', height: '2rem', backgroundColor: '#F5F5F5'}}> </h2>
                  </div>
                  <div style={{marginTop: '0.4rem', width: '80%', height: '1.7rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
                <ImageWrapper></ImageWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <BoardName style={{marginBottom: '0.4rem', width: '1.8rem', height: '1.3rem', backgroundColor: '#F5F5F5'}}></BoardName>
                <InfoWrapper width={'80%'}>
                  <div className='infoHeader'>
                    <h2 style={{width: '70%', height: '2rem', backgroundColor: '#F5F5F5'}}> </h2>
                  </div>
                  <div style={{marginTop: '0.4rem', width: '80%', height: '1.7rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
                <ImageWrapper></ImageWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <BoardName style={{marginBottom: '0.4rem', width: '1.8rem', height: '1.3rem', backgroundColor: '#F5F5F5'}}></BoardName>
                <InfoWrapper width={'80%'}>
                  <div className='infoHeader'>
                    <h2 style={{width: '70%', height: '2rem', backgroundColor: '#F5F5F5'}}> </h2>
                  </div>
                  <div style={{marginTop: '0.4rem', width: '80%', height: '1.7rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
                <ImageWrapper></ImageWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <BoardName style={{marginBottom: '0.4rem', width: '1.8rem', height: '1.3rem', backgroundColor: '#F5F5F5'}}></BoardName>
                <InfoWrapper width={'80%'}>
                  <div className='infoHeader'>
                    <h2 style={{width: '70%', height: '2rem', backgroundColor: '#F5F5F5'}}> </h2>
                  </div>
                  <div style={{marginTop: '0.4rem', width: '80%', height: '1.7rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
                <ImageWrapper></ImageWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <PostSection>
              {renderPostItems(startPoint, perView, list)}
            </PostSection>
          )}
        </>
      )}
    </PostListWrapper>
  );
};

const PostListWrapper = styled.div`
  width: 100%;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  width: 100%;
  height: 9.6rem;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 0.2rem;

  width: 6.4rem;
  height: 6.4rem;
  background-color: #F5F5F5;
  border-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  padding-top: 1rem;
  width: ${props => props.width};
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & div.infoHeader {
    width: auto;
    height: 2rem;
    display: flex;
    & h2 {
      margin: 0;
      margin-right: 0.4rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    & span.replyCount {
      margin-right: 0.4rem;
      padding: 0;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #FD6258;
    }
    & img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  & div {
    display: flex;
    align-items: center;
    & span {
      padding: 0 0.8rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
    & span:first-child {
      padding-left: 0;
    }
    & span:first-child {
      border-right: 0.1rem solid #ddd;
    }
    & span.postedDate {
      border-right: 0.1rem solid #ddd;
    }
  }
`;

const BoardName = styled.span`
  position: absolute;
  top: 1.6rem;
  left: 0rem;
  padding: 0.2rem 0.7rem;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: -0.04em;
  color: #FD6258;
  background: #FFEAE9;
  border-radius: 0.2rem;
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;