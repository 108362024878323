import React from 'react';
import styled from 'styled-components';

export const RadioComponents = ({ label, infoMessage, id, value, objectData, onChange, disabled}) => {
  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <RadioGroup>
      <div className='title' htmlFor={id}>{label}</div>
      <div className='radios'>
        {Object.keys(objectData).map((key) => (
          <div key={key}>
            <input
              id={`radio-${key}`}
              name={`radio-${key}`}
              type="radio"
              checked={objectData[key].value === value}
              onChange={handleOnChange}
              value={objectData[key].value}
              disabled={disabled}
            />
            <label htmlFor={`radio-${key}`}>{objectData[key].label}</label>
          </div>
        ))}
      </div>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </RadioGroup>
  );
}

const RadioGroup = styled.div`
  margin: 0.7rem 0;
  width: 100%;
  height: auto;
  min-height: 5.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  & div.title {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div.radios {
    height: 3.2rem;
    display: flex;
    & div {
      display: flex;
      align-items: center;
      & input {
        margin: 0;
        width: 1.8rem;
        height: 1.8rem;
        &:focus-visible {
          outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
        }
      }
      & input[type=radio] {
        accent-color: #f86258;
      }
      & label {
        margin: 0 2rem 0 0.7rem;
        font-size: 14px;
      }
    }
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;