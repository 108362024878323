import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { SelectComponent } from '../../../common/SelectComponents';
import { RadioComponents } from '../../../common/RadioComponents';
import { RadioButtonComponents } from '../../../common/RadioButtonComponents';
import { MarketCategoryModal } from '../component/MarketCategoryModal';
import { TutorLearnerModal } from './component/TutorLearnerModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { MarketEditor } from '../component/MarketEditor';
import { Loader } from '../../../common/Loader';
import { TutorApi } from '../api/TutorApi';
import { UserApi } from '../../user/api/UserApi';
import {
  addHyphenForPhoneNumber,
  calculateDateString,
  isBlankValue,
  isPositiveOrZero,
  isValidEmail
} from '../../../utils/utils';
import {
  Cities,
  TutorCategories,
  TutorContacts,
  TutorLectureLanguages,
  TutorLearnerNumber,
  TutorLearners,
  TutorLectureType,
  TutorRate
} from '../../../__mocks__/PostData';

export const TutorDetailEdit = ({setDialog}) => {
  const tutorApi = new TutorApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showLearnerModal, setShowLearnerModal] = useState(false);
  const [step, setStep] = useState(1);
  const [isNewPost] = useState(location?.state?.id?false:true);
  const [article, setArticle] = useState({
    id: location?.state?.id?location?.state?.id:'',
    boardId: 6,
    active: 1,
    type: location?.state?.jobType?location?.state?.jobType:'',
    name: '',
    phone: '',
    email: '',
    contactCode: 'phone',
    title: '',
    description: '',
    categoryCode: '',
    learners: '',
    location: '5',
    lectureType: '',
    learnerNumber: '',
    lectureLanguages: '',
    tutorRate: 'hour',
    price: 0,
    trialType: '',

    payType: '9',

    isPremium: false,
    premiumStart: '',
    premiumEnd: '',
    updateDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------
  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeArticleImages = (index, imageData) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = {};
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleStepOnClick = (step, id='') => {
    const checkId = (id) ? id : article.id;
    if(2 === step && !checkId) {
      return;
    } else {
      setStep(step);
    }
  }

  const handleNameOnChange = (value) => {
    changeArticleData("name", value);
  }

  const handleEmailOnChange = (value) => {
    changeArticleData("email", value);
  }

  const handleTitleOnChange = (value) => {
    changeArticleData("title", value);
  }

  const handlePriceOnChange = (value) => {
    changeArticleData("price", value.replace(/[^.0-9]/g, ""));
    changeArticleData("price", value);
  }

  const handlePhoneNumberOnChange = (value) => {
    changeArticleData("phone", autoAddHyphenForPhoneNumber(value));
  };

  const handleContactCodeOnChange = (e) => {
    changeArticleData('contactCode', e.target.value);
  };

  const handleCategoryCodeOnChange = (e) => {
    changeArticleData("categoryCode", e.target.value);
  }

  const handleLocationOnChange = (e) => {
    changeArticleData('location', e.target.value);
  }

  const handleLectureTypeOnChange = (value) => {
    changeArticleData("lectureType", value);
  }

  const handleLearnerNumberOnChange = (value) => {
    changeArticleData("learnerNumber", value);
  }

  const handleLectureLanguagesOnChange = (value) => {
    changeArticleData("lectureLanguages", value);
  }

  const handleTutorRateOnChange = ({target}) => {
    changeArticleData('tutorRate', target.value);
    if('deal' === target.value) {
      handlePriceOnChange('0.00');
    }
  };

  const handleSearchClick = () => {
    if(article.id) {
      setLoading(true);

      const formData = new FormData();
      formData.append('id', article.id);

      tutorApi.fetchTutorEditById(article.id, formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          setArticle(result);
        },
        (error) => {
          setLoading(false);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1);
        }
      );
    } else {
      userApi.fetchUser(
        (data) => {
          const { result: {nickName, contactEmail, phone} } = data;
          changeArticleData("name", nickName);
          changeArticleData("phone", phone);
          changeArticleData("email", contactEmail);
        },
        (error) => {
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              // setDialog({hasError: true, message: `${message}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              // setDialog({hasError: true, message: `${statusText}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const uploadImageFile = (file, index) => {
    if(article.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', article.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      tutorApi.createTutorImageFile(
        article.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(article.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': article.id,
      });

      tutorApi.deleteTutorImageFile(
        article.id
        , deleteImageFileData
        , () => {
          setLoading(false);
          deleteArticleImages(index);
        }
        , () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const handleSaveForStep1OnClick = () => {
    const id = article.id;
    if(id) {
      saveForStep1();
    } else {
      createForStep1();
    }
  }

  const createForStep1 = () => {
    if(validateArticleDataForStep1ForCreate()) {
      const { boardId, active, type, name,
        phone, email, contactCode,
        title, categoryCode, learners, location,
        lectureType, learnerNumber, lectureLanguages,
        tutorRate, price, trialType,
      } = article;
      const createData = JSON.stringify({
        'boardId': boardId,
        'active': active,
        'type': type,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'categoryCode': categoryCode,
        'learners': learners,
        'location': location,
        'lectureType': lectureType,
        'learnerNumber': learnerNumber,
        'lectureLanguages': lectureLanguages,
        'tutorRate': tutorRate,
        'price': price,
        'trialType': trialType,
      });

      setLoading(true);
      tutorApi.createTutorForStep1(
        createData,
        (data) => {
          setLoading(false);
          changeArticleData('id', data.id);
          handleStepOnClick(2, data.id);
        },
        (error)=> {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const saveForStep1 = () => {
    if(validateArticleDataForStep1()) {
      setLoading(true);
      const { id, boardId, active, type, name,
        phone, email, contactCode,
        title, categoryCode, learners, location,
        lectureType, learnerNumber, lectureLanguages,
        tutorRate, price, trialType,
      } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'type': type,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,

        'title': title,
        'categoryCode': categoryCode,
        'learners': learners,
        'location': location,
        'lectureType': lectureType,
        'learnerNumber': learnerNumber,
        'lectureLanguages': lectureLanguages,
        'tutorRate': tutorRate,
        'price': price,
        'trialType': trialType,
      });

      tutorApi.saveTutorForStep1(id, updateData,
        (data) => {
          handleSearchClick();
          setDialog({isOpen: true, message: "저장하였습니다.", type: "alert"});
          handleStepOnClick(2);
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { message, errors } = data;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const handleSaveForStep2OnClick = () => {
    if(window.confirm(isNewPost?"결제 하시겠습니까?":"Step2 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active, type, payType, } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'type': type,
        'description': editorRef.current.getContent(),
      });

      if(isNewPost) {
        tutorApi.createTutorForStep2(id, updateData,
          (data) => {
            setLoading(false);
            // setDialog({isOpen: true, message: "등록 하였습니다.", type: "alert"});
            if('9' === payType) {
              handleExtendPostOnClick();
            } else {
              navigate(`/tutor`, {replace : true});
            }
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      } else {
        tutorApi.saveTutorForStep2(id, updateData,
          (data) => {
            setDialog({isOpen: true, message: "저장하였습니다.", type: "alert"});
            navigate(`/tutor/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      }
    }
  }

  const handleExtendPostOnClick = () => {
    const { id, boardId, payType } = article;
    navigate('/market-payment-step1',
      {state: {
        refType: 'tutor',
        refPostId: id,
        refBoardId: boardId,
        itemId: payType,
        backLink: `/tutor/${id}`,
        cancelLink: `/tutor`,
      }}
    );
  }

  const handlerCategoryCodeOnChange = (value) => {
    changeArticleData("categoryCode", value);
  }

  const toggleCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal);
  }

  const handlerLearnersOnChange = (value) => {
    changeArticleData("learners", value);
  }

  const toggleLearnerModal = () => {
    setShowLearnerModal(!showLearnerModal);
  }

  // ==== valid check ---------------------------------
  const isStep1 = () => {
    return (step === 1) ? true : false;
  }

  const isStep2 = () => {
    return (step === 2) ? true : false;
  }

  const isAcademy = (type) => {
    if("학원" === type)
      return true;
    return false;
  }

  const isPriceDisabled = (tutorRate) => {
    return ('deal'===tutorRate) ? true: false;
  }

  const autoAddHyphenForPhoneNumber = (value) => {
    const number = value.replace(/[^0-9]/g, "");
    let phone = "";

    if (number.length < 5) {
      return number;
    } else if (number.length < 8) {
      phone = `${number.substr(0, 3)}-${number.substr(3)}`;
    } else if (number.length < 10) {
      phone = `${number.substr(0, 2)}-${number.substr(2, 3)}-${number.substr(5)}`;
    } else if (number.length < 11) {
      phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
    } else if (number.length < 12) {
      phone = `${number.substr(0, 3)}-${number.substr(3, 4)}-${number.substr(7)}`;
    }
    return phone;
  }

  const validateArticleDataForStep1ForCreate = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "업체/튜터명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: "교습과목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.learners)) {
      setDialog({isOpen: true, message: "교습대상은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "지역은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.lectureType)) {
      setDialog({isOpen: true, message: "수업모드는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.learnerNumber)) {
      setDialog({isOpen: true, message: "학습인원은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.lectureLanguages)) {
      setDialog({isOpen: true, message: "수업언어는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "가격은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateArticleDataForStep1 = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "업체/튜터명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: "교습과목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.learners)) {
      setDialog({isOpen: true, message: "교습대상은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "지역은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.lectureType)) {
      setDialog({isOpen: true, message: "수업모드는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.learnerNumber)) {
      setDialog({isOpen: true, message: "학습인원은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.lectureLanguages)) {
      setDialog({isOpen: true, message: "수업언어는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "가격은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // render ------------------------------------------------
  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <StepGroup>
        <div className={`${isStep1() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(1)}>Step 1</div>
        <div className={`${isStep2() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(2)}>Step 2</div>
      </StepGroup>
      {isStep1() && (
        <>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>학원/튜터 정보를 입력해주세요</div>
              <InputComponent
                label={isAcademy(article.type)?'업체명':'튜터명'}
                id={'name'}
                type={'text'}
                checkType={'text'}
                value={article.name}
                onChange={handleNameOnChange} />
              <InputComponent
                label={'전화번호'}
                id={'phone'}
                type={'text'}
                checkType={'text'}
                value={addHyphenForPhoneNumber(article.phone)}
                maxLength={13}
                placeholder={'00*-00*-0000'}
                onChange={handlePhoneNumberOnChange} />
              <InputComponent
                label={'이메일'}
                id={'email'}
                type={'email'}
                checkType={'email'}
                value={article.email}
                placeholder={'입력하세요.'}
                onChange={handleEmailOnChange} />
              <RadioComponents
                label={'연락망 선택'}
                id={'contactCode'}
                value={article.contactCode||''}
                objectData={TutorContacts}
                onChange={handleContactCodeOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>교습 정보를 입력해주세요</div>
              <InputComponent
                label={'제목'}
                id={'title'}
                type={'text'}
                checkType={'text'}
                value={article.title}
                placeholder={'입력하세요.'}
                onChange={handleTitleOnChange} />
              <SelectComponent
                label={'교습과목'}
                id={'categoryCode'}
                value={article.categoryCode}
                objectData={{'': { value: '', label: '선택하세요'}, ...TutorCategories}}
                placeholder={'교습과목을 선택하세요'}
                infoMessage={'여러 교습 과목은 대표 과목만 선택해 주세요.'}
                onChange={handleCategoryCodeOnChange} />
              <MarketCategoryModal
                title={'카테고리'}
                categories={TutorCategories}
                categoryCode={article.categoryCode}
                showCategoryModal={showCategoryModal}
                toggleCategoryModal={toggleCategoryModal}
                handlerCategoryCodeOnChange={handlerCategoryCodeOnChange}
              />

              <LearnerWrap>
                <div className='title'>
                  교습대상
                </div>
                <div className='learnerList' onClick={toggleLearnerModal}>
                  {article.learners
                    ? article.learners.split(",").map(learner =>
                      <>
                        {learner && (
                          <span key={learner}>
                            {learner?TutorLearners[learner].label+',':learner+','}
                          </span>
                        )}
                      </>)
                    : <span className='none'>교습 과목을 선택하세요</span>
                  }
                  <img src='/icon/angle-down-gray-icon.png' alt='선택하세요'/>
                </div>
                <div className='infoMessage'>
                  <img src="/icon/info-icon.png" alt="정보 아이콘" />
                  중복 선택 가능합니다.
                </div>
                <TutorLearnerModal
                  title={'교습대상'}
                  learnerList={TutorLearners}
                  learners={article.learners}
                  showLearnerModal={showLearnerModal}
                  toggleLearnerModal={toggleLearnerModal}
                  handlerLearnersOnChange={handlerLearnersOnChange}
                />
              </LearnerWrap>
              <SelectComponent
                label={'지역'}
                id={'location'}
                value={article.location}
                objectData={Cities}
                placeholder={'지역 선택'}
                onChange={handleLocationOnChange} />
              <RadioButtonComponents
                label={'수업모드'}
                id={'lectureType'}
                value={article.lectureType}
                objectData={TutorLectureType}
                width={'48%'}
                infoMessage={'중복 선택 가능합니다.'}
                multipleChoice={true}
                onChange={handleLectureTypeOnChange} />
              <RadioButtonComponents
                label={'학습인원'}
                id={'learnerNumber'}
                value={article.learnerNumber}
                objectData={TutorLearnerNumber}
                width={'48%'}
                infoMessage={'중복 선택 가능합니다.'}
                multipleChoice={true}
                onChange={handleLearnerNumberOnChange} />
              <RadioButtonComponents
                label={'수업언어'}
                id={'lectureLanguages'}
                value={article.lectureLanguages}
                objectData={TutorLectureLanguages}
                width={'48%'}
                infoMessage={'중복 선택 가능합니다.'}
                multipleChoice={true}
                onChange={handleLectureLanguagesOnChange} />
              <InputGroup>
                <label htmlFor={'tutorRate'}>수업료</label>
                <div>
                  <select className="form-select"
                    id={'tutorRate'}
                    value={article.tutorRate}
                    onChange={handleTutorRateOnChange}
                  >
                    {Object.keys(TutorRate).map((key) =>
                      <option key={key} value={TutorRate[key].value}>{TutorRate[key].label}</option>
                    )}
                  </select>
                  <input
                    type={'number'}
                    id={'price'}
                    value={article.price||0.00}
                    placeholder={0.00}
                    onChange={(e) => handlePriceOnChange(e.target.value)}
                    autoComplete="off"
                    disabled={isPriceDisabled(article.tutorRate)}
                  />
                </div>
              </InputGroup>
              {/* <CheckBoxTypeComponent
                id={'trialType'}
                value={article.trialType}
                target={'free-trial'}
                message={'무료 트라이얼 수업 가능'}
                onChange={handleTrialTypeOnChange}
              /> */}
            </ArticleForm>
            <NextButton
              type='button'
              color="#fff"
              backgroundColor='#fd6258'
              onClick={() => handleSaveForStep1OnClick()}>
              {isNewPost? '다음' : '수정하기'}
            </NextButton>

          </ContentWrap>
        </>
      )}
      {isStep2() && (
        <ContentWrap>
          <ImageUploadComponent
            images={article.images}
            uploadProcess={uploadImageFile}
            deleteProcess={deleteImageFile}
            setDialog={setDialog}
          />
          <Label htmlFor='description'>
            상세내용
            <span>(선택)</span>
          </Label>
          <MarketEditor
            id={'description'}
            editorRef={editorRef}
            placeholder='교습 상세 내용을 입력하세요. 예) 지도 경력, 학력, 수업 커리큘럼 등'
            description={article.description}
            infoMessage={'* 학원/튜터 광고글 외에 주제와 맞지 않는 내용을 기재하면 사전통지 없이 삭제됩니다.'}
            disabled={false}
          />
          <NextButton
            type='button'
            color="#fff"
            backgroundColor='#fd6258'
            onClick={() => handleSaveForStep2OnClick()}>
            {isNewPost? '결제하기' : '수정하기'}
          </NextButton>
          {(!isNewPost) && (
            <PostExtendGroup>
              <input type='text'
                id='PostExtend'
                value={`${article?.premiumStart ? calculateDateString(article?.premiumStart, 1) : '시작일'} ~ ${article?.premiumEnd ? calculateDateString(article?.premiumEnd, 1) : '종료일'}`}
                // value={`${article?.premiumStart} ~ ${article?.premiumEnd}`}
                readOnly/>
              <button type='button'
                onClick={() => handleExtendPostOnClick()}
              > 광고 연장신청</button>
            </PostExtendGroup>
          )}
        </ContentWrap>
      )}
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;

const StepGroup = styled.div`
  padding: 1.3rem 1.6rem 0;
  display: flex;
  justify-content: center;
  & div.step {
    width: 50%;
    height: 4.2rem;

    font-size: 14px;
    font-weight: 700;
    line-height: 4.2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  & div.step:first-child {
    border: 0.1rem solid #eee;
    border-radius: 0.4rem 0rem 0rem 0.4rem;
  }
  & div.step:last-child {
    border: 0.1rem solid #eee;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
  }
  & div.step.active {
    color: #fd6258;
    background-color: #ffeae9;
    border-color: #ffeae9;
  }
`;

const ContentWrap = styled.div`
  padding: 1.4rem;
  height: 77%;
`;

const ArticleForm = styled.div`
  margin-bottom: 0.5rem;
  & div.articleLabel {
    margin: 3.2rem 0rem 2rem 0rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const NextButton = styled.button`
  margin: 3.6rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const Label = styled.label`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 2.3rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const InputGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & select {
      margin-right: 0.8rem;
      padding-left: 1.6rem;
      width: 35%;
      height: 4.15rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }

    & input {
      padding-right: 0.8rem;
      min-width: 21rem;
      width: 65%;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 3.8rem;
      letter-spacing: -0.04em;
      color: #424242;
      text-align: right;

      background-image: url('/icon/dollar-icon.png');
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position: 1.6rem center;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }
    & input:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.success {
      color: ${({ theme }) => theme.colors.success};
      border-color: ${({ theme }) => theme.colors.borderSuccess};
    }
    & input.success:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.error {
      border-color: ${({ theme }) => theme.colors.errorBorder};
    }
    & input.error:focus-visible {
      outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
    }
  }
`;

const LearnerWrap = styled.div`
  & div.title {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    & span {
      margin-left: 0.5rem;
      font-weight: 400;
      font-size: 13px;
      line-height: 2.3rem;
      letter-spacing: -0.04em;
      color: #FD6258;
    }
  }
  & div.learnerList {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 3.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 0.1rem solid #ddd;
    & span {
      margin-right: 1rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
    & span.none {
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
    & img {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.infoMessage {
    margin-top: 1rem;
    width: 100%;
    height: 1.7rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    display: flex;
    align-items: center;
    & img {
      margin-right: 0.4rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const PostExtendGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    padding-right: 0.8rem;
    width: 60%;
    height: 4rem;

    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #616161;
    text-align: center;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }

  & button {
    width: 35%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    background: #F5F5F5;
    border: 0.1rem solid #eee;
    border-radius: 4px;
  }
`;
