import React from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../../common/SkeletonComponent';
import { elapsedText, priceToString } from '../../../../utils/utils';

export const CarCardComponent = ({ list, link, isLoading=true, postOnClick}) => {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-car.png`;
  }

  const addFlagTag = ({flagType}) => {
    if(!flagType) {
      return;
    } else if('급매' === flagType) {
      return (
        <img className='flagIcon' src='icon/realty-rush-sale-icon.png' alt='급매'/>
      );
    }
  }

  const addSoldTag = ({isSoldOut}) => {
    if(!isSoldOut) return;
    return (
      <SoldOut>SOLD</SoldOut>
    );
  }

  return (
    <GridListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <GridSection>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <CarDetailInfo style={{backgroundColor: '#F5F5F5'}}></CarDetailInfo>
                  <WriterName style={{backgroundColor: '#F5F5F5'}}></WriterName>
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <CarDetailInfo style={{backgroundColor: '#F5F5F5'}}></CarDetailInfo>
                  <WriterName style={{backgroundColor: '#F5F5F5'}}></WriterName>
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <CarDetailInfo style={{backgroundColor: '#F5F5F5'}}></CarDetailInfo>
                  <WriterName style={{backgroundColor: '#F5F5F5'}}></WriterName>
                </InfoWrapper>
              </div>
            </GridItem>
          </GridSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <GridSection>
              {list.map((row)=>(
                <GridItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                      {addFlagTag(row)}
                      {addSoldTag(row)}
                    </ImageWrapper>
                    <InfoWrapper>
                      <Title>{row?.title}</Title>
                      <Updated>{elapsedText(row?.updatedDate)}</Updated>
                      <Price>
                        {0 < row?.price ? priceToString(row?.price) : '가격문의'}
                      </Price>
                      <CarDetailInfo>
                        <div className='ageRange'>
                          {0 < row?.ageRange ? `${row?.ageRange}년식` : '-'}
                        </div>
                        <div className='type'>
                          {row?.type ? `${row?.type}` : '-'}
                        </div>
                        <div className='mileage'>
                          {0 < row?.mileage ? `${priceToString(row?.mileage)}Km` : '-'}
                        </div>
                      </CarDetailInfo>
                      <WriterName>
                        {row.name}
                      </WriterName>
                    </InfoWrapper>
                  </div>
                </GridItem>
              ))}
            </GridSection>
          )}
        </>
      )}
    </GridListWrapper>
  );
};

const GridListWrapper = styled.div`
  width: 100%;
`;

const GridSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GridItem = styled.li`
  margin-bottom: 3.4rem;
  width: 100%;
  height: 32.4rem;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    border: 0.1rem solid #F5F5F5;
    border-radius: 0.4rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 18rem;
  background-color: #F5F5F5;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
  }
  & img.flagIcon {
    position: absolute;
    top: 1.1rem;
    left: 1.1rem;
    width: 4.6rem;
    height: 4.6rem;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  margin:  0;
  padding: 1.4rem 1.5rem 1.3rem 1.5rem;
  height: 14.4rem;
  color: #666;
  box-sizing: border-box;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  width: 88%;
  height: 1.8rem;

  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Updated = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.6rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;
`;

const Price = styled.div`
  margin: 0.3rem 0 1.2rem 0;
  height: 2.9rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 2.9rem;
  letter-spacing: -0.04em;
  color: #000000;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const CarDetailInfo = styled.div`
  padding: 1.4rem 0 0.7rem 0;
  display: flex;
  border-top: 0.1rem solid #F5F5F5;
  & div.ageRange {
    padding-right: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
  & div.type {
    padding: 0 1rem;
    max-width: 18.5rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
  }
  & div.mileage {
    padding: 0 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
  }
`;

const WriterName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #BDBDBD;
  text-align: right;
`;

const SoldOut = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  text-align: center;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(0.2rem);
`;

const NoData = styled.p`
  height: 5rem;
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

`;