import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { elapsedText, removeHtmlTags } from '../../../utils/utils';

export const NewsPostListComponent = memo(({ list, link, isLoading, itemOnClick}) => {

  const handlerPostOnClick = (url) => {
    itemOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-img-news.png`;
  }

  const getImgAbsoluteUrl = () => {
    let absoluteUrl = "";
    switch (link) {
      case "community":
        absoluteUrl = process.env.REACT_APP_COMMUNITY_URL;
        break;
      default:
        absoluteUrl = process.env.REACT_APP_NEWS_URL;
        break;
    }

    return absoluteUrl;
  }

  return (
    <PostListWrapper>
      {(isLoading)?(
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{ width: '100%', height: '2.2rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <PostSection>
              {list.map((row) => (
                <PostItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                    <ImageWrapper>
                      <img src={`${getImgAbsoluteUrl()}${row.imageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper left={row.imageFile ? 124 : 5}>
                      <h2>{row.title}</h2>
                      <div>{`${removeHtmlTags(row.description)}`}</div>
                      <span>{`${row.reporter} · ${elapsedText(row.updatedDate)}`}</span>
                    </InfoWrapper>
                  </div>
                </PostItem>
              ))}
            </PostSection>
          )}
        </>
      )}
    </PostListWrapper>
  );
});

const PostListWrapper = styled.div`
  width: 100%;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
  border-bottom: 0rem solid #ddd;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 15.2rem;
  height: 11.4rem;
  background-color: #F5F5F5;
  background-image: url('/icon/no-img-news-icon.png');
  background-size: 9rem 7.8rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 16.7rem;
  right: 0rem;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & h2 {
    margin: 0;
    width: inherit;
    height: 2rem;

    font-size: 16px;
    font-weight: 500;
    line-height: 2rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div {
    margin: 0.3rem 0;
    display: block;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #9e9e9e;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0.4rem;
  }
  & span {
    display: block;

    font-size: 12px;
    font-weight: 300;
    line-height: 2rem;
    color: #9e9e9e;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & span:first-child {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 2rem;
    color: #000;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;