import React, { memo } from 'react';
import styled from 'styled-components';
import { addHyphenForPhoneNumber } from '../../../../utils/utils';

export const YellowpageGridComponent = memo(({ list, link, isLoading=true, postOnClick}) => {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-yellowpage.png`;
  }

  const addPremiumTag = (premium) => {
    if("1" === premium) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    } else {
      return '';
    }
  }

  const getImgAbsoluteUrl = () => {
    return process.env.REACT_APP_STORE_URL;
  }

  return (
    <GridListWrapper>
      {list.length === 0 ? (
        <NoData>데이터가 없습니다.</NoData>
      ):(
        <GridSection>
          {list.map((row) => (
            <GridItem key={row.id}>
              <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                <ImageWrapper>
                  <img src={`${getImgAbsoluteUrl()}${row.imageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                </ImageWrapper>
                <InfoWrapper>
                  {addPremiumTag(row.premium)}
                  <h2>{row.title}</h2>
                  <div className='description'>{`${row.address} ${row.postal}`}</div>
                  <div className='phoneNumber'>
                    <img className='phoneIcon' src='/icon/phone-icon.png' alt='Phone Number'/>
                    {`${addHyphenForPhoneNumber(row.phone)}`}
                  </div>
                </InfoWrapper>
              </div>
            </GridItem>
          ))}
        </GridSection>
      )}
    </GridListWrapper>
  );
});

const GridListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const GridSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
`;

const GridItem = styled.li`
  margin-top: 1.9rem;
  width: 16.6rem;
  height: 21.8rem;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  @media (max-width: 370px) {
    width: 16rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 12.4rem;
  background-color: #F5F5F5;
  border-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    background-color: #fff;
    border: 0.1rem solid #f5f5f5;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 1rem;
  height: 4rem;
  color: #666;
  & h2 {
    margin: 0;
    padding: 0;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0.4rem;
  }
  & div.description {
    display: block;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div.phoneNumber {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    & img.phoneIcon {
      margin-right: 0.3rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & img.premium {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    top: 1.2rem;
    right: 0.6rem;
  }
  & img.premiumIcon {
    width: 5rem;
    height: 1.8rem;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;