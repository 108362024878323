import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Separator } from '../../common/Separator';
import { autoAddHyphenForPhoneNumber, addHyphenForPhoneNumber, isValidPassword, isBlankValue, isValidEmail, isValidImageFile, isValidNickName, removedNotAllowedNickName } from '../../utils/utils';
import { UserApi } from './api/UserApi';

export const ProfileEdit = ({setDialog}) => {
  const userApi = new UserApi();
  const navigate = useNavigate();
  const DEFAULT_LINK = '/market';

  const photoInput = useRef();
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '*********',
    newPassword: null,
    conformNewPassword: null,
    toggleNewPw: false,
    nickName: '',
    toggleNickName: true,
    phone: '',
    userImage: '',
  });

  const [hasErrors, setHasErrors] = useState({
    email: false,
    newPassword: false,
    conformPassword: false,
    nickName: false,
  });

  // == state 변경 ------------------------------------------------
  const changeUserInfo = (name, value) => {
    setUserInfo((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeHasErrors = (name, value) => {
    setHasErrors((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------

  const handleEmailOnChange = (value) => {
    changeUserInfo("email", value);
    if(!isBlankValue(value) && isValidEmail(value)) {
      changeHasErrors("email", false);
    } else {
      changeHasErrors("email", true);
    }
  }

  const handleNewPasswordOnChange = (value) => {
    changeUserInfo("newPassword", value);
    changeUserInfo("conformNewPassword", "");
    if(isValidPassword(value)) {
      changeHasErrors("newPassword", false)
    } else {
      changeHasErrors("newPassword", true);
    }
  };

  const handleConformNewPasswordOnChange = (value) => {
    changeUserInfo("conformNewPassword", value);
    if(isValidPassword(value) && userInfo.newPassword === value) {
      changeHasErrors("conformNewPassword", false)
    } else {
      changeHasErrors("conformNewPassword", true);
    }
  };

  const toggleInputNickName = (value) => {
    changeUserInfo("toggleNickName", value);
  }

  const handleNickNameOnChange = (value) => {
    if(isValidNickName(value)) {
      changeUserInfo("nickName", value);
    } else {
      changeUserInfo("nickName", removedNotAllowedNickName(value));
    }
  }

  const handlePhoneNumberOnChange = (value) => {
    changeUserInfo("phone", autoAddHyphenForPhoneNumber(value));
  };

  const toggleNewPassword = () => {
    setUserInfo((values) => {
      const updated = {...values};
      updated["toggleNewPw"] = !updated.toggleNewPw;
      updated["newPassword"] = null;
      updated["conformNewPassword"] = null;
      return updated;
    });
  }

  const handleUserSearch = () => {
    userApi.fetchUser(
      (data) => {
        setUserInfo(data.result);
        changeUserInfo("toggleNickName", true);
      },
      (error) => {
        const { response, status, message, errors } = error;
        if(status) {
          if(403 === status) {
            setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
            alert(`로그인후 사용해주세요.`);
            navigate(`${DEFAULT_LINK}`, {replace : true});
          } else {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          }
        } else if (errors) {
          const { field, defaultMessage } = errors[0];
          setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
        } else if(response) {
          const {status, statusText} = response;
          if(403 === status) {
            // setDialog({hasError: true, message: `${statusText}`, type: "error"});
            alert(`로그인후 사용해주세요.`);
            navigate(`${DEFAULT_LINK}`, {replace : true});
          } else {
            setDialog({hasError: true, message: `${statusText}`, type: "error"});
          }
        }
      }
    );
  }

  const handleSaveOnClick = () => {
    if(validateDataForSignUp()) {
      userApi.saveUserInfo(
        userInfo,
        (data) => {
          navigate(`/profile`, {replace : true});
        },
        (error) => {
          if(400 === error?.response?.status) {
            if(error?.response?.data) {
              const { code, message, errors } = error?.response?.data;
              if("INVALID_INPUT_VALUE" === code && 0 < errors.length) {
                setDialog({isOpen: true, message: errors[0].defaultMessage, type: "nonHeader"});
              } else {
                setDialog({isOpen: true, message: message, type: "nonHeader"});
              }
            } else {
              setDialog({isOpen: true, message: "이메일 또는 비밀번호가 일치하지 않습니다.", type: "nonHeader"});
            }
          } else if(401 === error?.response?.status) {
            if(error?.response?.data) {
              const { message, } = error?.response?.data;
              setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
              // alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            }
          } else if(403 === error?.response?.status) {
            if(error?.response?.data) {
              const { message, } = error?.response?.data;
              setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
              // alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            }
          } else {
            setDialog({isOpen: true, message: "요청중 에러가 발생하였습니다.", type: "nonHeader"});
          }
        }
      );
    }
  }

  const handleDeregisterOnClick = () => {
    if(window.confirm("정말 탈퇴하시겠습니까?")) {
      userApi.deregisterUser(
        (data) => {
          userApi.signOut(
            ()=> navigate(`${DEFAULT_LINK}`, {replace : true})
          );
        },
        (error) => {
          if(400 === error?.response?.status) {
            if(error?.response?.data) {
              const { code, message, errors } = error?.response?.data;
              if("INVALID_INPUT_VALUE" === code && 0 < errors.length) {
                setDialog({isOpen: true, message: errors[0].defaultMessage, type: "nonHeader"});
              } else {
                setDialog({isOpen: true, message: message, type: "nonHeader"});
              }
            } else {
              setDialog({isOpen: true, message: "요청중 오류가 발생하였습니다.", type: "nonHeader"});
            }
          } else if(401 === error?.response?.status) {
            if(error?.response?.data) {
              const { message, } = error?.response?.data;
              setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
              // alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            }
          } else if(403 === error?.response?.status) {
            if(error?.response?.data) {
              const { message, } = error?.response?.data;
              setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
              // alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            }
          } else {
            setDialog({isOpen: true, message: "요청중 에러가 발생하였습니다.", type: "nonHeader"});
          }
        }
      );
    }
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-profile.png`;
  }

  const handleUploadImageOnClick = () => {
    photoInput.current.click();
  }

  const handleUploadImage = (event) => {
    const files = event.target.files;
    if (files && 0 < files.length) {
      const file = files[0];
      if(isValidImageFile(file)) {
        uploadImageFile(file);
      }
    }
  }

  const uploadImageFile = (file) => {
    if(file) {
      const formData = new FormData();
      formData.append('imageFile', file);

      userApi.uploadUserImage(
        formData,
        (data) => {
          const { result } = data;
          changeUserInfo("userImage", `${result.domain}${result.filePath}${result.thumbnailFileName}`);
        },
        () => {
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const handleDeleteOnclick = () => {
    if(window.confirm("이미지를 삭제하시겠습니까?")) {
      userApi.deleteUserImage(
        () => {
          changeUserInfo("userImage", ``);
        },
        () => {
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const validateDataForSignUp = () => {
    if(isBlankValue(userInfo.nickName)) {
      setDialog({isOpen: true, message: "이름(닉네임)은 필수입니다.", type: "alert"});
      return false;
    }
    if(userInfo.toggleNewPw) {
      if(isBlankValue(userInfo.newPassword)) {
        changeHasErrors("newPassword", true);
        setDialog({isOpen: true, message: "신규 비밀번호는 필수입니다.", type: "alert"});
        return false;
      }
      if(!isValidPassword(userInfo.newPassword)) {
        changeHasErrors("newPassword", true);
        setDialog({isOpen: true, message: "신규 비밀번호는 8~16자의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요.", type: "alert"});
        return false;
      }
      if(isBlankValue(userInfo.conformNewPassword)) {
        changeHasErrors("conformNewPassword", true);
        setDialog({isOpen: true, message: "신규 비밀번호 확인은 필수입니다.", type: "alert"});
        return false;
      }
      if(userInfo.newPassword !== userInfo.conformNewPassword) {
        changeHasErrors("conformNewPassword", true);
        setDialog({isOpen: true, message: "신규 비밀번호가 일치하지 않습니다.", type: "alert"});
        return false;
      }
    }
    return true;
  }

  useEffect(()=> {
    handleUserSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <MyCard>
          <div>
            <img src={`${userInfo.userImage}`} alt={`${userInfo.nickName}`} onError={(e) => handleImageError(e)}/>
            <img className='editImage' src='/icon/profile-img-edit-icon.png' alt='편집' onClick={handleUploadImageOnClick}/>
            <input type="file"
              accept="image/jpg, image/jpeg, image/png"
              ref={photoInput}
              onChange={(e) => handleUploadImage(e)}/>
          </div>
          <span>{userInfo.nickName}</span>
          {userInfo?.userImage &&
            <span className='profileImageDelete' onClick={()=>handleDeleteOnclick()}>사진삭제</span>
          }
        </MyCard>
        <Separator height={'1rem'} color={'#F7F7F7'} />
        <ContentWrap>
          <InputGroup>
            <label htmlFor='nickName'>이름(닉네임)</label>
            <input
              type='text'
              id='nickName'
              value={userInfo.nickName||''}
              maxLength={12}
              onChange={(e) => handleNickNameOnChange(e.target.value)}
              disabled={userInfo.toggleNickName}
              autoComplete="off"
            />
            {userInfo.toggleNickName ? (
              <button type='button' onClick={()=>toggleInputNickName(false)}>변경</button>
            ) : (
              <button type='button' onClick={()=>toggleInputNickName(true)}>취소</button>
            )}

          </InputGroup>
          <InputGroup>
            <label htmlFor='email'>이메일</label>
            <input
              type='email'
              id='email'
              value={userInfo.email||''}
              onChange={(e) => handleEmailOnChange(e.target.value)}
              disabled
            />
          </InputGroup>
          <InputGroup>
            <label htmlFor='email'>비밀번호</label>
            <input
              type='password'
              id='password'
              value='*********'
              disabled
            />
            <button type='button' onClick={toggleNewPassword}>
              {userInfo.toggleNewPw ? '취소' : '재설정'}
            </button>
          </InputGroup>
          {userInfo.toggleNewPw && (
            <>
              <InputGroup>
                <label htmlFor='newPassword'>새 비밀번호</label>
                <input
                  type='password'
                  id='newPassword'
                  value={userInfo.newPassword||''}
                  onChange={(e) => handleNewPasswordOnChange(e.target.value)}
                  placeholder="새 비밀번호를 입력해주세요."
                  autoComplete="off"
                  required
                />
                {hasErrors.newPassword && (
                  <div className='infoMessage'>
                    새 비밀번호가 올바르지 않습니다.
                  </div>
                )}
              </InputGroup>
              <InputGroup>
                <label htmlFor='conformNewPassword'>새 비밀번호 확인</label>
                <input
                  type='password'
                  id='conformNewPassword'
                  value={userInfo.conformNewPassword||''}
                  onChange={(e) => handleConformNewPasswordOnChange(e.target.value)}
                  placeholder="새 비밀번호를 확인해주세요."
                  autoComplete="off"
                  required
                />
                {hasErrors.conformNewPassword && (
                  <div className='infoMessage'>
                    새 비밀번호가 일치하지 않습니다.
                  </div>
                )}
              </InputGroup>
            </>
          )}
          <InputGroup>
            <label htmlFor='phone'>전화번호</label>
            <input
              type='text'
              id='phone'
              value={addHyphenForPhoneNumber(userInfo.phone)}
              maxLength={13}
              onChange={(e) => handlePhoneNumberOnChange(e.target.value)}
              placeholder="전화번호를 입력해주세요."
              autoComplete="off"
              required
            />
            {hasErrors.phone && (
              <div className='infoMessage'>
                전화번호를 입력하세요.
              </div>
            )}
          </InputGroup>
          <RequirementContent>
            <li>∙ 비밀번호는 8~16자의 영문 대소문자, 숫자, 특수문자를<br/>&nbsp;&nbsp;조합하여 설정해주세요.</li>
            <li>∙ 닉네임은 최대 한글 10자, 영문 10자로 입력해주세요.</li>
            {/* <li>∙ 닉네임은 설정 후 24시간 뒤 재변경이 가능합니다.</li> */}
          </RequirementContent>
          <SaveButton
            type='button'
            color="#fff"
            backgroundColor='#fd6258'
            onClick={() => handleSaveOnClick()}>
            저장
          </SaveButton>
          <DeregisterButton onClick={() => handleDeregisterOnClick()}>
            탈퇴하기
          </DeregisterButton>
        </ContentWrap>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;

const ContentWrap = styled.div`
  padding: 1.6rem 1.6rem 3.2rem;
`;

const MyCard = styled.div`
  margin: 0;
  padding: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & div {
    position: relative;
    width: 7.4rem;
    height: 7.4rem;
    border-radius: 50%;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    & img.editImage {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
    }
    & input {
      display: none;
    }
  }
  & span {
    padding-top: 0.5rem;
    font-size: 20px;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & span.profileImageDelete {
    padding: 0.1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #BDBDBD;
    text-align: center;
    border-bottom: 0.1rem solid #BDBDBD;
    cursor: pointer;
  }
`;

const InputGroup = styled.div`
  position: relative;
  margin: 0;
  padding: 0.8rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  & label {
    padding-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & input {
    width: 100%;
    height: 3rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0rem solid #ddd;
    border-bottom: 0.1rem solid #ddd;
    box-sizing : border-box;
    outline: none;
  }
  & input:disabled {
    background-color: #fff;
  }
  & div.infoMessage {
    padding-top: 0.4rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
  & button {
    position: absolute;
    top: 3.4rem;
    right: 0.1rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #FD6258;

    background: #fff;
    border: 0rem solid #E0E0E0;
    border-radius: 0.4rem;
    cursor: pointer;
  }
`;

const RequirementContent = styled.ul`
  margin: 3rem 0 0;
  padding: 2.1rem 2rem;
  border: 0.1rem solid #E0E0E0;
  border-radius: 0.4rem;
  & li {
    margin: 0;
    padding: 0.2rem 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #616161;
    list-style: none;
  }
`;


const SaveButton = styled.button`
  margin: 3rem 0 2rem;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const DeregisterButton = styled.div`
  width: 100%;
  height: 1.8rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #BDBDBD;
  text-align: center;
  cursor: pointer;
`;