import React from 'react';
import { NewsCardComponent } from './NewsCardComponent';
import { NewsGridComponent } from './NewsGridComponent';
import { NewsPostListComponent } from './NewsPostListComponent';
import { NewsSimpleTextListComponent } from './NewsSimpleTextListComponent';
import { NewsTextListComponent } from './NewsTextListComponent';

export const NewsListComponent = ({ viewMode, list, link, isLoading=true, itemOnClick }) => {
  return (
    <>
      { 'simple' === viewMode ? (
        <NewsSimpleTextListComponent list={list} link={link} isLoading={isLoading} itemOnClick={itemOnClick} />
      ) : 'text' === viewMode ? (
        <NewsTextListComponent list={list} link={link} isLoading={isLoading} itemOnClick={itemOnClick} />
      ) : 'grid' === viewMode ? (
        <NewsGridComponent list={list} link={link} isLoading={isLoading} itemOnClick={itemOnClick} />
      ) : 'card' === viewMode ? (
        <NewsCardComponent list={list} link={link} isLoading={isLoading} itemOnClick={itemOnClick} />
      ) : (
        <NewsPostListComponent list={list} link={link} isLoading={isLoading} itemOnClick={itemOnClick} />
      )}
    </>
  );
}