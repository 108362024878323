import React from 'react';
import styled from 'styled-components';

export const RadioButtonComponent = React.memo(({ id, value, list, width, onChange }) => {

  const handleOnChange = (sourceValue, radioValue) => {
    if(isSame(sourceValue, radioValue)) {
      onChange?.(0);
    } else {
      onChange?.(radioValue);
    }
  }

  const isSame = (sourceValue, radioValue) => {
    return sourceValue === radioValue;
  }

  return (
    <RadioGroup>
      <div className='radios'>
        {list?.map((item) =>
          <RadioItem
            key={`${id}-${item.value}`}
            className={`${isSame(value, item.value) ? 'active' : ''}`}
            width={width}
            onClick={()=>handleOnChange(value, item.value)}
          >
            {item.label}
          </RadioItem>
        )}
      </div>
    </RadioGroup>
  );
});

const RadioGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  & div.radios {
    margin-top: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const RadioItem = styled.div`
  padding: 0;
  width: ${props => props.width};
  height: 3.8rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 3.8rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;
  text-align: center;

  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  cursor: ${props => (props.disabled)?'not-allowed':'pointer'};
  &.active {
    color: #FD6258;
    border: 1px solid #FD6258;
  }
`;
