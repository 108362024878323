import React, { useState } from 'react';
import styled from 'styled-components';
import { isBlankValue, isPositiveOrZero, isValidEmail, isValidPassword } from '../utils/utils';

export const InputComponent = ({ label, subLabel, infoMessage, type, checkType, id, value, placeholder, maxLength, onChange, onBlur, onKeyUp, disabled, style}) => {
  const [validation, setValidation] = useState('');

  const handleOnChange = (value) => {
    switch(checkType) {
      case 'text':
        isBlankValue(value) ? setValidation("error") : setValidation("success");
        break;
      case 'positiveNumber':
        isPositiveOrZero(value) ? setValidation("success") : setValidation("error");
        break;
      case 'email':
        if(!isBlankValue(value) && isValidEmail(value)) {
          setValidation("success");
        } else {
          setValidation("error")
        }
        break;
      case 'password':
        isValidPassword(value) ? setValidation("success") : setValidation("error");
        break;
      default:
        break;
    }
    onChange?.(value);
  }

  const handleOnBlur = (value) => {
    onBlur?.(value);
  }

  const handleOnKeyUp = (event) => {
    onKeyUp?.(event);
  }

  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <span>{subLabel}</span>
      <input className={`form-text ${validation}`}
        type={type}
        id={id}
        value={value||''}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        onKeyUp={(e) => handleOnKeyUp(e)}
        maxLength={maxLength}
        autoComplete="off"
        required
        disabled={disabled}
        style={style}/>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </InputGroup>
  );
}

const InputGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & input {
    display: block;
    padding-left: 0.4rem;
    width: 100%;
    height: 3rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0rem solid #ddd;
    border-radius: 0.4rem 0.4rem 0 0;
    border-bottom: 0.1rem solid #ddd;
    box-sizing : border-box;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;