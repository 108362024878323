import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { NewsListComponent } from '../component/NewsListComponent';
import Pagination from '../../../common/Pagination';
import { NewsApi } from '../api/NewsApi';
import { NewsSearch } from './component/NewsSearch';
import { numberToSimple } from '../../../utils/utils';
import { Helmet } from 'react-helmet-async';

export const NewsSearchList = memo(() => {
  const newsApi = new NewsApi();
  const navigate = useNavigate();
  const location = useLocation();
  const SAVED_SEARCH_NAME = "news_search";
  const [loading, setLoading] = useState(false);
  const [newsCountData, setNewsCountData] = useState({
    list:[
      {totalCount: 0, boardName: "전체", filter: "news", boardId: 0},
      {totalCount: 0, boardName: "컬럼", filter: "columnist", boardId: 20},
      {totalCount: 0, boardName: "기고", filter: "opinion", boardId: 22},
  ]});
  const [newsData, setNewsData] = useState({list:[]});

  const [searchData, setSearchData] = useState(() => {
    const isBack = location.state?.isBack;
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(isBack && saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: 10,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          filter: 'news',
          title: null,
          type: '',
          scrollY: 0,
          viewMode: 'text',
        };
      }
    }
  });

  const handleSearchOnClick = () => {
    handleSearchCount();
    handleSearchList();
  }

  const handleSearchCount = () => {
    if(searchData.title) {
      newsApi.fetchNewsSearchCount(
        searchData,
        (data) => {
          setNewsCountData(data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  const handleSearchList = () => {
    if(searchData.title) {
      setLoading(true);
      newsApi.fetchNewsSearchList(
        searchData,
        (data) => {
          setLoading(false);
          setNewsData(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          handleMoveScroll();
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    }
  }

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  const getTabTotalCount = (key) => {
    let list = newsCountData?.list.filter((element) => {
      return element.filter === key;
    });
    return list[0]?.totalCount;
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const isNews = () => {
    if('news' === searchData.filter) {
      return true;
    }
    return false;
  }

  const isOpinion = () => {
    if('opinion' === searchData.filter) {
      return true;
    }
    return false;
  }

  const isColumnist = () => {
    if('columnist' === searchData.filter) {
      return true;
    }
    return false;
  }

  const handleTabOnClick = (tabType) => {
    changeSearchData('filter', tabType);
    changeSearchData('page', 1);
  }

  useEffect(() => {
    handleSearchOnClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.title]);

  useEffect(() => {
    handleSearchList();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.filter]);

  return (
    <NewsWrapper>
      <Helmet>
        <title>{`뉴스 통합 검색 - 밴쿠버 조선일보`}</title>
      </Helmet>
      <NewsSearch
        placeholder={'검색어를 입력해주세요.'}
        searchData={searchData}
        changeSearchData={changeSearchData}
        handleSearchClick={handleSearchOnClick}
      />
      <Tabs>
        <div className={`${isNews() ? 'tab active' : 'tab'}`}
          onClick={() => handleTabOnClick('news')}>
          뉴스 <span>({numberToSimple(getTabTotalCount('news'))})</span>
        </div>
        <div className={`${isOpinion() ? 'tab active' : 'tab'}`}
          onClick={() => handleTabOnClick('opinion')}>
          기고 <span>({numberToSimple(getTabTotalCount('opinion'))})</span>
        </div>
        <div className={`${isColumnist() ? 'tab active' : 'tab'}`}
          onClick={() => handleTabOnClick('columnist')}>
          칼럼 <span>({numberToSimple(getTabTotalCount('columnist'))})</span>
        </div>
      </Tabs>
      <div className='list'>
        <NewsListComponent
          viewMode={searchData.viewMode}
          list={newsData.list}
          link={'news'}
          isLoading={loading}
          itemOnClick={handlerItemCardOnClick}
        />
      </div>
      <Pagination
        currentPage={searchData.page}
        totalPage={searchData.totalPageCount}
        perPageSize={searchData.perPageSize}
        handlePageChange={handlePageChange}
        color={'#424242'}
      />
    </NewsWrapper>
  );
});

const NewsWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 0rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 0rem 1.6rem;
    min-height: 46vh;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 0.1rem solid #F5F5F5;
  & div.tab {
    padding: 0 1.6rem;
    height: 2.75rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    text-align: center;
    cursor: pointer;
    & span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
  }
  & div.tab:first-child {
    margin-left: 1.6rem;
  }
  & div.active {
    font-weight: 500;
    color: #000000;
    border-bottom: 0.2rem solid #000;
    & span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #000000;
    }
  }
`;