import React, { useState } from 'react';
import styled from 'styled-components';

export const SortComponent = React.memo(({ sortDisplayList, sortCode, changeSortData }) => {
  const [show, setShow] = useState(false);

  const handleToggleSortModal = () => {
    setShow(!show);
  }

  const handlerOnClick = (id, name) => {
    changeSortData(id, name);
    handleToggleSortModal();
  }

  return (
    <SortWrapper>
      <SortTitle onClick={(e)=> handleToggleSortModal(e)}>
        {sortDisplayList[sortCode]?.label}
        {show?
          (
            <img src='/icon/angle-up-gray-icon.png' alt='정렬선택 열림'/>
          ):(
            <img src='/icon/angle-down-black-sm-icon.png' alt='정렬선택 닫힘'/>
          )
        }
      </SortTitle>
      <SortList show={show}>
        <ul>
          {Object.keys(sortDisplayList).map((key) => (
            <li key={key} className={`${sortCode === key ? 'active' : ''}`} onClick={() => handlerOnClick(key, sortDisplayList[key].label)}>
              <div>{sortDisplayList[key]?.label}</div>
            </li>
          ))}
        </ul>
      </SortList>
    </SortWrapper>
  );
});

const SortWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const SortTitle = styled.div`
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  color: #424242;

  border-left: 0.1rem solid #E0E0E0;
  cursor: pointer;
  & img {
    margin-left: 1rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`;
const SortList = styled.div`
  display: ${props => props.show ? '':'none'};
  position: absolute;
  top: 3.4rem;
  right: 0;
  z-index: 100;
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    box-shadow: 0 0.6rem 0.6rem 0 rgba(0, 0, 0, 0.1);

    & li {
      margin: 0rem;
      margin-bottom: -0.1rem;
      padding-left: 1.489rem;
      width: 14rem;
      height: 4.2rem;

      display: flex;
      flex-direction: column;
      justify-content: center;

      border: 0.1rem solid #eee;
      cursor: pointer;
      & div {
        font-weight: 400;
        font-size: 14px;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #616161;
      }
    }
    & li:first-child {
      border-radius: 0.4rem 0.4rem 0 0;
    }
    & li:last-child {
      border-radius: 0 0 0.4rem 0.4rem;
    }
    & li.active {
      background: #FFEAE9;
      & div {
        font-weight: 500;
        font-size: 14px;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #FD6258;
      }
    }
  }
`;