import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';

export const MainPhotoNews = ({list}) => {
  return (
    <MainNewsSection>
      {0 < list.length ? (
        <NewsMain>
          <Header>
            <Link to={`/photo`}>
              <h2>포토</h2>
            </Link>
          </Header>
          <ul>
            {list.map((row) => (
              <li key={row.id}>
                <Link to={`/photo/${row.id}`}>
                  <img src={`//cdn.vanchosun.com/news/File_images_board/${row.imageFile}`} alt="" loading='lazy'/>
                  <h3>{row.tagName ? (<span>[{row.tagName?.toUpperCase()}]</span>):''}{row.title}</h3>
                </Link>
              </li>
            ))}
          </ul>
        </NewsMain>
      ) : (
        <SkeletonComponent>
          <SkeletonSection>
            <Header>
              <h2>포토</h2>
            </Header>
            <ul>
              <li>
                <div className='skImg'></div>
                <div className='skTitle'></div>
              </li>
              <li>
                <div className='skImg'></div>
                <div className='skTitle'></div>
              </li>
            </ul>
          </SkeletonSection>
        </SkeletonComponent>
      )}
    </MainNewsSection>
  )
};

const MainNewsSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem 2rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
`;

const Header = styled.div`
  margin: 0;
  padding: 2rem 0 1.6rem;
  & h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;

const NewsMain = styled.div`
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    & li {
      margin: 0 auto;
      width: 48.5%;
      & a {
        color: #000;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        & img {
          height: 10.2rem;
          object-fit: cover;
          -webkit-user-drag: none;
        }
        & h3 {
          margin: 0;
          padding-top: 0.6rem;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.7rem;
          letter-spacing: -0.04em;
          color: #000000;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & span {
          padding-right: 0.5rem;
          font-weight: 500;
          color: #de2b36;
        }
      }
    }
  }
`;

const SkeletonSection = styled.div`
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    & li {
      margin: 0 auto;
      width: 48.5%;
      & div.skImg {
        width: 100%;
        height: 10.2rem;
        background-color: #F5F5F5;
      }
      & div.skTitle {
        margin-top: 0.6rem;
        padding: 0;
        width: 100%;
        height: 2.3rem;
        background-color: #F5F5F5;
      }
    }
  }
`;