import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import 'swiper/css';
import { SkeletonComponent } from '../../../common/SkeletonComponent';

export const MainBuySells = ({list}) => {
  const navigate = useNavigate();

  const handleButtonLinkOnClick = (e, url) => {
    navigate(url);
  }

  return (
    <>
      <MainBuySellSection>
        <Header>
          <h2>사고팔고</h2>
          <span><Link to={`/buysell`}>전체보기</Link></span>
        </Header>
        <ItemWrapper>
          {0 < list?.length ? (
            <>
              {list?.map((row) => (
                <li key={row.id} onClick={(e)=>handleButtonLinkOnClick(e, `/buysell/${row.id}`)}>
                  <div>{row.title}</div>
                </li>
              ))}
            </>
          ) : (
            <SkeletonComponent>
              <SkeletonSection>
                <ul>
                  <li><div></div></li>
                  <li><div></div></li>
                  <li><div></div></li>
                  <li><div></div></li>
                  <li><div></div></li>
                </ul>
              </SkeletonSection>
            </SkeletonComponent>
          )}
        </ItemWrapper>
      </MainBuySellSection>
    </>
  )
};

const MainBuySellSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 2rem 1.6rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
`;

const ItemWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & li {
    height: 4.4rem;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #F5F5F5;
    cursor: pointer;
    & div {
      font-size: 14px;
      font-weight: 400;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const Header = styled.div`
  padding: 2rem 0 1.6rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;

const SkeletonSection = styled.div`
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      height: 4.4rem;
      width: 100%;
      border-bottom: 0.1rem solid #F5F5F5;
      & div {
        display: block;
        width: 90%;
        height: 1.8rem;
        font-size: 14px;
        font-weight: 400;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        background-color: #F5F5F5;
      }
    }
  }
`;