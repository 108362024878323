import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
// import { RadioComponents } from '../../../common/RadioComponents';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { CommunityBorderCategoryLv1Modal } from './component/CommunityBorderCategoryLv1Modal';
import { CommunityBorderCategoryLv2Modal } from './component/CommunityBorderCategoryLv2Modal';
import { MarketEditor } from '../component/MarketEditor';
import { Loader } from '../../../common/Loader';
import { CommunityApi } from '../api/CommunityApi';
import { UserApi } from '../../user/api/UserApi';
import { isBlankValue, isValidEmail } from '../../../utils/utils';
import {
  CommunityBorderLv1Category,
  CommunityBorderLv2Category,
  // CommunityContacts,
} from '../../../__mocks__/PostData';

export const CommunityDetailEdit = ({setDialog}) => {
  const communityApi = new CommunityApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showBorderCategoryLv1Modal, setShowBorderCategoryLv1Modal] = useState(false);
  const [showBorderCategoryLv2Modal, setShowBorderCategoryLv2Modal] = useState(false);
  const [step, setStep] = useState(1);
  const [isNewPost] = useState(location?.state?.id?false:true);
  const [article, setArticle] = useState({
    id: location?.state?.id?location?.state?.id:'',
    rootBoardId: 0,
    boardId: 0,
    active: 1,

    name: '',
    email: '',

    title: '',
    description: '',

    images: {},
  });

  // == state 변경 ------------------------------------------------
  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeArticleImages = (index, imageData) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = {};
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleStepOnClick = (step, id='') => {
    const checkId = (id) ? id : article.id;
    if(2 === step && !checkId) {
      return;
    } else {
      setStep(step);
    }
  }

  const handleNameOnChange = (value) => {
    changeArticleData("name", value);
  }

  const handleEmailOnChange = (value) => {
    changeArticleData("email", value);
  }

  const handleTitleOnChange = (value) => {
    changeArticleData("title", value);
  }

  const handleSearchClick = () => {
    if(article.id) {
      setLoading(true);

      const formData = new FormData();
      formData.append('id', article.id);

      communityApi.fetchCommunityEditById(article.id,
        (data) => {
          setLoading(false);
          const { result } = data;
          setArticle(result);
        },
        (error) => {
          setLoading(false);
          console.log(error);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1);
        }
      );
    } else {
      userApi.fetchUser(
        (data) => {
          const { result: {nickName, contactEmail, }} = data;
          changeArticleData("name", nickName);
          changeArticleData("email", contactEmail);
        },
        (error) => {
          console.log(error);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const handleSaveForStep1OnClick = () => {
    const id = article.id;
    if(id) {
      saveForStep1();
    } else {
      createForStep1();
    }
  }

  const createForStep1 = () => {
    if(validateArticleDataForStep1ForCreate()) {
      const {
        boardId, active, name, email, title,
      } = article;
      const createData = JSON.stringify({
        'boardId': boardId,
        'active': active,
        'name': name,
        'email': email,
        'title': title,
      });

      setLoading(true);
      communityApi.createCommunityDetailForStep1(
        createData,
        (data) => {
          setLoading(false);
          changeArticleData('id', data.id);
          handleStepOnClick(2, data.id);
        },
        (error)=> {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const saveForStep1 = () => {
    if(validateArticleDataForStep1ForSave() && window.confirm("Step1 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const {
        id, boardId, name, email, title,
      } = article;
      const createData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'name': name,
        'email': email,
        'title': title,
      });

      communityApi.saveCommunityDetailForStep1(
        id,
        createData,
        (data) => {
          setLoading(false);
          handleStepOnClick(2);
        },
        (error)=> {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const handleSaveForStep2OnClick = () => {
    if(window.confirm(isNewPost?"Step2 정보를 등록 하시겠습니까?":"Step2 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id } = article;
      const updateData = JSON.stringify({
        'id': id,
        'description': editorRef.current.getContent(),
      });

      if(isNewPost) {
        communityApi.createCommunityDetailForStep2(id, updateData,
          (data) => {
            setLoading(false);
            setDialog({isOpen: true, message: "등록 하였습니다.", type: "alert"});
            navigate(`/community/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      } else {
        communityApi.saveCommunityDetailForStep2(id, updateData,
          (data) => {
            setLoading(false);
            setDialog({isOpen: true, message: "저장 하였습니다.", type: "alert"});
            navigate(`/community/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      }
    }
  }

  const uploadImageFile = (file, index) => {
    if(article.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', article.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      communityApi.createCommunityDetailImageFile(
        article.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(article.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': article.id,
      });

      communityApi.deleteCommunityDetailImageFile(
        article.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteArticleImages(index);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const toggleBorderCategoryLv1Modal = () => {
    setShowBorderCategoryLv1Modal(!showBorderCategoryLv1Modal);
  }

  const toggleBorderCategoryLv2Modal = () => {
    setShowBorderCategoryLv2Modal(!showBorderCategoryLv2Modal);
  }

  const handleBorderCategoryLv1OnChange = (value) => {
    changeArticleData('rootBoardId', value);
    toggleBorderCategoryLv1Modal();
    toggleBorderCategoryLv2Modal();
  };

  const handleBorderCategoryLv2OnChange = (value) => {
    changeArticleData('boardId', value);
    toggleBorderCategoryLv2Modal();
  };

  // ==== valid check ---------------------------------
  const isStep1 = () => {
    return (step === 1) ? true : false;
  }

  const isStep2 = () => {
    return (step === 2) ? true : false;
  }

  const validateArticleDataForStep1ForCreate = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "이름/닉네임은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(0>=article.boardId) {
      setDialog({isOpen: true, message: "게시판 선택은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateArticleDataForStep1ForSave = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "이름/닉네임은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(0>=article.boardId) {
      setDialog({isOpen: true, message: "게시판 선택은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // render ------------------------------------------------
  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <StepGroup>
        <div className={`${isStep1() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(1)}>Step 1</div>
        <div className={`${isStep2() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(2)}>Step 2</div>
      </StepGroup>
      {isStep1() && (
        <>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>작성자 정보를 입력해주세요</div>
              <InputComponent
                label={'이름/닉네임'}
                id={'name'}
                type={'text'}
                checkType={'text'}
                value={article.name}
                onChange={handleNameOnChange}
                disabled />
              <InputComponent
                label={'이메일'}
                id={'email'}
                type={'email'}
                checkType={'email'}
                value={article.email}
                placeholder={'입력하세요.'}
                onChange={handleEmailOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>게시글 정보를 입력해주세요</div>
              <RentTypeComponent>
                <label htmlFor='type'>게시판</label>
                <div className='rentTypeDisplay' onClick={()=>toggleBorderCategoryLv1Modal()}>
                  <span>전체</span>
                  <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
                  <span>{CommunityBorderLv1Category[CommunityBorderLv2Category[article.boardId]?.lv1]?.label}</span>
                  {CommunityBorderLv1Category[CommunityBorderLv2Category[article.boardId]?.lv1]?.label && (
                    <>
                      <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
                      <span>{CommunityBorderLv2Category[article?.boardId]?.label}</span>
                    </>
                  )}
                  <img className='rentTypeButton' src='/icon/angle-right-gray-icon.png' alt='arrow right' />
                </div>
                <CommunityBorderCategoryLv1Modal
                  title={"카테고리"}
                  borderLv1Categories={CommunityBorderLv1Category}
                  showModal={showBorderCategoryLv1Modal}
                  toggleModal={toggleBorderCategoryLv1Modal}
                  handlerValueOnChange={(value)=>handleBorderCategoryLv1OnChange(value)}
                />
                <CommunityBorderCategoryLv2Modal
                  title={"카테고리"}
                  borderLv1Categories={CommunityBorderLv1Category}
                  borderLv1CategoryValue={article.rootBoardId}
                  borderLv2Categories={CommunityBorderLv2Category}
                  showModal={showBorderCategoryLv2Modal}
                  toggleModal={toggleBorderCategoryLv2Modal}
                  handlerValueOnChange={(value)=>handleBorderCategoryLv2OnChange(value)}
                />
              </RentTypeComponent>
              <InputComponent
                label={'제목'}
                id={'title'}
                type={'text'}
                checkType={'text'}
                value={article.title}
                placeholder={'제목을 입력하세요.'}
                onChange={handleTitleOnChange} />
            </ArticleForm>
            <NextButton
              type='button'
              color="#fff"
              backgroundColor='#fd6258'
              onClick={() => handleSaveForStep1OnClick()}>
              {isNewPost? '다음' : '수정하기'}
            </NextButton>
          </ContentWrap>
        </>
      )}
      {isStep2() && (
        <ContentWrap>
          <ImageUploadComponent
            images={article.images}
            uploadProcess={uploadImageFile}
            deleteProcess={deleteImageFile}
            setDialog={setDialog}
          />
          <Label htmlFor='description'>
            상세내용
            <span>(선택)</span>
          </Label>
          <MarketEditor
            id={'description'}
            editorRef={editorRef}
            placeholder='궁금하거나 공유하고 싶은 게시글 내용을 입력해주세요.'
            infoMessage='* 타인에게 불쾌감을 주는 욕설, 비방, 저작권 위반 등의 내용은 사전통지 없이 삭제됩니다.'
            infoMessageColor={'#FD6258'}
            description={article.description}
            disabled={false}
          />
          <NextButton
            type='button'
            color="#fff"
            backgroundColor='#fd6258'
            onClick={() => handleSaveForStep2OnClick()}>
            {isNewPost? '등록' : '수정하기'}
          </NextButton>
        </ContentWrap>
      )}
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;
const StepGroup = styled.div`
  padding: 1.3rem 1.6rem 0;
  display: flex;
  justify-content: center;
  & div.step {
    width: 50%;
    height: 4.2rem;

    font-size: 14px;
    font-weight: 700;
    line-height: 4.2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  & div.step:first-child {
    border: 0.1rem solid #eee;
    border-radius: 0.4rem 0rem 0rem 0.4rem;
  }
  & div.step:last-child {
    border: 0.1rem solid #eee;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
  }
  & div.step.active {
    color: #fd6258;
    background-color: #ffeae9;
    border-color: #ffeae9;
  }
`;

const ContentWrap = styled.div`
  padding: 1.4rem;
  height: 77%;
`;

const ArticleForm = styled.div`
  margin-bottom: 0.5rem;
  & div.articleLabel {
    margin: 3.2rem 0rem 2rem 0rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const NextButton = styled.button`
  margin: 3.6rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const Label = styled.label`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 2.3rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const RentTypeComponent = styled.div`
  margin: 2rem 0;
  height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div.rentTypeDisplay {
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 0.1rem solid #F5F5F5;
    & span {
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
    & img.rentTypeButton {
      position: absolute;
      top: 0.8rem;
      right: 0rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;
