import React, { useEffect, useState } from 'react';
import { BuysellApi } from '../api/BuysellApi';
import { HomestayApi } from '../api/HomestayApi';
import { RentApi } from '../api/RentApi';
import { DwellingApi } from '../api/DwellingApi';
import { CommercialApi } from '../api/CommercialApi';
import { JobApi } from '../api/JobApi';
import { CarApi } from '../api/CarApi';
import { TutorApi } from '../api/TutorApi';
import { MarketMainTopAd } from '../../ads/MarketMainTopAd';
import { MobileBanner } from '../../ads/MobileBanner';
import { MainRestaurant } from '../component/MainRestaurant';
import { MainRestaurantCategory } from '../component/MainRestaurantCategory';
import { RecommendedAdList } from '../../ads/RecommendedAdList';
import { MarketPickAd } from '../../ads/MarketPickAd';
import { MainBuySells } from '../component/MainBuySells';
import { MainJobs } from '../component/MainJobs';
import { MainTutor } from '../component/MainTutor';
import { MainCars } from '../component/MainCars';
import { MainRentHomestay } from '../component/MainRentHomestay';
import { MainRealty } from '../component/MainRealty';
import { MainCommunity } from '../component/MainCommunity';
import { MarketMenuButtons } from '../component/MarketMenuButtons';
import { MarketPopupModal } from '../../ads/MarketPopupModal';
import { Separator } from '../../../common/Separator';
import { Helmet } from 'react-helmet-async';

export const MarketMain = () => {
  const HAS_VISITED_BEFORE = "hasMarketVisitedBefore";
  const buysellApi = new BuysellApi();
  const homestayApi = new HomestayApi();
  const rentApi = new RentApi();
  const dwellingApi = new DwellingApi();
  const commercialApi = new CommercialApi();
  const jobApi = new JobApi();
  const carApi = new CarApi();
  const tutorApi = new TutorApi();

  const [showMarketPopupModal, setShowMarketPopupModal] = useState(() => {
    if(typeof window !== "undefined") {
      const hasVisitedBefore = window.localStorage.getItem(HAS_VISITED_BEFORE);
      if(hasVisitedBefore && hasVisitedBefore > new Date()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
  const [buysellList, setBuysellList] = useState({list:[]});
  const [homestayList, setHomestayList] = useState({list:[]});
  const [rentList, setRentList] = useState({list:[]});
  const [dwellingList, setDwellingList] = useState({list:[]});
  const [commercialList, setCommercialList] = useState({list:[]});
  const [jobList, setJobList] = useState({list:[]});
  const [carList, setCarList] = useState({list:[]});
  const [tutorList, setTutorList] = useState({list:[]});

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/market/main/index.php'; // 여기서 www.com URL로 변경
  }

  const handleSearch = () => {
    buysellApi.fetchBuysellList({ page: 1, listSize: 15, premiumListSize: 0 }, (data) => {
      setBuysellList(data);
    });
    homestayApi.fetchHomestayList({ page: 1, listSize: 1 }, (data) => {
      setHomestayList(data);
    });
    rentApi.fetchRentList({ page: 1, listSize: 1 }, (data) => {
      setRentList(data);
    });
    dwellingApi.fetchDwellingList({ page: 1, listSize: 1 }, (data) => {
      setDwellingList(data);
    });
    commercialApi.fetchCommercialList({ page: 1, listSize: 1 }, (data) => {
      setCommercialList(data);
    });
    jobApi.fetchJobList({ page: 1, listSize: 4, premiumListSize: 0 }, (data) => {
      setJobList(data);
    });
    carApi.fetchCarList({ page: 1, listSize: 8 }, (data) => {
      setCarList(data);
    });
    tutorApi.fetchTutorList({ page: 1, listSize: 4, premiumListSize: 0 }, (data) => {
      setTutorList(data);
    });
  };

  const toggleMarketPopupModal = () => {
    setShowMarketPopupModal(!showMarketPopupModal);
  }

  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{`밴마켓 - 밴쿠버 조선일보`}</title>
      </Helmet>
      <div>
        <MarketMainTopAd position={'market_top_ra'} listSize={15}/>
      </div>
      <div style={{paddingBottom: '1.5rem', backgroundColor: '#fff'}}>
        <MarketMenuButtons />
        <MobileBanner position={'market_homebutton_da'} listSize={5} />
      </div>
      <Separator height={'1rem'}/>
      <div style={{backgroundColor: '#fff'}}>
        <MainCommunity />
        <MobileBanner position={'market_community_da'} listSize={5} />
        <MainBuySells list={buysellList.list} />
      </div>
      <Separator height={'1rem'}/>
      <div>
        <RecommendedAdList position={'market_recommend_ra'} listSize={5} />
      </div>
      <Separator height={'1rem'}/>
      <div>
        <MainJobs list={jobList.list} />
      </div>
      <Separator height={'1rem'}/>
      <div>
        <MainRestaurant position={'market_restaurant_ra'} listSize={5} />
        <MainRestaurantCategory />
      </div>
      <Separator height={'1rem'}/>
      <div>
        <MainRentHomestay homestayList={homestayList.list} rentList={rentList.list}/>
      </div>
      <Separator height={'1rem'}/>
      <div>
        <MainRealty dwellingList={dwellingList.list} commercialList={commercialList.list} />
      </div>
      <Separator height={'1rem'}/>
      <div>
        <MarketPickAd position={'market_pick_da'} listSize={8}/>
      </div>
      <Separator height={'1rem'}/>
      <div style={{backgroundColor: '#fff'}}>
        <MainTutor list={tutorList.list} />
        <MobileBanner position={'market_tutor_da'} listSize={2}/>
        <MainCars list={carList.list} />
        <MobileBanner position={'market_car_da'} listSize={2}/>
      </div>
      <MarketPopupModal
        show={showMarketPopupModal}
        toggle={toggleMarketPopupModal}
        position={'market_popup_ad'}
        savedName={HAS_VISITED_BEFORE}
        listSize={2}
      />
    </>
  );
}
