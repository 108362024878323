import React from 'react';
import styled from 'styled-components';

export const MarketProhibitGoods = () => {

  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <PageTitle>
            거래 하시기 전에<br/>
            안내 사항을 꼭 확인해주세요!
          </PageTitle>
          <PageDescription>
            <ul className='info'>
              <li><div>밴쿠버 조선일보는 사이트 내의 장터에서 이루어지는 거래에 대하여 어떠한 책임도 지지 않습니다.</div></li>
              <li><div>중고거래의 공간만을 제공할 뿐 거래에 대한 어떠한 규제/관리의 의무를 보증하지 않습니다.</div></li>
              <li><div>중고장터의 거래는 전적으로 회원의 책임임을 인지하시기를 바랍니다.</div></li>
            </ul>
            <ul className='warning'>
              <li><div>거래 금지 항목 또는 저희 밴조선과 계약을 맺지 않은 상업적 광고나 상업적 이윤을 취하기 위한 물품 판매 등 게시판 취지에 어긋나는 글은 경고없이 삭제됩니다.</div></li>
              <li><div>지속적으로 거래 금지 항목이나 상업적 광고 게시글을 작성할 경우 해당 IP, 연락처 등을 차단되고 밴조선 이용에 제한이 있을 수 있습니다.</div></li>
            </ul>
          </PageDescription>
        </ContentWrapper>
        <Separator />
        <ContentWrapper>
          <DescriptionTitle>자동차 및 오토바이, 차량용 타이어</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              계절용 타이어(여름용, 겨울용, 4계절용 모두 포함) 금지
            </div>
            <ul>
              <li><div> Note: 자동차와 오토바이 거래는 자동차 매매 페이지에서 거래 가능</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>환전</DescriptionTitle>
          <DescriptionBox>
            <ul>
              <li><div>Note: OSC, BCSC에 등록된 외환 거래소만 허용. 그 외에는 모든 환전 거래 금지</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>애완 동물</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              애완 동물 입양 및 파양 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>부동산 (토지, 주택, 비즈니스 매물 등)</DescriptionTitle>
          <DescriptionBox>
            <ul>
              <li><div>Note: 부동산 관련 거래는 부동산 페이지에서 거래 가능</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>아기용품<br/>(Health Canada에서 안전하지 않을 수도 있다고 여기는 아기용품들)</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              유아용 역류방지 쿠션 (Baby Nests)<br/>
              유아용 걸음보조기 (Baby Walkers)<br/>
              유아용 해먹<br/>
              카시트와 부스터 시트 (제작 년도가 2017년 01월 01일 이전 제품은 금지)
            </div>
            <ul>
              <li><div>Note: 카시트와 부스터 시트를 판매할 때, 해당 제품 제작 년도 사진을 필히 첨부해야 허용. 그렇지 않을 시, 해당 게시물 삭제 조치</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>채혈 또는 신체 일부분</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              신체 일부 또는 장기 거래 금지<br/>
              수술, 헌혈 강요 또는 신체 기증 금지 (ex. 정자, 난자, 각막, 기타 신체 장기 기증)
            </div>
          </DescriptionBox>

          <DescriptionTitle>담배 또는 니코틴이 금지된 제품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              전자 담배, 액상/전자 주스(e-juice), 기타 혼합형 담배가루 등 금지<br/>
              물 담배, 파이프 담배, 전자 담배 금지<br/>
              담배 씨앗 또는 모종 금지<br/>
              담배 관련 판촉 및 기념품 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>대마 및 대마 관련 제품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              대마 성분을 추출한 식용 음식 또는 기름 금지<br/>
              물 담배, 파이프 담배, 전자 담배 금지<br/>
              Cannabis 판촉 및 기념품 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>
            입양 서비스
          </DescriptionTitle>
          <DescriptionTitle>COVID-19 관련 물품들</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              COVID-19 PCR과 신속 항원 검사기(Rapid Antigen Tests)<br/>
              백신 증명서
            </div>
          </DescriptionBox>

          <DescriptionTitle>위조 또는 지적 재산권을 침해하는 물품들</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              위조 화폐, 위조 우표, 위조 동전 등<br/>
              e-book 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>온라인 상품이나 온라인 계정</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              게임 계정, 다중 계정을 허용하는 OTT 서비스, 디지털 저작권을 가지고 있는 영화, 서적, 소프트웨어 등 파일 공유 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>
            캐나다 내 수입금지 품목
          </DescriptionTitle>
          <DescriptionTitle>정부발행 ID, 정부 관련 배지, 유니폼 또는 자격증</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              군대, 경찰, 우체국 등 공공기관 물품 및 유니폼 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>개인 신분증, 재정 서류 또는 개인 은행 거래 정보, 개인 정보 등</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              어떠한 형태로든 거래 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>위험 화학 물품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              사용제한 또는 금지된 화학품이나 해충 방제품과 불꽃놀이 용품 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>허가 받지 않은 약물이나 의료 용품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              마약류, 불법 스테로이드 등 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>약 또는 의료기구 처방전</DescriptionTitle>

          <DescriptionTitle>범죄(주거침입, 강도, 절도 등)에 사용되는 도구</DescriptionTitle>

          <DescriptionTitle>절도 물품</DescriptionTitle>

          <DescriptionTitle>불법 야생 동, 식물 관련 물품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              곰 덫 (크기 무관)<br/>
              마리모(Moss ball) 관련 제품<br/>
              불법 또는 허가 받지 않은 동물들의 장기<br/>
              검역 해충
            </div>
          </DescriptionBox>

          <DescriptionTitle>칼</DescriptionTitle>
          <DescriptionBox>
            <ul>
              <li><div>Note: 수집용 도검류 (날카롭지 않고 위험성이 낮은) 주방용 조리 기구 및 도축용 칼은 허용</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>로또, 대행 서비스, 슬롯머신</DescriptionTitle>

          <DescriptionTitle>회수 대상 제품들</DescriptionTitle>

          <DescriptionTitle>식재료 및 식품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              아래 항목들을 금지하지만, 단 이에 국한되지 않음:<br/>
              - 미 살균 유제품<br/>
              - 가열 살균 처리가 안된 과일주스나 야채 주스<br/>
              - 야생 버섯<br/>
              - Ackee 과일 (씨앗도 금지)<br/>
              - Canadian Food Inspection Agency에서 회수처분을 한 음식료 제품들<br/>
              - 유통기한 지난 제품과 유아 식품<br/>
              - 개봉하거나 변질된 제품 또는 유아 식품<br/>
              - 3년 이상 지난 간편 조리형 제품
            </div>
          </DescriptionBox>

          <DescriptionTitle>주식 또는 암호화폐 매매</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              모든 암호 화폐 금지<br/>
              바이너리 옵션 (binary options) 금지
            </div>
            <ul>
              <li><div>Note: 2017년 9월 28일, CSA(the Canadian Securities Administrators)에서 바이너리 옵션에 대한 광고, 오퍼, 판매, 거래 등을 모두 금한다고 발표하였습니다.</div></li>
            </ul>
          </DescriptionBox>

          <DescriptionTitle>성인용품</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              포르노, 누드사진, 영상, 소설, 성인용품 금지<br/>
              매춘 행위 금지
            </div>
          </DescriptionBox>

          <DescriptionTitle>무기 및 흉기</DescriptionTitle>
          <DescriptionBox>
            <div className='singleDescription'>
              아래 항목들을 금지하지만, 단 이에 국한되지 않음:<br/>
              - 총기류<br/>
              - 총기류 부품이나 관련 서적<br/>
              - 탄약<br/>
              - BB탄 또는 쇠구슬 탄 총기류<br/>
              - 복제 무기류<br/>
              - 최루탄<br/>
              - 스턴건 (Stun guns)<br/>
              - 석궁<br/>
              - 무술용 무기류(ex. 봉, 곤봉, 쌍절곤, 톤파 등)<br/>
              - 공기총, 공기총 부품, 탄약 금지<br/>
            </div>
            <ul>
              <li><div>Note: 양궁 및 페인트볼 장비는 허용</div></li>
            </ul>
          </DescriptionBox>
        </ContentWrapper>
      </PageWrapper>
    </>
  );

}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  width: auto;
  position: relative;
  background-color: #fff;
  border-top: 0.1rem solid #e5e5e5;
`;

const PageTitle = styled.h1`
  margin: 2rem auto;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000 ;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: auto;
  position: relative;
  background-color: #fff;
`;

const Separator = styled.div`
  margin: 0;
  padding: 0;
  height: 1rem;
  width: 100%;
  background-color: #f7f7f7;
`;

const DescriptionTitle = styled.div`
  margin-top: 3rem;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const DescriptionBox = styled.div`
  padding: 0;
  margin: 0.6rem 0;
  & ul {
    margin: 0;
    padding-left: 2.1rem;
    list-style-type: disc;
    & li {
      font-size: 7px;
      color: #424242;
      & div {
        margin: 1rem 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 1.7rem;
        color: #424242;
      };
    }
  }
  & div.singleDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;

const PageDescription = styled.div`
  padding: 0;
  margin: 0;
  & ul.info {
    margin: 0;
    padding: 1rem 2rem 1rem 2.6rem;
    background-color: #f5f5f5;
    border-radius: 0.4rem;
    & li {
      font-size: 7px;
      color: #424242;
      & div {
        margin: 1rem 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #424242;
      };
    }
  }
  & ul.warning {
    margin: 0;
    padding: 0;
    padding-left: 1.3rem;
    list-style-type: "※";
    & li {
      font-size: 12px;
      color: #FD6258;
      & div {
        margin: 1rem 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.04em;
        line-height: 1.7rem;
      };
    }
  }
`;

