import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../../common/CommonModal';

export const CommunityBorderCategoryLv2Modal = React.memo(({
  title, borderLv1Categories, borderLv1CategoryValue, borderLv2Categories,
  showModal, toggleModal, handlerValueOnChange }) => {

  const handleToggleModal = () => {
    toggleModal?.();
  }

  const handlerOnClick = (value) => {
    handlerValueOnChange?.(value);
  }

  useEffect(() => {
    if(showModal) {
      document.body.style= `overflow: hidden`;
    }
    return () => document.body.style = `overflow: auto`;
  }, [showModal]);

  return (
    <>
      {showModal&&(
        <CommonModal width={'100%'}>
          <ContentModalWrapper>
            <ContentTitle>
              {title}
              <img src='/icon/x-mark.png'
                alt='Modal Close'
                onClick={()=> handleToggleModal()}
              />
            </ContentTitle>
            <RoomTypeList>
              <div className='roomTypeListTitle'>
                전체
                <img src='/icon/angle-right-gray-icon.png' alt='arrow right' />
                <span>{`${borderLv1Categories[borderLv1CategoryValue].label}`}</span>
              </div>
              <ul>
                {Object.keys(borderLv2Categories).map((key) => (borderLv2Categories[key].lv1 === borderLv1CategoryValue) && (
                  <li key={key} onClick={()=>handlerOnClick(borderLv2Categories[key].value)}>
                    <div>
                      {borderLv2Categories[key].label}
                    </div>
                  </li>
                ))}
              </ul>
            </RoomTypeList>
          </ContentModalWrapper>
        </CommonModal>
      )}
    </>
  );
});

const ContentModalWrapper = styled.div`
  position: relative;
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;
const ContentTitle = styled.div`
  padding: 0rem 2rem;
  width: auto;
  height: 6.8rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RoomTypeList = styled.div`
  display: flex;
  flex-direction: column;
  & div.roomTypeListTitle {
    padding-left: 2rem;
    height: 3.5rem;

    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    border-bottom: 0.1rem solid #f5f5f5;
    & span {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      color: #FD6258;
    }
    & img {
      margin: 0 0.7rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  & ul {
    margin: 0;
    padding-left: 2rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & li {
      position: relative;
      width: auto;
      height: 4.8rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      & div {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4rem;
      }
    }
    & li.active {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #FD6258;
      background: #FFEAE9;
    }
  }
`;
