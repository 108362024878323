import React from 'react';
import styled from 'styled-components';

export const MarketPrivacyPolicy = () => {

  return (
    <PageWrapper>
      <PageTitle>
        개인정보 취급/수집 및 사용 동의<br/>
        (Privacy Policy)
      </PageTitle>
      <DescriptionBox>
        <ul>
          <li><div>개인 정보란 한 사람을 식별하거나 한 사람에게 연락하는데 사용할 수 있는 데이터를 말합니다.</div></li>
          <li><div>밴조선 미디어 또는 밴쿠버 코리언 프레스(이하 밴조선)는 언제든지 개인 정보를 제공 요청할 수 있습니다.</div></li>
          <li><div>밴조선은 개인 정보를 사내 공유하고, 본 개인정보 취급방침에 따라 사용할 수 있습니다.</div></li>
          <li><div>또한 콘텐츠, 서비스와 광고를 개선하기 위해 다른 정보와 함께 사용할 수 있습니다.</div></li>
          <li><div>고객은 밴조선에 개인 정보를 제공할 의무는 없으나 제공하지 않으면 밴조선은 일부 서비스를 제공할 수 없습니다.</div></li>
          <li><div>Personal information is data that can be used to identify or contact a single person.</div></li>
          <li><div>You may be asked to provide your personal information anytime you are in contact with Vanchosun Media and Vancouver Korean Press Ltd. (the "Vanchosun", "we", or "us")</div></li>
          <li><div>The Vanchosun may share the personal information within company staffs and use it consistent with this privacy policy.</div></li>
          <li><div>They may also combine it with other information to provide and improve our content, services, and advertising.</div></li>
          <li><div>You are not required to provide the personal information that we have requested, but, if you chose not to do so, in many cases we will not be able to provide you with our services or respond to any queries you may have.</div></li>
        </ul>
      </DescriptionBox>

      <Separator />

      <DescriptionTitle>1. 밴조선이 수집하는 개인 정보</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>고객이 밴조선 회원에 가입하거나, 글이나 광고를 남기거나, 저희와 연락을 취하거나, 설문에 참여할 때 저희는 고객의 성함, 주소, 전화번호, 이메일 주소, IP주소, 서비스 이용날짜와 시간, 신용카드 정보 등 다양한 정보를 수집할 수 있습니다.</div></li>
          <li><div>밴조선은 웹사이트에서 고객에게 더 유용한 정보를 제공하기 위해 고객의 웹사이트 이용 정보를 수집할 수 있습니다.</div></li>
          <li><div>이때 수집된 자료는 저희 웹사이트와 콘텐츠, 서비스 중 무엇이 고객의 관심을 끄는지 파악하는데 이용됩니다.</div></li>
          <li><div>이용 정보는 개인 정보가 아니지만, 만약 개인정보와 연결해 사용할 때는 개인 정보로 간주하고 취급하게 됩니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>1. Collection and Use of Personal Information</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>When you join a Vanchosun Membership, posting your articles or advertisement, contact us or participate in a survey, we may collect a variety of information, including your name, mailing address, phone number, email address, contact preferences, IP Address, service date and time, and credit card information.</div></li>
          <li><div>We may collect information regarding customer activities on our website.</div></li>
          <li><div>This information is aggregated and used to help us provide more useful information to our customers and to understand which parts of our website, content and services are of most interest.</div></li>
          <li><div>Aggregated data is considered non-personal information for the purposes of this Privacy Policy. If we do combine non-personal information with personal information the combined information will be treated as personal information for as long as it remains combined.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>2. 개인정보 사용처</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>밴조선은 수집한 개인 정보를 통해 뉴스와 안내를 제공합니다. 저희 메일 알림 수신을 원치 않으시면, 언제든지 회원 정보 변경을 통해 거절할 수 있습니다.</div></li>
          <li><div>밴조선은 서비스, 콘텐츠 및 광고를 제작, 개발, 운영, 제공 및 개선하기 위해, 그리고 손실 방지 및 사기 방지 용도로 개인정보를 사용할 수 있습니다.</div></li>
          <li><div>상황에 따라 개인 정보를 이용해 여러분의 광고나 글, 약관이나 조건, 정책 변경과 같은 중요한 통보를 할 수도 있습니다. 운영에 필수적인 통보이기 때문에, 이때는 수신을 거부할 수 없습니다.</div></li>
          <li><div>또한 밴조선은 콘텐츠와 서비스 강화, 고객과 연락을 위해 개인정보를 사내에서 활용할 수도 있습니다.</div></li>
          <li><div>여러분이 추첨, 경연, 또는 이와 유사한 판촉행사 참가 시, 여러분이 제공한 개인정보를 사용하게 됩니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>2. How we use your personal information</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>The personal information we collect allows us to keep you received Vanchosun’s news and announcement. If you don&#39;t want to be on our mailing list, you can opt out anytime by updating your preferences.</div></li>
          <li><div>We also use personal information to help us create, develop, operate, deliver, and improve our services, content, and advertising, and for loss prevention and anti-fraud purposes.</div></li>
          <li><div>From time to time, we may use your personal information to send important notices, such as communications about your advertisement, Your articles and changes to our terms, conditions, policies. Because this information is important to your interaction with Vanchosun, you may not opt out of receiving these communications.</div></li>
          <li><div>We may also use personal information for internal purposes such as auditing, data analysis, and research to improve our contents, services, and customer communications.</div></li>
          <li><div>If you engage a sweepstake, contest, or similar promotion we may use the information you provide to administer those programs.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>3. 개인정보 보호</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>밴조선은 개인 정보를 아래 정의하는 예외적인 경우를 제외하고 제3자와 공유하지 않습니다. 밴조선 미디어에 개인 정보 공개를 요청할 경우 저희는 거절할 수 있습니다.</div></li>
          <li><div>밴조선은 국가안보, 법 집행, 또는 기타 중요한 공공 사안으로 공개가 필요하거나 적절하다고 판단하는 경우에 고객 정보를 공개할 수 있습니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>3. Protection of Personal Information</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>Vanchosun may not disclose the personal information with third parties unless exceptional circumstances in this policy. If third parties request to disclose the personal information, we may deny disclosing.</div></li>
          <li><div>We may disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>4. 환불 정책</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>최종적으로 상품 결제가 완료가 되면, 환불이 되지 않습니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>4. Return Policy</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>All purchases are final once the transaction is completed and are not subject to any refunds.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>5. 개인정보 접근</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>밴조선은 서면요청을 받으면 수집, 사용, 공개한 개인 정보를 당사자에게 법이 허용하는 선에서 제공할 수 있습니다.</div></li>
          <li><div>사소하거나, 업무 방해 목적으로 이뤄지거나, 다른 이들의 개인 정보 보안을 위태롭게 하거나, 극도로 비실용적이거나, 법에서 적절치 않은 요청에 대해서는 처리를 거부할 수 있습니다.</div></li>
          <li><div>개인 정보의 접근, 수정, 삭제 요청은 서면으로 요청할 수 있습니다.<br/>이메일 주소: web@vanchosun.com</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>5. Access to Personal Information</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>Vanchosun will make available to you, your own personal information that we have collected, used, or disclosed, upon your written request, to the extent permitted by law.</div></li>
          <li><div>We may decline to process requests that are frivolous/vexatious, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by law.</div></li>
          <li><div>Access, correction, or deletion requests can be made through your written request, e-mail: web@vanchosun.com</div></li>
        </ul>
      </DescriptionBox>

    </PageWrapper>
  );

}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: auto;
  position: relative;
  border-top: 0.1rem solid #e5e5e5;
  background-color: #fff;
`;

const PageTitle = styled.h1`
  margin: 2rem auto;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000 ;
`;

const Separator = styled.div`
  margin: 3rem 0;
  height: 0rem;
  width: 100%;
  border-top: 0.1rem solid #e5e5e5;
`;

const DescriptionTitle = styled.div`
  margin-top: 3rem;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const DescriptionBox = styled.div`
  padding: 0;
  margin: 0;
  & ul {
    margin: 0;
    padding-left: 2.1rem;
    list-style-type: disc;
    & li {
      font-size: 7px;
      color: #424242;
      & div {
        margin: 1rem 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 1.7rem;
        color: #424242;
      };
    }
  }
`;

