import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { SelectComponent } from '../../../common/SelectComponents';
import { RadioComponents } from '../../../common/RadioComponents';
import { RadioButtonComponents } from '../../../common/RadioButtonComponents';
import { InputPriceComponent } from '../../../common/InputPriceComponent';
import { CheckBoxTypeComponent } from '../../../common/CheckBoxTypeComponent';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { SelectForListComponents } from '../../../common/SelectForListComponents';
import { SwitchComponent } from '../../../common/SwitchComponent';
import { MarketEditor } from '../component/MarketEditor';
import { Loader } from '../../../common/Loader';
import { BuysellApi } from '../api/BuysellApi';
import { UserApi } from '../../user/api/UserApi';
import { autoAddHyphenForPhoneNumber, isBlankValue, isPositiveOrZero, isValidEmail, isValidSpecificCharacters } from '../../../utils/utils';
import {
  BuysellContacts,
  BuysellCategories,
  BuysellConditions,
  BuysellPurchaseStatus,
  CityList
} from '../../../__mocks__/PostData';

export const BuysellDetailEdit = ({setDialog}) => {
  const buysellApi = new BuysellApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showHideActive, setShowHideActive] = useState(false);
  const [isNewPost] = useState(location?.state?.id?false:true);
  const [article, setArticle] = useState({
    id: location?.state?.id?location?.state?.id:0,
    boardId: 1,
    active: 0,
    name: '',
    phone: '',
    email: '',
    contactCode: 'phone',
    title: '',
    description: '',
    type: '',
    categoryCode: '',
    location: '5',
    price: 0,
    conditionCode: '',
    purchaseStateCode: '',
    updatedDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------
  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeArticleImages = (index, imageData) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = null;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleStepOnClick = (step, id='') => {
    const checkId = (id) ? id : article.id;
    if(2 === step && !checkId) {
      return;
    } else {
      setStep(step);
    }
  }

  const handleNameOnChange = (value) => {
    if(isValidSpecificCharacters(value)) {
      changeArticleData("name", value);
    }
  }

  const handleEmailOnChange = (value) => {
    changeArticleData("email", value);
  }

  const handleTitleOnChange = (value) => {
    if(isValidSpecificCharacters(value)) {
      changeArticleData("title", value);
    }
  }

  const handlePriceOnChange = (value) => {
    changeArticleData("price", value.replace(/[^.0-9]/g, ""));
    changeArticleData("price", value);
  }

  const handlePhoneNumberOnChange = (value) => {
    changeArticleData("phone", autoAddHyphenForPhoneNumber(value));
  };

  const handleLocationOnChange = (e) => {
    changeArticleData('location', e.target.value);
  }

  const handleCategoryCodeOnChange = (e) => {
    changeArticleData("categoryCode", e.target.value);
  }

  const handleContactCodeOnChange = (e) => {
    changeArticleData('contactCode', e.target.value);
  };

  const handleConditionCodeOnChange = (value) => {
    changeArticleData('conditionCode', value);
  }

  const handlePurchaseStateCodeOnChange = (value) => {
    changeArticleData('purchaseStateCode', value);
  }

  const handleMovingSaleOnChange = ({target}) => {
    if(target.checked) {
      changeArticleData("type", '무빙세일');
    } else {
      changeArticleData("type", '');
    }
  }

  const handleFreeOnChange = ({target}) => {
    if(target.checked) {
      changeArticleData("type", '무료나눔');
    } else {
      changeArticleData("type", '');
    }
  }

  const handleCloseActiveOnChange = (value) => {
    if(value) {
      changeArticleData("active", 0);
    } else {
      changeArticleData("active", 1);
    }
  }

  const handleSearchClick = () => {
    if(article.id) {
      setLoading(true);

      buysellApi.fetchBuysellEditById(
        article.id,
        (data) => {
          setLoading(false);
          const { result } = data;
          setArticle(result);
          if(result?.active===1){
            setShowHideActive(true);
          } else {
            setShowHideActive(false);
          }
        },
        (error) => {
          setLoading(false);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1);
        }
      );
    } else {
      userApi.fetchUser(
        (data) => {
          const { result: {nickName, contactEmail, phone} } = data;
          changeArticleData("name", nickName);
          changeArticleData("phone", phone);
          changeArticleData("email", contactEmail);
        },
        (error) => {
          console.log(error);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              // setDialog({hasError: true, message: `${message}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              // setDialog({hasError: true, message: `${statusText}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const uploadImageFile = (file, index) => {
    if(article.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', article.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      buysellApi.createBuysellImageFile(
        article.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(article.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': article.id,
      });

      buysellApi.deleteBuysellImageFile(
        article.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteArticleImages(index);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const handleSaveForStep1OnClick = () => {
    const id = article.id;
    if(id) {
      saveForStep1();
    } else {
      createForStep1();
    }
  }

  const createForStep1 = () => {
    if(validateArticleDataForStep1ForCreate()) {
      const {
        boardId, active, name, email,
        phone, title, type, location, contactCode, categoryCode, price
      } = article;
      const createData = JSON.stringify({
        'boardId': boardId,
        'active': active,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'type': type,
        'location': location,
        'categoryCode': categoryCode,
        'price': price,
      });
      setLoading(true);

      buysellApi.createBuysellForStep1(
        createData,
        (data) => {
          setLoading(false);
          changeArticleData('id', data.id);
          handleStepOnClick(2, data.id);
        },
        (error) => {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const saveForStep1 = () => {
    if(validateArticleDataForStep1ForSave() && window.confirm("Step1 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const {
        id, boardId, active, name, email,
        phone, title, type, location, contactCode, categoryCode, price
      } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'type': type,
        'location': location,
        'categoryCode': categoryCode,
        'price': price,
      });

      buysellApi.saveBuysellForStep1(
        id,
        updateData,
        (data) => {
          setLoading(false);
          handleStepOnClick(2);
        },
        (error)=> {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const handleSaveForStep2OnClick = (activeVal) => {

    if(window.confirm(isNewPost?"상품 정보를 등록 하시겠습니까?":"Step2 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active, conditionCode, purchaseStateCode } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': activeVal?activeVal:active,
        'description': editorRef.current.getContent(),
        'conditionCode': conditionCode,
        'purchaseStateCode': purchaseStateCode,
      });

      if(isNewPost) {
        buysellApi.createBuysellForStep2(
          id,
          updateData,
          (data) => {
            setLoading(false);
            setDialog({isOpen: true, message: "등록 하였습니다.", type: "alert"});
            navigate(`/buysell/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const { message, errors } = error;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      } else {
        buysellApi.saveBuysellForStep2(
          id,
          updateData,
          (data) => {
            handleSearchClick();
            setDialog({isOpen: true, message: "저장 하였습니다.", type: "alert"});
            navigate(`/buysell/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const { message, errors } = error;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      }
    }
  }

  // == valid check ------------------------------------------------
  const isStep1 = () => {
    return (step === 1) ? true : false;
  }

  const isStep2 = () => {
    return (step === 2) ? true : false;
  }

  const validateArticleDataForStep1ForCreate = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "판매자는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "상품명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: "카테고리는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "판매가격은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "거래지역은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateArticleDataForStep1ForSave = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "판매자는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "상품명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: "카테고리는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "판매가격은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "거래지역은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // == render -------------------------------------------------

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <StepGroup>
        <div className={`${isStep1() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(1)}>Step 1</div>
        <div className={`${isStep2() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(2)}>Step 2</div>
      </StepGroup>
      {isStep1() && (
        <>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>판매자 정보를 입력해주세요</div>
              <InputComponent
                label={'판매자'}
                id={'name'}
                type={'text'}
                checkType={'text'}
                value={article.name}
                onChange={handleNameOnChange}
                placeholder={'이름을 입력하세요.'} />
              <InputComponent
                label={'전화번호'}
                id={'phone'}
                type={'text'}
                checkType={'text'}
                value={article.phone}
                maxLength={13}
                placeholder={'00*-00*-0000'}
                onChange={handlePhoneNumberOnChange} />
              <InputComponent
                label={'이메일'}
                id={'email'}
                type={'email'}
                checkType={'email'}
                value={article.email}
                placeholder={'입력하세요.'}
                onChange={handleEmailOnChange} />
              <RadioComponents
                label={'연락망 선택'}
                id={'contactCode'}
                value={article.contactCode}
                objectData={BuysellContacts}
                infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
                onChange={handleContactCodeOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>상품 정보를 입력해주세요</div>
              <InputComponent
                label={'상품명'}
                id={'title'}
                type={'text'}
                checkType={'text'}
                value={article.title}
                placeholder={'입력하세요.'}
                onChange={handleTitleOnChange} />
              <SelectComponent
                label={'카테고리'}
                id={'categoryCode'}
                value={article.categoryCode}
                objectData={{'': { value: '', label: '선택'}, ...BuysellCategories}}
                placeholder={'카테고리 선택'}
                infoMessage={'여러 개 상품은 대표 아이템만 선택하세요.'}
                onChange={handleCategoryCodeOnChange}
              />
              <CheckBoxTypeComponent
                id={'movingSale'}
                value={article.type}
                target={'무빙세일'}
                message={'무빙세일'}
                onChange={handleMovingSaleOnChange}
              />
              <InputPriceComponent
                label={'판매가격'}
                id={'price'}
                value={article.price}
                maxLength={12}
                placeholder={'판매 희망가를 입력하세요. Ex) $20'}
                onChange={handlePriceOnChange} />
              <CheckBoxTypeComponent
                id={'free'}
                value={article.type}
                target={'무료나눔'}
                message={'무료나눔'}
                onChange={handleFreeOnChange}
              />
              <SelectForListComponents
                label={'거래지역'}
                id={'location'}
                value={article.location}
                list={CityList}
                onChange={handleLocationOnChange} />
            </ArticleForm>
            <ButtonGroup>
              <NextButton
                type='button'
                color="#fff"
                backgroundColor='#fd6258'
                onClick={() => handleSaveForStep1OnClick()}>
                {isNewPost? '다음' : '수정하기'}
              </NextButton>
            </ButtonGroup>
          </ContentWrap>
        </>
      )}
      {isStep2() && (
        <>
          <ContentWrap>
            <ImageUploadComponent
              images={article.images}
              uploadProcess={uploadImageFile}
              deleteProcess={deleteImageFile}
              setDialog={setDialog}
            />
            <Label htmlFor='description'>
              상품설명
              <span>(선택)</span>
            </Label>
            <MarketEditor
              id={'description'}
              editorRef={editorRef}
              placeholder={'거래할 상품과 관련된 내용을 입력해주세요. 예) 구매 시기, 판매 이유, 거래 방식, 네고 여부 등'}
              description={article.description}
              disabled={false}
            />
            <ArticleForm>
              <RadioButtonComponents
                label={'상품상태'}
                subLabel={'(선택)'}
                id={'conditionCode'}
                value={article.conditionCode}
                objectData={BuysellConditions}
                width={'31%'}
                infoMessage={'여러 개 상품은 대표 아이템만 선택하세요.'}
                onChange={handleConditionCodeOnChange} />
              <RadioButtonComponents
                label={'거래상태'}
                subLabel={'(선택)'}
                id={'purchaseStateCode'}
                value={article.purchaseStateCode}
                objectData={BuysellPurchaseStatus}
                width={'48%'}
                onChange={handlePurchaseStateCodeOnChange} />
            </ArticleForm>
            {showHideActive&&(
              <ArticleForm>
                <SwitchComponent
                  label={'내 게시글 숨기기'}
                  id={'closeActive'}
                  value={article.active}
                  defaultValue={0}
                  onChange={handleCloseActiveOnChange}
                />
              </ArticleForm>
            )}
            {isNewPost ? (
              <ButtonGroup>
                <NextButton
                  type='button'
                  color="#fff"
                  backgroundColor='#fd6258'
                  onClick={() => handleSaveForStep2OnClick(0)}>
                  임시저장
                </NextButton>
                <NextButton
                  type='button'
                  color="#fd6258"
                  backgroundColor='#fff'
                  onClick={() => handleSaveForStep2OnClick(1)}>
                  등록
                </NextButton>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                {showHideActive ? (
                  <NextButton
                    type='button'
                    color="#fff"
                    backgroundColor='#fd6258'
                    onClick={() => handleSaveForStep2OnClick()}>
                    수정하기
                  </NextButton>
                ): (
                  <NextButton
                    type='button'
                    color="#fff"
                    backgroundColor='#fd6258'
                    onClick={() => handleSaveForStep2OnClick(1)}>
                    게시하기
                  </NextButton>
                )}
              </ButtonGroup>
            )}
          </ContentWrap>
        </>
      )}
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;

const StepGroup = styled.div`
  padding: 1.3rem 1.6rem 0;
  display: flex;
  justify-content: center;
  & div.step {
    width: 50%;
    height: 4.2rem;

    font-size: 14px;
    font-weight: 700;
    line-height: 4.2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  & div.step:first-child {
    border: 0.1rem solid #eee;
    border-radius: 0.4rem 0rem 0rem 0.4rem;
  }
  & div.step:last-child {
    border: 0.1rem solid #eee;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
  }
  & div.step.active {
    color: #fd6258;
    background-color: #ffeae9;
    border-color: #ffeae9;
  }
`;

const ContentWrap = styled.div`
  padding: 0.5rem 1.6rem;
`;

const ArticleForm = styled.div`
  margin-bottom: 0.5rem;
  & div.articleLabel {
    margin: 3.2rem 0rem 2rem 0rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ButtonGroup = styled.div`
  margin: 3.6rem 0rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  & button {
    margin-right: 0.6rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NextButton = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
  &:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
`;

const Label = styled.label`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 2.3rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;