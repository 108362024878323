import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { MarketSearch } from '../component/MarketSearch';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { MarketListCategory } from '../component/MarketListCategory';
import { LocationModal } from '../component/LocationModal';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { SortComponent } from '../component/SortComponent';
import { TutorListComponent } from './component/TutorListComponent';
import { TutorApi } from '../api/TutorApi';
import { UserApi } from '../../user/api/UserApi';
import {
  TutorCategories,
  TutorSort
} from '../../../__mocks__/PostData';
import { Helmet } from 'react-helmet-async';

export const TutorList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "tutor_search";
  const DEFAULT_VIEW_MODE = "list";
  const LIST_SIZE = 20;

  const tutorApi = new TutorApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isNewButtonClick, setIsNewButtonClick] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/market/main/frame.php?main=tutor'; // 여기서 www.com URL로 변경
  }

  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: '',
          locationCode: '',
          locationName: '',
          locationKrName: '모든지역',
          sort: 'newest',
          title: null,
          filterToggle: false,
          scrollY: 0,
          viewMode: DEFAULT_VIEW_MODE,
        };
      }
    }
  });
  const [tutorList, setTutorList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeCategoryData = (id) => {
    changeSearchData("page", 1);
    changeSearchData("categoryCode", id);
  };

  const changeLocationData = (code, name, krName) => {
    changeSearchData("page", 1);
    changeSearchData('locationCode', code);
    changeSearchData('locationName', name);
    changeSearchData('locationKrName', krName);
  };
  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal)
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    tutorApi.fetchTutorList(
      searchData,
      (data) => {
        setTutorList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        // setLoading(false);
        if(error) {
          if(error.response) {
            const {response: {data}} = error;
            setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
          } else if(error.message) {
            setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
          }
        }
      }
    );
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  const handleNewOnClick = () => {
    if(userApi.isSignIn()) {
      setIsNewButtonClick(!isNewButtonClick);
    } else {
      navigate("/signIn");
    }
  }

  const handleNewTutorForAcademyOnClick = () => {
    navigate("/tutor-new", { state: { jobType: '학원' }});
  }

  const handleNewTutorForTutorOnClick = () => {
    navigate("/tutor-new", { state: { jobType: '튜터' }});
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const handleChangeViewMode = () => {
    if(isGridMode()) {
      changeSearchData('viewMode', 'list');
    } else {
      changeSearchData('viewMode', 'grid');
    }
  }

  const isGridMode = () => {
    return searchData.viewMode==='grid'?true:false;
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.sort, searchData.title, searchData.categoryCode, searchData.locationCode]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`학원튜터 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'교육 전문가를 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'tutor_top_ra'}
          listSize={5}
        />
        <MarketListCategory
          categories={{'': { value: '', label: '전체보기'}, ...TutorCategories}}
          searchData={searchData}
          changeSearchData={changeCategoryData}
          handleSearchClick={handleSearchClick}
        />
        <MarketLineAdList
          boardId={6}
          mobileAdOnClick={handlerItemCardOnClick}
        />
        <SearchFilter>
          <div onClick={()=>handleChangeViewMode()}>
            {isGridMode()
              ?<img className={'imageList'} src='/icon/image-list.png' alt=''/>
              :<img className={'cardList'} src='/icon/grid-2.png' alt=''/>
            }
          </div>
          <div>
            <div className='mapPin' onClick={()=> toggleLocationModal()}>
              {searchData?.locationKrName}
              <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
            </div>
            <LocationModal
              showLocationModal={showLocationModal}
              toggleLocationModal={toggleLocationModal}
              locationCode={searchData?.locationCode}
              changeLocationData={changeLocationData}
            />
            <SortComponent
              sortDisplayList={TutorSort}
              sortCode={searchData?.sort}
              changeSortData={changeSortData}
            />
          </div>
        </SearchFilter>

        <div className='list'>
          <TutorListComponent
            viewMode={searchData.viewMode}
            list={tutorList.list}
            link={'tutor'}
            isLoading={loading}
            postOnClick={handlerItemCardOnClick}
          />
        </div>
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}
        />
        {isNewButtonClick ? (
          <ButtonGroup>
            <WriteButton bottom={15.1} right={1.6} width={'2.072rem'} height={'1.909rem'} onClick={()=>handleNewTutorForAcademyOnClick()}>
              <span>학원</span>
              <img src='/icon/job-search-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton bottom={8.9} right={1.6} width={'1.909rem'} height={'1.909rem'} onClick={()=>handleNewTutorForTutorOnClick()}>
              <span>튜터</span>
              <img src='/icon/job-offer-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton className='active' bottom={2.7} right={1.6} width={'1.583rem'} height={'1.583rem'} onClick={()=>handleNewOnClick()}>
              <img src='/icon/job-new-write-clicked-icon.png' alt='등록 선택' />
            </WriteButton>
          </ButtonGroup>
        ) : (
          <WriteButton bottom={2.7} right={1.6} width={'2.322rem'} height={'2.318rem'} onClick={()=>handleNewOnClick()}>
            <img src='/icon/job-new-write-icon.png' alt='등록' />
          </WriteButton>
        )}
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 0 1.6rem;
    min-height: 39vh;
  }
`;

const WriteButton = styled.div`
  position: fixed;
  bottom: ${props => props.bottom +'rem'};
  right: ${props => props.right +'rem'};
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fd6258;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width};
    height: ${props => props.height};

  }
  & span {
    position: fixed;
    bottom: ${props => props.bottom + 1.3 +'rem'};
    right: ${props => props.right + 5.5 +'rem'};
    width: 4.4rem;
    height: 2.1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.1rem;
    letter-spacing: -0.04em;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.4rem;
  }
  &.active {
    position: fixed;
    bottom: ${props => props.bottom+'rem'};
    right: ${props => props.right+'rem'};
    width: 4.4rem;
    height: 4.4rem;
    background-color: #fff;
    border: 0.1rem solid #ddd;
    border-radius: 50%;
    z-index: 10;
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props => props.width};
      height: ${props => props.height};
      cursor: pointer;
    }
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & img.cardList {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & img.imageList {
    width: 1.7rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const ButtonGroup = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
`;
