import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CommonFooter = () => {
  const handlerMoveTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  return (
    <FooterWrapper>
      <FooterMenu>
        <div>회사소개</div>
        <div>
          <Link to="/news-report">
            기사제보
          </Link>
        </div>
        <div>
          <Link to="/advertising-information">
            광고안내
          </Link>
        </div>
        <div>
          <Link to="//www.vanchosun.com/news/main/main.php">
            PC버전
          </Link>
        </div>
      </FooterMenu>
      <Logo>
        <Link to="/">
          <img className='logo' src="/icon/vanchosun-bottom-logo.png" alt="밴쿠버 조선일보" />
        </Link>
      </Logo>
      <Address>Copyright ⓒ 2021 vanchosun.com All rights reserved</Address>
      <div className='topButton' onClick={() => handlerMoveTop()}>
        <img src={'/icon/move-top.png'} alt='move top'/>
      </div>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  padding-bottom: 4.3rem;
  width: 100%;
  height: 15rem;
  background-color: #f8f8f8;
  & div.topButton {
    position: absolute;
    top: 3.4rem;
    right: 1.6rem;
    width: 4rem;
    height: 4rem;

    background: #FFFFFF;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    & > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 1.6rem;
      height: 1.8rem;
    }
  }
`;

const Logo = styled.div`
  margin: 0;
  padding: 4.3rem 1.6rem 1.5rem 1.6rem;
  & img {
    height: 2rem;
  }
`;

const FooterMenu = styled.div`
  height: 4.9rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #E4E5E4;
  & div {
    padding: 0 1.2rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    text-align: center;
    border-right: 0.1rem solid #E4E5E4;
  }
  & div:first-child {
    padding-left: 1.6rem;
  }
  & div:last-child {
    border-right: 0rem solid #E4E5E4;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;

const Address = styled.div`
  padding: 0 1.6rem;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  color: #9c9c9c;
`;
