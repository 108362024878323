import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { elapsedText } from '../../../utils/utils';

export const MainTutor = ({list}) => {
  const navigate = useNavigate();

  const handleItemOnClick = (url) => {
    navigate(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/icon/pencil-gray-icon.png`;
  }

  return (
    <MainTutorSection>
      <Header>
        <h2>내게 필요한 모든 전문가!</h2>
        <span><Link to={`/tutor`}>전체보기</Link></span>
      </Header>
      <ul>
        {list.map((row) => (
          <li key={row.id}>
            <div className={'item'} onClick={()=>handleItemOnClick(`/tutor/${row.id}`)}>
              <div className='imageWrapper'>
                {row.thumbnailImageFile ? (
                  <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e)=>handleImageError(e)} loading='lazy' />
                ): (
                  <img className='noImg' src={`/icon/pencil-gray-icon.png`} alt={row.title} />
                )}
              </div>
              <div className='info'>
                <h3>{row.name}</h3>
                <span className='title'>{row.title}</span>
                <div className='infoEtc'>
                  <span className='location'>{row.locationKrName}</span>
                  <img className='period' src='/icon/period-icon.png' alt='period'/>
                  <span className='date'>{elapsedText(row.updateDate)}</span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </MainTutorSection>
  )
};

const MainTutorSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: auto;
  background-color: #ffffff;

  & ul {
    margin: 0;
    padding: 0 1.6rem;
    list-style: none;
    & li {
      padding: 1rem 0;
      & div.item {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        & div.imageWrapper {
          margin-right: 1.4rem;
          width: 7rem;
          height: 7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border: 0.1rem solid #eee;
          border-radius: 50%;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          & img.noImg {
            width: 4.5rem;
            height: 4.5rem;
          }
        }
        & div.info {
          max-width: 76%;
          & h3 {
            margin: 0;
            padding: 0;
            height: 2rem;
            font-size: 14px;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: -0.04em;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          & span.title {
            display: block;
            height: 2rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 2rem;
            letter-spacing: -0.04em;
            color: #424242;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          & div.infoEtc {
            margin-top: 0.6rem;
            display: flex;
            align-items: center;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            & span.location {
              font-size: 12px;
              font-weight: 400;
              line-height: 1.7rem;
              letter-spacing: -0.04em;
              color: #9E9E9E;
            }
            & span.date {
              font-size: 12px;
              font-weight: 400;
              line-height: 1.7rem;
              letter-spacing: -0.04em;
              color: #9E9E9E;
            }
            & img.period {
              margin: 0 0.4rem;
              width: 0.2rem;
              height: 0.2rem;
            }
          }
        }
      }
    }
    & li:last-child {
      border-bottom: 0px solid #dddddd;
    }
  }
`;

const Header = styled.div`
  position: relative;
  padding: 2.4rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;