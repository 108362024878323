import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { RecaptchaComponent } from '../../common/RecaptchaComponent';
import { isBlankValue, isValidEmail, isValidPassword } from '../../utils/utils';
import { UserApi } from './api/UserApi';

export const ResetPassword = ({setDialog}) => {
  const userApi = new UserApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [resetPassword, setResetPassword] = useState({
    token: searchParams.get("token")?searchParams.get("token"):'',
    email: '',
    password: '',
    conformPassword: '',
    recaptcha: '',
  });

  const [hasErrors, setHasErrors] = useState({
    email: false,
    password: false,
    conformPassword: false,
  });

  // == state 변경 ------------------------------------------------
  const changeUserInfo = (name, value) => {
    setResetPassword((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeHasErrors = (name, value) => {
    setHasErrors((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const hasToken = () => {
    return 0<resetPassword.token.length?true:false;
  }

  const handlerRecaptchaOnChange = (value) => {
    changeUserInfo('recaptcha', value);
  }

  const handleEmailOnChange = (value) => {
    changeUserInfo("email", value);
    if(!isBlankValue(value) && isValidEmail(value)) {
      changeHasErrors("email", false);
    } else {
      changeHasErrors("email", true);
    }
  }


  const handlePasswordOnChange = (value) => {
    changeUserInfo("password", value);
    if(isValidPassword(value)) {
      changeHasErrors("password", false)
    } else {
      changeHasErrors("password", true);
    }
  };

  const handleConformPasswordOnChange = (value) => {
    changeUserInfo("conformPassword", value);
    if(isValidPassword(value)) {
      changeHasErrors("conformPassword", false)
    } else {
      changeHasErrors("conformPassword", true);
    }
  };

  const handleHistoryBackClick = () => {
    navigate('/signIn', {replace : true});
  }

  const handleResetPasswordOnClick = () => {
    if(validateDataForResetRequest() && window.confirm("비밀번호 재설정을 하시겠습니까?")) {
      userApi.resetPassword(
        resetPassword
        ,(data)=>{
          setDialog({isOpen: true, message: "비밀번호를 재설정할 수 있는 링크가 이메일로 발송되었습니다. 비밀번호를 다시 설정해주세요.", type: "nonHeader"});
          handleHistoryBackClick();
        }
        ,(error)=>{
          // console.log(error?.response);
          if(400 === error?.response?.status) {
            if(error?.response?.data) {
              const { code, message, errors } = error?.response?.data;
              if("INVALID_INPUT_VALUE" === code && 0 < errors.length) {
                setDialog({isOpen: true, message: errors[0].defaultMessage, type: "nonHeader"});
              } else {
                setDialog({isOpen: true, message: message, type: "nonHeader"});
              }
            } else {
              setDialog({isOpen: true, message: "이메일 또는 비밀번호가 일치하지 않습니다.", type: "nonHeader"});
            }
          } else {
            setDialog({isOpen: true, message: "요청중 에러가 발생하였습니다.", type: "nonHeader"});
          }
        }
      );
    }
  }

  const handleSaveNewPasswordOnClick = () => {
    if(validateSavePassword() && window.confirm("비밀번호 재설정을 하시겠습니까?")) {
      userApi.savePassword(
        resetPassword
        ,(data)=>{
          setDialog({isOpen: true, message: "비밀번호 재설정 하였습니다.", type: "nonHeader"});
          handleHistoryBackClick();
        }
        ,(error)=>{
          // console.log(error?.response);
          if(400 === error?.response?.status) {
            if(error?.response?.data) {
              const { code, message, errors } = error?.response?.data;
              if("INVALID_INPUT_VALUE" === code && 0 < errors.length) {
                setDialog({isOpen: true, message: errors[0].defaultMessage, type: "nonHeader"});
              } else {
                setDialog({isOpen: true, message: message, type: "nonHeader"});
              }
            } else {
              setDialog({isOpen: true, message: "이메일 또는 비밀번호가 일치하지 않습니다.", type: "nonHeader"});
            }
          } else {
            setDialog({isOpen: true, message: "요청중 에러가 발생하였습니다.", type: "nonHeader"});
          }
        }
      );
    }
  }

  const validateSavePassword = () => {
    if(isBlankValue(resetPassword.password)) {
      changeHasErrors("password", true);
      setDialog({isOpen: true, message: "비밀번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidPassword(resetPassword.password)) {
      changeHasErrors("password", true);
      setDialog({isOpen: true, message: "비밀번호는 최소 8자,대문자,소문자,특수문자가 들어가야합니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(resetPassword.conformPassword)) {
      changeHasErrors("conformPassword", true);
      setDialog({isOpen: true, message: "비밀번호 확인은 필수입니다.", type: "alert"});
      return false;
    }
    if(resetPassword.password !== resetPassword.conformPassword) {
      changeHasErrors("conformPassword", true);
      setDialog({isOpen: true, message: "비밀번호가 일치하지 않습니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateDataForResetRequest = () => {
    if(isBlankValue(resetPassword.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(resetPassword.recaptcha)) {
      setDialog({isOpen: true, message: "로봇이 아닙니다. 확인은 필수 입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  return (
    <PageWrapper>
      {hasToken()? (
        <ContentWrap>
          <ContentText>
            <img src='/icon/lock-light-icon.png' alt='비밀번호 찾기'/>
            <h2>비밀번호 변경</h2>
            <p>
              새 비밀번호를 등록해주세요.<br/>
              변경 후 새로운 비밀번호로 로그인할 수 있어요.
            </p>
          </ContentText>
          <InputGroup>
          <input
            type='password'
            id='password'
            value={resetPassword.password||''}
            onChange={(e) => handlePasswordOnChange(e.target.value)}
            placeholder="비밀번호 입력"
            autoComplete="off"
            required
          />
          {hasErrors.password && (
            <div className='infoMessage'>
              비밀번호가 올바르지 않습니다.
            </div>
          )}
        </InputGroup>
        <InputGroup>
          <input
            type='password'
            id='conformPassword'
            value={resetPassword.conformPassword||''}
            onChange={(e) => handleConformPasswordOnChange(e.target.value)}
            placeholder="비밀번호 확인"
            autoComplete="off"
            required
          />
          {hasErrors.conformPassword && (
            <div className='infoMessage'>
              비밀번호가 일치하지 않습니다.
            </div>
          )}
        </InputGroup>
          <ButtonStyle type='button' onClick={()=>handleSaveNewPasswordOnClick()}>
            확인
          </ButtonStyle>
          <ForgotInformation>
            회원가입 시 입력한 정보가 기억나지 않는다면?<br/>
            (604-877-1178)
          </ForgotInformation>
        </ContentWrap>
      ) : (
        <ContentWrap>
          <ContentText>
            <img src='/icon/lock-light-icon.png' alt='비밀번호 찾기'/>
            <h2>비밀번호 찾기</h2>
            <p>
              등록한 이메일 주소를 입력해주세요.<br/>
              비밀번호 재설정을 위한 이메일을 보내드릴게요.
            </p>
          </ContentText>
          <InputGroup>
            <input
              type='email'
              id='email'
              value={resetPassword.email||''}
              onChange={(e) => handleEmailOnChange(e.target.value)}
              placeholder="이메일 입력"
              autoComplete="off"
              required
            />
            {hasErrors.email && (
              <div className='infoMessage'>
                이메일 형식이 올바르지 않습니다.
              </div>
            )}
          </InputGroup>
          <ButtonStyle type='button' onClick={()=>handleResetPasswordOnClick()}>
            확인
          </ButtonStyle>
          <ForgotInformation>
            회원가입 시 입력한 정보가 기억나지 않는다면?<br/>
            (604-877-1178)
          </ForgotInformation>
          <RecaptchaComponent
            onChange={handlerRecaptchaOnChange}
          />
        </ContentWrap>
      )}
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  margin: auto;
  width: 100%;
  min-height: 50rem;
  height: 74.5vh;
  background-color: #fff;
  box-sizing : border-box;
`;

const ContentText = styled.div`
  margin: 0;
  padding: 5rem 0 0;
  & img {
    width: 3.4rem;
    height: 3.4rem;
  }
  & h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & p {
    padding-bottom: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #616161;
  }
`;

const ContentWrap = styled.div`
  padding: 0.5rem 1.6rem;
`;

const InputGroup = styled.div`
  margin: 2rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & input {
    padding-left: 1.5rem;
    width: 100%;
    height: 4.8rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 4.8rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
    box-sizing : border-box;
    outline: none;
  }
  & div {
    padding-top: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const ButtonStyle = styled.button`
  height: 5.2rem;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const ForgotInformation = styled.div`
  padding: 2rem 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;
  letter-spacing: -0.04em;
  color: #9E9E9E;
`;
