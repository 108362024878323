import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export const ImageGalleryModal = ({ title, showModal, toggleShowModal, imageList }) => {
  const [images, setImages] = useState([]);

  const handleToggleModal = () => {
    toggleShowModal?.();
  }

  const changeImagesData = (value) => {
    setImages((values) => [...values, value]);
  };

  useEffect(() => {
    Object.keys(imageList).forEach(key => {
      const { domain, filePath, fileName, thumbnailName} = imageList[key];
      changeImagesData({
        original: `${domain}${filePath}${fileName}`,
        thumbnail: `${domain}${filePath}${thumbnailName}`,
      });
    });
  }, [imageList]);

  return (
    <>
      {showModal&&(
        <Modal>
          <ModalHeader>
            <img className='btnBack' src='/icon/arrow-left-white-icon.png' alt='뒤로가기 버튼' onClick={()=>handleToggleModal()}/>
            <div className='title'>{title}</div>
          </ModalHeader>
          <ModalBody>
            <ModalWrapper>
              <ImageGallery
                showThumbnails={false}
                items={images}
              />
            </ModalWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const Modal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 41.6rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
`;

const ModalHeader = styled.div`
  padding: 0;
  width: auto;
  height: 5.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  & div.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
  }
  & div.title {
    position: absolute;
    top: 0.1rem;
    left: 50%;
    font-weight: 500;
    font-size: 18px;
    line-height: 5.4rem;
    letter-spacing: -0.04em;
    color: #fff;
    transform: translate(-50%, 0);
  }
  & img.btnBack {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 2rem;
    height: 2rem;
    color: #fff;
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  top: 5.8rem;
  left: 50%;
  width: 100%;
  max-width: 41.4rem;
  background-color: rgb(255, 255, 255);
  transform: translate(-50%, 0);
`;

const ModalWrapper = styled.div`
  position: relative;
`;