import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { removeHtmlTags } from '../../../utils/utils';

export const HeadLineNews = React.memo(({newsList}) => {
  return (
    <>
      <HeadLineNewsSection>
        {0 < newsList.length ? (
          <ul>
            {newsList.map((row) => (
              <li key={row.id}>
                <Link to={`/news/${row.id}`}>
                  <img src={`//cdn.vanchosun.com/news/File_images_board/${row.imageFile}`} alt=""/>
                  <h3>{row.tagName ? (<span>[{row.tagName?.toUpperCase()}]</span>):''}{row.title}</h3>
                  <p>└ {`${removeHtmlTags(row.subTitle)}`}</p>
                </Link>
              </li>
            ))}
            </ul>
        ) : (
          <SkeletonComponent>
            <SkeletonFirstItem>
              <SkeletonImg />
              <SkeletonTitle />
              <SkeletonSubTitle />
            </SkeletonFirstItem>
            <SkeletonSecondItem >
              <SkeletonSecondImg />
              <SkeletonSecondTitle />
            </SkeletonSecondItem>
          </SkeletonComponent>
        )}
      </HeadLineNewsSection>
    </>
  )
});

const HeadLineNewsSection = styled.section`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  & li {
    width: 100%;
    height: 9.8rem;
    & a {
      margin: 0;
      padding: 0.8rem 1.6rem;

      display: flex;
      align-items: center;

      color: #000;
      text-decoration: none;

      border-top: 0.1rem solid #F5F5F5;
      border-bottom: 0.1rem solid #F5F5F5;
      & img {
        width: 12rem;
        height: 8rem;
        object-fit: cover;
        -webkit-user-drag: none;
      }
      & h3 {
        padding-left: 0.8rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #000000;

        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & p {
        display: none;
      }
      & span {
        color: #de2b36;
        margin-right: 1rem;
      }
    }
  }
  & li:nth-child(1) {
    width: 100%;
    height: auto;
    & a {
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      color: #000;
      text-decoration: none;

      border-top: 0 solid #dddddd;
      border-bottom: 0 solid #dddddd;
      & img {
        width: 100%;
        height: 21.6rem;
        object-fit: cover;
      }
      & h3 {
        margin: 0;
        padding: 0.8rem 1.6rem 0 1.6rem;
        width: auto;

        font-size: 16px;
        font-weight: 600;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & p {
        display: block;
        margin: 0;
        padding: 0 0 0.5rem 2.5rem;

        width: 92%;
        height: 2rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;


const SkeletonFirstItem = styled.div`
  border-bottom: 0.1rem solid #f5f5f5;
  box-sizing: border-box;
`;

const SkeletonImg = styled.div`
  width: 100%;
  height: 21.6rem;
  background-color: #F5F5F5;
  background-image: url('/icon/no-img-news-icon.png');
  background-size: 9rem 7.8rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;

`;

const SkeletonTitle = styled.div`
  margin: 0.8rem 1.6rem 0.3rem;
  width: 20rem;
  height: 2.2rem;
  background-color: #F5F5F5;
`;

const SkeletonSubTitle = styled.div`
  margin: 0rem 1.6rem 0.5rem;
  width: 30rem;
  height: 2rem;
  background-color: #F5F5F5;
`;

const SkeletonSecondItem = styled.div`
  padding: 0.8rem 1.6rem;
  width: auto;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #f5f5f5;

`;

const SkeletonSecondImg = styled.div`
  width: 12rem;
  height: 8rem;
  background-color: #F5F5F5;
`;
const SkeletonSecondTitle = styled.div`
  margin-left: 0.8rem;
  width: 80%;
  height: 2rem;
  background-color: #F5F5F5;
`;

