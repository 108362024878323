import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { Loader } from '../../../common/Loader';
import { cardSerialNumberFormatter, isBlankValue } from '../../../utils/utils';
import { PaymentCardType } from '../../../__mocks__/PostData';
import { OrderApi } from '../api/OrderApi';

export const PaymentStep2 = ({setDialog}) => {
  const DEFAULT_LINK = '/market';
  const orderApi = new OrderApi();
  // const [isAgreeTermOfConditions, setIsAgreeTermOfConditions] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const agreeTermsOfService = useRef();
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    orderId: state?.orderId,
    refType: state?.refType,
    refPostId: state?.refPostId,
    refBoardId: state?.refBoardId,
    backLink: state?.backLink,
    cancelLink: state?.cancelLink,

    cardType: 'visa',
    cardNumber: '',
    secretCodeNumber: '',
    cardholderName: '',
    month: '',
    year: '',
  });

  // == state 변경 ------------------------------------------------
  const changePaymentInfo = (name, value) => {
    setPaymentInfo((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleCardTypeOnChange = (e) => {
    changePaymentInfo("cardType", e.target.value);
  }
  const handleCardNumberOnChange = (e) => {
    changePaymentInfo("cardNumber", cardSerialNumberFormatter(e.target.value));
  }
  const handleSecretCodeNumberOnChange = (e) => {
    changePaymentInfo("secretCodeNumber", e.target.value);
  }
  const handleCardholderNameOnChange = (value) => {
    changePaymentInfo("cardholderName", value);
  }
  const handleMonthOnChange = (e) => {
    changePaymentInfo("month", e.target.value);
  }
  const handleYearOnChange = (e) => {
    changePaymentInfo("year", e.target.value);
  }
  // const handleIsAgreeTermOfConditionsOnChange = (e) => {
  //   setIsAgreeTermOfConditions(e.target.checked);
  // }

  const handleNextButtonOnClick = () => {

    const {
      orderId, refType, refPostId, refBoardId, paymentType, backLink, cancelLink,
      cardType, cardNumber, secretCodeNumber, cardholderName, month, year
    } = paymentInfo;

    if(validateDataForPaymentStep2()) {
      const paymentData = JSON.stringify({
        'orderId': orderId,
        'refType': refType,
        'refPostId': refPostId,
        'refBoardId': refBoardId,
        'paymentType': paymentType,
        'cardType': cardType,
        'cardNumber': cardNumber?.replaceAll('-',''),
        'secretCodeNumber': secretCodeNumber,
        'cardholderName': cardholderName,
        'month': month,
        'year': year,
      });

      orderApi.paymentStep2(
        paymentData,
        (data)=> {
          setLoading(false);
          if(true) {
            navigate('/market-payment-step3',
              { state: {
                  orderId: orderId,
                  refType: refType,
                  refPostId: refPostId,
                  refBoardId: refBoardId,
                  paymentType: paymentType,
                  backLink: backLink,
                  cancelLink: cancelLink,
                },
                replace : true
              }
            );
          }
        },
        (error)=> {
          setLoading(false);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              // setDialog({hasError: true, message: `${statusText}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(`${DEFAULT_LINK}`, {replace : true});
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const validateDataForPaymentStep2 = () => {
    if(isBlankValue(paymentInfo.cardNumber)) {
      setDialog({isOpen: true, message: "카드번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(paymentInfo.secretCodeNumber)) {
      setDialog({isOpen: true, message: "CVC/CVV번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(paymentInfo.cardholderName)) {
      setDialog({isOpen: true, message: "카드홀더는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(paymentInfo.month)) {
      setDialog({isOpen: true, message: "유효기간 월은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(paymentInfo.year)) {
      setDialog({isOpen: true, message: "유효기간 년은 필수입니다.", type: "alert"});
      return false;
    }
    if(!agreeTermsOfService.current.checked) {
      setDialog({isOpen: true, message: "개인정보 처리방침 및 서비스 약관 동의는 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  useEffect(() => {
    const { orderId, refPostId, refBoardId } = paymentInfo;

    if(!orderId || !refPostId || !refBoardId) {
      alert("잘못된 접근 입니다.");
      navigate('/market', {replace : true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ContentTitle>카드정보</ContentTitle>
        <InputGroup>
          <div className='title'>카드선택</div>
          <CardType>
            <select id='cardType'
              value={paymentInfo?.cardType}
              onChange={handleCardTypeOnChange}
            >
              {Object.keys(PaymentCardType).map((key) =>
                <option key={key} value={PaymentCardType[key].value}>{PaymentCardType[key].label}</option>
              )}
            </select>
          </CardType>
        </InputGroup>
        <InputGroup>
          <div className='title'>카드번호</div>
          <CardNumber>
            <label>
              {'visa' === paymentInfo?.cardType && (
                <img src='/icon/card-visa-icon.png' alt='Visa'/>
              )}
              {'master' === paymentInfo?.cardType && (
                <img src='/icon/card-master-icon.png' alt='Master'/>
              )}
            </label>
            <input className="form-text"
              id={'cardNumber'}
              type='text'
              maxLength={19}
              value={paymentInfo?.cardNumber}
              onChange={handleCardNumberOnChange}
            />
          </CardNumber>
        </InputGroup>
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          (-) 하이픈과 띄어쓰기 없이 입력해주세요.
        </InfoMessage>
        <InputGroup>
          <div className='title'>CVC/CVV 번호</div>
          <div>
            <div className='input'>
              <input className="form-text"
                id={'secretCodeNumber'}
                type='text'
                maxLength={4}
                value={paymentInfo?.secretCodeNumber}
                onChange={handleSecretCodeNumberOnChange}
              />
            </div>
          </div>
        </InputGroup>
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          카드뒷면 서명란 옆에 CVC/CVV 번호를 입력해주세요.
        </InfoMessage>
        <InputComponent
          label={'카드홀더'}
          id={'cardholderName'}
          type={'text'}
          checkType={'text'}
          value={paymentInfo?.cardholderName}
          infoMessage={'ex) John Doe, 카드상의 영문이름을 입력해주세요.'}
          onChange={handleCardholderNameOnChange} />
        <InputGroup>
          <div className='title'>유효기간</div>
          <div>
            <div className='input'>
              <input className="form-text"
                id={'month'}
                type='text'
                maxLength={2}
                value={paymentInfo?.month}
                onChange={handleMonthOnChange}
              />
              <label htmlFor='month'>월</label>
            </div>
            <div className='input'>
              <input className="form-text"
                id={'year'}
                type='text'
                maxLength={2}
                value={paymentInfo?.year}
                onChange={handleYearOnChange}
              />
              <label htmlFor='year'>년</label>
            </div>
          </div>
        </InputGroup>
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          ex) MM/YY
        </InfoMessage>
        <CheckboxGroup>
          <AgreeTermOfConditions>
            <input type='checkbox'
              id={`agreeTermsOfService`}
              name={'agreeTermsOfService'}
              ref={agreeTermsOfService}
            />
            <label htmlFor={`agreeTermsOfService`}></label>
            <label className='message' htmlFor={`agreeTermsOfService`}>
            나는 밴조선의 term of conditions에 동의 합니다.
            </label>
          </AgreeTermOfConditions>
        </CheckboxGroup>
        <NextButton
          type='button'
          color="#fff"
          backgroundColor='#fd6258'
          onClick={() => handleNextButtonOnClick()}>결제하기</NextButton>
      </ContentWrapper>
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
  border-top: 0.1rem solid #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const ContentTitle = styled.div`
  margin-top: 3.2rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.6rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const NextButton = styled.button`
  margin: 3.6rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const InputGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & div.title {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div {
    display: flex;
    & div.input {
      margin-top: 1.1rem;
      margin-right: 1rem;
      position: relative;
      width: 12.6rem;
      border: 0.1rem solid #ddd;
      border-radius: 0.4rem;
      & label {
        position: absolute;
        top: 0.4rem;
        right: 0.8rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #9E9E9E;
      }
      & input {
        display: block;
        padding: 0rem;
        width: 10rem;
        height: 3rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 3rem;
        letter-spacing: -0.04em;
        text-align: right;
        color: #424242;

        border: 0rem solid red;
      }
      & input:focus {
        outline: none;
      }
    }

  }
`;

const CardType = styled.div`
  & select {
    display: block;
    padding: 0.9rem 1.6rem;
    width: 12.6rem;
    height: 3.8rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0.1rem solid #ddd;
    border-radius: 0.4rem;
  }
  & select:focus {
    outline: none;
  }
`;

const CardNumber = styled.div`
    margin-top: 1.1rem;
    margin-right: 1rem;
    position: relative;
    width: 100%;
    border: 0.1rem solid #ddd;
    border-radius: 0.4rem;
    & label {
      position: absolute;
      top: 0.2rem;
      left: 0.4rem;
      & img {
        width: 5.2rem;
        height: 2.6rem;
      }
    }
    & input {
      display: block;
      padding: 0rem;
      padding-left: 5.8rem;
      width: 100%;
      height: 3rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 3rem;
      letter-spacing: -0.04em;
      color: #424242;

      border: 0rem solid red;
    }
    & input:focus {
      outline: none;
    }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const CheckboxGroup = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AgreeTermOfConditions = styled.div`
  display: flex;
  align-items: center;
  & input + label {
    display: inline-block;
    margin: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  & input:checked + label {
    background-color: #f86258;
    border: 0.1rem solid #f86258;
    background-image: url('/icon/input-checkbox-checked-icon.png');
    background-size: 1.12rem;
    background-repeat: no-repeat;
    background-position: center;
  }
  & input {
    display: none;
  }
  & label.message {
    margin-left: 1rem;
    padding: 0rem;
    height: 2rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000;
    cursor: pointer;
  }
`;