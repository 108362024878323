import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../common/CommonModal';

export const UserBoardModal = React.memo(({ list, showUserBoardModal, toggleUserBoardModal, boardId, changeUserBoardData }) => {

  const handleToggleLocationModal = () => {
    toggleUserBoardModal?.();
  }

  const handlerOnClick = (value, label) => {
    changeUserBoardData(value, label);
    handleToggleLocationModal?.();
  }

  useEffect(() => {
    if(showUserBoardModal) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [showUserBoardModal]);

  return (
    <>
      {showUserBoardModal&&(
        <CommonModal
          width={'100%'}
        >
          <LocationWrapper>
            <div className='title'>
              필터
              <img src='/icon/x-mark.png' alt='Location modal close' onClick={()=> handleToggleLocationModal()} />
            </div>
            <LocationList>
              <ul>
                {list.map((location) => (
                  <li key={location.value} 
                    className={(location.value === boardId)?'active':''} 
                    onClick={()=>handlerOnClick(location.value, location.label)}>
                    <div>
                      {location.label}
                    </div>
                  </li>
                ))}
              </ul>
            </LocationList>
          </LocationWrapper>
        </CommonModal>
      )}
    </>
  );
});

const LocationWrapper = styled.div`
  position: relative;
  & div.title {
    padding: 0rem 2rem;
    height: 5.9rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const LocationList = styled.div`
  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    & li {
      width: 49.8%;
      height: 4.5rem;
      cursor: pointer;

      & div {
        padding-left: 1.6rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 4.5rem;
      }
    }
    & li:nth-child(odd) {
      border-top: 0.1rem solid #ddd;
      border-right: 0.1rem solid #ddd;
    }
    & li:nth-child(even) {
      border-top: 0.1rem solid #ddd;
    }
    & li:nth-last-child(1) {
      border-bottom: 0.1rem solid #ddd;
    }
    & li:nth-last-child(2) {
      border-bottom: 0.1rem solid #ddd;
    }
    & li.active {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #FD6258;
      background: #FFEAE9;
    }
  }
`;