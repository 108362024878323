import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { CommunityReplyListComponent } from './component/CommunityReplyListComponent';
import { CommunityReplySortComponent } from './component/CommunityReplySortComponent';
import { Loader } from '../../../common/Loader';
import { elapsedText } from '../../../utils/utils';
import { CommunityApi } from '../api/CommunityApi';
import {
  CommunityBorderLv1Category,
  CommunityBorderLv2Category
} from '../../../__mocks__/PostData';
import { GoogleAdvertiseForInFeed } from '../../ads/GoogleAdvertiseForInFeed';


export const CommunityDetail = ({setDialog}) => {
  const communityApi = new CommunityApi();
  const navigate = useNavigate();
  const PER_VIEW_LIST = 18;
  const FIRST_LIST_POINT = 0;
  const { id } = useParams();
  const [viewEditConformGroup, setViewEditConformGroup] = useState(false);
  const [viewDeleteConformGroup, setViewDeleteConformGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    id: id,
    sort: 'updated',
    sortName: '최신순',
  });
  const [article, setArticle] = useState({
    id: '',
    boardId: '',
    active: '',
    email: '',
    name: '',
    phone: '',
    title: '',
    description: '',
    price: 0,
    hitCount: 0,
    replyCount: 0,
    postDate: '',
    updatedDate: '',
    owner: false,
    userImage: '',
    images: {},
  });
  const [reply, setReply] = useState({
    totalCount: 0,
    list:[]
  });

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeSortData = (code, codeName) => {
    changeSearchData('sort', code);
    changeSearchData('sortName', codeName);
  };

  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    communityApi.fetchCommunityById(
      id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        // setDialog({isOpen: true, message: "현재 등록된 게시물이 없습니다.", type: "alert"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate(-1, {state: {isBack: true}});
  }

  const handleReplaySearchClick = () => {
    communityApi.fetchCommunityReplyList(
      searchData,
      (data) => {
        setReply(data);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
      }
    );
  }

  const handleViewEditConformOnClick = () => {
    setViewEditConformGroup((value) => !value);
    if(viewDeleteConformGroup)
      handleViewDeleteConformOnClick();
  }

  const handleViewDeleteConformOnClick = () => {
    setViewDeleteConformGroup((value) => !value);
    if(viewEditConformGroup)
      handleViewEditConformOnClick();
  }

  const handlePasswordOnChange = (value) => {
    changeArticleData("password", value)
  };

  const handleEditOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      handleEditOnClick();
    }
  }

  const handleEditOnClick = () => {
    if(article.id) {
      navigate(`/community-edit/${article.id}`,  {
        state: {
          id: article.id,
        },
      });
    }
  }

  const handleDeleteOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      handleDeleteOnClick();
    }
  }

  const handleDeleteOnClick = () => {
    if(article.id && window.confirm("삭제하시겠습니까?")) {
      communityApi.deleteCommunityDetail(article.id, (data) => {
        setDialog({isOpen: true, message: "삭제 되었습니다.", type: "alert"});
        navigate('/buysell');
      });
    }
  }

  const handleUserImageError = (e) => {
    e.target.src=`/img/no-store-owner-image.png`;
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  // render -------------------------------------------------
  const renderCategory = ({boardId}) => {
    const lv2 = CommunityBorderLv2Category[boardId];
    return (
      <>
        {(boardId) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span>{CommunityBorderLv1Category[lv2?.lv1]?.label}</span>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span className='categoryLv1'>{lv2.label}</span>
          </>
        )}
      </>
    );
  }

  const addImageBox = (index) => {
    const imgUrl = article.images[index]['fileName'];
    const imgPath = article.images[index]['filePath'];
    const domain = article.images[index]['domain'];
    return (
      <div key={index}>
        {imgUrl && (
          <div className="preview-image-box">
            <img src={`${domain}${imgPath}${imgUrl}`} alt={`이미지${index}`} onError={(e)=>handleImageError(e)} loading='lazy' />
          </div>
        )}
      </div>
    );
  }

  const renderDeleteEditButton = ({owner}) => {
    if(!owner) return '';
    return (
      <ConformGroup>
        <ButtonGroup>
          <ButtonStyle type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>수정하기</ButtonStyle>
          <ButtonStyle type='button' color="#fd6258" backgroundColor='#fff' onClick={() => handleDeleteOnClick()}>삭제하기</ButtonStyle>
        </ButtonGroup>
        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
          {viewEditConformGroup && (
            <>
              <InputComponent
                label={'수정: 비밀번호 입력 후 확인버튼을 누르세요'}
                type={'password'}
                id={'password'}
                value={article.password}
                onChange={handlePasswordOnChange}
                onKeyUp={handleEditOnKeyUp}
                style={{width: '27.3rem', height: '3.7rem'}}
                />
              <ConformButton type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>확인</ConformButton>
            </>
          )}
          {viewDeleteConformGroup && (
            <>
              <InputComponent
                label={'삭제: 비밀번호 입력 후 확인버튼을 누르세요'}
                type={'password'}
                id={'password'}
                value={article.password}
                onChange={handlePasswordOnChange}
                onKeyUp={handleDeleteOnKeyUp}
                style={{width: '27.3rem', height: '3.7rem'}}
              />
              <ConformButton type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleDeleteOnClick()}>확인</ConformButton>
            </>
          )}
        </div>
      </ConformGroup>
    );
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleReplaySearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.sort]);

  return (
    <>
      <PageWrapper>
        <CategoryGroup>
          <div className='category'>
            <span className='categoryLv0'>전체</span>
            {renderCategory(article)}
          </div>
          <span className='postId'>{`게시글번호 ${article.id}`}</span>
        </CategoryGroup>

        <ContentWrapper>
          <Title>{article.title}</Title>
          <WriterInfo>
            <img src={`/img/no-store-owner-image.png`} onError={(e)=>handleUserImageError(e)} alt='writer' />
            <div className='writerDetail'>
              <div className='writerName'>{article.name}</div>
              <div className='writerDetail'>
                <span>{elapsedText(article.updatedDate)}</span>
                <span>{`·조회수${article.hitCount}`}</span>
                <span>{`·댓글${article.replyCount}`}</span>
              </div>
            </div>
          </WriterInfo>
        </ContentWrapper>
        <Separator height={'0.1rem'} />
        <ContentWrapper>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
          <ImageWrapper>
            {Object.keys(article.images).map((key) => addImageBox(key))}
          </ImageWrapper>
        </ContentWrapper>
        <GoogleAdvertiseForInFeed
          client="ca-pub-2380324855411184"
          slot="9916647141"
          format="fluid"
          responsive="true"
          layoutKey="-fb+5w+4e-db+86"
        />
        <Separator height={'0.1rem'} />
        <ContentWrapper>
          <ReplyHeader>
            <div className='title'>
              댓글
              <span>{`${reply.totalCount}`}</span>
            </div>
            <CommunityReplySortComponent
              sortCode={searchData?.sort}
              sortName={searchData?.sortName}
              changeSortData={changeSortData}
            />
          </ReplyHeader>
        </ContentWrapper>
        <CommunityReplyListComponent
          boardId={article.boardId}
          bdId={article.id}
          list={reply.list}
          startPoint={FIRST_LIST_POINT}
          perView={PER_VIEW_LIST}
          setLoading={setLoading}
          setDialog={setDialog}
          handleReplaySearchClick={handleReplaySearchClick}
        />
        { renderDeleteEditButton(article) }
        <Loader loading={loading} />
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0.5rem 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const CategoryGroup = styled.div`
  position: relative;
  padding-left: 1.4rem;
  & div.category {
    width: auto;
    height: 4rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    display: flex;
    align-items: center;
    & span.categoryLv0 {
      color: #424242;
    }
    & span.categoryLv1 {
      font-weight: 500;
      color: #FD6258;
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & span.postId {
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    padding: 0.2rem 0.8rem;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 30rem;
  }
`;

const Title = styled.h1`
  display: block;
  min-height: 3.2rem;
  font-size: 22px;
  font-weight: 700;
  word-break: break-all;
`;

const WriterInfo = styled.div`
  height: 8rem;
  display: flex;
  align-items: center;
  & img {
    margin-right: 0.8rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    border: 0.1rem solid #ddd;
  }
  & div.writerName {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.writerDetail {
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
    & span {
      padding: 0 0.1rem;
    }
  }
`;
const Content = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;
const ImageWrapper = styled.section`
  margin-bottom: 0.5rem;
  & p {
    padding: 0.75rem 1.25rem;
    color: #721c24;
    font-size: 14px;
    font-weight: 500;
    background-color: #F8D7DA;
  }
  & div {
    margin: 1rem 0;
    & label.form-label-button {
      padding: 0.6rem 2.5rem;
      height: 2.4rem;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      font-weight: 700;
      color: white;

      background-color: #b1b1b1;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    & div.preview-image-box {
      position: relative;
      margin-top: 1rem;
      width: 100%;
      height: 33.8rem;
      border: 0.1rem solid #ddd;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & button {
        position: absolute;
        right: 0;
        z-index: 100;
      }
    }
  }
`;

const ReplyHeader = styled.div`
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div.title {
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
    & span {
      margin-left: 0.4rem;
    }
  }
`;

const ConformGroup = styled.div`
  padding: 1.6rem;
`;
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 0.6rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #fd6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;
const ConformButton = styled.button`
  margin-left: 1rem;
  margin-bottom: 1.3rem;
  width: 6rem;
  height: 3.8rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 3.8rem;
  letter-spacing: -0.04em;
  color: #9e9e9e;
  background-color: #eee;
  border: 0.1rem solid #e0e0e0;
  border-radius: 0.4rem;
  cursor: pointer;
`;