import React from 'react';
import styled from 'styled-components';

export const Separator = ({height, color}) => {
  return (
    <SeparatorWrapper height={height} color={color}/>
  );
}

const SeparatorWrapper = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: ${props => props.color?props.color : '#f7f7f7'};
`;