import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export const JobEndDatepickerComponent = ({
  label, chkLabel, id, value, dateFormat, placeholderText,
  onChange, onClick,
  isClearable=false, isShowTimeSelect=false, isShow=false}) => {
  const [isNoneEndDate, setIsNoneEndDate] = useState(false);

  const handleOnChange = (date) => {
    onChange?.(dateToString(date));
    onClick?.();
  }

  const handleDatepickerOnInputClick = () => {
    onClick?.();
  }

  const handleNoneEndDateOnChange = ({target}) => {
    if(target.checked) {
      setIsNoneEndDate(target.checked);
      onChange?.('');
    } else {
      setIsNoneEndDate(target.checked);
    }
  }

  const dateToString = (date) => {
    if(isShowTimeSelect && date) {
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    } else if(!isShowTimeSelect && date) {
      return moment(date).format("yyyy-MM-DD");
    }
    return '';
  }

  const stringToDate = (str) => {
    if(isShowTimeSelect && str) {
      return moment(str, "yyyy-MM-DD HH:mm:ss").toDate();
    } else if(!isShowTimeSelect && str) {
      return moment(str, "yyyy-MM-DD").toDate();
    }
    return '';
  }

  return (
    <InputGroup>
      <label>{label}</label>
      <div className='row'>
        <MyDatePicker
          id={id}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          selected={stringToDate(value)}
          onInputClick={()=>handleDatepickerOnInputClick()}
          onChange={date => handleOnChange(date)}
          isClearable={isClearable}
          showTimeSelect={isShowTimeSelect}
          timeIntervals={60}
          dropdownMode="select"
          open={!isNoneEndDate ? isShow : false}
          disabled={isNoneEndDate}
          readOnly
        />
        <div className='datepickerButton' onClick={()=>handleDatepickerOnInputClick()}>
          <img src='/icon/calender-icon.png' alt='' />
          날짜선택
        </div>
      </div>
      <div className='checkboxGroup'>
        <input className='noneEndDate' id='isNoneEndDate' type='checkbox' checked={isNoneEndDate} onChange={e=>handleNoneEndDateOnChange(e)}/>
        <label htmlFor="isNoneEndDate">{chkLabel}</label>
      </div>
    </InputGroup>
  );
}

const InputGroup = styled.div`
  position: relative;
  margin: 1.3rem 0;
  padding: 0;
  height: auto;
  min-height: 5.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div.row {
    width: 100%;
    display: flex;
    align-items: center;
    & .react-datepicker {
      width: max-content;
      font-size: 1.3rem !important;
    }
    & .react-datepicker__current-month {
      font-size: 1.5rem !important;
    }
    & .react-datepicker__header {
      padding-top: 0.6rem !important;
    }
    & .react-datepicker__day-name, .react-datepicker__day {
      margin: 0.5rem !important;
    }
    & .saturday {
      color: rgb(0, 0, 255) !important;
    }
    & .sunday {
      color: rgb(255, 0, 0) !important;
    }
    & .react-datepicker__day--disabled {
      color: #cccccc !important;
    }
    & div.datepickerButton {
      margin-left: 0.8rem;
      width: 50%;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 0.1rem solid #ddd;
      border-radius: 0.5rem;

      cursor: pointer;

      & img {
        margin-right: 0.7rem;
        width: 1.8rem;
        height: 1.9rem;
      }
    }
  }
  & div.checkboxGroup {
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & input {
      margin: 0;
      width: 2rem;
      height: 2rem;
    }
    & input[type=checkbox] {
      accent-color: #f86258;
    }
    & label {
      margin-left: 0.6rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
`;

const MyDatePicker = styled(DatePicker)`
  display: inline-block;
  padding-left: 1.1rem;
  width: 100%;
  height: 3.8rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #424242;

  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  box-sizing: border-box;
`;