import React, { memo } from 'react';
import { JobListGridComponent } from './JobListGridComponent';
import { JobListPostListComponent } from './JobListPostListComponent';

export const JobListComponent = memo(({ viewMode, list, link, isLoading=true, postOnClick}) => {
  return (
    <>
      { 'grid' === viewMode ?
        (
          <JobListGridComponent
            list={list}
            link={link}
            isLoading={isLoading}
            postOnClick={postOnClick}
          />
        ) : (
          <JobListPostListComponent
            list={list}
            link={link}
            isLoading={isLoading}
            postOnClick={postOnClick}
          />
        )
      }
    </>
  );
});