import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

export const MarketMenuButtons = () => {
  const navigate = useNavigate();
  const handleButtonLinkOnClick = (url) => {
    navigate(url);
  }
  return (
    <>
      <MarketMenu>
        <ul>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/buysell')}>
              <div>
                <img src='/icon/handbag.png' alt='사고팔고'/>
              </div>
              <span>사고팔고</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/job')}>
              <div>
                <img src='/icon/identification.png'alt='구인구직'/>
              </div>
              <span>구인구직</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/rent-homestay')}>
              <div>
                <img src='/icon/pin.png'alt='렌트민박'/>
              </div>
              <span>렌트민박</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/realty-sale')}>
              <div>
                <img src='/icon/house.png'alt='부동산'/>
              </div>
              <span>부동산</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/tutor')}>
              <div>
                <img src='/icon/pencil.png'alt='학원튜터'/>
              </div>
              <span>학원튜터</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/car')}>
              <div>
                <img src='/icon/car.png'alt='자동차'/>
              </div>
              <span>자동차</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/community')}>
              <div>
                <img src='/icon/chat-circle.png'alt='커뮤니티'/>
              </div>
              <span>커뮤니티</span>
            </div>
          </li>
          <li>
            <div onClick={()=>handleButtonLinkOnClick('/yellowpage')}>
              <div>
                <img src='/icon/address-book.png'alt='업소록'/>
              </div>
              <span>업소록</span>
            </div>
          </li>
        </ul>
      </MarketMenu>
    </>
  );
};

const MarketMenu = styled.section`
  margin: 0 auto;
  padding: 2.8rem 0 0.8rem;
  width: 100%;
  height: auto;
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & li {
      margin-right: 0.8rem;
      width: 8rem;
      height: 8rem;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        & > div {
          width: 5.2rem;
          height: 5.2rem;
          border-radius: 1.6rem;
          background-color: #f7f7f7;

          display: flex;
          justify-content: center;
          align-items: center;
        }
        & > span {
          font-size: 12px;
        }
      }
    }
    & li:nth-child(4) {
      margin-right: 0;
    }
    & li:nth-child(8) {
      margin-right: 0;
    }
  }
`;