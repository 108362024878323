import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";

export class CommunityApi {

  fetchCommunityList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityNoticeList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/notice`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityReplyList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/reply/${searchData.id}`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityBestList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/best`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityNewList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/new`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchExportQnAList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/export-qna`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchImmigrantList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/immigrant`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchMeetingUpList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/meeting-up`,
      params: searchData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/${id}`,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityEditById(id, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community/${id}/edit`,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  createCommunityDetailForStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  createCommunityDetailForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteCommunityDetail(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/community/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveCommunityDetailNewPassword(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community/${id}/password`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  saveCommunityDetailForStep1(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/community/${id}/step1`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  saveCommunityDetailForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/community/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  createCommunityDetailImageFile(id, formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/community/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteCommunityDetailImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/community/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  checkCommunityDetailPermission(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community/${id}/check-permission`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  createReplyComment(bdId, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/community/reply/${bdId}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  saveReplyComment(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/community/reply/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteReplyComment(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/community/reply/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  checkReplyPermission(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/community/reply/${id}/check-permission`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}