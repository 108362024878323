import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../../common/CommonModal';

export const HomestayTypesModal = memo(({ title, types, value,
  showModal, toggleModal, handlerValueOnChange }) => {

  const handleToggleModal = () => {
    toggleModal?.();
  }

  const handlerOnClick = (value) => {
    handlerValueOnChange?.(value);
  }

  useEffect(() => {
    if(showModal) {
      document.body.style= `overflow: hidden`;
    }
    return () => document.body.style = `overflow: auto`;
  }, [showModal]);

  return (
    <>
      {showModal&&(
        <CommonModal
          width={'100%'}
          maxWidth={'41.4rem'}
        >
          <ContentModalWrapper>
            <div className='modalTitle'>
              {title}
              <img src='/icon/x-mark.png'
                alt='Modal Close'
                onClick={()=> handleToggleModal()}
              />
            </div>
            <TypeList>
              <div className='typeListTitle'>전체</div>
              <ul>
                {Object.keys(types).map((key) => (
                  <li key={key} onClick={()=>handlerOnClick(types[key].value)}>
                    <div>
                      {types[key].label}
                    </div>
                    <img src='/icon/angle-right-gray-icon.png' alt='arrow right' />
                  </li>
                ))}
              </ul>
            </TypeList>
          </ContentModalWrapper>
        </CommonModal>
      )}
    </>
  );
});

const ContentModalWrapper = styled.div`
  position: relative;
  & div.modalTitle {
    padding: 0rem 2rem;
    width: auto;
    height: 6.8rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const TypeList = styled.div`
  display: flex;
  flex-direction: column;
  & div.typeListTitle {
    padding-left: 2rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #9E9E9E;
    border-bottom: 0.1rem solid #f5f5f5;
  }
  & ul {
    margin: 0;
    padding-left: 2rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & li {
      position: relative;
      width: auto;
      height: 4.8rem;
      cursor: pointer;

      display: flex;
      align-items: center;

      & div {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4rem;
      }
      & img {
        position: absolute;
        top: 1.7rem;
        right: 1.7rem;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    & li.active {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #FD6258;
      background: #FFEAE9;
    }
  }
`;
