import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../../common/CommonModal';
import {
  YellowpageCategories
} from '../../../../__mocks__/PostData';

export const CategoryLv1Modal = React.memo(({ showCategoryLv1Modal, toggleCategoryLv1Modal, categoryLv1, changeCategoryLv1Data }) => {

  const [categoryLv1List] = useState({
    '': { value:'', label: '전체', isDisplay: true},
    ...YellowpageCategories,
  });

  const handleToggleModal = () => {
    toggleCategoryLv1Modal?.();
  }

  const handlerOnClick = (code, name) => {
    changeCategoryLv1Data(code, name);
    handleToggleModal?.();
  }

  useEffect(() => {
    if(showCategoryLv1Modal) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [showCategoryLv1Modal]);

  return (
    <>
      {showCategoryLv1Modal && (
        <CommonModal
          width={'100%'}
          maxWidth={'41.4rem'}
        >
          <CategoryLv1Wrapper>
            <div className='title'>
              카테고리
              <img src='/icon/x-mark.png' alt='Modal close' onClick={()=> handleToggleModal()} />
            </div>
            <CategoryLv1List>
              {Object.keys(categoryLv1List).map((key) => (
                <li key={key}
                  className={(categoryLv1List[key].value === categoryLv1)?'active':''}
                  onClick={()=>handlerOnClick(categoryLv1List[key].value, categoryLv1List[key].label)}>
                  <div>
                    {categoryLv1List[key].label}
                  </div>
                </li>
              ))}
            </CategoryLv1List>
          </CategoryLv1Wrapper>
        </CommonModal>
      )}
    </>
  );
});

const CategoryLv1Wrapper = styled.div`
  position: relative;
  & div.title {
    padding: 0rem 2rem;
    height: 5.9rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const CategoryLv1List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  & li {
    width: 49.8%;
    height: 4.5rem;
    cursor: pointer;

    & div {
      padding-left: 1.6rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 4.5rem;
    }
  }
  & li:nth-child(odd) {
    border-top: 0.1rem solid #ddd;
    border-right: 0.1rem solid #ddd;
  }
  & li:nth-child(even) {
    border-top: 0.1rem solid #ddd;
  }
  & li:nth-last-child(1) {
    border-bottom: 0.1rem solid #ddd;
  }
  & li:nth-last-child(2) {
    border-bottom: 0.1rem solid #ddd;
  }
  & li.active {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FD6258;
    background: #FFEAE9;
  }
`;