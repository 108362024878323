import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';

export const NewsSimpleTextListComponent = memo(({ list, link, isLoading, itemOnClick }) =>{

  const handlerItemCardOnClick = (url) => {
    itemOnClick?.(url);
  }

  return (
    <NewsTextListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <TextItemSection>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
          </TextItemSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData></NoData>
          ):(
            <TextItemSection>
              {list.map((row) => (
                <TextItem key={row.id}>
                  <div onClick={()=>handlerItemCardOnClick(`/${link}/${row.id}`)} >
                    {row.tagName ? (<span>[{row.tagName}]</span>):''}
                    {row.title}
                  </div>
                </TextItem>
              ))}
            </TextItemSection>
          )}
        </>
      )}
    </NewsTextListWrapper>
  );
});

const NewsTextListWrapper = styled.div`
  width: 100%;
`;

const TextItemSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const TextItem = styled.li`
  padding: 1.3rem 1rem;
  border-bottom: 0.1rem solid #dfdfdf;
  & div {
    width: 100%;
    text-decoration: none;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #000;

    display: inline-block;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    & span {
      color: #D6000F;
      margin-right: 1rem;
    }
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;