import React from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../../common/SkeletonComponent';
import { addHyphenForPhoneNumber, elapsedText, removeHtmlTags } from '../../../../utils/utils';

export function TutorListPostListComponent({ list, link, isLoading=true, postOnClick}) {

  const handlerPostOnClick = (url, extUrl) => {
    if(extUrl) {
      window.open(`${extUrl}`);
    }
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-tutor.png`;
  }

  const addPremiumTag = ({premium}) => {
    if("1" === premium) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addTypeTag = ({premium, type}) => {
    if("1" === premium) return '';
    if("튜터" === type) {
      return (<img className='typeIcon' src='/icon/tutor-tutor-icon.png' alt='튜터' style={{width: '3.2rem', height: '1.8rem'}}/>);
    } else if("학원" === type) {
      return (<img className='typeIcon' src='/icon/tutor-academy-icon.png' alt='학원' style={{width: '3.2rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addTitleTag = ({premium, title}) => {
    if("1" === premium) {
      return <h2 className='premiumTitle'>{title}</h2>;
    } else {
      return (<h2>{title}</h2>);
    }
  }

  const addNameTag = ({name}) => {
    if(name)
      return (<div className='description'>{`${removeHtmlTags(name)}`}</div>);
    else
      return '';
  }

  const addLocationNContactTag = ({ premium, phone, locationKrName, updateDate}) => {
    if("1" === premium) {
      return (
        <span>
          <img src='/icon/phone-icon.png' alt='Phone Number'/>
          {addHyphenForPhoneNumber(phone)}
        </span>
      );
    } else {
      return (<span>{locationKrName?locationKrName+" · ":''}{elapsedText(updateDate)}</span>)
    }
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <PostSection>
              {list.map((row)=>(
                <PostItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`, row.link)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper>
                      {addPremiumTag(row)}
                      <div>
                        {addTypeTag(row)}
                      </div>
                      {addTitleTag(row)}
                      {addNameTag(row)}
                      {addLocationNContactTag(row)}
                    </InfoWrapper>
                  </div>
                </PostItem>
              ))}
            </PostSection>
          )}
        </>
      )}

    </PostListWrapper>
  );
}

const PostListWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
  border-bottom: 0rem solid #ddd;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 15.2rem;
  height: 11.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 16.7rem;
  right: 0rem;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & h2 {
    margin: 0;
    width: inherit;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & h2.premiumTitle {
    margin: 0;
    width: inherit;
    height: 3.8rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;

    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
  & div.description {
    display: block;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & span {
    margin-top: 0.6rem;
    display: block;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & img {
      margin-right: 0.3rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & span:first-child {
    font-weight: 500;
    color: #3eac3e;
  }
  & div.price {
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    color: #000;
  }
  & img.premiumIcon {
    width: 5rem;
    height: 1.8rem;
  }
  & img.typeIcon {
    display: inline-block;
    margin-right: 0.4rem;
    height: 1.8rem;
  }
  & img.purchaseIcon {
    display: inline-block;
    height: 1.8rem;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;