import React from 'react';
import styled from 'styled-components';

export const RadioButtonComponents = ({ label, subLabel, infoMessage, id, value, objectData, width, onChange, multipleChoice=false, disabled=false}) => {

  const handleOnChange = (sourceValue, radioValue) => {
    if(disabled) return;
    if(isSame(sourceValue, radioValue)) {
      if(multipleChoice) {
        onChange?.(sourceValue.replace(`${radioValue},`, ''));
      } else {
        onChange?.('');
      }
    } else {
      if(multipleChoice) {
        onChange?.(`${sourceValue}${radioValue},`);
      } else {
        onChange?.(radioValue);
      }
    }
  }

  const isSame = (sourceValue, radioValue) => {
    if(multipleChoice) {
      return 0 <= sourceValue?.indexOf(radioValue);
    } else {
      return sourceValue === radioValue;
    }
  }

  return (
    <RadioGroup>
      {label && (
        <Label htmlFor={id}>
          {label}
          <span>{subLabel}</span>
        </Label>
      )}
      <div className='radios'>
        {Object.keys(objectData).map((key) =>
          <RadioItem
            key={key}
            className={`${isSame(value, objectData[key].value) ? 'active' : ''}`}
            width={width}
            onClick={()=>handleOnChange(value, objectData[key].value)}
            disabled={disabled}
          >
            {objectData[key].label}
          </RadioItem>
        )}
      </div>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </RadioGroup>
  );
}

const RadioGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  & div.radios {
    margin-top: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const Label = styled.label`
  width: 100%;
  height: 2.3rem;

  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const RadioItem = styled.div`
  padding: 0;
  width: ${props => props.width};
  height: 3.8rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 3.8rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;
  text-align: center;

  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  cursor: ${props => (props.disabled)?'not-allowed':'pointer'};
  &.active {
    color: #FD6258;
    border: 1px solid #FD6258;
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;
