import React from 'react';
import styled from 'styled-components';

export const MarketTermOfService = () => {

  return (
    <PageWrapper>
      <PageTitle>
        이용약관 동의<br/>
        (Terms of Service)
      </PageTitle>

      <DescriptionTitle>목적</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          이 약관은 밴쿠버 조선일보 (이하 “밴조선” 또는 “회사”라 합니다)가 운영하는 밴조선 홈페이지인 www.vanchosun.com의 여러 인터넷 관련 제반 서비스(이하 “서비스”라 한다) 이용과 관련하여 밴조선과 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정하는데 목적이 있습니다.
        </div>
      </DescriptionBox>

      <DescriptionTitle>Purpose</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          This agreement is to set conditions, procedures and requirements in using the service (herein and after “Service”) of www.vanchosun.com provided by the Vancouver Korean Press (“Vanchosun” or “company”) between the member and company and the conditions of use.
        </div>
      </DescriptionBox>

      <DescriptionTitle>용어의 정의</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
          회원 : 회사의 약관에 동의하여 이용자 아이디(ID) 및 비밀번호를 부여 받아 서비스 이용계약을 체결한 자
        </div>
      </DescriptionBox>

      <DescriptionTitle>Definition</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          The terms used herein shall have the following meanings:<br />
          Member: “Member(s) means those who have agreed to the user agreement and have been issued a user ID and password entitled to use the information and services.
        </div>
      </DescriptionBox>

      <DescriptionTitle>약관의 명시, 효력 및 개정</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. 이 약관의 내용은 온라인 상의 공지 및 기타 방법에 의한 회원 공지를 통해 효력이 발생합니다.</div></li>
          <li><div>2. 밴조선은 합리적인 사유가 발생한 경우에는 사전 고지 없이 본 약관을 변경할 수 있습니다. 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 제1항과 같은 방법으로 회원에 통지하며, 별도로 정하지 않는 한 회원에계 변경된 약관이 통지되었을때 부터 효력이 발생합니다.</div></li>
          <li><div>3. 밴조선은 제 2 항에 따라 변경 약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주합니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>Disclosure, Effect and Amendment of the Terms and Conditions</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. The content of the terms and conditions become effective through disclosing it online or through other methods to the members.</div></li>
          <li><div>2. In reasonable cases, Vanchosun can change the content of the terms and conditions without prior notice. The company discloses the amended content of the terms and conditions to the Members by the way mentioned in Clause 1 and when there is no further notice, it becomes effective the moment the Member receives notice.</div></li>
          <li><div>3. If the Member does not agree with the amended User Agreement, she/he can request to withdraw hers/his membership. When the Member continues to use the service after the amended agreement is effective, the company regards that the Member has agreed to the amended Agreement.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>약관 이외의 준칙</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. 이 약관에 언급되지 않은 사항은 캐나다 연방 및 B.C 주법, 혹은 기타 관련 법령 또는 상관례에 따릅니다.</div></li>
          <li><div>2. 이 약관은 밴조선이 제공하는 개별서비스에 관한 이용안내와 함께 적용합니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>Other Regulations</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. Other contents not mentioned in this Agreement follows other related laws and regulations as the Canada federal and provincial Privacy Act and BC law, or other relevant laws.</div></li>
          <li><div>2. Vanchosun can set the detailed matters for the individual services and the content of the service is disclosed through notification.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>광고 표준이용약관</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. 밴조선에서는 광고 게재를 위한 www.vanchosun.com(웹사이트) 혹은 밴쿠버 조선일보 (출판미디어)의 법규에 따라 다음과같은 이용약관을 규정하고 있습니다.</div></li>
          <li><div>2. 광고계약을 함으로써 광고주(직접 상품 혹은 서비스에 대한 광고를 게재하는 개인, 광고대행사, 광고주들을 위한 미디어 바이어 등)는 광고와 관련된 모든 이용약관에 동의 하는 것으로 간주합니다.</div></li>
          <li><div>3. 광고제작에 필요한 내용물은 웹사이트나 출판 미디어 광고 가격표에 기재된 마감일 전에 기술적인 규격에 맞춰 반드시 제공되어야 합니다.</div></li>
          <li><div>4. 밴조선은 광고내용이 부적절하거나 이용약관에 어긋나다고 생각되는 경우 광고를 거부, 취소, 수정, 중지 할 수 있으며 광고주에게 이것에 대한 어떠한 책임도 지지않습니다. 밴조선은 웹사이트나 프린트 미디어에 게재된 광고비를 지불기일까지 지불하지 못한 광고주의 광고 게재를 거부할 수 있습니다. 광고주는 미결제된 채무에 대한 모든 책임을 집니다.</div></li>
          <li><div>5. 밴조선에 의한 광고게재가 이용약관을 모두 준수했다는 것을 밴조선이 인정한다거나 밴조선이 약관에 명시된 권리를 포기했다는 것을 의미하는 것은 아닙니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>Standard terms and conditions for advertising</DescriptionTitle>
      <DescriptionBox>
        <ul>
          <li><div>1. Orders for publication of advertisements (“Advertisements”) on the www.vanchosun.com(“Websites”) or Vancouver Chosun Ilbo (“Print Media”) are accepted by The Vancouver Korean Press (“Vanchosun”) on the following terms and conditions (“Terms”).</div></li>
          <li><div>2. By placing an order, the Advertiser (which is the person placing the order for the Advertisement whether they are the advertiser of the product or service referred to in the Advertisement or the advertising agency or media buyer for such advertiser) accepts and agrees to be bound by these Terms in full.</div></li>
          <li><div>3. Materials for an Advertisement must be provided no later than the deadline specified in the appropriate rate card and for Website Advertisements or Print Media, in accordance with the Website technical specification or Print Media technical specifications.</div></li>
          <li><div>4. Vanchosun may, without any responsibility to the Advertiser, reject, cancel or require any Advertisement to be amended that it considers unsuitable or contrary to these Terms and remove, suspend or change the position of any such Advertisement. Vanchosun may refuse to publish any Advertisement for any Advertiser who has not paid any sums due for any advertising on the Websites or in the Print Media. The Advertiser will remain responsible for all outstanding charges.</div></li>
          <li><div>5. The publication of an Advertisement by Vanchosun does not mean that Vanchosun accepts the Advertisement has been provided in accordance with these Terms or that Vanchosun has waived its rights under these Terms.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>서비스 이용 책임</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          회원은 회사에서 서면을 통해 공식적으로 허용한 경우를 제외하고는 서비스를 이용한 어떠한 영업활동도 할 수 없으며 특히 해킹, 상업적 목적의 광고, 음란사이트를 통한 상업행위, 상용S/W 불법배포 등의 불법 행위를 할 수 없습니다. 회원이 이를 위반한 경우 발생한 결과 및 손해, 관계기관에 의한 법적 조치 등에 관해서는 회원이 모든 책임을 부담합니다.
        </div>
      </DescriptionBox>

      <DescriptionTitle>Responsibility for using the service</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          Unless approved officially through written documents by the company, Member cannot use the service for any kind of commercial activity and especially cannot engage in hacking, advertisements for earning money, commercial activities using pornography sites, distributing licensed software.<br/>
          Member is responsible in violations for the resulting loss, legal measures and other responsibilities.
        </div>
      </DescriptionBox>

      <DescriptionTitle>이용제한</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지 및 IP 주소를 차단 할 수 있습니다.
        </div>
        <ul>
          <li><div>1. 타인의 서비스 ID 및 비밀번호를 도용한 경우</div></li>
          <li><div>2. 서비스 운영을 고의로 방해한 경우</div></li>
          <li><div>3. 가입신청서에 기재한 회원의 이름이 실명이 아닌 경우</div></li>
          <li><div>4. 동일 사용자가 다른 ID로 이중등록을 한 경우</div></li>
          <li><div>5. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</div></li>
          <li><div>6. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우</div></li>
          <li><div>7. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</div></li>
          <li><div>8. 다른 회원의 의사에 반하여 광고성 정보를 전송하는 경우</div></li>
          <li><div>9. 정보통신설비 또는 정보 등의 파괴, 변경 기타 장애를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</div></li>
          <li><div>10. 회사 기타 타인의 지적재산권을 침해하는 경우</div></li>
          <li><div>11. 타인의 개인정보, 이용자ID 및 비밀번호를 부정하게 사용하는 경우</div></li>
          <li><div>12. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</div></li>
          <li><div>13. 회원이 게시판에 음란물을 게재하거나 게시판을 통하여 음란 기타 미풍양속을 해치는 내용의 사이트로 링크할 수 있게 한 경우</div></li>
          <li><div>14. 본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우</div></li>
          <li><div></div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>Limitation of Use</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          The Company may terminate the Use Contract and block IP Address in the event of occurrence or verification of the following without prior notice or prohibit service for a certain period.
        </div>
        <ul>
          <li><div>1. Using other member’s Member ID and password illegally</div></li>
          <li><div>2. Obstruction of the service procedure on purpose</div></li>
          <li><div>3. Providing names other than one’s real name in the application form</div></li>
          <li><div>4. When one Member registers twice with a different ID.</div></li>
          <li><div>5. Distributing contents on purpose that hurt the public order and public moral</div></li>
          <li><div>6. When the Member uses the service or plans to use with the purpose to damage the national interest or social public interest.</div></li>
          <li><div>7. Damaging other’s reputation or giving disadvantage</div></li>
          <li><div>8. Sending advertisement information contrary to the intention of the other members.</div></li>
          <li><div>9. Distributing computer virus programs that can damage, destroy or change the communication equipments or information.</div></li>
          <li><div>10. Violating the intellectual property right of the company or other people.</div></li>
          <li><div>11. Using other member’s personal information, Member ID and password wrongly.</div></li>
          <li><div>12.Distribute of copy the information obtained through the company service or use it commercially without the approval of the company</div></li>
          <li><div>13. Posting lewd content on the member’s bulletin board or posting a link connecting to a Web site with contents that harm public morals.</div></li>
          <li><div>14. Violating the content within the User Agreement and other user agreements set by the company.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>게시물의 저작권</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.
        </div>
        <ul>
          <li><div>1. 게시물에 대한 권리와 책임은 게시자에게 있습니다. 다만 회사는 위 게시물에 대한 서비스내의 게재권을 가지는 한편 밴조선 서비스를 이용하여 생성된 게시물들을 가공.편집할 수 있는 권리를 가지며, 해당 게시물을 게재한 회원은 이에 동의한 것으로 간주합니다.</div></li>
          <li><div>2. 회원은 서비스에 게재된 자료를 가공, 판매하는 등 상업적으로 이용할 수 없습니다.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>Copyright</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          The rights of the material related to the service are the following.
        </div>
        <ul>
          <li><div>1.The right and obligation of the posted material accrues to the person who posted the material. However, the company has the right to post the material within the service and has the right to process and edit the material created using the Vanchosun service and regards that the member has agreed to this when posting the material.</div></li>
          <li><div>2.Members can not use the material posted through the service commercially as by processing it and editing it.</div></li>
        </ul>
      </DescriptionBox>

      <DescriptionTitle>손해배상</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          회사는 제공하는 서비스 이용에 대해서는 회사 측의 고의 또는 중대한 과실에 의한 것이 아닌 한 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.
        </div>
      </DescriptionBox>

      <DescriptionTitle>Compensation for Damage</DescriptionTitle>
      <DescriptionBox>
        <div className='singleDescription'>
          The company does not take any responsibility for the damage of the members occurred using the service provided by free unless the accident was intentionally done by the company or resulted from a major fault of the company.
        </div>
      </DescriptionBox>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: auto;
  position: relative;
  border-top: 0.1rem solid #e5e5e5;
  background-color: #fff;
`;

const PageTitle = styled.h1`
  margin: 2rem auto;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000 ;
`;

const DescriptionTitle = styled.div`
  margin-top: 3rem;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const DescriptionBox = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 0.6rem;
  & ul {
    margin: 0;
    padding: 0;
    /* padding-left: 2.1rem; */
    list-style: none;
    & li {
      font-size: 12px;
      color: #424242;
      & div {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 1.7rem;
        color: #424242;
      };
    }
  }
  & div.singleDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;

