import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RecaptchaComponent } from '../../common/RecaptchaComponent';
import { isBlankValue, isValidEmail, isValidNickName, isValidPassword } from '../../utils/utils';
import { UserApi } from './api/UserApi';

export const SignUp = ({setDialog}) => {
  const userApi = new UserApi();
  const navigate = useNavigate();
  const [signUpData, setSignUpData] = useState({
    email: '',
    password: '',
    conformPassword: '',
    nickName: '',
    serviceAgreement: false,
    recaptcha: '',
  });

  const [hasErrors, setHasErrors] = useState({
    email: false,
    password: false,
    conformPassword: false,
  });

  // == state 변경 ------------------------------------------------
  const changeSignUpData = (name, value) => {
    setSignUpData((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeHasErrors = (name, value) => {
    setHasErrors((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleEmailOnChange = (value) => {
    changeSignUpData("email", value);
    if(!isBlankValue(value) && isValidEmail(value)) {
      changeHasErrors("email", false);
    } else {
      changeHasErrors("email", true);
    }
  }

  const handlePasswordOnChange = (value) => {
    changeSignUpData("password", value);
    changeSignUpData("conformPassword", "");
    if(isValidPassword(value)) {
      changeHasErrors("password", false)
    } else {
      changeHasErrors("password", true);
    }
  };

  const handleConformPasswordOnChange = (value) => {
    changeSignUpData("conformPassword", value);
    if(isValidPassword(value) && signUpData.password === value) {
      changeHasErrors("conformPassword", false)
    } else {
      changeHasErrors("conformPassword", true);
    }
  };

  const handleNickNameOnChange = (value) => {
    if(isValidNickName(value)) {
      changeSignUpData("nickName", value);
    }
  }

  const handleServiceAgreementOnChange = (e) => {
    changeSignUpData("serviceAgreement", e.target.checked);
  }

  const handlerRecaptchaOnChange = (value) => {
    changeSignUpData('recaptcha', value);
  }

  const handleHistoryBackClick = () => {
    navigate('/market', {replace : true});
  }

  const handleSignUpOnClick = () => {
    if(validateDataForSignUp()) {
      userApi.signUp(
        signUpData,
        (data) => {
          // setDialog({isOpen: true, message: "등록하신 이메일로 인증 메일이 발송 되었습니다. 이메일을 열어 가입 절차를 완료해주세요.", type: "nonHeader"});
          setDialog({isOpen: true, message: "회원 가입이 완료되었습니다.", type: "nonHeader"});
          handleHistoryBackClick();
        },
        (error) => {
          // console.log(error?.response);
          if(400 === error?.response?.status) {
            if(error?.response?.data) {
              const { code, message, errors } = error?.response?.data;
              if("INVALID_INPUT_VALUE" === code && 0 < errors.length) {
                setDialog({isOpen: true, message: errors[0].defaultMessage, type: "nonHeader"});
              } else {
                setDialog({isOpen: true, message: message, type: "nonHeader"});
              }
            } else {
              setDialog({isOpen: true, message: "이메일 또는 비밀번호가 일치하지 않습니다.", type: "nonHeader"});
            }
          } else {
            setDialog({isOpen: true, message: "요청중 에러가 발생하였습니다.", type: "nonHeader"});
          }
        },
      );
    }
  }

  const validateDataForSignUp = () => {
    if(isBlankValue(signUpData.email)) {
      changeHasErrors("email", true);
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(signUpData.email)) {
      changeHasErrors("email", true);
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(signUpData.password)) {
      changeHasErrors("password", true);
      setDialog({isOpen: true, message: "비밀번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidPassword(signUpData.password)) {
      changeHasErrors("password", true);
      setDialog({isOpen: true, message: "비밀번호는 8~16자의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요.", type: "alert"});
      return false;
    }
    if(isBlankValue(signUpData.conformPassword)) {
      changeHasErrors("conformPassword", true);
      setDialog({isOpen: true, message: "비밀번호 확인은 필수입니다.", type: "alert"});
      return false;
    }
    if(signUpData.password !== signUpData.conformPassword) {
      changeHasErrors("conformPassword", true);
      setDialog({isOpen: true, message: "비밀번호가 일치하지 않습니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(signUpData.nickName)) {
      setDialog({isOpen: true, message: "이름(닉네임)은 필수입니다.", type: "alert"});
      return false;
    }
    if(!signUpData.serviceAgreement) {
      setDialog({isOpen: true, message: "개인전보 처리방침 및 서비스 이용약관에 동의는 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const handleUserPrivacyPolicyOnClick = () => {
    navigate('/user-privacy-policy');
  }

  const handleUserTermOfServiceOnClick = () => {
    navigate('/user-term-of-service');
  }

  return (
    <PageWrapper>
      <ContentText>
        <span>밴마켓</span>에 가입하고<br/>
        다양한 혜택을 누리세요!
      </ContentText>
      <ContentWrap>
        <InputGroup>
          <input
            type='email'
            id='email'
            value={signUpData.email||''}
            onChange={(e) => handleEmailOnChange(e.target.value)}
            placeholder="이메일 입력"
            autoComplete="off"
            required
          />
          {hasErrors.email && (
            <div className='infoMessage'>
              이메일 형식이 올바르지 않습니다.
            </div>
          )}
        </InputGroup>
        <InputGroup>
          <input
            type='password'
            id='password'
            value={signUpData.password||''}
            onChange={(e) => handlePasswordOnChange(e.target.value)}
            placeholder="비밀번호 입력"
            autoComplete="off"
            required
          />
          {hasErrors.password && (
            <div className='infoMessage'>
              비밀번호가 올바르지 않습니다.
            </div>
          )}
        </InputGroup>
        <InputGroup>
          <input
            type='password'
            id='conformPassword'
            value={signUpData.conformPassword||''}
            onChange={(e) => handleConformPasswordOnChange(e.target.value)}
            placeholder="비밀번호 확인"
            autoComplete="off"
            required
          />
          {hasErrors.conformPassword && (
            <div className='infoMessage'>
              비밀번호가 일치하지 않습니다.
            </div>
          )}
        </InputGroup>
        <InputGroup>
          <input
            type='text'
            id='nickName'
            value={signUpData.nickName||''}
            onChange={(e) => handleNickNameOnChange(e.target.value)}
            placeholder="이름(닉네임)"
            autoComplete="off"
            required
          />
          {/* <button type='button'>중복확인</button> */}
        </InputGroup>
        <RequirementContent>
          <li>∙ 비밀번호는 8~16자의 영문 대소문자, 숫자, 특수문자를<br/>&nbsp;&nbsp;조합하여 설정해주세요.</li>
          <li>∙ 닉네임은 최대 한글 10자, 영문 10자로 입력해주세요.</li>
          {/* <li>∙ 닉네임은 설정 후 24시간 뒤 재변경이 가능합니다.</li> */}
        </RequirementContent>
        <CheckboxGroup>
          <input type='checkbox'
            id='serviceAgreement'
            name='serviceAgreement'
            onChange={(e)=>handleServiceAgreementOnChange(e)}
          />
          <label htmlFor={'serviceAgreement'}></label>
          <div className='message'>
            <span onClick={()=>handleUserPrivacyPolicyOnClick()}>개인정보 처리방침</span> 및 <span onClick={()=>handleUserTermOfServiceOnClick()}>서비스 이용약관</span>에 동의
          </div>
        </CheckboxGroup>
        <ButtonStyle type='button' onClick={()=>handleSignUpOnClick()}>
          가입하기
        </ButtonStyle>
      </ContentWrap>
      <ContentWrap>
        <RecaptchaComponent
          onChange={handlerRecaptchaOnChange}
        />
      </ContentWrap>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0 0 6.2rem;
  width: 100%;
  min-height: 68rem;
  height: 74.5vh;
  background-color: #fff;
  box-sizing : border-box;
`;

const ContentText = styled.p`
  margin: 0;
  padding: 5rem 1.6rem 2.2rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: -0.04em;
  & span {
    color: #FD6258;
  }
`;
const ContentWrap = styled.div`
  padding: 0.5rem 1.6rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin: 0;
  padding: 0.8rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  & input {
    padding-left: 1.5rem;
    width: 100%;
    height: 4.8rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 4.8rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0.1rem solid #ddd;
    box-sizing : border-box;
    outline: none;
  }
  & div.infoMessage {
    padding-top: 0.4rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
  & button {
    position: absolute;
    top: 1.6rem;
    right: 0.8rem;
    width: 8.2rem;
    height: 3.2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    background: #F5F5F5;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem;
  }
`;

const CheckboxGroup = styled.div`
  margin: 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  & input + label {
    display: inline-block;
    margin: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  & input:checked + label {
    background-color: #f86258;
    border: 0.1rem solid #f86258;
    background-image: url('/icon/input-checkbox-checked-icon.png');
    background-size: 1.12rem;
    background-repeat: no-repeat;
    background-position: center;
  }
  & input {
    display: none;
  }
  & div.message {
    margin-left: 0.8rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #000000;
    & span {
      font-weight: 500;
      cursor: pointer;
    }
  }
`;

const RequirementContent = styled.ul`
  margin: 0;
  padding: 2.1rem 2rem;
  border: 0.1rem solid #E0E0E0;
  border-radius: 0.4rem;
  & li {
    margin: 0;
    padding: 0.2rem 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #616161;
    list-style: none;
  }
`;

const ButtonStyle = styled.button`
  height: 5.2rem;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;
