import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { MarketSearch } from '../component/MarketSearch';
import { CategoryLv1Modal } from './component/CategoryLv1Modal';
import { SortComponent } from '../component/SortComponent';
import { YellowpageListComponent } from './component/YellowpageListComponent';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { YellowpageApi } from '../api/YellowpageApi';
import {
  YellowpageSort
} from '../../../__mocks__/PostData';
import { Helmet } from 'react-helmet-async';

export const YellowpageList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "yellowpage_search";
  const DEFAULT_VIEW_MODE = "list";
  const LIST_SIZE = 20;

  const yellowpageApi = new YellowpageApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showCategoryLv1Modal, setShowCategoryLv1Modal] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/stores/main/frame.php'; // 여기서 www.com URL로 변경
  }

  const [searchData, setSearchData] = useState(() => {
    const isBack = location.state?.isBack;
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(isBack && saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: '',
          categoryName: '전체',
          marketHomeShow: (location?.state)?location?.state.marketHomeShow:0,
          marketHomeKeyword: (location?.state)?location?.state.marketHomeKeyword:'',
          sort: 'ascending',
          title: '',
          type: null,
          typeName: '업소록',
          filterToggle: false,
          scrollY: 0,
          viewMode: DEFAULT_VIEW_MODE,
        };
      }
    }
  });
  const [yellowPageList, setYellowPageList] = useState({list:[]});

  const handleSearchClick = () => {
    setLoading(true);
    yellowpageApi.fetchYellowpageList(searchData,
      (data) => {
        setYellowPageList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        // setLoading(false);
        if(error) {
          if(error.response) {
            const {response: {data}} = error;
            setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
          } else if(error.message) {
            setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
          }
        }
      }
    );
  }

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeCategoryLv1Data = (code, name) => {
    changeSearchData('page', 1);
    changeSearchData('marketHomeShow', 0);
    changeSearchData('marketHomeKeyword', '');
    changeSearchData('categoryCode', code);
    changeSearchData('categoryName', name);
  };

  const toggleCategoryLv1Modal = () => {
    setShowCategoryLv1Modal(!showCategoryLv1Modal);
  };

  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }

  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const handleChangeViewMode = () => {
    if(isGridMode()) {
      changeSearchData('viewMode', 'list');
    } else {
      changeSearchData('viewMode', 'grid');
    }
  }

  const isGridMode = () => {
    return searchData.viewMode==='grid'?true:false;
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.categoryCode, searchData.sort, searchData.title]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`업소찾기 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'어떤 업소를 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'yellowpage_top_ra'}
          listSize={5}
        />
        <SearchFilter>
          <div onClick={()=>handleChangeViewMode()}>
            {isGridMode()
              ?<img className={'imageList'} src='/icon/image-list.png' alt=''/>
              :<img className={'cardList'} src='/icon/grid-2.png' alt=''/>
            }
          </div>
          <div>
            <div className='mapPin' onClick={()=> toggleCategoryLv1Modal()}>
              {searchData?.categoryName}
              <img src='/icon/angle-down-black-sm-icon.png' alt='Category Name'/>
            </div>
            <CategoryLv1Modal
              showCategoryLv1Modal={showCategoryLv1Modal}
              toggleCategoryLv1Modal={toggleCategoryLv1Modal}
              categoryLv1={searchData?.categoryCode}
              changeCategoryLv1Data={changeCategoryLv1Data}
            />
            <SortComponent
              sortDisplayList={YellowpageSort}
              sortCode={searchData?.sort}
              changeSortData={changeSortData}
            />
          </div>
        </SearchFilter>
        <div className='list'>
          <YellowpageListComponent
            viewMode={searchData.viewMode}
            list={yellowPageList.list}
            link={'yellowpage'}
            isLoading={loading}
            postOnClick={handlerItemCardOnClick}
          />
        </div>
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}/>
      </PageWrapper>
    </>
  )
};

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 0rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 1.6rem;
    min-height: 39vh;
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & img.cardList {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & img.imageList {
    width: 1.7rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;