import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { DwellingApi } from '../api/DwellingApi';
import { CommercialApi } from '../api/CommercialApi';
import { MarketSearch } from '../component/MarketSearch';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { SortComponent } from '../component/SortComponent';
import { RealtyCardComponent } from './component/RealtyCardComponent';
import { DwellingFilterModal } from './component/DwellingFilterModal';
import { CommercialSort, DwellingSort } from '../../../__mocks__/PostData';
import { CommercialFilterModal } from './component/CommercialFilterModal';
import { Helmet } from 'react-helmet-async';
import { RealtyLocationModal } from './component/RealtyLocationModal';

export const RealtySaleList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "realty_sale_search";
  const LIST_SIZE = 20;

  const dwellingApi = new DwellingApi();
  const commercialApi = new CommercialApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDwellingFilter, setShowDwellingFilter] = useState(false);
  const [showCommercialFilter, setShowCommercialFilter] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/realestate/view/list2.php?cate=saleb'; // 여기서 www.com URL로 변경
  }

  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: 'all',
          locationCode: '',
          locationName: '',
          locationKrName: '모든지역',

          types: '',
          minPrice: 0,
          maxPrice: 3000000,

          flagType: '',
          bedRoomCount: 0,
          bathroomCount: 0,
          includedOptions: '',
          additionalFacilities: '',

          minFloorNumber: 0,
          maxFloorNumber: 10,
          minNumberParkingSpaces: 0,
          maxNumberParkingSpaces: 50,
          minIndoorSpace: 0,
          maxIndoorSpace: 0,
          minLandSpace: 0,
          maxLandSpace: 0,

          sort: 'newest',
          title: null,
          scrollY: 0,
          tabType: (location?.state?.type)?location?.state?.type:'dwelling',
        };
      }
    }
  });
  const [dwellingList, setDwellingList] = useState({list:[]});
  const [commercialList, setCommercialList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeLocationData = (code, name, krName) => {
    changeSearchData('locationCode', code);
    changeSearchData('locationName', name);
    changeSearchData('locationKrName', krName);
  };
  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal)
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    if(isDwelling()) {
      dwellingApi.fetchDwellingList(searchData,
        (data) => {
          setDwellingList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          // setLoading(false);
          if(error) {
            if(error.response) {
              const {response: {data}} = error;
              setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
            } else if(error.message) {
              setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
            }
          }
        }
      );
    } else if(isCommercial()) {
      commercialApi.fetchCommercialList(searchData,
        (data) => {
          setCommercialList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          // setLoading(false);
          if(error) {
            if(error.response) {
              const {response: {data}} = error;
              setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
            } else if(error.message) {
              setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
            }
          }
        }
      );
    }
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const isDwelling = () => {
    if('dwelling' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const isCommercial = () => {
    if('commercial' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const handleTabOnClick = (tabType) => {
    handlePageChange(1);
    changeSearchData('tabType', tabType);
  }

  const toggleShowDwellingFilter = () => {
    setShowDwellingFilter(!showDwellingFilter);
  }

  const toggleShowCommercialFilter = () => {
    setShowCommercialFilter(!showCommercialFilter);
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.tabType, searchData.page, searchData.sort, searchData.title, searchData.categoryCode, searchData.locationCode]);

  return (
    <>
      <PageWrapper>
        <MarketSearch
          placeholder={'매매 매물을 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'realtysale_top_ra'}
          listSize={5}
        />
        <Tabs>
          <div className={`${isDwelling() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('dwelling')}>주거매매</div>
          <div className={`${isCommercial() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('commercial')}>비즈매매</div>
        </Tabs>
        {isDwelling() && (
          <>
            <Helmet>
              <title>{`부동산 - 밴마켓`}</title>
            </Helmet>
            <MarketLineAdList
              boardId={1}
              mobileAdOnClick={handlerItemCardOnClick}
            />
            <SearchFilter>
              <div className={'btnFilter'} onClick={()=>toggleShowDwellingFilter()}>
                필터
                <img className={'imageList'} src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <div>
                <div className='mapPin' onClick={()=> toggleLocationModal()}>
                  {searchData?.locationKrName}
                  <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
                </div>
                <RealtyLocationModal
                  showLocationModal={showLocationModal}
                  toggleLocationModal={toggleLocationModal}
                  locationCode={searchData?.locationCode}
                  changeLocationData={changeLocationData}
                />
                <SortComponent
                  sortDisplayList={DwellingSort}
                  sortCode={searchData?.sort}
                  changeSortData={changeSortData}
                />
              </div>
            </SearchFilter>

            <div className='list'>
              <RealtyCardComponent
                type={searchData.tabType}
                list={dwellingList.list}
                link={'dwelling-sale'}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
          </>
        )}
        {isCommercial() && (
          <>
            <MarketLineAdList
              boardId={1}
              mobileAdOnClick={handlerItemCardOnClick}
            />
            <SearchFilter>
              <div className={'btnFilter'} onClick={()=>toggleShowCommercialFilter()}>
                필터
                <img className={'imageList'} src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <div>
                <div className='mapPin' onClick={()=> toggleLocationModal()}>
                  {searchData?.locationKrName}
                  <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
                </div>
                <RealtyLocationModal
                  showLocationModal={showLocationModal}
                  toggleLocationModal={toggleLocationModal}
                  locationCode={searchData?.locationCode}
                  changeLocationData={changeLocationData}
                />
                <SortComponent
                  sortDisplayList={CommercialSort}
                  sortCode={searchData?.sort}
                  changeSortData={changeSortData}
                />
              </div>
            </SearchFilter>

            <div className='list'>
              <RealtyCardComponent
                type={searchData.tabType}
                list={commercialList.list}
                link={'commercial-sale'}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
          </>
        )}
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}
        />
      </PageWrapper>
      {isDwelling() && (
        <DwellingFilterModal
          show={showDwellingFilter}
          toggle={toggleShowDwellingFilter}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
      )}
      {isCommercial() && (
        <CommercialFilterModal
          show={showCommercialFilter}
          toggle={toggleShowCommercialFilter}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
      )}
    </>
  );
}

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0.5rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 1.6rem;
    min-height: 39vh;
  }
`;

const Tabs = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.tab {
    width: 40%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
  & div.active {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;

    color: #FD6258;
    border-bottom: 0.1rem solid #FD6258;
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.btnFilter {
    width: 4.8rem;
    height: 2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.filter {
    padding-left: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;