import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../../common/CommonModal';

export const CommunityCategoryModal = ({ categories, showCategoryModal, toggleCategoryModal, categoryCode, changeCategoryData }) => {

  const handleToggleCategoryModal = () => {
    toggleCategoryModal?.();
  }

  const handlerOnClick = (code, name) => {
    changeCategoryData(code, name);
    handleToggleCategoryModal?.();
  }

  useEffect(() => {
    if(showCategoryModal) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [showCategoryModal]);

  return (
    <>
      {showCategoryModal&&(
        <CommonModal width={'100%'}>
          <CategoryWrapper>
            <div className='title'>
              카테고리
              <img src='/icon/x-mark.png' alt='Modal close' onClick={()=> handleToggleCategoryModal()}/>
            </div>
            <CategoryList>
              <ul>
                {categories?.map((category) => (
                  <li key={category.code} className={(category.code === categoryCode)?'active':''} onClick={()=>handlerOnClick(category.code, category.name)}>
                    <div>
                      {category.name}
                    </div>
                  </li>
                ))}
              </ul>
            </CategoryList>
          </CategoryWrapper>
        </CommonModal>
      )}
    </>
  );
};

const CategoryWrapper = styled.div`
  position: relative;
  & div.title {
    padding: 0rem 2rem;
    height: 5.9rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const CategoryList = styled.div`
  border-top: 0.1rem solid #F5F5F5;
  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    & li {
      width: 100%;
      height: 5.4rem;
      cursor: pointer;
      & div {
        padding: 1.5rem 1.6rem;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #000000;
      }
    }
    & li.active {
      color: #FD6258;
      background: #FFEAE9;
      & div {
        color: #FD6258;
      }
    }
  }
`;