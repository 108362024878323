import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';

export const MainColumnist = ({list}) => {
  SwiperCore.use([Autoplay]);
  const [setSwiperRef] = useState(null);

  return (
    <MainColumnistSection>
      <Header>
        <Link to={`/opinion`}>
          <h2>칼럼</h2>
        </Link>
      </Header>
      <MySwiper
        onSwiper={setSwiperRef}
        autoplay={{ "delay": 6000, }}
        slidesPerView={1}
        >
        {list.map((row) => (
          <SwiperSlide key={row.id} >
            <Columnist>
              <Link to={`/opinion/${row.id}`}>
                <img src={`https://www.vanchosun.com/news/columns/img/cs_${row.type}.gif`} alt='' />
                <div>
                  <h3>[{row.header}] {row.columnName}</h3>
                  <h4>{row.title}</h4>
                </div>
              </Link>
            </Columnist>
          </SwiperSlide>
        ))}
      </MySwiper>
    </MainColumnistSection>
  )
};

const MainColumnistSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem 2rem 1.6rem;
  width: auto;
  background-color: #fff;
`;

const MySwiper = styled(Swiper)`
  padding: 0;
  max-width: 100%;
`;

const Columnist = styled.div`
  height: 7rem;
  border: 0.1rem solid #ddd;
  & a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    color: #000;
    text-decoration: none;
    & img {
      padding: 1rem;
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      border-radius: 50%;
    }
    & div {
      display: inline-grid;
      align-content: center;
      justify-content: start;

      & h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 700;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & h4 {
        margin: 0;
        font-size: 14px;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

const Header = styled.div`
  & h2 {
    margin: 0;
    padding: 2rem 0 1.6rem;

    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;