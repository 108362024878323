import React, { useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

export const NewsSearch = React.memo(({placeholder, searchData, changeSearchData, handleSearchClick}) => {
  const inputRef = useRef(null);
  const [showClearButton, setShowClearButton] = useState(false);

  const handleCleanOnClick = () => {
    inputRef.current.value = "";
    changeSearchData?.('title', inputRef.current.value);
    changeSearchData('page', 1);
    isNeedClean();
  }

  const handleSearchOnClick = () => {
    handleSearchClick?.();
    isNeedClean();
  }

  const handleSearchOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      changeSearchData?.('title', inputRef.current.value);
      changeSearchData('page', 1);
      inputRef.current.blur();
    }
    isNeedClean();
  }

  const handleSearchOnBlur = () => {
    changeSearchData?.('title', inputRef.current.value);
    changeSearchData('page', 1);
  }

  const isNeedClean = () => {
    const str = inputRef?.current?.value||'';
    setShowClearButton(0<str.length);
  }

  useEffect(() => {
    inputRef.current.value = searchData.title;
  }, [searchData.title]);

  return (
    <SearchSection>
      <div className={'searchGroup'}>
        <input type="text" ref={inputRef} title="검색어 입력" placeholder={placeholder} maxLength={"255"}
          onKeyUp={handleSearchOnKeyUp} onBlur={handleSearchOnBlur}
        />
        <CleanButton show={showClearButton} src='/icon/x-mark-gray-icon.png' alt="Clean Button" onClick={handleCleanOnClick} />
      </div>
      <img className='search' src='/icon/search-icon.png' alt="Search Button" onClick={handleSearchOnClick} />
    </SearchSection>
  );
});

const SearchSection = styled.div`
  margin: 1.5rem 0;
  padding: 0 0.8rem 0 1.6rem;
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  & div.searchGroup {
    position: relative;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    & input {
      padding-left: 1.47rem;
      height: 4.8rem;
      width: 100%;

      font-weight: 400;
      font-size: 14px;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #000000;


      background-color: #F5F5F5;
      border: 0rem solid #F5F5F5;
      border-radius: 0.4rem;
      outline-color: #F5F5F5;
    }
    & input::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
  }
  & img.search {
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
  }
`;

const CleanButton = styled.img`
  display: ${props => (props.show)?'':'none'};
  position: absolute;
  top: 1.6rem;
  right: 1.4rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;