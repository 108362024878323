import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RadioButtonComponents } from '../../../common/RadioButtonComponents';
import { Loader } from '../../../common/Loader';
import { ItemApi } from '../api/ItemApi';
// import { useConfirm } from '../../../hooks/userConfirm';
import { Separator } from '../../../common/Separator';
import { priceToStringWithDecimal } from '../../../utils/utils';
import { OrderApi } from '../api/OrderApi';

export const PaymentStep1 = ({setDialog}) => {
  // const confirmPhonePayment = useConfirm(
  //   '전화결제는 웹담당자(604-877-1178) 또는 web@vanchosun.com으로 문의 부탁드립니다.'
  //   ,()=>payPhonePayment()
  //   ,()=>false
  // );
  const itemApi = new ItemApi();
  const orderApi = new OrderApi();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    orderId: 0,
    refType: state?.refType,
    refPostId: state?.refPostId,
    refBoardId: state?.refBoardId,
    itemId: state?.itemId,
    paymentType: 'online',
    quantity: 1,
    backLink: state?.backLink,
    cancelLink: state?.cancelLink,
  });

  const [itemInfo, setItemInfo] = useState({
    itemId: state?.itemId,
    itemEnNm: '',
    itemKrNm: '',
    itemDescription: '',
    amount: 0.00,
    gstRate: 0.00,
    gstAmount: 0.00,
  });

  // == state 변경 ------------------------------------------------
  const changePaymentInfo = (name, value) => {
    setPaymentInfo((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // handler ---------------------------------
  const handleNextButtonOnClick = () => {
    setLoading(true);
    const { refType, refPostId, refBoardId, itemId, paymentType, backLink, cancelLink } = paymentInfo;

    const paymentData = JSON.stringify({
      'refType': refType,
      'refPostId': refPostId,
      'refBoardId': refBoardId,
      'itemId': itemId,
      'quantity': 1,
      'paymentType': paymentType,
    });

    orderApi.paymentStep1(
      paymentData,
      (data)=> {
        setLoading(false);
        changePaymentInfo("orderId", data?.orderId);

        if('online' === paymentType) {
          navigate('/market-payment-step2',
            {state: {
              orderId: data?.orderId,
              refType: refType,
              refPostId: refPostId,
              refBoardId: refBoardId,
              itemId: itemId,
              paymentType: paymentType,
              backLink: backLink,
              cancelLink: cancelLink,
            }}
          );
        } else {
          alert("'전화결제는 웹담당자(604-877-1178) 또는 web@vanchosun.com으로 문의 부탁드립니다.'");
          payPhonePayment(data?.orderId);
        }
      },
      (error)=> {
        setLoading(false);
        const {response: {data}} = error;
        const { message, errors } = data;
        if (errors) {
          const { field, defaultMessage } = errors[0];
          setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
        }
      }
    );
  }

  const payPhonePayment = (orderId) => {
    const { refType, refPostId, refBoardId, paymentType, backLink, cancelLink } = paymentInfo;
    navigate('/market-payment-step3',
      { state: {
          orderId: orderId,
          refType: refType,
          refPostId: refPostId,
          refBoardId: refBoardId,
          paymentType: paymentType,
          backLink: backLink,
          cancelLink: cancelLink,
        },
        replace : true
      }
    );
  }

  const handleSearch = () => {
    setLoading(true);
    const { itemId, refPostId, refBoardId } = paymentInfo;

    if(itemId && refPostId && refBoardId) {
      itemApi.fetchItemById(
        itemId,
        (data) => {
          setLoading(false);
          setItemInfo(data.result);
        },
        (error) => {
          setLoading(false);
          const { response: {data} } = error;
          const { status, message, errors } = data;
          console.log(data);
          if(403 === status) {
            alert("결제 서비스 이용시 로그인이 필요합니다.");
            navigate("/signIn", {replace : true});
          } else {
            setDialog({isOpen: true, message: message +" : "+ errors, type: "nonHeader"});
          }
        },
      );
    } else {
      alert("잘못된 접근 입니다.");
      navigate(-1, {replace : true});
    }

  }

  const handlerPaymentMethodOnChange = (value) => {
    if(5 === state?.refBoardId) {//홈스테이
      changePaymentInfo('paymentType', value);
    } else if(6 === state?.refBoardId) {//학원튜터
      changePaymentInfo('paymentType', value);
    } else if(7 === state?.refBoardId) {//구인
      changePaymentInfo('paymentType', value);
    } else if(21 === state?.refBoardId) {//렌트
      changePaymentInfo('paymentType', value);
    }else {
      alert("온라인카드 결제는 개발중입니다.");
    }
  }

  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <Info>
        <table>
          <tbody>
            <tr>
              <td className='head'>광고명</td>
              <td className='col'>[{itemInfo.itemId}] {itemInfo.itemKrNm}</td>
            </tr>
            <tr>
              <td className='head'>상세정보</td>
              <td className='col'>{itemInfo.itemDescription}</td>
            </tr>
            <tr>
              <td className='head'>참조</td>
              <td className='col'>{`${paymentInfo.refBoardId}-${paymentInfo.refPostId}`}</td>
            </tr>
          </tbody>
        </table>
      </Info>
      <ContentWrapper>
        <SubTotal>
          <label>주문금액</label>
          <div>{priceToStringWithDecimal(itemInfo.amount)}</div>
        </SubTotal>
        <EstimatedTex>
          <label>GST (%{itemInfo.gstRate * 100})</label>
          <div>{priceToStringWithDecimal(itemInfo.gstAmount)}</div>
        </EstimatedTex>
        <GrandTotal>
          <label>총 결제금액</label>
          <div>{priceToStringWithDecimal(itemInfo.amount + itemInfo.gstAmount)}</div>
        </GrandTotal>
      </ContentWrapper>
      <Separator height={'1rem'} color={'#F7F7F7'}/>
      <ContentWrapper>
        <RadioButtonComponents
          label={'결제하기'}
          id={'paymentType'}
          value={paymentInfo.paymentType}
          objectData={{'online': { value: 'online', label: '온라인카드'},
            'phone': { value: 'phone', label: '전화결제'},}}
          width={'48%'}
          onChange={(value)=>handlerPaymentMethodOnChange(value)}
        />

        <NextButton
          type='button'
          color="#fff"
          backgroundColor='#fd6258'
          onClick={() => handleNextButtonOnClick()}>다음</NextButton>
      </ContentWrapper>
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Info = styled.div`
  margin: 0;
  padding: 1.6rem;
  background-color: #f5f5f5;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 0.4rem;
    & tr {
      height: 8.1rem;
      border-bottom: 0.1rem solid #eee;
      & td {
        font-size: 14px;
      }
      & td.head {
        padding-left: 1.5rem;
        width: 30%;
        font-size: 14px;
        font-weight: 500;
        line-height: 2rem;
        text-align: left;
        color: #000;
      }
      & td.col {
        width: 70%;
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        color: #424242;
      }
      & td.left {
        text-align: left;
      }
      & td.right {
        text-align: right;
      }
    }
  }
`;

const SubTotal = styled.div`
  margin: 0.6rem 0;
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div {
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;
const EstimatedTex = styled.div`
  margin: 0.6rem 0;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div {
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;
const GrandTotal = styled.div`
  margin: 0;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid #eee;
  & label {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #fd6258;
  }
  & div {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #fd6258;
  }
`;

const NextButton = styled.button`
  margin: 3.6rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;