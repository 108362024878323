import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RealtyAccordionComponent } from '../realty/component/RealtyAccordionComponent';
import { ImageGalleryModal } from '../component/ImageGalleryModal';
import { ImageSlideComponent } from '../component/ImageSlideComponent';
import { Loader } from '../../../common/Loader';
import { RentApi } from '../api/RentApi';
import { addHyphenForPhoneNumber, elapsedText, isEmptyObj, priceToString } from '../../../utils/utils';
import {
  RealEstateCities,
  RentDetailedOptions,
  RentMovingTypes,
  RentRoomTypes,
  RentTypes
} from '../../../__mocks__/PostData';

export const RentDetail = ({setDialog}) => {
  const rentApi = new RentApi();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewEditConformGroup, setViewEditConformGroup] = useState(false);
  const [viewDeleteConformGroup, setViewDeleteConformGroup] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    id: '',
    boardId: '',
    active: '',
    name: '',
    phone: '',
    email: '',
    contactCode: '',
    title: '',
    description: '',
    locationId: '',
    locationSubId: '',
    address: '',
    postal: '',
    type: '',
    roomType:'',
    bedroomCount: 0,
    bathroomCount: 0,
    size: 0,
    builtIn: 0,
    paymentFrequency: '',
    price: 0,
    deposit: 0,
    moveInType: '',
    moveInDate: '',
    leaseTerm: '',
    detailedOptions: '',
    updateDate: '',
    isOwner: false,
    images: {},
  });

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    rentApi.fetchRentById(id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate("/rent-homestay", {state: {isBack: true, type: 'rent'}});
  }

  const handleViewEditConformOnClick = () => {
    setViewEditConformGroup((value) => !value);
    if(viewDeleteConformGroup)
      handleViewDeleteConformOnClick();
  }

  const handleEditOnClick = () => {
    if(article.id) {
      navigate(`/rent-edit/${article.id}`,  {
        state: {
          id: article.id,
        },
      });
    }
  }

  const handleViewDeleteConformOnClick = () => {
    setViewDeleteConformGroup((value) => !value);
    if(viewEditConformGroup)
      handleViewEditConformOnClick();
  }

  const handleDeleteOnClick = () => {
    if(article.id && window.confirm("삭제 하시겠습니까?")) {
      setLoading(true);
      rentApi.deleteRent(article.id,
        (data) => {
          setLoading(false);
          setDialog({isOpen: true, message: "삭제 되었습니다.", type: "alert"});
          handleHistoryBackClick();
        },
        (error)=> {
          setLoading(false);
          setDialog({isOpen: true, message: `처리중 에러가 발생했습니다. ${error.message}`, type: "error"});
        }
      );
    }
  }

  const handlerToggleShowModal = () => {
    if(isEmptyObj(article.images)) return;
    setShowImageModal(!showImageModal);
  }

  const isDetailedOptionsChecked = (value) => {
    return 0<=article.detailedOptions?.indexOf(value);
  }

  // render -------------------------------------------------
  const renderDeleteEditButton = ({isOwner}) => {
    if(!isOwner) return '';
    return (
      <ConformGroup>
        <ButtonGroup>
          <ButtonStyle type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>수정하기</ButtonStyle>
          <ButtonStyle type='button' color="#fd6258" backgroundColor='#fff' onClick={() => handleDeleteOnClick()}>삭제하기</ButtonStyle>
        </ButtonGroup>
      </ConformGroup>
    );
  }

  const renderCategory = ({type, roomType}) => {
    const rentTypeLabel = type ? RentTypes[type].label : type;
    const rentRoomLabel = roomType ? RentRoomTypes[roomType].label : roomType;
    const rentRoomButtonUrl = roomType ? RentRoomTypes[roomType].buttonUrl : '';
    return (
      <>
        {(rentTypeLabel) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span className='categoryLv1'>{rentTypeLabel}</span>
          </>
        )}
        {(rentRoomLabel) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span className='categoryLv2'>
              {rentRoomLabel}
              {rentRoomButtonUrl && (
                <img src={rentRoomButtonUrl} alt='이상' />
              )}
            </span>
          </>
        )}
      </>
    );
  }

  const addLocationNContactTag = ({updatedDate, hitCount}) => {
    return (<span>{elapsedText(updatedDate)} · 조회수 {hitCount}</span>);
  }

  const addDetailedOptionsTag = (id, label) => {
    if(isDetailedOptionsChecked(id)) {
      return (
        <div className='checkbox-checked' >
          <img src='/icon/checkbox-checked-icon.png' alt='checked' />
          <label>{label}</label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-unchecked'>
          <img src='/icon/checkbox-unchecked-icon.png' alt='unchecked' />
          <label>{label}</label>
        </div>
      );
    }
  }

  const addContactTag = ({contactCode, phone, email}) => {
    if(contactCode==="phone") {
      return (<div>{addHyphenForPhoneNumber(phone)}</div>);
    } else if(contactCode==="email") {
      return (<div>{email}</div>);
    } else {
      return (
        <>
          <div>{addHyphenForPhoneNumber(phone)}</div>
          <div>{email}</div>
        </>
      );
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <ImageSlideComponent
          images={article.images}
          noImgUrl={`/img/no-image-rent-homestay.png`}
          onClick={()=>handlerToggleShowModal()}
        />
        <CategoryGroup>
          <div className='category'>
            <span className='categoryLv0'>전체</span>
            {renderCategory(article)}
          </div>
          <span className='postId'>{`게시글번호 ${article.id}`}</span>
        </CategoryGroup>
        <ContentWrapper>
          <ArticleTag>
          </ArticleTag>
          <PageTitle>{article.title}</PageTitle>
          <UpdateInfo>
            {addLocationNContactTag(article)}
          </UpdateInfo>
          <PriceInfo>
            {(0<article.price) ? (
                <>
                  {priceToString(article.price)}<span> / 월</span>
                </>
              ) : '가격문의'
            }
          </PriceInfo>
          <Info>
            <table>
              <tbody>
                <tr>
                  <td className='head'>임대인</td>
                  <td className='col'>{article.name}</td>
                </tr>
                <tr>
                  <td className='head'>지역</td>
                  <td className='col'>{`${RealEstateCities[article.locationSubId]?.label}`}</td>
                </tr>
                <tr>
                  <td className='head'>주소</td>
                  <td className='col'>{article.address}</td>
                </tr>
                <tr>
                  <td className='head'>연락처</td>
                  <td className='col'>
                    {addContactTag(article)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Info>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>한 눈에 보는 렌트</Title>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='bedroomNumberIcon' src='/icon/bedroom-number-icon.png' alt='bedroom'/>
                      </div>
                      <div className='label'>방(수)</div>
                      <div className='value'>
                      {(article.bedroomCount) ? `${article.bedroomCount}개` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='bathroomNumberIcon' src='/icon/bathroom-number-icon.png' alt='bathroom'/>
                      </div>
                      <div className='label'>욕실(수)</div>
                      <div className='value'>
                        {(article.bathroomCount) ? `${article.bathroomCount}개` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='floorSpaceIcon' src='/icon/floor-space-icon.png' alt='Space'/>
                      </div>
                      <div className='label'>전용면적</div>
                      <div className='value'>
                        {(0<article.size) ? `${article.size} sqft` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='builtInIcon' src='/icon/built-in-icon.png' alt='builtIn'/>
                      </div>
                      <div className='label'>건축년도</div>
                      <div className='value'>
                        {(0<article.builtIn? `${article.builtIn}년` : '-')}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Separator height={'0.2rem'} style={{margin:'2.4rem 0'}}/>
            <table>
              <tbody>
                <tr className='icon'>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='moveInDateIcon' src='/icon/move-in-date-icon.png' alt='moveIn Date'/>
                      </div>
                      <div className='label'>입주날짜</div>
                      <div className='value'>
                        {(!article.moveInType)
                          ? '정보없음'
                          : ('moveIn-date' === article.moveInType)
                            ? article.moveInDate
                            : RentMovingTypes[article.moveInType]?.label
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='contractPeriodIcon' src='/icon/contract-period-icon.png' alt='contract  Period'/>
                      </div>
                      <div className='label'>계약기간</div>
                      <div className='value'>
                        {(article.leaseTerm)?article.leaseTerm: '기타'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='depositIcon' src='/icon/deposit-icon.png' alt='deposit'/>
                      </div>
                      <div className='label'>디파짓</div>
                      <div className='value'>
                        {(0<article.deposit)? priceToString(article.deposit): '없음'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                      </div>
                      <div className='label'></div>
                      <div className='value'>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>기본 사항</Title>
          <RealtyAccordionComponent>
            <AdditionalInfo>
              {Object.keys(RentDetailedOptions).map((key =>
                <li key={key}>
                  {addDetailedOptionsTag(key, RentDetailedOptions[key]?.label)}
                </li>
              ))}
            </AdditionalInfo>
          </RealtyAccordionComponent>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>렌트설명</Title>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
        </ContentWrapper>
        { renderDeleteEditButton(article) }
        <ImageGalleryModal
          title='렌트사진'
          showModal={showImageModal}
          toggleShowModal={()=>handlerToggleShowModal()}
          imageList={article.images} />
        <Loader loading={loading} />
      </PageWrapper>
    </>
  );
}
const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const CategoryGroup = styled.div`
  position: relative;
  padding-left: 1.4rem;
  & div.category {
    width: auto;
    height: 4rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #f5f5f5;
    & span.categoryLv0 {
      color: #424242;
    }
    & span.categoryLv1 {
      /* font-weight: 500; */
      color: #424242;
      /* color: #FD6258; */
    }
    & span.categoryLv2 {
      font-weight: 500;
      color: #FD6258;
      display: flex;
      align-items: center;
      & img {
        margin-left: 0.1rem;
        width: 1.4rem;
        height: 1.4rem;
      }
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  & span.postId {
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    padding: 0.2rem 0.8rem;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 30rem;
  }
`;

const ArticleTag = styled.div`
  margin-top: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div {
    display: flex;
    & img {
      display: inline-block;
      margin-right: 0.6rem;
      height: 1.8rem;
    }
    & img.immediatelyMovingIcon {
      width: 5rem;
      height: 1.8rem;
    }
  }
`;
const PageTitle = styled.h1`
  margin: 0;
  padding: 1rem 0 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  word-break: break-all;
`;
const Title = styled.h1`
  margin: 0;
  padding: 2.8rem 0 2.4rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;
const PriceInfo = styled.div`
  margin-top: 0.8rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 2rem;
  color: #000;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;
const Info = styled.div`
  margin: 1.7rem 0;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    & td {
      font-size: 14px;
    }
    & td.head {
      width: 20%;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      vertical-align: top;
      color: #000;
    }
    & td.col {
      width: 70%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;
    }
    & td.left {
      text-align: left;
    }
    & td.right {
      text-align: right;
    }
  }
`;

const PreviewInfo = styled.div`
  margin-bottom: 2.8rem;
  border-radius: 0.4rem;
  & table {
    padding: 0rem 0rem;
    width: 100%;
    & tr {
      width: 100%;
      & td {
        width: 8.3rem;
        & div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & div.icon {
            width: 4.2rem;
            height: 4.2rem;
            & img {
              width: 2.13rem;
              height: 2.13rem;
            }
            & img.bedroomNumberIcon {
              width: 2.4rem;
              height: 1.86rem;
            }
            & img.bathroomNumberIcon {
              width: 2.4rem;
              height: 2.13rem;
            }
            & img.builtInIcon {
              width: 2.4rem;
              height: 2.13rem;
            }
          }
          & div.label {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #424242;
          }
          & div.value {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #9E9E9E;
          }
        }
      }
    }
  }
`;

const AdditionalInfo = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & li {
    margin: 0.4rem;
    width: 47.6%;
    height: 3.2rem;
    & div.checkbox-checked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #fd6258;
      }
    }
    & div.checkbox-unchecked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #9E9E9E;
      }
    }
  }
`;

const Content = styled.div`
  padding: 2.4rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;

const ConformGroup = styled.div`
  padding: 1.6rem;
  border-top: 0.1rem solid #f5f5f5;
`;
const ButtonGroup = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  column-gap: 0.6rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #fd6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;
