import axios from "axios"
import { checkToken } from "./JwtUtils";
import { remoteToken } from "./TokenManager";

export const jsonApi = axios.create({
	baseURL : `${process.env.REACT_APP_API_HOST}`,
	headers: { 'Content-Type': 'application/json', },
	//쿼리로 넘길 키들을 prams 객체에 키밸류로 순서대로 넣어준다.
	// params : {
	// 	api_key : '받은 api',
	// 	laguage : '밸류'
	// }
});
jsonApi.interceptors.request.use(checkToken);
jsonApi.interceptors.response.use(
  (response) => {
    if(response.hasError) {
      return;
    }
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    if(401 === status || 403 === status) {
      axios.post(`${process.env.REACT_APP_API_HOST}/api/account/signOut`);
      remoteToken();
    }
    return Promise.reject(error);
  }
);

export const jsonNoAuthApi = axios.create({
	baseURL : `${process.env.REACT_APP_API_HOST}`,
	headers: { 'Content-Type': 'application/json', },
});

export const formWithMultipartApi = axios.create({
	baseURL : `${process.env.REACT_APP_API_HOST}`,
	headers: { 'Content-Type': 'multipart/form-data', },
});

formWithMultipartApi.interceptors.request.use(checkToken);
formWithMultipartApi.interceptors.response.use(
  (response) => {
    if(response.hasError) {
      return;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);