import React from 'react';
import styled from 'styled-components';

export const SelectComponent = (({ label, infoMessage, id, value, objectData, onChange, disabled}) => {

  const handleOnChange = (value) => {
    onChange?.(value);
  }

  const isSelected = (key, value) => {
    return (key === value);
  }

  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <select className="form-select"
        id={id}
        value={value||''}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {Object.keys(objectData).map((key) =>
          <option key={key} value={objectData[key].value} selected={()=>isSelected(key, value)}>{objectData[key].label}</option>
        )}
      </select>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </InputGroup>
  );
});

const InputGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & select {
    display: block;
    padding: 0rem;
    width: 100%;
    height: 3rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0rem solid #ddd;
    border-bottom: 0.1rem solid #ddd;
    &:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;