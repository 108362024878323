export const GoogleSlot = {
  '': { slot: '9766234213', format: 'auto', responsive: 'true'},
  'buysell_top_ra': { slot: '9411010994', format: 'auto', responsive: 'true'},
  'job_top_ra': { slot: '5826989209', format: 'auto', responsive: 'true'},
  'tutor_top_ra': { slot: '9600952371', format: 'auto', responsive: 'true'},
  'renthomestay_top_ra': { slot: '5470135675', format: 'auto', responsive: 'true'},
  'realtysale_top_ra': { slot: '9355170674', format: 'auto', responsive: 'true'},
  'car_da': { slot: '4489308061', format: 'auto', responsive: 'true'},
  'community_top_ra': { slot: '8687573376', format: 'auto', responsive: 'true'},
  'yellowpage_top_ra': { slot: '1153247025', format: 'auto', responsive: 'true'},
}

export const ActiveType = {
  0: 'CLOSE',
  1: 'OPEN',
  2: 'APPLY',
  9: 'DELETE',
};

export const PostState = [
  { value: '',label: '' },
];

export const BuysellTypes =  {
  free: { value: 'free', label: '무료'},
  buy: { value: 'buy', label: '삽니다'},
  sell: { value: 'sell', label: '팝니다'},
};

export const BuysellSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const BuysellContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const BuysellCategories = {
  'fashion-beauty': { value: 'fashion-beauty', label: '패션/미용', displaySelect: true},
  'living-furniture': { value: 'living-furniture', label: '생활/가구', displaySelect: true},
  'digital-appliances': { value: 'digital-appliances', label: '디지털/가전', displaySelect: true},
  'sports-leisure': { value: 'sports-leisure', label: '스포츠/레저', displaySelect: true},
  'childBirth-care': { value: 'childBirth-care', label: '출산/육아', displaySelect: true},
  'hobby-book': { value: 'hobby-book', label: '취미/도서', displaySelect: true},
  'etc-goods': { value: 'etc-goods', label: '기타/잡화', displaySelect: true},
};

export const BuysellCategoriesWithFree = {
  'fashion-beauty': { value: 'fashion-beauty', label: '패션/미용', displaySelect: true},
  'living-furniture': { value: 'living-furniture', label: '생활/가구', displaySelect: true},
  'digital-appliances': { value: 'digital-appliances', label: '디지털/가전', displaySelect: true},
  'sports-leisure': { value: 'sports-leisure', label: '스포츠/레저', displaySelect: true},
  'childBirth-care': { value: 'childBirth-care', label: '출산/육아', displaySelect: true},
  'hobby-book': { value: 'hobby-book', label: '취미/도서', displaySelect: true},
  'etc-goods': { value: 'etc-goods', label: '기타/잡화', displaySelect: true},
  'free': { value: 'free', label: '무료나눔', displaySelect: false},
  'moving-sale': { value: 'moving-sale', label: '무빙세일', displaySelect: false},
};

export const BuysellConditions =  {
  new: { value: 'new', label: '미사용'},
  good: { value: 'good', label: '상태좋음'},
  used: { value: 'used', label: '사용감 있음'},
};

export const BuysellPurchaseStatus =  {
  'trading': { value: 'trading', label: '거래중'},
  'sold-out': { value: 'sold-out', label: '판매완료'},
};

export const JobTypes = [
  '구인','구직',
];

export const JobPayTypes = {
  '8': '인터넷(Credit Card) 결제 ($10+GST/month)',
  '': '전화 결제 (604-877-1178)',
};

export const JobSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
};

export const JobCategories = {
  'restaurant': { value: 'restaurant', label: '외식/요식'},
  'office-accounting': { value: 'office-accounting', label: '사무/회계'},
  'sales-consultation': { value: 'sales-consultation', label: '영업/상담'},
  'retail-dealership': { value: 'retail-dealership', label: '유통/판매'},
  'shipping-logistics': { value: 'shipping-logistics', label: '배송/물류'},
  'production-tech': { value: 'production-tech', label: '생산/기술'},
  'construction': { value: 'construction', label: '건설/토목'},
  'care-cleaning': { value: 'care-cleaning', label: '돌봄/청소'},
  'it-design': { value: 'it-design', label: 'IT/디자인'},
  'beauty-ceremony': { value: 'beauty-ceremony', label: '미용/예식'},
  'healthcare': { value: 'healthcare', label: '간호/의료'},
  'teaching-lecturer': { value: 'teaching-lecturer', label: '교육/강사'},
  'etc': { value: 'etc', label: '기타'},
};

export const JobContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const JobEmploymentType = {
  'full-time': { value: 'full-time', label: '풀타임'},
  'part-time': { value: 'part-time', label: '파트타임'},
  'both': { value: 'both', label: '무관'},
};

export const JobCareerType = {
  'entry-level': { value: 'entry-level', label: '신입'},
  'experienced': { value: 'experienced', label: '경력'},
  'both': { value: 'both', label: '무관'},
};

export const JobPayType = {
  '8': { value: '8', label: '온라인카드' },
  '': { value: '', label: '전화 결제' },
};

export const JobSoldOut = {
  true: { value: true, label: '구인완료' },
};

export const JobSearchSoldOut = {
  true: { value: true, label: '구직완료' },
};

export const JobSalaryRate = {
  'hour': { value: 'hour', label: '시급' },
  'month': { value: 'month', label: '월급' },
  'year': { value: 'year', label: '연봉' },
  'deal': { value: 'deal', label: '협의' },
};

export const PaymentCardType = {
  'visa': { value: 'visa', label: 'Visa' },
  'master': { value: 'master', label: 'Master' },
};

export const TutorTypes = {
  '학원': { value: '학원', label: '학원'},
  '튜터': { value: '튜터', label: '튜터'},
};

export const TutorSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
};

export const TutorPayTypes = {
  '9': { value: '9', label: '온라인카드',},
  '': { value: '', label: '전화결제',},
};

export const TutorContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const TutorCategories = {
  'school-curriculum': { value: 'school-curriculum', label: '교과'},
  'language': { value: 'language', label: '외국어'},
  'art': { value: 'art', label: '예술'},
  'sports-leisure': { value: 'sports-leisure', label: '스포츠/여가'},
  'computer-it': { value: 'computer-it', label: '컴퓨터/IT'},
  'professional-skill': { value: 'professional-skill', label: '전문/기술'},
  'etc': { value: 'etc', label: '기타'},
};

export const TutorLearners = {
  'grade0': { value: 'grade0', label: '미취학아동'},
  'grade1-6': { value: 'grade1-6', label: '초등(Gr.1-6)'},
  'grade7-9': { value: 'grade7-9', label: '중등(Gr.7-9)'},
  'grade10-12': { value: 'grade10-12', label: '고등(Gr.10-12)'},
  'college-entrance': { value: 'college-entrance', label: '고등(입시)'},
  'bachelor-master-degree': { value: 'bachelor-master-degree', label: '대학(원)'},
  'career-adult': { value: 'career-adult', label: '커리어(성인)'},
};

export const TutorLectureType = {
  'in-person': { value: 'in-person', label: '대면'},
  'remote': { value: 'remote', label: '비대면'},
};

export const TutorLearnerNumber = {
  '1-1': { value: '1-1', label: '1:1'},
  'group': { value: 'group', label: '그룹'},
};

export const TutorLectureLanguages = {
  'korean': { value: 'korean', label: '한국어'},
  'english': { value: 'english', label: '영어'},
};

export const TutorRate = {
  'hour': { value: 'hour', label: '시간당' },
  'time': { value: 'time', label: '회당' },
  'month': { value: 'month', label: '월' },
  'deal': { value: 'deal', label: '문의' },
};

export const CarCategories = {
  'Cars': { value: 'Cars', label: 'Cars'},
  'Minivans': { value: 'Minivans', label: 'Minivans'},
  'Trucks': { value: 'Trucks', label: 'Trucks'},
  'Crossovers': { value: 'Crossovers', label: 'Crossovers'},
  'SUVs': { value: 'SUVs', label: 'SUVs'},
  'Fuel': { value: 'Fuel', label: 'Fuel'},
  'Hybrid': { value: 'Hybrid', label: 'Hybrid'},
  'Electric': { value: 'Electric', label: 'Electric'},
};

export const CityList = [
  { value: '5',  label: '밴쿠버', enName: 'Vancouver', },
  { value: '9',  label: '리치몬드', enName: 'Vancouver', },
  { value: '15', label: '뉴웨스터민스터', enName: 'Vancouver', },
  { value: '2',  label: '코퀴틀람', enName: 'Vancouver', },
  { value: '1',  label: '버나비', enName: 'Vancouver', },
  { value: '14', label: '포트코퀴틀람', enName: 'Vancouver', },
  { value: '3',  label: '포트무디', enName: 'Vancouver', },
  { value: '4',  label: '써리', enName: 'Vancouver', },
  { value: '11', label: '랭리', enName: 'Vancouver', },
  { value: '6',  label: '노스밴쿠버', enName: 'Vancouver', },
  { value: '7',  label: '웨스트밴쿠버', enName: 'Vancouver', },
  { value: '8',  label: '메이플릿지', enName: 'Vancouver', },
  { value: '16', label: '핏메도우', enName: 'Vancouver', },
  { value: '10', label: '화이트락', enName: 'Vancouver', },
  { value: '12', label: '델타', enName: 'Vancouver', },
  { value: '19', label: '아보츠포드', enName: 'Vancouver', },
  { value: '13', label: '기타', enName: 'Vancouver', },
];

export const Cities = {
  '5' :{ value: '5',  label: '밴쿠버', enName: 'Vancouver', },
  '9' :{ value: '9',  label: '리치몬드', enName: 'Vancouver', },
  '15':{ value: '15', label: '뉴웨스터민스터', enName: 'Vancouver', },
  '2' :{ value: '2',  label: '코퀴틀람', enName: 'Vancouver', },
  '1' :{ value: '1',  label: '버나비', enName: 'Vancouver', },
  '14':{ value: '14', label: '포트코퀴틀람', enName: 'Vancouver', },
  '3' :{ value: '3',  label: '포트무디', enName: 'Vancouver', },
  '4' :{ value: '4',  label: '써리', enName: 'Vancouver', },
  '11':{ value: '11', label: '랭리', enName: 'Vancouver', },
  '6' :{ value: '6',  label: '노스밴쿠버', enName: 'Vancouver', },
  '7' :{ value: '7',  label: '웨스트밴쿠버', enName: 'Vancouver', },
  '8' :{ value: '8',  label: '메이플릿지', enName: 'Vancouver', },
  '16':{ value: '16', label: '핏메도우', enName: 'Vancouver', },
  '10':{ value: '10', label: '화이트락', enName: 'Vancouver', },
  '12':{ value: '12', label: '델타', enName: 'Vancouver', },
  '19':{ value: '19', label: '아보츠포드', enName: 'Vancouver', },
  '13':{ value: '13', label: '기타', enName: 'Vancouver', },
  // { value: '17', label: '재스퍼', enName: 'Vancouver', },
  // { value: '20', label: '킬로나 ', enName: 'Vancouver', },
  // '5' :{ label: '밴쿠버',         value: 'Vancouver'},
  // '9' :{ label: '리치몬드',       value: 'Richmond'},
  // '15':{ label: '뉴웨스터민스터',  value: 'New Westminster'},
  // '2' :{ label: '코퀴틀람',       value: 'Coquitlam'},
  // '1' :{ label: '버나비',         value: 'Burnaby'},
  // '14':{ label: '포트코퀴틀람',   value: 'Port Coquitlam'},
  // '3' :{ label: '포트무디',       value: 'Port Moody'},
  // '4' :{ label: '써리',           value: 'Surrey'},
  // '11':{ label: '랭리',           value: 'Langley'},
  // '6' :{ label: '노스밴쿠버',      value: 'North Vancouver'},
  // '7' :{ label: '웨스트밴쿠버',    value: 'West Vancouver'},
  // '8' :{ label: '메이플릿지',      value: 'Maple Ridge'},
  // '16':{ label: '핏메도우',        value: 'Pitt Meadow'},
  // '10':{ label: '화이트락',        value: 'White Rock'},
  // '12':{ label: '델타',            value: 'Delta'},
  // '19':{ label: '아보츠포드',      value: 'Abbotsford'},
  // '17':{ label: '재스퍼',          value: 'Jasper'},
  // // '18':{ key:'18', label: 'Lower Mainland', value: 'Lower Mainland'},
  // '20':{ label: '킬로나 ',         value: 'Kelowna'},
  // '13':{ label: '기타',            value: 'Other'},
};

export const RealEstateProvinces = {
  '1': { value: '1', label: 'BC'},
  '4': { value: '4', label: 'AB'},
  '7': { value: '7', label: 'SK'},
};

export const RealEstateCities = {
  '1': { value: '1', label: '밴쿠버', enName: 'Vancouver'},
  '8': { value: '8', label: '리치몬드', enName: 'Richmond'},
  '25': { value: '25', label: '뉴웨스민스터', enName: 'New Westminster'},
  '5': { value: '5', label: '	코퀴틀람', enName: 'Coquitlam'},
  '4': { value: '4', label: '	버나비', enName: 'Burnaby'},
  '14': { value: '14', label: '포트코퀴틀람', enName: 'Port Coquitlam'},
  '2': { value: '2', label: '포트무디', enName: 'Port Moody'},
  '7': { value: '7', label: '써리', enName: 'Surrey'},
  '10': { value: '10', label: '랭리', enName: 'Langley'},
  '6': { value: '6', label: '노스밴쿠버', enName: 'North Vancouver'},
  '11': { value: '11', label: '웨스트밴쿠버', enName: 'West Vancouver'},
  '21': { value: '21', label: '메이플릿지', enName: 'Maple Ridge'},
  '24': { value: '24', label: '핏메도우', enName: 'White Rock'},
  '19': { value: '19', label: '화이트락', enName: 'White Rock'},
  '9': { value: '9', label: '	델타', enName: 'Delta'},
  '12': { value: '12', label: '아보츠포드', enName: 'Abbotsford'},
  '13': { value: '13', label: '칠리왁', enName: 'Chilliwack'},
  '15': { value: '15', label: '나나이모', enName: 'Nanaimo'},
  '16': { value: '16', label: '빅토리아', enName: 'Victoria'},
  '17': { value: '17', label: '켈로나', enName: 'Kelowna'},
  '18': { value: '18', label: '캠룹스', enName: 'Kamloops'},
  '20': { value: '20', label: '미션', enName: 'Mission'},
  '22': { value: '22', label: '스쿼미쉬', enName: 'Squamish'},
  '23': { value: '23', label: '휘슬러', enName: 'Whistler'},
  '40': { value: '40', label: '기타', enName: 'Other'},
};

export const RentTypes = {
  '1': { value: '1', label: '하우스'},
  '2': { value: '2', label: '타운하우스'},
  '3': { value: '3', label: '콘도/아파트'},
  '4': { value: '4', label: '사무실/상가'},
  '5': { value: '5', label: '창고/차고'},
  '6': { value: '6', label: '기타'},
};

export const RentFilterTypeList = [
  { value: '3', label: '콘도/아파트' },
  { value: '1', label: '하우스' },
  { value: '2', label: '타운하우스' },
  { value: '4', label: '사무실/상가' },
  { value: '5', label: '창고/차고' },
  { value: '6', label: '기타' },
];

export const RentRoomTypes = {
  '1': { value: '1', label: '스튜디오', buttonUrl: '' },
  '2': { value: '2', label: '1BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '3': { value: '3', label: '2BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '4': { value: '4', label: '3BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '5': { value: '5', label: '4BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '6': { value: '6', label: '5BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '7': { value: '7', label: '룸렌트', buttonUrl: '' },
  '8': { value: '8', label: '기타', buttonUrl: '' },
};

export const RentFilterRoomTypeList = [
  { value: '1', label: '스튜디오', buttonUrl: '' },
  { value: '2', label: '1BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  { value: '3', label: '2BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  { value: '4', label: '3BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  { value: '5', label: '4BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  { value: '6', label: '5BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  { value: '7', label: '룸렌트', buttonUrl: '' },
  { value: '8', label: '기타', buttonUrl: '' },
];

export const RentSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const RentBedroomNumbers = {
  0: { value: 0, label: '0개' },
  1: { value: 1, label: '1개' },
  1.5: { value: 1.5, label: '1.5개' },
  2: { value: 2, label: '2개' },
  2.5: { value: 2.5, label: '2.5개' },
  3: { value: 3, label: '3개' },
  3.5: { value: 3.5, label: '3.5개' },
  4: { value: 4, label: '4개' },
  4.5: { value: 4.5, label: '4.5개' },
  5: { value: 5, label: '5개 이상' },
};

export const RentBedroomNumberList = [
  { value: 0, label: '0개'},
  { value: 1.0, label: '1개'},
  { value: 1.5, label: '1.5개'},
  { value: 2.0, label: '2개'},
  { value: 2.5, label: '2.5개'},
  { value: 3.0, label: '3개'},
  { value: 3.5, label: '3.5개'},
  { value: 4.0, label: '4개'},
  { value: 4.5, label: '4.5개'},
  { value: 5.0, label: '5개 이상'},
];

export const RentBathroomNumbers = {
  0: { value: 0, label: '0개'},
  1: { value: 1, label: '1개'},
  2: { value: 2, label: '2개'},
  3: { value: 3, label: '3개'},
  4: { value: 4, label: '4개'},
  5: { value: 5, label: '5개 이상'},
};

export const RentPaymentFrequency = {
  '월': { value: '월', label: '월' },
  '': { value: '', label: '가격문의' },
};

export const RentNeedDeposit = {
  false: { value: false, label: '없음' },
  true: { value: true, label: '있음' },
};

export const RentMovingTypes = {
  'immediate-moveIn': { value: 'immediate-moveIn', label: '즉시입주'},
  'moveIn-date': { value: 'moveIn-date', label: '날짜선택'},
  'deal': { value: 'deal', label: '협의 가능'},
};

export const RentContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const RentLeaseTerms = {
  '1달 미만': { value: '1달 미만', label: '1달 미만'},
  '1달 이상': { value: '1달 이상', label: '1달 이상'},
  '3달 이상': { value: '3달 이상', label: '3달 이상'},
  '6달 이상': { value: '6달 이상', label: '6달 이상'},
  '1년 이상': { value: '1년 이상', label: '1년 이상'},
  '기타': { value: '기타', label: '기타'},
};

export const RentFilterLeaseTermList = [
  { value: '1달 미만', label: '1달 미만'},
  { value: '1달 이상', label: '1달 이상'},
  { value: '3달 이상', label: '3달 이상'},
  { value: '6달 이상', label: '6달 이상'},
  { value: '1년 이상', label: '1년 이상'},
  { value: '기타', label: '기타'},
];

export const RentDetailedOptions = {
  '[fully-furnished]': { value: '[fully-furnished]', label: '가구완비' },
  '[included-utilities]': { value: '[included-utilities]', label: '유틸리티(포함)' },
  '[semi-utilities]': { value: '[semi-utilities]', label: '유틸리티(분할)' },
  '[included-internet]': { value: '[included-internet]', label: '인터넷(포함)' },
  '[semi-internet]': { value: '[semi-internet]', label: '인터넷(분할)' },
  '[allowed-parking]': { value: '[allowed-parking]', label: '주차가능' },
  '[allowed-pet]': { value: '[allowed-pet]', label: '반려동물 가능' },
  '[allowed-smoking]': { value: '[allowed-smoking]', label: '흡연 가능' },
  '[included-washroom]': { value: '[included-washroom]', label: '전용 화장실' },
  '[included-washingMachine]': { value: '[included-washingMachine]', label: '별도 전용세탁기' },
  '[included-entranceDoor]': { value: '[included-entranceDoor]', label: '별도 출입문' },
  '[need-tenantInsurance]': { value: '[need-tenantInsurance]', label: '세입자보험' },
};

export const RentFilterDetailedOptionList = [
  { value: '[fully-furnished]', label: '가구완비' },
  { value: '[included-utilities]', label: '유틸리티 포함' },
  { value: '[included-internet]', label: '인터넷 포함' },
  { value: '[allowed-parking]', label: '주차가능' },
  { value: '[allowed-pet]', label: '반려동물 가능' },
  { value: '[allowed-smoking]', label: '흡연 가능' },
  { value: '[included-washroom]', label: '전용화장실' },
  { value: '[included-washingMachine]', label: '전용세탁기' },
  { value: '[included-entranceDoor]', label: '별도출입문' },
  { value: '[need-tenantInsurance]', label: '세입자보험' },
];

export const RentDetailedOptionForCard = {
  '[fully-furnished]': { value: '[fully-furnished]', label: '가구완비'},
  '[included-utilities]': { value: '[included-utilities]', label: '유틸포함'},
  '[semi-utilities]': { value: '[semi-utilities]', label: '유틸분할'},
  '[included-internet]': { value: '[included-internet]', label: '인터넷포함'},
  '[semi-internet]': { value: '[semi-internet]', label: '인터넷분할'},
  '[allowed-parking]': { value: '[allowed-parking]', label: '주차가능'},
  '[allowed-smoking]': { value: '[allowed-smoking]', label: '흡연가능'},
  '[allowed-pet]': { value: '[allowed-pet]', label: '반려동물'},
  '[included-washroom]': { value: '[included-washroom]', label: '전용화장실'},
  '[included-washingMachine]': { value: '[included-washingMachine]', label: '전용세탁기'},
  '[included-entranceDoor]': { value: '[included-entranceDoor]', label: '별도출입문'},
  '[need-tenantInsurance]': { value: '[need-tenantInsurance]', label: '세입자보험'},
};

export const RentStatus =  {
  false: { value: false, label: '렌트가능'},
  true: { value: true, label: '렌트완료'},
};

export const RentPayTypes = {
  '410': { value: '410', label: '온라인카드',},
  '': { value: '', label: '전화결제',},
};

// ------
export const HomestayContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};
export const HomestayTypes = {
  '1': { value: '1', label: '하우스'},
  '2': { value: '2', label: '타운하우스'},
  '3': { value: '3', label: '콘도/아파트'},
  '4': { value: '4', label: '사무실/상가'},
  '5': { value: '5', label: '비지니스'},
  '6': { value: '6', label: '기타'},
};
export const HomestaySort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const HomestayRoomTypes = {
  '1': { value: '1', label: '독채'},
  '2': { value: '2', label: '개인실'},
  '3': { value: '3', label: '다인실'},
  '4': { value: '4', label: '가족실'},
  '5': { value: '5', label: '기타'},
};

export const HomestayFilterRoomTypeList = [
  { value: '1', label: '독채'},
  { value: '2', label: '개인실'},
  { value: '3', label: '다인실'},
  { value: '4', label: '가족실'},
  { value: '5', label: '기타'},
];

export const HomestayBedroomNumbers = {
  0: { value: 0, label: '0개'},
  1: { value: 1, label: '1개'},
  2: { value: 2, label: '2개'},
  3: { value: 3, label: '3개'},
  4: { value: 4, label: '4개'},
  5: { value: 5, label: '5개 이상'},
};

export const HomestayBathroomNumbers = {
  0: { value: 0, label: '0개'},
  1: { value: 1, label: '1개'},
  2: { value: 2, label: '2개'},
  3: { value: 3, label: '3개'},
  4: { value: 4, label: '4개'},
  5: { value: 5, label: '5개 이상'},
};

export const HomestayCheckInTypes = {
  'immediate-checkIn': { value: 'immediate-checkIn', label: '즉시입주'},
  'checkIn-date': { value: 'checkIn-date', label: '날짜선택'},
  'deal': { value: 'deal', label: '협의 가능'},
};

export const HomestayGuestGender = {
  'male-only': { value: 'male-only', label: '남성전용'},
  'female-only': { value: 'female-only', label: '여성전용'},
  '': { value: '', label: '성별무관'},
};

export const HomestayFilterGuestGenderList = [
  { value: 'male-only', label: '남성전용'},
  { value: 'female-only', label: '여성전용'},
];

export const HomestayPaymentFrequency = {
  '1박': { value: '1박', label: '1박' },
  '월': { value: '월', label: '월' },
  '': { value: '', label: '가격문의' },
};

export const HomestayMinimumReservation = {
  '1박': { value: '1박', label: '1박' },
  '2박': { value: '2박', label: '2박' },
  '3박': { value: '3박', label: '3박' },
  '4박': { value: '4박', label: '4박' },
  '5박': { value: '5박', label: '5박' },
  '6박': { value: '6박', label: '6박' },
  '7박 이상': { value: '7박 이상', label: '7박 이상' },
  '1달 이상': { value: '1달 이상', label: '1달 이상' },
  '': { value: '', label: '기타' },
};

export const HomestayFilterMinimumReservationList = [
  { value: '1박', label: '1박' },
  { value: '2박', label: '2박' },
  { value: '3박', label: '3박' },
  { value: '4박', label: '4박' },
  { value: '5박', label: '5박' },
  { value: '6박', label: '6박' },
  { value: '7박 이상', label: '7박 이상' },
  { value: '1달 이상', label: '1달 이상' },
];

export const HomestayDetailedOptions = {
  '[meals]': { value: '[meals]', label: '식사제공'},
  '[airport-pick-up]': { value: '[airport-pick-up]', label: '공항픽업'},
  '[wifi]': { value: '[wifi]', label: 'WIFI'},
  '[ride]': { value: '[ride]', label: '라이드'},
  '[kitchen]': { value: '[kitchen]', label: '주방시설'},
  '[parking]': { value: '[parking]', label: '주차가능'},
  '[pet]': { value: '[pet]', label: '반려동물'},
  '[cleaning]': { value: '[cleaning]', label: '청소/세탁 서비스'},
  '[learning-management]': { value: '[learning-management]', label: '학습관리'},
  '[separate-door]': { value: '[separate-door]', label: '별도 출입문'},
  '[washroom]': { value: '[washroom]', label: '전용 화장실'},
};

export const HomestayFilterDetailedOptionList = [
  { value: '[meals]', label: '식사제공'},
  { value: '[airport-pick-up]', label: '공항픽업'},
  { value: '[wifi]', label: 'WIFI'},
  { value: '[ride]', label: '라이드'},
  { value: '[kitchen]', label: '주방시설'},
  { value: '[parking]', label: '주차가능'},
  { value: '[pet]', label: '반려동물'},
  { value: '[cleaning]', label: '청소/세탁 서비스'},
  { value: '[learning-management]', label: '학습관리'},
  { value: '[separate-door]', label: '별도 출입문'},
  { value: '[washroom]', label: '전용 화장실'},
];

export const HomestayDetailedOptionForCard = {
  '[meals]': { value: '[meals]', label: '식사제공'},
  '[airport-pick-up]': { value: '[airport-pick-up]', label: '공항픽업'},
  '[wifi]': { value: '[wifi]', label: 'WIFI'},
  '[ride]': { value: '[ride]', label: '라이드'},
  '[kitchen]': { value: '[kitchen]', label: '주방시설'},
  '[parking]': { value: '[parking]', label: '주차가능'},
  '[pet]': { value: '[pet]', label: '반려동물'},
  '[cleaning]': { value: '[cleaning]', label: '청소/세탁'},
  '[learning-management]': { value: '[learning-management]', label: '학습관리'},
  '[separate-door]': { value: '[separate-door]', label: '별도출입문'},
  '[washroom]': { value: '[washroom]', label: '전용화장실'},
};

export const HomestayPayTypes = {
  '2': { value: '2', label: '온라인카드',},
  '': { value: '', label: '전화결제',},
};

export const DwellingTypes = {
  '하우스': { value: '하우스', label: '하우스'},
  '타운하우스': { value: '타운하우스', label: '타운하우스'},
  '콘도/아파트': { value: '콘도/아파트', label: '콘도/아파트'},
  '기타': { value: '기타', label: '기타'},
};

export const DwellingTypeList = [
  { value: '콘도/아파트', label: '콘도/아파트'},
  { value: '하우스', label: '하우스'},
  { value: '타운하우스', label: '타운하우스'},
  { value: '기타', label: '기타'},
];

export const DwellingSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const DwellingSpaceUnits = {
  'sqft': { value: 'sqft', label: 'Sqft'},
  'acre': { value: 'acre', label: 'Acre'},
};

export const DwellingIncludedOptions = {
  '[refrigerator]': { value: '[refrigerator]', label: '냉장고'},
  '[dishwasher]': { value: '[dishwasher]', label: '식기세척기'},
  '[microwave]': { value: '[microwave]', label: '전자레인지'},
  '[washer-dryer]': { value: '[washer-dryer]', label: '세탁기/건조기'},
  '[clothes-machine]': { value: '[clothes-machine]', label: '의류관리기'},
  '[air-conditioner]': { value: '[air-conditioner]', label: '에어컨'},
  '[floor-heating]': { value: '[floor-heating]', label: '바닥난방'},
  '[laminated-floor]': { value: '[laminated-floor]', label: '라미네이트 바닥'},
  '[carpet-floor]': { value: '[carpet-floor]', label: '카펫바닥'},
  '[fireplace]': { value: '[fireplace]', label: '벽난로'},
  '[storage]': { value: '[storage]', label: '스토리지'},
};

export const DwellingFilterIncludedOptionList = [
  { value: '[refrigerator]', label: '냉장고'},
  { value: '[dishwasher]', label: '식기세척기'},
  { value: '[microwave]', label: '전자레인지'},
  { value: '[washer-dryer]', label: '세탁기/건조기'},
  { value: '[clothes-machine]', label: '의류관리기'},
  { value: '[air-conditioner]', label: '에어컨'},
  { value: '[floor-heating]', label: '바닥난방'},
  { value: '[laminated-floor]', label: '라미네이트 바닥'},
  { value: '[carpet-floor]', label: '카펫바닥'},
  { value: '[fireplace]', label: '벽난로'},
  { value: '[storage]', label: '스토리지'},
];

export const DwellingAdditionalFacilities = {
  '[fitness-room]': { value: '[fitness-room]', label: '피트니스룸'},
  '[yoga-room]': { value: '[yoga-room]', label: '요가룸'},
  '[pool]': { value: '[pool]', label: '수영장'},
  '[playground]': { value: '[playground]', label: '놀이터'},
  '[lounge]': { value: '[lounge]', label: '라운지'},
  '[concierge]': { value: '[concierge]', label: '컨시어지'},
  '[rooftop]': { value: '[rooftop]', label: '루프탑'},
  '[ev-charger]': { value: '[ev-charger]', label: 'EV 충전기'},
};

export const DwellingFilterAdditionalFacilityList = [
  { value: '[fitness-room]', label: '피트니스룸'},
  { value: '[yoga-room]', label: '요가룸'},
  { value: '[pool]', label: '수영장'},
  { value: '[playground]', label: '놀이터'},
  { value: '[lounge]', label: '라운지'},
  { value: '[concierge]', label: '컨시어지'},
  { value: '[rooftop]', label: '루프탑'},
  { value: '[ev-charger]', label: 'EV 충전기'},
];

export const CommercialUnits = {
  'sqft': { value: 'sqft', label: 'Sqft'},
  'acre': { value: 'acre', label: 'Acre'},
};

export const CommercialTypes = {
  '비즈니스': { value: '비즈니스', label: '비즈니스'},
  '소매': { value: '소매', label: '소매'},
  '상가': { value: '상가', label: '상가'},
  '사무실': { value: '사무실', label: '사무실'},
  '땅/부지': { value: '땅/부지', label: '땅/부지'},
  '기타': { value: '기타', label: '기타'},
};

export const CommercialTypeList = [
  { value: '비즈니스', label: '비즈니스'},
  { value: '소매', label: '소매'},
  { value: '상가', label: '상가'},
  { value: '사무실', label: '사무실'},
  { value: '땅/부지', label: '땅/부지'},
  { value: '기타', label: '기타'},
];


export const CommercialSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const CommercialIncludedOptions = {
  '[elevator]': { value: '[elevator]', label: '엘리베이터'},
  '[full-kitchen]': { value: '[full-kitchen]', label: '풀키친'},
  '[storage]': { value: '[storage]', label: '창고'},
  '[cellar]': { value: '[cellar]', label: '지하실'},
  '[liquor-license]': { value: '[liquor-license]', label: '주류면허'},
  '[patio]': { value: '[patio]', label: '파티오'},
  '[walk-in-cooler]': { value: '[walk-in-cooler]', label: '워크인 쿨러'},
  '[residential-space]': { value: '[residential-space]', label: '주거공간'},
  '[security-system]': { value: '[security-system]', label: '보안 시스템'},
  '[private-washroom]': { value: '[private-washroom]', label: '전용 화장실'},
};
export const CommercialIncludedOptionList = [
  { value: '[elevator]', label: '엘리베이터'},
  { value: '[full-kitchen]', label: '풀키친'},
  { value: '[storage]', label: '창고'},
  { value: '[cellar]', label: '지하실'},
  { value: '[liquor-license]', label: '주류면허'},
  { value: '[patio]', label: '파티오'},
  { value: '[walk-in-cooler]', label: '워크인 쿨러'},
  { value: '[residential-space]', label: '주거공간'},
  { value: '[security-system]', label: '보안 시스템'},
  { value: '[private-washroom]', label: '전용 화장실'},
];

export const CommercialAdditionalFacilities = {
  '[negotiable-price]': { value: '[negotiable-price]', label: '가격협상 가능'},
  '[changeable-business]': { value: '[changeable-business]', label: '타업종 변경가능'},
  '[full-training]': { value: '[full-training]', label: '풀 트레이닝 가능'},
  '[no-demolition-clause]': { value: '[no-demolition-clause]', label: '데몰리션 조항 없음'},
};
export const CommercialAdditionalFacilityList = [
  { value: '[negotiable-price]', label: '가격협상 가능'},
  { value: '[changeable-business]', label: '타업종 변경가능'},
  { value: '[full-training]', label: '풀 트레이닝 가능'},
  { value: '[no-demolition-clause]', label: '데몰리션 조항 없음'},
];
// ------
export const CarTypes = {
  '경차': { value: '경차', label: '경차'},
  '소형차': { value: '소형차', label: '소형차'},
  '준중형차': { value: '준중형차', label: '준중형차'},
  '중형차': { value: '중형차', label: '중형차'},
  '대형차': { value: '대형차', label: '대형차'},
  'SUV': { value: 'SUV', label: 'SUV'},
  '미니밴': { value: '미니밴', label: '미니밴'},
  'RV/트럭': { value: 'RV/트럭', label: 'RV/트럭'},
  '기타': { value: '기타', label: '기타'},
};

export const CarFilterTypeList = [
  { value: '경차', label: '경차'},
  { value: '소형차', label: '소형차'},
  { value: '준중형차', label: '준중형차'},
  { value: '중형차', label: '중형차'},
  { value: '대형차', label: '대형차'},
  { value: 'SUV', label: 'SUV'},
  { value: '미니밴', label: '미니밴'},
  { value: 'RV/트럭', label: 'RV/트럭'},
  { value: '기타', label: '기타'},
];

export const CarDrivingSystem = {
  '4WD': { value: '4WD', label: '4WD'},
  'AWD': { value: 'AWD', label: 'AWD'},
  'FWD': { value: 'FWD', label: 'FWD'},
  'RWD': { value: 'RWD', label: 'RWD'},
  '기타': { value: '기타', label: '기타'},
};

export const CarGesType = {
  '디젤': { value: '디젤', label: '디젤'},
  '가솔린': { value: '가솔린', label: '가솔린'},
  '하이브리드': { value: '하이브리드', label: '하이브리드'},
  '전기': { value: '전기', label: '전기'},
  '기타': { value: '기타', label: '기타'},
};

export const CarFilterGesTypeList = [
  { value: '디젤', label: '디젤'},
  { value: '전기', label: '전기'},
  { value: '가솔린', label: '가솔린'},
  { value: '하이브리드', label: '하이브리드'},
  { value: '기타', label: '기타'},
];

export const CarTransmission = {
  '오토': { value: '오토', label: '오토'},
  '수동': { value: '수동', label: '수동'},
  '세미오토': { value: '세미오토', label: '세미오토'},
  'CVT': { value: 'CVT', label: 'CVT'},
  '기타': { value: '기타', label: '기타'},
};

export const CarFilterTransmissionList = [
  { value: '오토', label: '오토'},
  { value: '수동', label: '수동'},
  { value: '세미오토', label: '세미오토'},
  { value: 'CVT', label: 'CVT'},
  { value: '기타', label: '기타'},
];

export const CarSort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "low-price": { code: "low-price", label: "낮은가격순"},
  "high-price": { code: "high-price", label: "높은가격순"},
};

export const CarColors = {
  '2': { value: '2', label: '블랙'},
  '6': { value: '6', label: '화이트'},
  '3': { value: '3', label: '그레이'},
  '9': { value: '9', label: '실버'},
  '12': { value: '12', label: '브론즈'},
  '13': { value: '13', label: '브라운'},
  '11': { value: '11', label: '블루'},
  '1': { value: '1', label: '레드'},
  '14': { value: '14', label: '골드'},
  '8': { value: '8', label: '티이일'},
  '7': { value: '7', label: '다크그레이'},
  '4': { value: '4', label: '그린'},
  '5': { value: '5', label: '옐로우'},
  '10': { value: '10', label: '기타'},
};

export const CarMinYear = {
  2000: { value: 2000, label: '2000년'},
  2001: { value: 2001, label: '2001년'},
  2002: { value: 2002, label: '2002년'},
  2003: { value: 2003, label: '2003년'},
  2004: { value: 2004, label: '2004년'},
  2005: { value: 2005, label: '2005년'},
  2006: { value: 2006, label: '2006년'},
  2007: { value: 2007, label: '2007년'},
  2008: { value: 2008, label: '2008년'},
  2009: { value: 2009, label: '2009년'},
  2010: { value: 2010, label: '2010년'},
  2011: { value: 2011, label: '2011년'},
};

export const CarMaxYear = {
  2012: { value: 2012, label: '2012년'},
  2013: { value: 2013, label: '2013년'},
  2014: { value: 2014, label: '2014년'},
  2015: { value: 2015, label: '2015년'},
  2016: { value: 2016, label: '2016년'},
  2017: { value: 2017, label: '2017년'},
  2018: { value: 2018, label: '2018년'},
  2019: { value: 2019, label: '2019년'},
  2020: { value: 2020, label: '2020년'},
  2021: { value: 2021, label: '2021년'},
  2022: { value: 2022, label: '2022년'},
  2023: { value: 2023, label: '2023년'},
};

export const CarIncludedOptions = {
  '[sunroof]': { value: '[sunroof]', label: '선루프'},
  '[roof-rack]': { value: '[roof-rack]', label: '루프랙'},
  '[navigation]': { value: '[navigation]', label: '네비게이션'},
  '[bluetooth]': { value: '[bluetooth]', label: '블루투스'},
  '[leather-seat]': { value: '[leather-seat]', label: '가죽시트'},
  '[electric-seat]': { value: '[electric-seat]', label: '전동시트'},
  '[rear-camera]': { value: '[rear-camera]', label: '후방카메라'},
  '[collision-avoidance]': { value: '[collision-avoidance]', label: '추돌방지기능'},
  '[lane-departure-warning]': { value: '[lane-departure-warning]', label: '차선이탈경보'},
};

export const CarIncludedOptionList = [
  { value: '[sunroof]', label: '선루프'},
  { value: '[roof-rack]', label: '루프랙'},
  { value: '[navigation]', label: '네비게이션'},
  { value: '[bluetooth]', label: '블루투스'},
  { value: '[leather-seat]', label: '가죽시트'},
  { value: '[electric-seat]', label: '전동시트'},
  { value: '[rear-camera]', label: '후방카메라'},
  { value: '[collision-avoidance]', label: '추돌방지기능'},
  { value: '[lane-departure-warning]', label: '차선이탈경보'},
];

export const CarAdditionalOptions = {
  '[viewable-car-history]': { value: '[viewable-car-history]', label: '차량기록조회 가능'},
  '[possible-trade-in]': { value: '[possible-trade-in]', label: '트레이드인 가능'},
  '[full-inspection-complete]': { value: '[full-inspection-complete]', label: '풀인스펙션 완료'},
  '[extensible-warranty]': { value: '[extensible-warranty]', label: '워런티 연장가능'},
  '[owned-by-one-person]': { value: '[owned-by-one-person]', label: '1인 소유'},
  '[non-smoking]': { value: '[non-smoking]', label: '비흡연'},
  '[accident-free]': { value: '[accident-free]', label: '무사고'},
};

export const CarBrand = {
  'Acura': { value: 'Acura', label: '아큐라'},
  'Alfa Romeo': { value: 'Alfa Romeo', label: '알파로미오'},
  'Audi': { value: 'Audi', label: '아우디'},
  'BMW': { value: 'BMW', label: 'BMW'},
  'Buick': { value: 'Buick', label: '뷰익'},
  'Cadillac': { value: 'Cadillac', label: '캐딜락'},
  'Caliber': { value: 'Caliber', label: '캘리버'},
  'Chevrolet': { value: 'Chevrolet', label: '쉐보레'},
  'Chrysler': { value: 'Chrysler', label: '크라이슬러'},
  'Dodge': { value: 'Dodge', label: '닷지'},
  'Fiat': { value: 'Fiat', label: '피아트'},
  'Ford': { value: 'Ford', label: '포드'},
  'GMC': { value: 'GMC', label: 'GMC'},
  'Genesis': { value: 'Genesis', label: '제네시스'},
  'Honda': { value: 'Honda', label: '혼다'},
  'Hummer': { value: 'Hummer', label: '허머'},
  'Hyundai': { value: 'Hyundai', label: '현대'},
  'Infiniti': { value: 'Infiniti', label: '인피니티'},
  'Jaguar': { value: 'Jaguar', label: '재규어'},
  'Jeep': { value: 'Jeep', label: 'Jeep'},
  'Kia': { value: 'Kia', label: '기아'},
  'Land Rover': { value: 'Land Rover', label: '랜드로버'},
  'Lexus': { value: 'Lexus', label: '렉서스'},
  'Lincoln': { value: 'Lincoln', label: '링컨'},
  'Mini': { value: 'Mini', label: 'Mini'},
  'Maserati': { value: 'Maserati', label: '마세라티'},
  'Mazda': { value: 'Mazda', label: '마쓰다'},
  'Mercedes-AMG': { value: 'Mercedes-AMG', label: '메르세데스-AMG'},
  'Mercedes-Benz': { value: 'Mercedes-Benz', label: '메르세데스-벤츠'},
  'Mitsubishi': { value: 'Mitsubishi', label: '미쓰비시'},
  'Morgan': { value: 'Morgan', label: '모건'},
  'Nissan': { value: 'Nissan', label: '닛산'},
  'Pontiac': { value: 'Pontiac', label: '폰티액'},
  'Porsche': { value: 'Porsche', label: '포르쉐'},
  'Ram': { value: 'Ram', label: '램'},
  'Saab': { value: 'Saab', label: '사브'},
  'Saturn': { value: 'Saturn', label: '새턴'},
  'Scion': { value: 'Scion', label: '사이언'},
  'Smart': { value: 'Smart', label: '스마트'},
  'Subaru': { value: 'Subaru', label: '스바루'},
  'Suzuki': { value: 'Suzuki', label: '스즈키'},
  'Tesla': { value: 'Tesla', label: '테슬라'},
  'Toyota': { value: 'Toyota', label: '토요타'},
  'Volkswagen': { value: 'Volkswagen', label: '폭스바겐'},
  'Volvo': { value: 'Volvo', label: '볼보'},
};

export const CommunitySort = {
  "newest": { code: "newest", label: "최신순"},
  "most-viewed": { code: "most-viewed", label: "조회많은순"},
  "high-reply": { code: "high-reply", label: "댓글많은순"},
};

export const CommunityBoards = {
  '1': { value: '1', label: '자유게시판'},
  '2': { value: '2', label: '만남의광장'},
  '3': { value: '3', label: '포토사랑'},
  '4': { value: '4', label: '교육/학교/학원'},
  '5': { value: '5', label: '부동산/모기지'},
  '6': { value: '6', label: '이민/비자/정착'},
  '7': { value: '7', label: '자동차/정비'},
  '11': { value: '11', label: '유학/어학원'},
  '17': { value: '17', label: '생활/기타'},
  '18': { value: '18', label: '회계'},
  '21': { value: '21', label: '이민정착'},
  '23': { value: '23', label: '컴퓨터'},
  '24': { value: '24', label: '모기지'},
  '26': { value: '26', label: '밴조선 맘스'},
  '27': { value: '27', label: '미용/건강/의료'},
  '28': { value: '28', label: 'Options 취업광장'},
  '29': { value: '29', label: 'ISS of BC 무료워크샵'},
  '31': { value: '31', label: '건축/인테리어'},
  '32': { value: '32', label: '핸디맨/집수리'},
  '37': { value: '37', label: '반려동물/케어'},
  '39': { value: '39', label: 'SUCCESS 이민정착'},
  '47': { value: '47', label: 'DIVERSEcity 정부지원'},
  '51': { value: '51', label: '금융/보험/회계'},
  '52': { value: '52', label: 'MOSAIC'},
  '56': { value: '56', label: 'RMCS 취업정착'},
  '63': { value: '63', label: '전자제품/수리'},
  '65': { value: '65', label: 'SUCCESS 생활지원'},
};

export const CommunityOpenCategory = [
  {code: '',   name: '전체보기'},
  {code: '1', name: '자유게시판'},
  {code: '3', name: '포토사랑'},
  {code: '26', name: '밴조선 맘스'},
];

export const CommunityExportQnACategory = [
  {code: '',   name: '전체보기'},
  {code: '4', name: '교육'},
  {code: '11', name: '유학'},
  {code: '6', name: '이민'},
  {code: '5', name: '부동산'},
  {code: '24', name: '모기지'},
  {code: '51', name: '보험'},
  {code: '18', name: '회계'},
  {code: '31', name: '건축/시공'},
  {code: '32', name: '핸디맨'},
  {code: '7', name: '자동차'},
  {code: '37', name: '반려동물'},
  // {code: '63', name: '전자제품/수리'},
  // {code: '27', name: '미용/건강/의료'},
  // {code: '23', name: '컴퓨터'},
  // {code: '21', name: '이민정착'},
  // {code: '17', name: '생활/기타'},
];

export const CommunityImmigrantCategory = [
  {code: '',   name: '전체보기'},
  {code: '28', name: 'Options 취업광장'},
  {code: '29', name: 'ISS of BC 무료워크샵'},
  {code: '39', name: 'SUCCESS 이민정착'},
  {code: '65', name: 'SUCCESS 생활지원'},
  {code: '47', name: 'DIVERSEcity 정부지원'},
  {code: '52', name: 'MOSAIC'},
  {code: '56', name: 'RMCS 취업정착'},
];

export const CommunityMeetingUpCategory = [
  {code: '',   name: '전체보기'},
  {code: '2', name : '만남의 광장'},
];

export const CommunityBorderLv1Category = {
  1:{value: 1, label : '자유게시판'},
  2:{value: 2, label : '전문가 Q&A'},
  // 6:{value: 6, label : '이민정착'},
  7:{value: 7, label : '만남의광장'},
};

export const CommunityBorderLv2Category = {
  1:{value: 1, label : '자유게시판', lv1: 1},
  26:{value: 26, label : '밴조선 맘스', lv1: 1},
  3:{value: 3, label : '포토사랑', lv1: 1},
  4:{value: 4, label: '교육', lv1: 2},
  11:{value: 11, label: '유학', lv1: 2},
  6:{value: 6, label: '이민', lv1: 2},
  5:{value: 5, label: '부동산', lv1: 2},
  24:{value: 24, label: '모기지', lv1: 2},
  51:{value: 51, label: '보험', lv1: 2},
  18:{value: 18, label: '회계', lv1: 2},
  31:{value: 31, label: '건축/시공', lv1: 2},
  32:{value: 32, label: '핸디맨', lv1: 2},
  7:{value: 7, label: '자동차', lv1: 2},
  37:{value: 37, label: '반려동물', lv1: 2},
  // 63:{value: 63, label: '전자제품/수리', lv1: 2},
  // 27:{value: 27, label: '미용/건강/의료', lv1: 2},
  // 23:{value: 23, label: '컴퓨터', lv1: 2},
  // 21:{value: 21, label: '이민정착', lv1: 2},
  // 17:{value: 17, label: '생활/기타', lv1: 2},
  28:{value: 28, label: 'Options 취업광장', lv1: 6},
  29:{value: 29, label: 'ISS of BC 무료워크샵', lv1: 6},
  39:{value: 39, label: 'SUCCESS 이민정착', lv1: 6},
  65:{value: 65, label: 'SUCCESS 생활지원', lv1: 6},
  47:{value: 47, label: 'DIVERSEcity 정부지원', lv1: 6},
  52:{value: 52, label: 'MOSAIC', lv1: 6},
  56:{value: 56, label: 'RMCS 취업정착', lv1: 6},
  2:{value: 2, label: '만남의 광장', lv1: 7},
};

export const CommunityContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const YellowpageCategories = {
  '1': { value: '1', label: '금융·회계·법률', isDisplay: true},
  '19': { value: '19', label: '부동산', isDisplay: true},
  '4': { value: '4', label: '의료·한방·약국', isDisplay: true},
  '10': { value: '10', label: '학교·학원', isDisplay: true},
  '27': { value: '27', label: '이민·유학', isDisplay: true},
  '3': { value: '3', label: '생활·미용', isDisplay: true},
  '5': { value: '5', label: '식당·식품', isDisplay: true},
  '6': { value: '6', label: '여행·숙박', isDisplay: true},
  '12': { value: '12', label: '오락·스포츠', isDisplay: true},
  '7': { value: '7', label: '전자·통신', isDisplay: true},
  '8': { value: '8', label: '건축·시공', isDisplay: true},
  '2': { value: '2', label: '자동차·정비', isDisplay: true},
  '18': { value: '18', label: '운송·이사', isDisplay: true},
  '13': { value: '13', label: '전문서비스', isDisplay: true},
  '9': { value: '9', label: '공공기관·단체', isDisplay: true},
  '20': { value: '20', label: '미디어·광고', isDisplay: true},
  '11': { value: '11', label: '종교', isDisplay: true},
};

export const YellowpageSort = {
  "ascending": { value: "ascending", label: "오름차순"},
  "descending": { value: "descending", label: "내림차순"},
};

export const UserPostBoardList = [
  {value: '0', label: '전체'},
  {value: '1', label: '사고팔고'},
  {value: '7', label: '구인구직'},
  {value: '6', label: '학원튜터'},
  {value: '21', label: '렌트'},
  {value: '5', label: '민박/홈스테이'},
  {value: '11', label: '주거매매'},
  {value: '12', label: '비즈매매'},
  {value: '2', label: '자동차'},
  {value: '9999', label: '커뮤니티'},
  // {value: '업소록', label: '업소록'},
];

export const UserReplyBoardList = [
  {value: '0', label: '전체'},
  {value: '1', label: '자유게시판'},
  {value: '37', label: '전문가Q&A'},
  {value: '47', label: '이민정착'},
  {value: '2', label: '만남의광장'},
];