import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserPostListComponent } from './component/UserPostListComponent';
import { UserReplyListComponent } from './component/UserReplyListComponent';
import { UserBoardModal } from './component/UserBoardModal';
import Pagination from '../../common/Pagination';
import { UserApi } from './api/UserApi';
import {
  UserPostBoardList,
  UserReplyBoardList
} from '../../__mocks__/PostData';

export const UserPostList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "post_reply_search";
  const LIST_SIZE = 10;

  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showUserBoarderModal, setShowUserBoarderModal] = useState(false);
  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          boardId: 0,
          boardName: '전체',
          tabType: (location.state?.type)? location.state.type: 'post',
        };
      }
    }
  });
  const [writtenList, setWrittenList] = useState({
    currentCount: 0,
    list:[],
  });
  const [replyList, setReplyList] = useState({
    currentCount: 0,
    list:[],
  });

  // == state 변경 ------------------------------------------------
  const changeSearchData = (name, value) => {
    setSearchData((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeUserBoardData = (id, name) => {
    changeSearchData("page", 1);
    changeSearchData('boardId', id);
    changeSearchData('boardName', name);
  };

  // handler ---------------------------------------------
  const toggleUserBoarderModal = () => {
    setShowUserBoarderModal(!showUserBoarderModal);
  }

  const handleSearchClick = () => {
    if(isPost()) {
      setLoading(true);
      userApi.fetchUserPostList(
        searchData,
        (data) => {
          setWrittenList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          setLoading(false);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    } else {
      setLoading(true);
      userApi.fetchUserReplyList(
        searchData,
        (data) => {
          setReplyList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          setLoading(false);
          if(error) {
            if(error.response) {
              const {response: {data}} = error;
              setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
            } else if(error.message) {
              setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
            }
          }
        }
      );
    }
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const isPost = () => {
    if('post' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const isReply = () => {
    if('reply' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const handleTabOnClick = (tabType) => {
    handlePageChange(1);
    changeSearchData('tabType', tabType);
  }

  useEffect(()=> {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.tabType, searchData.page, searchData.boardId]);

  return (
    <>
      <PageWrapper>
        <Tabs>
          <div className={`${isPost() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('post')}>게시글</div>
          <div className={`${isReply() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('reply')}>댓글</div>
        </Tabs>
        {isPost() && (
          <>
            <SearchFilter>
              <div className='listCount'>
                {`${searchData?.boardName} ${writtenList.currentCount}`}
              </div>
              <div className='filter' onClick={()=> toggleUserBoarderModal()}>
                필터
                <img src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <UserBoardModal
                list={UserPostBoardList}
                showUserBoardModal={showUserBoarderModal}
                toggleUserBoardModal={toggleUserBoarderModal}
                boardId={searchData?.boardId}
                changeUserBoardData={changeUserBoardData}
              />
            </SearchFilter>
            <div className='list'>
              <UserPostListComponent
                list={writtenList.list}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
            <Pagination
              currentPage={searchData.page}
              totalPage={searchData.totalPageCount}
              perPageSize={searchData.perPageSize}
              handlePageChange={handlePageChange}
            />
          </>
        )}
        {isReply() && (
          <>
            <SearchFilter>
              <div className='listCount'>
                {`${searchData?.boardName} ${writtenList.currentCount}`}
              </div>
              <div className='filter' onClick={()=> toggleUserBoarderModal()}>
                필터
                <img src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <UserBoardModal
                list={UserReplyBoardList}
                showUserBoardModal={showUserBoarderModal}
                toggleUserBoardModal={toggleUserBoarderModal}
                boardId={searchData?.boardId}
                changeUserBoardData={changeUserBoardData}
              />
            </SearchFilter>
            <div className='list'>
              <UserReplyListComponent
                list={replyList.list}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
            <Pagination
              currentPage={searchData.page}
              totalPage={searchData.totalPageCount}
              perPageSize={searchData.perPageSize}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 0 1.6rem;
    min-height: 39vh;
  }
`;

const Tabs = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.tab {
    width: 50%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
  & div.active {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;

    color: #FD6258;
    border-bottom: 0.1rem solid #FD6258;
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.listCount {
    padding-right: 1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.filter {
    width: 4.8rem;
    height: 2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;
