import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

export const RecaptchaComponent = ({onChange}) => {

  function handlerRecaptchaOnChange(value) {
    onChange?.(value);
  }

  return (
    <ReCAPTCHAWrapper>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={handlerRecaptchaOnChange}
      />
    </ReCAPTCHAWrapper>
  );
}

const ReCAPTCHAWrapper = styled.div`
  & div {
    & div {
      margin: 0 auto;
    }
  }
`;
