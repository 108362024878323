
import React from 'react';
import styled from 'styled-components';

export const CommonModal = ({children, width, minWidth, maxWidth, height, toggle}) => {
  const handleToggleModal = () => {
    toggle?.();
  }
  return (
    <Modal onClick={()=>handleToggleModal()}>
      <ModalBody width={width} minWidth={minWidth} maxWidth={maxWidth} height={height}>
        {children}
      </ModalBody>
    </Modal>
  );
}

const Modal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 41.7rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;