import React from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export const HomestayCheckInDateComponent = React.memo(({
  label, checkInType, checkInDate,
  handleCheckInTypeOnChange, handleCheckInDateOnClick, handleCheckInDateOnChange,
  showCalender
}) => {
  
  const isDatePickerDisabled = () => {
    return ('checkIn-date'===checkInType) ? false: true;
  }

  const handleMyDatePickerOnChange = (date) => {
    handleCheckInDateOnChange?.(dateToString(date));
    handleDatepickerButtonOnClick();
  }

  const handleDatepickerButtonOnClick = () => {
    handleCheckInTypeOnChange?.('checkIn-date');
    handleCheckInDateOnClick?.();
  }

  const handleChoosingCheckInOnChange = ({target}) => {
    if(target.value !== 'checkIn-date') {
      handleCheckInDateOnChange?.('');
    }
    handleCheckInTypeOnChange?.(target.value);
  }

  const dateToString = (date) => {
    if(!date) return '';
    return moment(date).format("yyyy-MM-DD");
  }

  const stringToDate = (str) => {
    if(!str) return '';
    return moment(str, "yyyy-MM-DD").toDate();
  }

  const isChecked = (value) => {
    return checkInType === value;
  }

  return (
    <InputGroup>
      <label>{label}</label>
      <div className='row'>
        <input
          className='choosingCheckIn'
          id='choosingImmediateCheckIn'
          name='choosingCheckIn'
          type='radio'
          value='immediate-checkIn'
          checked={isChecked('immediate-checkIn')}
          onChange={e=>handleChoosingCheckInOnChange(e)}/>
        <label htmlFor="choosingImmediateCheckIn">즉시입실</label>
      </div>
      <div className='row'>
        <input
          className='choosingCheckInDate'
          id='choosingCheckInDate'
          name='choosingCheckIn'
          type='radio'
          value='checkIn-date'
          checked={isChecked('checkIn-date')}
          onChange={e=>handleChoosingCheckInOnChange(e)}/>
        <div className='datePicker'>
          <MyDatePicker
            id='checkInDate'
            dateFormat={'yyyy-MM-dd'}
            placeholderText={'yyyy-MM-dd'}
            selected={stringToDate(checkInDate)}
            onInputClick={()=>handleDatepickerButtonOnClick()}
            onChange={date => handleMyDatePickerOnChange(date)}
            timeIntervals={60}
            dropdownMode="select"
            open={!isDatePickerDisabled() ? showCalender : false}
            disabled={isDatePickerDisabled()}
            readOnly
          />
          <div className='datepickerButton'
            onClick={()=>handleDatepickerButtonOnClick()}>
            <img src='/icon/calender-icon.png' alt='' />
            날짜선택
          </div>
        </div>
      </div>
      <div className='row'>
        <input
          className='choosingMovingDate'
          id='deal'
          name='choosingMoveIn'
          type='radio'
          value='deal'
          checked={isChecked('deal')}
          onChange={e=>handleChoosingCheckInOnChange(e)} />
        <label htmlFor="deal">협의가능</label>
      </div>
    </InputGroup>
  );
});

const InputGroup = styled.div`
  position: relative;
  margin: 1.3rem 0;
  padding: 0;
  height: auto;
  min-height: 5.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & label {
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;
  }
  & div.row {
    width: 100%;
    height: 3.8rem;
    display: flex;
    align-items: center;
    & input[type=radio] {
      margin-right: 1rem;
      width: 1.8rem;
      height: 1.8rem;
      accent-color: #e95a51;
    }
    & div.datePicker {
      width: 100%;
      display: flex;
      align-items: center;
      & .react-datepicker {
        width: max-content;
        font-size: 1.3rem !important;
      }
      & .react-datepicker__current-month {
        font-size: 1.5rem !important;
      }
      & .react-datepicker__header {
        padding-top: 0.6rem !important;
      }
      & .react-datepicker__day-name, .react-datepicker__day {
        margin: 0.5rem !important;
      }
      & .saturday {
        color: rgb(0, 0, 255) !important;
      }
      & .sunday {
        color: rgb(255, 0, 0) !important;
      }
      & .react-datepicker__day--disabled {
        color: #cccccc !important;
      }
      & div.datepickerButton {
        margin-left: 0.8rem;
        width: 50%;
        height: 3.8rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #424242;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 0.1rem solid #ddd;
        border-radius: 0.5rem;

        cursor: pointer;

        & img {
          margin-right: 0.7rem;
          width: 1.8rem;
          height: 1.9rem;
        }
      }
    }
  }
  & div.checkboxGroup {
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & label {
      margin-left: 0.3rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
`;

const MyDatePicker = styled(DatePicker)`
  display: inline-block;
  padding-left: 1.1rem;
  width: 100%;
  height: 3.8rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #424242;

  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  box-sizing: border-box;
`;