export const priceToString = (price, minimumSignificantDigits=1) => {
  // if(price)
  //   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // else
  //   return '0';
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumSignificantDigits: minimumSignificantDigits,
    // notation: 'compact',
  });
  return formatter.format(price);
}

export const priceToStringWithDecimal = (price) => {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    // notation: 'compact',
  });
  return formatter.format(price);
}

export const priceToStringWithCompact = (price) => {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    notation: 'compact',
  });
  return formatter.format(price);
}

export const numberToString = (number) => {
  const formatter = new Intl.NumberFormat('en-CA');
  return formatter.format(number);
}

export const numberToSimple = (number) => {
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
  });
  return formatter.format(number);
}

export const cardSerialNumberFormatter = (serialnumber) => {
  if (serialnumber.length > 20) return;

  return serialnumber.match(/[0-9●]{1,4}/g)?.join('-') || serialnumber;
};

export const addHyphenForPhoneNumber = (strNumber) => {
  if(!strNumber) return;
  return strNumber.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

export const autoAddHyphenForPhoneNumber = (value) => {
  const number = value.replace(/[^0-9]/g, "");
  let phone = "";

  if (number.length < 5) {
    return number;
  } else if (number.length < 8) {
    phone = `${number.substr(0, 3)}-${number.substr(3)}`;
  } else if (number.length < 10) {
    phone = `${number.substr(0, 2)}-${number.substr(2, 3)}-${number.substr(5)}`;
  } else if (number.length < 11) {
    phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
  } else if (number.length < 12) {
    phone = `${number.substr(0, 3)}-${number.substr(3, 4)}-${number.substr(7)}`;
  }
  return phone;
}

export const toDateByFormatting = (sourceDate, delimiter = '-') => {
  // DateTimeFormat은 두번째 옵션 인자로서 Object parameter를 받는다
  const options = {
    hour: 'numeric', // 시간은 숫자로
    minutes: 'numeric', // 분도 숫자로
    day: 'numeric', // 날도 숫자로
    month: 'numeric', // 달은 글자로
    year: 'numeric', // 연도는 숫자로
    weekday: 'long', // 요일은 글자로
  };
  const locale = navigator.language;

  return new Intl.DateTimeFormat(locale, options).format(sourceDate)
}

export const toStringByFormatting = (strDate, delimiter = '-') => {
  const sourceDate = new Date(strDate?.replaceAll('-', '/'));
  const year = sourceDate.getFullYear();
  const month = leftPad(sourceDate.getMonth() + 1);
  const day = leftPad(sourceDate.getDate());
  return [year, month, day].join(delimiter);
}

const leftPad = (value) => {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

export const removeHtmlTags = (string) => {
  if(string) {
    return string.replace(/(<([^>]+)>)/ig, "").replace(/&quot;/g, "").replace(/&amp;/g, "").replace(/&nbsp;/g, "").replace(/\n/g, " ").replace(/\s+/g, " ").trim();
  } else {
    return '';
  }
}

export const isBlankValue = (value) => {
  if(value) {
    return false;
  } else {
    return true;
  }
};

export const isNumber = (value) => {
  if(0 > value) {
    return false;
  } else {
    return true;
  }
};

export const isPositiveOrZero = (value) => {
  if(0 > value) {
    return false;
  } else {
    return true;
  }
};

export const isValidEmail = (value) => {
  const emailRegex =/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (!(emailRegex.test(value))) {
    return false;
  } else {
    return true;
  }
}

export const isValidPassword = (password) => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
  if (!(passwordRegex.test(password))) {
    return false;
  } else {
    return true;
  }
}

export const isValidNickName = (value) => {
  const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|/-|/\s]{0,10}/gi;	//정규식 구문
  if(value) {
    let notAllowedStr = value.replace(regExp , '');
    if(notAllowedStr) {
      alert(`닉네임은 최대 한글 6자, 영문 12자로 입력해주세요.`);
      return false;
    } else {
      return true;
    }
  }
  return true;
}

export const removedNotAllowedNickName = (value) => {
  const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|/-|/\s]{0,10}/gi;	//정규식 구문
  if(value) {
    let notAllowedStr = value.replace(regExp , '');
    if(notAllowedStr) {
      console.log(value);
      console.log(value.replaceAll(notAllowedStr, ''));
      return value.replaceAll(notAllowedStr, '');
    } else {
      return value;
    }
  }
  return value;
}

export const isValidImageFile = (file, maxSize=4) => {
  const FILE_TYPE = "image/.*";
  const FILE_MAX_SIZE = maxSize * 1024 * 1024;
  const { type, size } = file;

  if(!file) {
    alert("이미지를 선택하세요.");
    return false;
  }
  if(FILE_MAX_SIZE < size) {
    alert(`이미지는 ${maxSize} MB 까지 지원합니다.`);
    return false;
  }
  if(!type.match(FILE_TYPE)) {
    alert("이미지 파일만 지원합니다.(JPG, PNG)");
    return false;
  }
  return true;
}

export const isValidSpecificCharacters = (value) => {
  const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|()~!?.,:\-+#$&[\]/\s]/gi;	//정규식 구문
  if(value) {
    let notAllowedStr = value.replace(regExp , '');
    if(notAllowedStr) {
      alert(`허용하지 않는 문자 ${notAllowedStr} 는 입력하실수 없습니다.`);
      return false;
    } else {
      return true;
    }
  }
  return true;
}

export const removeSpecificCharacters = (value) => {
  const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|()~!?.,:\-+#$&[\]/\s]/gi;	//정규식 구문
  if(value) {
    let notAllowedStr = value.replace(regExp , '');
    if(notAllowedStr) {
      alert(`허용하지 않는 문자 ${notAllowedStr} 는 입력하실수 없습니다.`);
      let notAllowedAry = Array.from(notAllowedStr);
      notAllowedAry.forEach(element=>{
        value = value.replace(element, '')
      });
      return value;
    }
  }
  return '';
}

export const randomColor = () => {
  return `#${Math.floor(Math.random()*16777215).toString(16)}`;
}

export const elapsedText = (strDate) => {
  // 초 (밀리초)
  const seconds = 1;
  // 분
  const minute = seconds * 60;
  // 시
  const hour = minute * 60;
  // 일
  const day = hour * 24;

  const displayDays = 30;

  const postDate = new Date(strDate?.replaceAll('-', '/')).getTime();
  const toDay = new Date().getTime();
  const elapsedTime = Math.trunc((toDay - postDate) / 1000);

  let elapsedText = "";
  if (elapsedTime < seconds) {
    elapsedText = "방금 전";
  } else if (elapsedTime < minute) {
    elapsedText = elapsedTime + "초 전";
  } else if (elapsedTime < hour) {
    elapsedText = Math.trunc(elapsedTime / minute) + " 분 전";
  } else if (elapsedTime < day) {
    elapsedText = Math.trunc(elapsedTime / hour) + " 시간 전";
  } else if (elapsedTime < (day * displayDays)) {
    elapsedText = Math.trunc(elapsedTime / day) + " 일 전";
  } else {
    if(strDate && 10 < strDate.length)
      elapsedText = strDate.substr(0, 10);
    else
      elapsedText = strDate;
  }
  return elapsedText;
}

export const getListLink = (boardId) => {
  switch (boardId) {
    case "1":
      return '/buysell';
    case "2":
      return '/car';
    case "6":
      return '/tutor';
    case "7":
      return '/job';
    default:
      return '/market';
  }
}

export const isEmptyObj = (obj) => {
  if(!obj) {
    return true;
  }
  if(typeof(obj) === "object"
    && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}

export const calculateDateString = (strDate, day=0) => {
  if(!strDate) return '';
  let date = new Date(strDate);
  date.setDate(date.getDate() + day);
  return new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(date) ;
}