import React from 'react';
import styled from 'styled-components';

export const CheckBoxTypeComponent = ({ label, id, value, target, message, onChange, disabled}) => {

  const isSameValue = (value) => {
    if(target && target === value) {
      return true;
    }
    return false;
  }
  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <CheckBoxGroup>
      {label && (
        <div className='title'>{label}</div>
      )}
      <div className='boxGroup'>
        <input type="checkbox"
          id={id}
          name={id}
          checked={isSameValue(value)}
          onChange={handleOnChange}
          disabled={disabled}
        />
        <label htmlFor={id}>{message}</label>
      </div>
    </CheckBoxGroup>
  );
}

const CheckBoxGroup = styled.div`
  margin: 0.7rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  & div.title {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div.boxGroup {
    height: 3.2rem;
    display: flex;
    align-items: center;
    & input {
      margin: 0;
      width: 2rem;
      height: 2rem;
      &:focus-visible {
        outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
      }
    }
    & input[type=checkbox] {
      accent-color: #f86258;
    }
    & label {
      margin-left: 0.6rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
  }
`;
