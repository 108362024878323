import qs from "qs";
import { jsonApi } from "../../../utils/AxiosApi";

export class CarApi {

  fetchCarList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/car`,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      params: searchData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCarById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/car/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}