import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../common/Loader';
import { TutorApi } from '../api/TutorApi';
import { addHyphenForPhoneNumber, elapsedText, priceToString } from '../../../utils/utils';
import {
  TutorCategories,
  TutorLearnerNumber,
  TutorLearners,
  TutorLectureLanguages,
  TutorLectureType,
  TutorRate
} from '../../../__mocks__/PostData';

export const TutorDetail = ({setDialog}) => {
  const tutorApi = new TutorApi();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewEditConformGroup, setViewEditConformGroup] = useState(false);
  const [viewDeleteConformGroup, setViewDeleteConformGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    id: id,
    boardId: 6,
    active: 1,
    type: '',
    name: '',
    password: null,
    phone: '',
    email: '',
    contactCode: '',
    title: '',
    categoryCode: '',
    learners: '',
    location: '1',
    lectureType: '',
    learnerNumber: '',
    lectureLanguages: '',
    tutorRate: '',
    price: 0,
    trialType: '',
    description: '',
    payType: '',
    isOwner: false,
    isPremium: false,
    premiumStart: '',
    premiumEnd: '',
    updateDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------

  // handler ------------------------------------------------
  const handleSearchClick = () => {
    tutorApi.fetchTutorById(
      id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        // const {response: {data}} = error;
        // setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate(-1, {state: {isBack: true}});
  }

  const handleViewEditConformOnClick = () => {
    setViewEditConformGroup((value) => !value);
    if(viewDeleteConformGroup)
      handleViewDeleteConformOnClick();
  }

  const handleEditOnClick = () => {
    if(article.id) {
      navigate(`/tutor-edit/${article.id}`,  {
        state: {
          id: article.id,
        },
      });
    }
  }

  const handleViewDeleteConformOnClick = () => {
    setViewDeleteConformGroup((value) => !value);
    if(viewEditConformGroup)
      handleViewEditConformOnClick();
  }

  const handleDeleteOnClick = () => {
    if(article.id && window.confirm("삭제 하시겠습니까?")) {
      setLoading(true);
      tutorApi.deleteTutor(article.id,
        (data) => {
          setLoading(false);
          setDialog({isOpen: true, message: "삭제 되었습니다.", type: "alert"});
          handleHistoryBackClick();
        },
        (error)=> {
          setLoading(false);
          setDialog({isOpen: true, message: `처리중 에러가 발생했습니다. ${error.message}`, type: "error"});
        }
      );
    }
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-tutor.png`;
  }

  // render -------------------------------------------------
  const addImageBox = (index) => {
    const domain = article.images[index]['domain'];
    const filePath = article.images[index]['filePath'];
    const fileName = article.images[index]['fileName'];
    return (
      <div key={index}>
        {fileName && (
          <div className="preview-image-box">
            <img src={`${domain}${filePath}${fileName}`} alt={`이미지${index}`} onError={(e)=>handleImageError(e)} loading='lazy' />
          </div>
        )}
      </div>
    );
  }

  const isAcademy = (type) => {
    if("학원" === type)
      return true;
    return false;
  }

  const addPremiumTag = (isPremium) => {
    if(isPremium) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addTypeTag = (isPremium, type) => {
    if(isPremium) return '';
    if("튜터" === type) {
      return (<img className='typeIcon' src='/icon/tutor-tutor-icon.png' alt='튜터' />);
    } else if("학원" === type) {
      return (<img className='typeIcon' src='/icon/tutor-academy-icon.png' alt='학원' />);
    }
    return '';
  }

  const addConnectTag = ({contactCode, phone, email}) => {
    return (
      <>
        {('phone' === contactCode) && addHyphenForPhoneNumber(phone)}
        {('email' === contactCode) && email}
        {('both' === contactCode) && (
          <>
            {addHyphenForPhoneNumber(phone)}
            <br/>
            {email}
          </>
        )}
      </>
    )
  }

  const addLocationNContactTag = (row) => {
    if("1" === row.premium) {
      return ''
    } else {
      return (<span>{elapsedText(row.updateDate)} · 조회수 {row.hitCount}</span>)
    }
  }

  const addLectureTypeTag = ({lectureType}) => {
    const lectureTypeList = lectureType?.split(",");
    return (
      lectureTypeList?.map(lectureType =>
        <>
          {
            lectureType && (
              <>
                <span key={lectureType}>
                  {lectureType?TutorLectureType[lectureType].label:lectureType}
                </span>
                <span>&middot;</span>
              </>
            )
          }
        </>
      )
    )
  }

  const addLearnerNumberTag = ({learnerNumber}) => {
    const learnerNumberList = learnerNumber?.split(",");
    return (
      learnerNumberList?.map(learnerNumber =>
        <>
          {
            learnerNumber && (
              <>
                <span key={learnerNumber}>
                  {learnerNumber?TutorLearnerNumber[learnerNumber].label:learnerNumber}
                </span>
                <span>&middot;</span>
              </>
            )
          }
        </>
      )
    )
  }

  const addLectureLanguagesTag = ({lectureLanguages}) => {
    const lectureLanguageList = lectureLanguages?.split(",");
    return (
      lectureLanguageList?.map((language, index) =>(
        <>
          {language && (
            <>
              <span key={`${language}-${index}`}>
                {language?TutorLectureLanguages[language]?.label:language}
              </span>
              <span>&middot;</span>
            </>
          )}
        </>
      ))
    )
  }

  const renderCategoryTag = ({categoryCode}) => {
    const label = categoryCode ? TutorCategories[categoryCode]?.label : categoryCode;
    return (
      <>
        {(label) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='angle right'/>
            <span className='categoryLv1'>{label}</span>
          </>
        )}
      </>
    );
  }

  const renderLearnersTag = ({learners}) => {
    const learnerList = learners?.split(",");
    return (
      learnerList?.map(learner =>
        <>
          {
            learner && (
              <span className='learner' key={learner}>
                {learner?TutorLearners[learner].label:learner}
              </span>
            )
          }
        </>
      )
    )
  }


  const renderDeleteEditButton = ({isOwner}) => {
    if(!isOwner) return '';
    return (
      <ConformGroup>
        <ButtonGroup>
          <ButtonStyle type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>수정하기</ButtonStyle>
          <ButtonStyle type='button' color="#fd6258" backgroundColor='#fff' onClick={() => handleDeleteOnClick()}>삭제하기</ButtonStyle>
        </ButtonGroup>
      </ConformGroup>
    );
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <ArticleTag>
            <div>
              {addPremiumTag(article.isPremium)}
              {addTypeTag(article.isPremium, article.type)}
            </div>
            <span>{`게시글번호 ${article.id}`}</span>
          </ArticleTag>
          <Title>{article.title}</Title>
          <UpdateInfo>
            {addLocationNContactTag(article)}
          </UpdateInfo>
          <Info>
            <table>
              <tbody>
                <tr>
                  <td className='head'>{isAcademy(article.type)?'업체명':'튜터명'}</td>
                  <td className='col'>{article.name}</td>
                </tr>
                <tr>
                  <td className='head'>연락처</td>
                  <td className='col'>{addConnectTag(article)}</td>
                </tr>
                <tr>
                  <td className='head'>지역</td>
                  <td className='col'>{article.locationKrName}</td>
                </tr>
                <tr>
                  <td className='head' rowSpan={2}>교습과목</td>
                  <td className='col'>
                    <Category>
                      <span className='categoryLv0'>전체</span>
                      {renderCategoryTag(article)}
                    </Category>
                  </td>
                </tr>
                <tr>
                  <td className='col'>
                    <Learner>
                      {renderLearnersTag(article)}
                    </Learner>
                  </td>
                </tr>
              </tbody>
            </table>
          </Info>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>한 눈에 보는 교습정보</Title>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/lecture-type-icon.png' alt='수업방식'/>
                      </div>
                      <div className='label'>수업모드</div>
                      <div className='value'>
                        {addLectureTypeTag(article)}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/learner-number-icon.png' alt='학습인원'/>
                      </div>
                      <div className='label'>학습인원</div>
                      <div className='value'>
                        {addLearnerNumberTag(article)}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/lecture-language-icon.png' alt='수업언어'/>
                      </div>
                      <div className='label'>수업언어</div>
                      <div className='value'>
                        {addLectureLanguagesTag(article)}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/tutor-price.png' alt='수업료'/>
                      </div>
                      <div className='label'>수업료</div>
                      <div className='value'>
                        {'deal' === (article.tutorRate||'deal')
                          ? TutorRate[article.tutorRate]?.label
                          : `${TutorRate[article.tutorRate]?.label} ${priceToString(article.price)}`
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <ContentTitle>
            {`${article.type}설명`}
          </ContentTitle>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
          <ImageWrapper>
            {Object.keys(article.images).map((key) => addImageBox(key))}
          </ImageWrapper>
        </ContentWrapper>
        { renderDeleteEditButton(article) }
        <Loader loading={loading} />
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0.5rem 0;
  width: auto;
  background-color: #fff;
  border-top: 0.1rem solid #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding: 2rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ArticleTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div {
    & img {
      display: inline-block;
      margin-right: 0.6rem;
      height: 1.8rem;
    }
    & img.premiumIcon {
      width: 5rem;
    }
    & img.jobType {
      width: 3.2rem;
      height: 1.8rem;
    }
    & img.jobSearchDone {
      width: 5rem;
      height: 1.8rem;
    }
    & img.purchaseIcon {
      height: 1.8rem;
    }
  }

  & span {
    margin-left: 1.5rem;
    padding: 0.2rem 0.8rem;
    display: inline-block;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 300px;
  }
`;

const Title = styled.h1`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  word-break: break-all;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;
const Info = styled.div`
  margin-top: 1.7rem;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    & td {
      font-size: 14px;
    }
    & td.head {
      width: 30%;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      vertical-align: top;
      color: #000;
    }
    & td.col {
      width: 70%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;
    }
    & td.left {
      text-align: left;
    }
    & td.right {
      text-align: right;
    }
  }
`;

const PreviewInfo = styled.div`
  margin: 0rem 0;
  border: 0rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 0rem 0rem;
    width: 100%;
    & tr {
      width: 100%;
      height: 12rem;
      & td {
        width: 8.3rem;
        & div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & div.icon {
            width: 4.2rem;
            height: 4.2rem;
            & img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
          & div.label {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #424242;
          }
          & div.value {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            display: flex;
            flex-direction: row;
            color: #9E9E9E;
            & span {
              padding: 0 0.3rem;
            }
            & span:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const Category = styled.div`
  width: auto;
  height: 2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0rem solid #f5f5f5;
  & span.categoryLv0 {
    color: #424242;
  }
  & span.categoryLv1 {
    font-weight: 500;
    color: #FD6258;
  }
  & img {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const Learner = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 0rem solid #f5f5f5;
  & span {
    margin: 0.3rem 0.5rem 0.3rem 0;
    padding: 0 0.9rem;
    height: 1.8rem;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: -0.06em;
    color: #FD6258;
    background-color: #FFEAE9;
    border-radius: 30rem;
  }
`;

const Content = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;

const ContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const ImageWrapper = styled.section`
  margin-bottom: 0.5rem;
  border-bottom: 0.1 dashed #ddd;
  & p {
    padding: 0.75rem 1.25rem;
    color: #721c24;
    font-size: 14px;
    font-weight: 500;
    background-color: #F8D7DA;
  }
  & div {
    margin: 1rem 0;
    & label.form-label-button {
      padding: 0.6rem 2.5rem;
      height: 2.4rem;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      font-weight: 700;
      color: white;

      background-color: #b1b1b1;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    & div.preview-image-box {
      position: relative;
      margin-top: 1rem;
      width: 100%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & button {
        position: absolute;
        right: 0px;
        z-index: 100;
      }
    }
  }
`;

const ConformGroup = styled.div`
  padding: 1.6rem;
  border-top: 0.1rem solid #f5f5f5;
`;
const ButtonGroup = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  column-gap: 0.6rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;

`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #fd6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;
