import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../common/CommonModal';

export const MarketCategoryModal = ({ title, categories, categoryCode, showCategoryModal, toggleCategoryModal, handlerCategoryCodeOnChange }) => {

  const handleToggleModal = () => {
    toggleCategoryModal?.();
  }

  const handlerOnClick = (value, label, enName) => {
    handlerCategoryCodeOnChange(value, enName, label);
    handleToggleModal?.();
  }

  useEffect(() => {
    if(showCategoryModal) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [showCategoryModal]);

  return (
    <>
      {showCategoryModal&&(
        <CommonModal
          width={'100%'}
          maxWidth={'41.4rem'}
        >
          <CategoryWrapper>
            <div className='title'>
              {title}
              <img src='/icon/x-mark.png'
                alt='Category Modal Close'
                onClick={()=> handleToggleModal()}
              />
            </div>
            <CategoryList>
              <ul>
                {Object.keys(categories).map((key) => (
                  <li key={key}
                    onClick={()=>handlerOnClick(categories[key].value, categories[key].label)}>
                    <input type='checkbox' id={key} value={categories[key].value} checked={categories[key].value === categoryCode} />
                    <label htmlFor={key}>
                      {categories[key].label}
                    </label>
                  </li>
                ))}
              </ul>
              <Button
                type='button'
                color="#fff"
                backgroundColor='#fd6258'
                onClick={() => alert()}>완료</Button>
            </CategoryList>
          </CategoryWrapper>
        </CommonModal>
      )}
    </>
  );
};
const CategoryWrapper = styled.div`
  position: relative;
  & div.title {
    padding: 0rem 2rem;
    height: 5.9rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & li {
      padding-left: 1.4rem;
      width: auto;
      height: 4.8rem;
      cursor: pointer;

      font-size: 14px;
      font-weight: 400;
      line-height: 4.8rem;
      & input {
        margin-right: 1.4rem;
      }
    }
    & li:nth-child(odd) {
      border-top: 0.1rem solid #ddd;
      border-right: 0.1rem solid #ddd;
    }
    & li:nth-child(even) {
      border-top: 0.1rem solid #ddd;
    }
    & li:nth-last-child(1) {
      border-bottom: 0.1rem solid #ddd;
    }
    & li:nth-last-child(2) {
      border-bottom: 0.1rem solid #ddd;
    }
    & li.active {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #FD6258;
      background: #FFEAE9;
    }
  }
`;

const Button = styled.button`
  margin: 0.5rem auto;
  width: 90%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;