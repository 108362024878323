import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../common/SkeletonComponent';
import { useInterval } from '../../hooks/useInterval';
import { AdsApi } from './api/AdsApi';

export const RecommendedAdList = React.memo(({ position, listSize=5 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});
  const [sliderPosition, setSliderPosition] = useState(null);
  const [page, setPage] = useState(0);

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(
      position,
      listSize,
      (data)=> {
        setAds(data);
      },
      (error)=> {
        console.log(error);
      }
    )
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  const handleNextClick = () => {
    if(page + 1 < ads?.list.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  const handlePreviousClick = () => {
    if(0 === page) {
      setPage(ads?.list.length-1);
    } else {
      setPage(page - 1);
    }
  }

  const handleOnTouchStart = (e) => {
    if(2>ads?.list.length) return;
    setSliderPosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    });
  }

  const handleOnTouchEnd = (e) => {
    if(2>ads?.list.length) return;
    moveSlide(e.changedTouches[0].pageX, e.changedTouches[0].pageY, null);
  }

  const handleOnMouseDown = (e) => {
    if(2>ads?.list.length) return;
    setSliderPosition({
      x: e.clientX,
      y: e.clientY
    });
  }

  const handleOnMouseUp = (e, row) => {
    if(2>ads?.list.length) {
      handleOpenDetail(row);
    } else {
      moveSlide(e.clientX, e.clientY, row);
    }
  }

  const moveSlide = (clientX, clientY, row) => {
    const distanceX = Math.abs(sliderPosition.x - clientX);
    const distanceY = Math.abs(sliderPosition.y - clientY);

    if((distanceY + distanceX > 30) && (distanceX > distanceY)) {
      if(sliderPosition.x - clientX < 0) {
        handlePreviousClick();
      } else if(sliderPosition.x - clientX > 0) {
        handleNextClick();
      }
    } else if(row) {
      handleOpenDetail(row);
    }
  }

  useInterval(()=> {
    handleNextClick();
  }, (1<ads?.list.length) ? 6000 : null);

  useEffect(() => {
    handleAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainRecommendedAdSection>
        <Header>
          <h2>당신을 위한 추천광고</h2>
        </Header>
        {(0 < ads?.list.length) ? (
          <>
            <SlideList translateX={page}

            >
              {ads?.list.map((row) => (
                <li key={row.id}>
                  <RecommendedItem
                    onTouchStart={(e)=>handleOnTouchStart(e)}
                    onTouchEnd={(e)=>handleOnTouchEnd(e)}
                    onMouseDown={(e)=>handleOnMouseDown(e)}
                    onMouseUp={(e)=>handleOnMouseUp(e, row)}
                  >
                    <img src={`${row.imageFile1}`} alt={row.title} onError={(e)=>handleImageError(e)} loading='lazy' />
                  </RecommendedItem>
                </li>
              ))}
            </SlideList>
            <SlidePageList>
              {ads?.list.map((row, index) => (
                <li key={row.id}>
                  <PageLabel backgroundColor={(page === index)? '#424242' : '#C4C4C4'}></PageLabel>
                </li>
              ))}
            </SlidePageList>
            <NextButton onClick={()=>handleNextClick()}>Next</NextButton>
          </>
        ) : (
          <SkeletonComponent>
            <SkeletonSection />
          </SkeletonComponent>
        )}
      </MainRecommendedAdSection>
    </>
  );
});

const MainRecommendedAdSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding-bottom: 2.4rem;
  max-width: 43.9rem;
  min-height: 30.7rem;
  height: auto;
  overflow: hidden;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const Header = styled.div`
  margin: 0;
  padding: 2.4rem 1.6rem 1.8rem 1.6rem;
  & h2 {
    margin: 0;
    padding: 0;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
  transform: ${props => `translateX(${props.translateX * -100}%)`};
  transition: all 0.45s linear;
  & li {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

const RecommendedItem = styled.div`
  position: relative;
  padding: 0;
  /* height: 20.7rem; */
  height: 100%;
  cursor: pointer;
  & img {
    width: 100%;
    /* height: 22.8rem; */
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
  }
`;

const SlidePageList = styled.ul`
  margin: 0;
  padding: 0.4rem 0 0;
  text-align: center;
  & li {
    display: inline-block;
    vertical-align: middle;
  }
`;

const PageLabel = styled.label`
  margin: 0rem 0.4rem;
  padding: 0.3rem;
  display: block;
  background: ${props => props.backgroundColor};
  border-radius: 3rem;
  cursor: pointer;
`;

const NextButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.4rem;
  width: 4rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  text-align: center;
  color: #FFFFFF;
  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 0 0.4rem;
  cursor: pointer;
  z-index: 10;
`;

const SkeletonSection = styled.div`
  padding: 0;
  width: 100%;
  height: 20.7rem;
  background-color: #F5F5F5;
`;