import React from 'react'
import styled from 'styled-components';
import { RadioButtonComponent } from './RadioButtonComponent';
import { SelectComponent } from './SelectComponent';
import { CheckBoxComponent } from './CheckBoxComponent';
// import { CarRangeComponent } from './CarRangeComponent';
// import { numberToString, priceToString, } from '../../../../utils/utils';
import {
  CarBrand,
  CarColors,
  CarDrivingSystem,
  CarFilterGesTypeList,
  CarFilterTransmissionList,
  CarFilterTypeList,
  CarIncludedOptionList,
  CarMaxYear,
  CarMinYear,
} from '../../../../__mocks__/PostData';


export const CarFilterModal = ({ show, toggle, searchData, changeSearchData, handleSearchClick }) => {

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerOnClick = () => {
    handleToggleModal?.();
  }

  const handlerApplyOnClick = () => {
    handleSearchClick?.();
    handleToggleModal();
  }

  const handlerResetFilterDataOnClick = () => {
    changeSearchData("brand", '');
    changeSearchData("color", '');
    changeSearchData("includedOptions", '');
    changeSearchData("type", '');
    changeSearchData("minYear", 1900);
    changeSearchData("maxYear", 2023);
    changeSearchData("gasType", '');
    changeSearchData("transmission", '');
    changeSearchData("drivingSystem", '');
    changeSearchData("minPrice", 0);
    changeSearchData("maxPrice", 1000000);
    changeSearchData("minMileage", 0);
    changeSearchData("maxMileage", 400000);
  }

  const handleBrandOnChange = (e) => {
    changeSearchData("brand", e.target.value);
  }

  const handleColorOnChange = (e) => {
    changeSearchData("color", e.target.value);
  }

  const handleIncludedOptionsOnChange = (value) => {
    changeSearchData("includedOptions", value);
  }

  const handleTypeOnChange = (value) => {
    changeSearchData("type", value);
  }

  const handleMinMileageOnChange = (e) => {
    changeSearchData("minYear", e.target.value);
  }

  const handleMaxMileageOnChange = (e) => {
    changeSearchData("maxYear", e.target.value);
  }

  const handleGasTypeOnChange = (value) => {
    changeSearchData("gasType", value);
  }

  const handleTransmissionOnChange = (value) => {
    changeSearchData("transmission", value);
  }

  const handleDrivingSystemOnChange = (value) => {
    changeSearchData("drivingSystem", value);
  }

  // const handlePriceOnChange = (minPrice, maxPrice) => {
  //   changeSearchData("minPrice", minPrice);
  //   changeSearchData("maxPrice", maxPrice);
  // }

  // const handleMileageOnChange = (minMileage, maxMileage) => {
  //   changeSearchData("minMileage", minMileage);
  //   changeSearchData("maxMileage", maxMileage);
  // }

  return (
    <>
      {show && (
        <Modal>
          <ModalBody>
            <CarFilterWrapper>
              <CarFilterTitle>
                <div>필터</div>
                <CloseBtn src="/icon/x-mark.png" alt='닫기 버튼' onClick={handlerOnClick}/>
              </CarFilterTitle>
              <Main>
                <Section>
                  <div className='title'>
                    차종
                  </div>
                  <CheckBoxComponent
                    id='type'
                    value={searchData?.type}
                    list={CarFilterTypeList}
                    onChange={handleTypeOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    브랜드
                  </div>
                  <SelectComponent
                    id={'brand'}
                    value={searchData?.brand}
                    objectData={{'': { value: '', label: '선택'}, ...CarBrand}}
                    onChange={handleBrandOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    연식
                  </div>
                  <div style={{display: 'flex', alignItems: 'center',}}>
                    <SelectComponent width={'48%'}
                      id={'minYear'}
                      value={searchData?.minYear}
                      objectData={CarMinYear}
                      onChange={handleMinMileageOnChange}
                    />
                    <div style={{padding: '0 1.6rem'}}>~</div>
                    <SelectComponent width={'48%'}
                      id={'maxYear'}
                      value={searchData?.maxYear}
                      objectData={CarMaxYear}
                      onChange={handleMaxMileageOnChange}
                    />
                  </div>
                </Section>
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    가격
                  </div>
                  <RangeTitle>{priceToString(searchData?.minPrice, 3)} ~ {priceToString(searchData?.maxPrice, 3)}</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <CarRangeComponent
                      id='price'
                      min={0}
                      max={100000}
                      median={'$50,000'}
                      step={1000}
                      minValue={searchData?.minPrice}
                      maxValue={searchData?.maxPrice}
                      onChange={handlePriceOnChange}
                    />
                  </div>
                </Section> */}
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    주행거리
                  </div>
                  <RangeTitle>{numberToString(searchData?.minMileage, 3)} ~ {numberToString(searchData?.maxMileage, 3)}km</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <CarRangeComponent
                      id='mileage'
                      min={0}
                      max={400000}
                      median={'200,000km'}
                      step={10000}
                      minValue={searchData?.minMileage}
                      maxValue={searchData?.maxMileage}
                      onChange={handleMileageOnChange}
                    />
                  </div>
                </Section> */}
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    연료
                  </div>
                  <CheckBoxComponent
                    id='gasType'
                    value={searchData?.gasType}
                    list={CarFilterGesTypeList}
                    onChange={handleGasTypeOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    변속기
                  </div>
                  <CheckBoxComponent
                    id='transmission'
                    value={searchData?.transmission}
                    list={CarFilterTransmissionList}
                    onChange={handleTransmissionOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    구동
                  </div>
                  <RadioButtonComponent
                    id={'drivingSystem'}
                    value={searchData?.drivingSystem||''}
                    objectData={CarDrivingSystem}
                    width={'18%'}
                    multipleChoice={true}
                    onChange={handleDrivingSystemOnChange} />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    색상
                  </div>
                  <SelectComponent
                    id={'color'}
                    value={searchData?.color}
                    objectData={{'': { value: '', label: '선택'}, ...CarColors}}
                    onChange={handleColorOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    옵션
                  </div>
                  <CheckBoxComponent
                    id='includedOptions'
                    value={searchData?.includedOptions}
                    list={CarIncludedOptionList}
                    onChange={handleIncludedOptionsOnChange}
                  />
                </Section>
                <ButtonGroup>
                  <ButtonStyle
                    type='button'
                    width='28%'
                    color='#BDBDBD'
                    backgroundColor='#F5F5F5'
                    borderColor='#F5F5F5'
                    onClick={handlerResetFilterDataOnClick}
                  >
                    초기화
                  </ButtonStyle>
                  <ButtonStyle
                    type='button'
                    width='68%'
                    color='#FFFFFF'
                    backgroundColor='#FD6258'
                    borderColor='#FD6258'
                    onClick={handlerApplyOnClick}
                  >
                    적용하기
                  </ButtonStyle>
                </ButtonGroup>
              </Main>
            </CarFilterWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;

const CarFilterWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const CarFilterTitle = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #f5f5f5;
  & div {
    margin: 0 auto;
    width: 8rem;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
  };
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.6rem;
  right: 1.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Main = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  height: 100%;
`;

const Section = styled.div`
  position: relative;
  margin: 0;
  padding: 2.4rem 0rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000;
  & div.title {
    margin-bottom: 2.4rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ButtonGroup = styled.div`
  margin: 0;
  padding: 2.9rem 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

const ButtonStyle = styled.button`
  width: ${props => props.width};
  height: 4.8rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.borderColor};
  border-radius: 0.4rem;
  cursor: pointer;
`;

// const RangeTitle = styled.div`
//   position: absolute;
//   top: 5.2rem;
//   left: 0;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 2.3rem;
//   letter-spacing: -0.04em;
//   color: #9E9E9E;
// `;
