import React from 'react';
import styled from 'styled-components';

export const NewsReport = () => {
  return (
    <Wrapper>
      <img src='/img/news-report.jpg' alt='기사제보 내용'/>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin: auto;
  padding: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
`;