import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { MarketSearch } from '../component/MarketSearch';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { SortComponent } from '../component/SortComponent';
import { RentHomestayCardComponent } from './component/RentHomestayCardComponent';
import { RealtyLocationModal } from '../realty/component/RealtyLocationModal';
import { RentFilterModal } from './component/RentFilterModal';
import { HomestayFilterModal } from './component/HomestayFilterModal';
import { RentApi } from '../api/RentApi';
import { HomestayApi } from '../api/HomestayApi';
import { UserApi } from '../../user/api/UserApi';
import {
  HomestaySort,
  RentSort
} from '../../../__mocks__/PostData';
import { Helmet } from 'react-helmet-async';

export const RentHomestayList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "rent_homestay_search";
  const LIST_SIZE = 20;

  const rentApi = new RentApi();
  const homestayApi = new HomestayApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isNewButtonClick, setIsNewButtonClick] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showRentFilter, setShowRentFilter] = useState(false);
  const [showHomestayFilter, setShowHomestayFilter] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/realestate/view/list2.php?cate=homestay'; // 여기서 www.com URL로 변경
  }


  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          category: 'all',
          locationCode: '',
          locationName: '',
          locationKrName: '모든지역',

          roomTypes: '',
          detailedOptions: '',
          minPrice: 0,
          maxPrice: 5000,

          types: '',
          leaseTerms: '',
          moveInType: '',

          guestGenders: '',
          minimumReservations: '',
          checkInType: '',

          sort: 'newest',
          title: '',
          scrollY: 0,
          tabType: (location.state?.type)? location.state.type: 'rent',
        };
      }
    }
  });
  const [rentList, setRentList] = useState({list:[]});
  const [homestayList, setHomestayList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeLocationData = (code, name, krName) => {
    changeSearchData('locationCode', code);
    changeSearchData('locationName', name);
    changeSearchData('locationKrName', krName);
  };
  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal)
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    if(isRent()) {
      rentApi.fetchRentList(
        searchData,
        (data) => {
          setRentList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          setLoading(false);
          if(error) {
            if(error.response) {
              const {response: {data}} = error;
              setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
            } else if(error.message) {
              setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
            }
          }
        }
      );
    } else if(isHomestay()) {
      homestayApi.fetchHomestayList(
        searchData,
        (data) => {
          setHomestayList(data);
          changeSearchData('totalCount', data.totalCount);
          changeSearchData('totalPageCount', data.totalPageCount);
          setLoading(false);
          handleMoveScroll();
        },
        (error) => {
          setLoading(false);
          if(error) {
            if(error.response) {
              const {response: {data}} = error;
              setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
            } else if(error.message) {
              setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
            }
          }
        }
      );
    }
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  const handleNewOnClick = () => {
    if(userApi.isSignIn()) {
      setIsNewButtonClick(!isNewButtonClick);
    } else {
      navigate("/signIn");
    }
  }

  const handleNewRentOnClick = () => {
    navigate("/rent-new");
  }

  const handleNewHomestayOnClick = () => {
    navigate("/homestay-new");
  }

  const isRent = () => {
    if('rent' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const isHomestay = () => {
    if('homestay' === searchData?.tabType) {
      return true;
    }
    return false;
  }

  const handleTabOnClick = (tabType) => {
    handlePageChange(1);
    changeSearchData('tabType', tabType);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const toggleShowRentFilter = () => {
    setShowRentFilter(!showRentFilter);
  }

  const toggleShowHomestayFilter = () => {
    setShowHomestayFilter(!showHomestayFilter);
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.tabType, searchData.page, searchData.locationCode, searchData.sort]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`렌트/민박 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'머물 곳을 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'renthomestay_top_ra'}
          listSize={5}
        />
        <Tabs>
          <div className={`${isRent() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('rent')}>렌트</div>
          <div className={`${isHomestay() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('homestay')}>민박/홈스테이</div>
        </Tabs>
        {isRent() && (
          <>
            <MarketLineAdList
              boardId={1}
              mobileAdOnClick={handlerItemCardOnClick}
            />
            <SearchFilter>
              <div className={'btnFilter'} onClick={()=>toggleShowRentFilter()}>
                필터
                <img src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <div>
                <div className='mapPin' onClick={()=> toggleLocationModal()}>
                  {searchData?.locationKrName}
                  <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
                </div>
                <RealtyLocationModal
                  showLocationModal={showLocationModal}
                  toggleLocationModal={toggleLocationModal}
                  locationCode={searchData?.locationCode}
                  changeLocationData={changeLocationData}
                />
                <SortComponent
                  sortDisplayList={RentSort}
                  sortCode={searchData?.sort}
                  changeSortData={changeSortData}
                />
              </div>
            </SearchFilter>

            <div className='list'>
              <RentHomestayCardComponent
                type={searchData?.tabType}
                list={rentList.list}
                link={'rent'}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
            <Pagination
              currentPage={searchData.page}
              totalPage={searchData.totalPageCount}
              perPageSize={searchData.perPageSize}
              handlePageChange={handlePageChange}
            />
          </>
        )}
        {isHomestay() && (
          <>
            <MarketLineAdList
              boardId={1}
              mobileAdOnClick={handlerItemCardOnClick}
            />
            <SearchFilter>
              <div className={'btnFilter'} onClick={()=>toggleShowHomestayFilter()}>
                필터
                <img src='/icon/rent-homestay-filter-icon.png' alt=''/>
              </div>
              <div>
                <div className='mapPin' onClick={()=> toggleLocationModal()}>
                  {searchData?.locationKrName}
                  <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
                </div>
                <RealtyLocationModal
                  showLocationModal={showLocationModal}
                  toggleLocationModal={toggleLocationModal}
                  locationCode={searchData?.locationCode}
                  changeLocationData={changeLocationData}
                />
                <SortComponent
                  sortDisplayList={HomestaySort}
                  sortCode={searchData?.sort}
                  changeSortData={changeSortData}
                />
              </div>
            </SearchFilter>

            <div className='list'>
              <RentHomestayCardComponent
                type={searchData?.tabType}
                list={homestayList.list}
                link={'homestay'}
                isLoading={loading}
                postOnClick={handlerItemCardOnClick}
              />
            </div>
            <Pagination
              currentPage={searchData.page}
              totalPage={searchData.totalPageCount}
              perPageSize={searchData.perPageSize}
              handlePageChange={handlePageChange}
            />
          </>
        )}
        {isNewButtonClick ? (
          <ButtonGroup>
            <WriteButton bottom={15.1} right={1.6} width={'2.3rem'} height={'2.3rem'} onClick={()=>handleNewRentOnClick()}>
              <span>렌트</span>
              <img src='/icon/rent-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton bottom={8.9} right={1.6} width={'2.3rem'} height={'2.3rem'} onClick={()=>handleNewHomestayOnClick()}>
              <span>민박/홈스테이</span>
              <img src='/icon/homestay-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton className='active' bottom={2.7} right={1.6} width={'1.583rem'} height={'1.583rem'} onClick={()=>handleNewOnClick()}>
              <img src='/icon/rent-homestay-new-write-clicked-icon.png' alt='등록 선택' />
            </WriteButton>
          </ButtonGroup>
        ) : (
          <WriteButton bottom={2.7} right={1.6} width={'2.322rem'} height={'2.318rem'} onClick={()=>handleNewOnClick()}>
            <img src='/icon/rent-homestay-new-write-icon.png' alt='등록' />
          </WriteButton>
        )}
      </PageWrapper>
      {isRent() && (
        <RentFilterModal
          show={showRentFilter}
          toggle={toggleShowRentFilter}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
      )}
      {isHomestay() && (
        <HomestayFilterModal
          show={showHomestayFilter}
          toggle={toggleShowHomestayFilter}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
      )}
    </>
  );
}

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0.5rem;
  width: auto;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 1.6rem;
    min-height: 39vh;
  }
`;

const Tabs = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.tab {
    width: 40%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
  & div.active {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;

    color: #FD6258;
    border-bottom: 0.1rem solid #FD6258;
  }
`;

const WriteButton = styled.div`
  position: fixed;
  bottom: ${props => props.bottom +'rem'};
  right: ${props => props.right +'rem'};
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fd6258;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width};
    height: ${props => props.height};

  }
  & span {
    position: fixed;
    bottom: ${props => props.bottom + 1.3 +'rem'};
    right: ${props => props.right + 5.5 +'rem'};
    padding: 0 0.8rem;
    /* min-width: 4.4rem; */
    height: 2.1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.1rem;
    letter-spacing: -0.04em;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.4rem;
  }
  &.active {
    position: fixed;
    bottom: ${props => props.bottom+'rem'};
    right: ${props => props.right+'rem'};
    width: 4.4rem;
    height: 4.4rem;
    background-color: #fff;
    border: 0.1rem solid #ddd;
    border-radius: 50%;
    z-index: 10;
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props => props.width};
      height: ${props => props.height};
      cursor: pointer;
    }
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.btnFilter {
    width: 4.8rem;
    height: 2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.filter {
    padding-left: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const ButtonGroup = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
`;