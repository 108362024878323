import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';

export const MarketEditor = ({editorRef, description='', placeholder='상세 내용을 입력해주세요', infoMessage='', disabled}) => {
  return (
    <>
      <Editor tinymceScriptSrc={process.env.PUBLIC_URL +'/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={description}
        disabled={disabled}
        init={{
          'width': '100%',
          'height': '600px',
          'menubar': false,
          'placeholder': placeholder,
          'plugins': 'preview paste importcss searchreplace autolink visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking insertdatetime advlist lists code wordcount textpattern noneditable help charmap emoticons',
          'toolbar': 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image table link | fullscreen preview help |',
          'content_style': 'body { font-family: Helvetica,Arial,sans-serif; font-size: 14px }',
        }}
      />
      {infoMessage && (
        <InfoMessage>
          {infoMessage}
        </InfoMessage>
      )}
    </>
  );
}

const InfoMessage = styled.div`
  margin: 1.6rem 0 2.7rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #FD6258;

  display: flex;
  align-items: center;
`;
