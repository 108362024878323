import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { elapsedText } from '../../../utils/utils';

export function UserPostListComponent({ list, isLoading=true, postOnClick}) {
  const navigate = useNavigate();

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-user-post-image.png`;
  }

  const handleMarketButtonOnClick = () => {
    navigate('/market', {replace : true});
  }

  const addPostFlagTag = ({postStatus, premium, premiumStart, premiumEnd}) => {
    ;

    return (
      <>
        {premium ? (
          <PostStatus>프리미엄</PostStatus>
        ):(
          <></>
        )}
        {9 === postStatus ? (
          <PostStatus>삭제</PostStatus>
        ): (
          (1 === postStatus ? (
            <>
              {addAdFlagTag(premiumStart, premiumEnd)}
            </>
          ) : (
            <PostStatus>대기</PostStatus>
          ))
        )}
      </>
    );
  }

  const addSoldTag = ({isSoldOut}) => {
    if(!isSoldOut) return;
    return (
      <SoldOut>SOLD</SoldOut>
    );
  }

  const addAdFlagTag = (premiumStart, premiumEnd) => {
    const toDay = new Date();
    const temPremiumStart = new Date(premiumStart?.replaceAll('-', '/')).getTime();
    const temPremiumEnd = new Date(premiumEnd?.replaceAll('-', '/')).getTime();
    const isAdvertising1 = temPremiumStart < toDay.getTime();
    const isAdvertising2 = toDay.getTime() < temPremiumEnd;
    return (
      <>
        {isAdvertising1 && isAdvertising2 ? (
          <AdStatus>광고중</AdStatus>
        ) : (
          temPremiumEnd ? (
            <AdStatus>광고종료</AdStatus>
          ) : (
            <AdStatus>게시중</AdStatus>
          )
        )}
      </>
    );
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list?.length === 0 ? (
            <NoData>
              <img src='/icon/no-data-icon.png' alt='알림 표시' />
              <p>
                아직 작성한 게시글이 없네요!
                건강한 커뮤니티 문화 함께 만들어요.
              </p>
              <div className='marketButton' onClick={()=>handleMarketButtonOnClick()}>
                밴마켓 메인 바로가기 <img src='/icon/angle-right-gray2-icon.png' alt='더보기' />
              </div>
            </NoData>
          ):(
            <PostSection>
              {list?.map((row)=>(
                <PostItem key={row?.postId}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${row?.boardLink}/${row?.postId}`)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row?.title} onError={(e) => handleImageError(e)} loading="lazy" />
                      {addSoldTag(row)}
                    </ImageWrapper>
                    <InfoWrapper>
                      <InfoFlag>
                        <BoardName>{row?.boardName}</BoardName>
                        {addPostFlagTag(row)}
                      </InfoFlag>
                      <h2>{row?.title}</h2>
                      <PostDateHitCount>
                        {elapsedText(row?.updatedDate)} · {`조회수 ${row?.hitCount}`}
                      </PostDateHitCount>
                    </InfoWrapper>
                  </div>
                </PostItem>
              ))}
            </PostSection>
          )}
        </>
      )}
    </PostListWrapper>
  );
}

const PostListWrapper = styled.div`
  width: 100%;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
  border-bottom: 0rem solid #ddd;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 15.2rem;
  height: 11.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 16.7rem;
  right: 0rem;
  width: auto;
  height: 100%;

  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & h2 {
    margin: 0;
    padding: 0.5rem 0;
    width: inherit;
    height: 1.7rem;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #000000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const InfoFlag = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  flex-wrap: wrap;
  & span {
    margin: 0 0.2rem;
  }
`;

const BoardName = styled.span`
  margin: 0;
  padding: 0.2rem 0.6rem;

  font-size: 10px;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: -0.06em;
  color: #FD6258;

  display: flex;
  align-items: center;

  background: #FFEAE9;
  border: 0.1rem solid #FFEAE9;
  border-radius: 0.2rem;
`;

const PostStatus = styled.span`
  margin: 0;
  padding: 0.2rem 0.6rem;

  font-size: 10px;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: -0.06em;
  color: #9E9E9E;

  display: flex;
  align-items: center;

  background: #FFFFFF;
  border: 0.1rem solid #EEEEEE;
  border-radius: 0.2rem;
`;

const AdStatus = styled.span`
  margin: 0;
  padding: 0 0.6rem;

  font-size: 10px;
  height: 1.8rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: -0.06em;
  color: #FD6258;

  display: flex;
  align-items: center;

  background: #FFFFFF;
  border: 0.1rem solid #FFEAE9;
  border-radius: 0.2rem;
`;


const PostDateHitCount = styled.span`
  margin: 0;
  padding: 0;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9e9e9e;

  display: flex;
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NoData = styled.p`
  margin: 0;
  padding: 5.9rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & img {
    width: 6rem;
    height: 6rem;
  }
  & p {
    width: 22.7rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    text-align: center;
    letter-spacing: -0.04em;
    word-break: keep-all;
    color: #000000;
  }
  & div.marketButton {
    width: 18.1rem;
    height: 3.6rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0.1rem solid #e0e0e0;
    border-radius: 30rem;
    cursor: pointer;
    & img {
      padding: 0.2rem 0 0 0.8rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const SoldOut = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  text-align: center;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(0.2rem);
`;