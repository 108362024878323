import React from 'react';
import styled from 'styled-components';
import { CommonFooter } from '../common/CommonFooter';
import { NewsHeaderForDetail } from '../domain/news/component/NewsHeaderForDetail';

export const NewsDetailLayout = ({
    title,
    children,
    cancelLink,
    showBackButton=false,
    showCancelButton=false,
  }) => {
  return (
    <>
      <NewsWrapper>
        <NewsHeaderForDetail
          title={title}
          cancelLink={cancelLink}
          showBackButton={showBackButton}
          showCancelButton={showCancelButton}
        />
        {children}
        <CommonFooter />
      </NewsWrapper>
    </>
  );
}

const NewsWrapper = styled.div`
  position: relative;
`;
