import React from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../../common/SkeletonComponent';
import { addHyphenForPhoneNumber } from '../../../../utils/utils';

export function YellowpagePostListComponent({ list, link, isLoading=true, postOnClick}) {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-yellowpage.png`;
  }


  const addPremiumTag = (premium) => {
    if("1" === premium) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    } else {
      return '';
    }
  }

  const getImgAbsoluteUrl = () => {
    let absoluteUrl = "";
    switch (link) {
      default:
        absoluteUrl = process.env.REACT_APP_STORE_URL;
        break;
    }

    return absoluteUrl;
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper left={124}>
                  <h2 style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}></div>
                  <div className='phoneNumber' style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}>
                  </div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper left={124}>
                  <h2 style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}></div>
                  <div className='phoneNumber' style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}>
                  </div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper left={124}>
                  <h2 style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}></div>
                  <div className='phoneNumber' style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}>
                  </div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper left={124}>
                  <h2 style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}></div>
                  <div className='phoneNumber' style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}>
                  </div>
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <ImageWrapper></ImageWrapper>
                <InfoWrapper left={124}>
                  <h2 style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}></div>
                  <div className='phoneNumber' style={{margin: '0.2rem', width: '18rem', height: '20px', backgroundColor: '#F5F5F5'}}>
                  </div>
                </InfoWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <PostSection>
              {list.map((row) => (
                <PostItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                    <ImageWrapper>
                      <img src={`${getImgAbsoluteUrl()}${row.imageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper>
                      {addPremiumTag(row.premium)}
                      <h2>{row.title}</h2>
                      <div>{`${row.address} ${row.postal}`}</div>
                      <div className='phoneNumber'>
                        <img className='phoneIcon' src='/icon/phone-icon.png' alt='Phone Number'/>
                        {`${addHyphenForPhoneNumber(row.phone)}`}
                      </div>
                    </InfoWrapper>
                  </div>
                </PostItem>
              ))}
            </PostSection>
          )}
        </>
      )}


    </PostListWrapper>
  );
}

const PostListWrapper = styled.div`
  width: 100%;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
  border-bottom: 0 solid #ddd;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 15.2rem;
  height: 11.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 16.7rem;
  right: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & h2 {
    margin: 0;
    width: inherit;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div {
    display: block;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div.phoneNumber {
    margin: 0.2rem 0;
    display: block;

    font-size: 12px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & img.phoneIcon {
      margin-right: 0.3rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & span {
    display: block;

    font-size: 12px;
    font-weight: 300;
    line-height: 2rem;
    color: #9e9e9e;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & span:first-child {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 2rem;
    color: #000;
  }

  & img.premium {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 0rem;
    bottom: 0.5rem;
    border-radius: 0.8rem;
    background-image: url('//www.vanchosun.com/stores/images_site/medal.gif');
    background-position: right;
    background-repeat: no-repeat;
    background-color: #fff;
    opacity: 0.8;
  }
  & img.premiumIcon {
    width: 5rem;
    height: 1.8rem;
  }
  & img.active {
    display: block;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;