import React from "react";
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import App from "./App";

// 리액트18 버전
const rootNode = document.getElementById('root');
ReactDOM.createRoot(rootNode).render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
