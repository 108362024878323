import React, { memo } from 'react';
import styled from 'styled-components';

export const Loader = memo(({loading}) => {
  return (
    <>
      { loading &&
        (
          <LoaderWrapper>
            <Spinner>
              <img src='/icon/loader-spinner-icon.png' alt='로딩중입니다.'/>
            </Spinner>
          </LoaderWrapper>
        )
      }
    </>
  )
});

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

const Spinner = styled.div`
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.6rem;
  height: 4.6rem;
  & img {
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;