import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Dialog = ({isOpen, handleClose, message, type}) => {

  // const handleClose = () => {
  //   setOpen(false);
  //   return 0;
  // }

  const modals = {
    'error': <ErrorModal open={isOpen} handleClose={handleClose} message={message}></ErrorModal>,
    'alert': <AlertModal open={isOpen} handleClose={handleClose} message={message}></AlertModal>,
    'nonHeader': <NonHeaderModal open={isOpen} handleClose={handleClose} message={message}></NonHeaderModal>,
  }

  const modal = modals[type];

  return (
    <div>
      {modal}
    </div>
  );
}

const ErrorModal = ({open, handleClose, message}) => {
  return (
    <>
      {open ? (
        <Modal className={open && 'openModal'}>
          <section>
            <header>
              {`Error `}
              <button className="close" onClick={handleClose}>
                {' '}
                &times;{' '}
              </button>
            </header>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      ) : null}
    </>
  );
}

const AlertModal = ({open, handleClose, message}) => {
  return (
    <>
      { open && (
        <Modal className={open && 'openModal'}>
          <section>
            <header>
              {`Alert`}
              <button className="close" onClick={handleClose}>
                {' '}
                &times;{' '}
              </button>
            </header>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      )}
    </>
  );
}

const NonHeaderModal = ({open, handleClose, message}) => {
  return (
    <>
      { open && (
        <Modal className={open && 'openModal'}>
          <section>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      )}
    </>
  );
}

const modalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -5rem;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;
const modalBgShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.6);
  & button {
    outline: none;
    cursor: pointer;
    border: 0;
  }
  & section {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 91%;
    max-width: 38rem;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: ${modalShow} 0.3s;
    overflow: hidden;
    & header {
      position: relative;
      padding: 1.6rem 6.4rem 1.6rem 1.6rem;
      background-color: #f1f1f1;
      font-size: 16px;
      font-weight: 700;
      line-height: 2rem;
      & button {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        color: #999;
        background-color: transparent;
      }
      & button.nonHeader {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 3rem;
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        color: #999;
        background-color: transparent;
      }
    }
    & main {
      padding: 1.6rem;
      border-bottom: 0.1rem solid #dee2e6;
      border-top: 0.1rem solid #dee2e6;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.7rem;

    }
    & footer {
      padding: 1.2rem 1.6rem;
      text-align: right;
      & button {
        padding: 0.6rem 1.2rem;
        color: #fff;
        background-color: #6c757d;
        border-radius: 0.5rem;
        font-size: 14px;
      }
    }
  }
  & div.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: ${modalBgShow} 0.3s;
  }
`;
