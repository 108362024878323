import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CommonFooter } from '../common/CommonFooter';
import { MarketHeaderForDetail } from '../domain/market/component/MarketHeaderForDetail';

export const MarketPaymentLayout = ({title, children, backLink, cancelLink, showBackButton=false, showCancelButton=false}) => {
  const { state } = useLocation();

  const getBackLink = () => {
    if(state?.backLink) {
      return state.backLink;
    } else if(backLink) {
      return backLink;
    } else {
      return '';
    }
  }

  const getCancelLink = () => {
    if(state?.cancelLink) {
      return state.cancelLink;
    } else if(cancelLink) {
      return cancelLink;
    } else {
      return '';
    }
  }
  return (
    <>
      <MarketWrapper>
        <MarketHeaderForDetail
          title={title}
          backLink={getBackLink()}
          cancelLink={getCancelLink()}
          showBackButton={showBackButton}
          showCancelButton={showCancelButton}
        />
        <main>
          {children}
        </main>
        <CommonFooter />
      </MarketWrapper>
    </>
  );
}

const MarketWrapper = styled.div`
  position: relative;
`;