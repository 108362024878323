import qs from "qs";
import { jsonApi } from "../../../utils/AxiosApi";

export class DwellingApi {

  fetchDwellingList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/dwelling`,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchDwellingById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/dwelling/${id}`,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

}