import React from 'react'
import styled from 'styled-components';
import { CheckBoxComponent } from './CheckBoxComponent';
// import { RangeComponent } from './RangeComponent';
// import { priceToString, } from '../../../../utils/utils';
import {
  HomestayFilterDetailedOptionList,
  HomestayFilterGuestGenderList,
  HomestayFilterMinimumReservationList,
  HomestayFilterRoomTypeList,
} from '../../../../__mocks__/PostData';

export const HomestayFilterModal = ({ show, toggle, searchData, changeSearchData, handleSearchClick }) => {

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerOnClick = () => {
    handleToggleModal?.();
  }

  const handlerApplyOnClick = () => {
    handleSearchClick?.();
    handleToggleModal();
  }

  const handlerResetFilterDataOnClick = () => {
    changeSearchData("roomTypes", '');
    changeSearchData("guestGenders", '');
    changeSearchData("minimumReservations", '');
    changeSearchData("detailedOptions", '');
    changeSearchData("checkInType", '');
    changeSearchData("minPrice", 0);
    changeSearchData("maxPrice", 5000);
  }

  const handleRoomTypeOnChange = (value) => {
    changeSearchData("roomTypes", value);
  }

  const handleGuestGenderOnChange = (value) => {
    changeSearchData("guestGenders", value);
  }

  const handleMinimumReservationOnChange = (value) => {
    changeSearchData("minimumReservations", value);
  }

  const handleDetailedOptionsOnChange = (value) => {
    changeSearchData("detailedOptions", value);
  }

  const handleImmediateCheckInOnChange = () => {
    if(searchData.checkInType === 'immediate-checkIn') {
      changeSearchData("checkInType", '');
    } else {
      changeSearchData("checkInType", 'immediate-checkIn');
    }
  }

  // const handlePriceOnChange = (minPrice, maxPrice) => {
  //   changeSearchData("minPrice", minPrice);
  //   changeSearchData("maxPrice", maxPrice);
  // }

  const isCheckInTypeChecked = (checkInType) => {
    return checkInType === "immediate-checkIn" ? true : false;
  }

  return (
    <>
      {show && (
        <Modal>
          <ModalBody>
            <FilterWrapper>
              <FilterTitle>
                <div>필터</div>
                <CloseBtn src="/icon/x-mark.png" alt='닫기 버튼' onClick={handlerOnClick}/>
              </FilterTitle>
              <Main>
                <Section>
                  <div className='title'>
                    객실형태
                  </div>
                  <CheckBoxComponent
                    id='roomTypes'
                    value={searchData?.roomTypes}
                    list={HomestayFilterRoomTypeList}
                    onChange={handleRoomTypeOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    성별구분
                  </div>
                  <CheckBoxComponent
                    id='guestGenders'
                    value={searchData?.guestGenders}
                    list={HomestayFilterGuestGenderList}
                    onChange={handleGuestGenderOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    숙박기간
                  </div>
                  <CheckBoxComponent
                    id='minimumReservations'
                    value={searchData?.minimumReservations}
                    list={HomestayFilterMinimumReservationList}
                    onChange={handleMinimumReservationOnChange}
                  />
                </Section>
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    민박/홈스테이 가격
                  </div>
                  <RangeTitle>{priceToString(searchData?.minPrice, 3)} ~ {priceToString(searchData?.maxPrice, 3)}이상(1인/1박 기준)</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <RangeComponent
                      id='price'
                      min={0}
                      max={5000}
                      median={'$2,500'}
                      step={10}
                      minValue={searchData?.minPrice}
                      maxValue={searchData?.maxPrice}
                      onChange={handlePriceOnChange}
                    />
                  </div>
                </Section> */}
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    추가옵션
                  </div>
                  <CheckBoxComponent
                    id='detailedOptions'
                    value={searchData?.detailedOptions||''}
                    list={HomestayFilterDetailedOptionList}
                    onChange={handleDetailedOptionsOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <SwitchWrapper>
                    <SwitchTitle>
                      즉시입실 모아보기
                    </SwitchTitle>
                    <Switch>
                      <input id='checkInType'
                        type="checkbox"
                        onChange={handleImmediateCheckInOnChange}
                        checked={isCheckInTypeChecked(searchData?.checkInType)}
                      />
                      <span className="slider">
                        {(isCheckInTypeChecked(searchData?.checkInType))
                          ? (<span className={`sliderOn`}>ON</span>)
                          : (<span className={'sliderOff'}>OFF</span>)
                        }
                      </span>
                    </Switch>
                  </SwitchWrapper>
                </Section>
                <ButtonGroup>
                  <ButtonStyle
                    type='button'
                    width='28%'
                    color='#BDBDBD'
                    backgroundColor='#F5F5F5'
                    borderColor='#F5F5F5'
                    onClick={handlerResetFilterDataOnClick}
                  >
                    초기화
                  </ButtonStyle>
                  <ButtonStyle
                    type='button'
                    width='68%'
                    color='#FFFFFF'
                    backgroundColor='#FD6258'
                    borderColor='#FD6258'
                    onClick={handlerApplyOnClick}
                  >
                    적용하기
                  </ButtonStyle>
                </ButtonGroup>
              </Main>
            </FilterWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;

const FilterWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const FilterTitle = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #f5f5f5;
  & div {
    margin: 0 auto;
    width: 8rem;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
  };
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.6rem;
  right: 1.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Main = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  height: 100%;
`;

const Section = styled.div`
  position: relative;
  margin: 0;
  padding: 2.4rem 0rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000;
  & div.title {
    margin-bottom: 2.4rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ButtonGroup = styled.div`
  margin: 0;
  padding: 2.9rem 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

const ButtonStyle = styled.button`
  width: ${props => props.width};
  height: 4.8rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.borderColor};
  border-radius: 0.4rem;
  cursor: pointer;
`;

// const RangeTitle = styled.div`
//   position: absolute;
//   top: 5.2rem;
//   left: 0;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 2.3rem;
//   letter-spacing: -0.04em;
//   color: #9E9E9E;
// `;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SwitchTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 8.2rem;
  height: 3.4rem;

  & input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  & span.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EEEEEE;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 3.4rem;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    & span.sliderOn {
      position: absolute;
      top: 0.5rem;
      left: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
    & span.sliderOff {
      position: absolute;
      top: 0.5rem;
      right: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
  }
  & input:checked + span.slider {
    background-color: #FD6258;
  }

  & input:focus + span.slider {
    box-shadow: 0 0 0.1rem #FD6258;
  }
  & input:checked + span.slider::before {
    -webkit-transform: translateX(4.8rem);
    -ms-transform: translateX(4.8rem);
    transform: translateX(4.8rem);
  }
`;
