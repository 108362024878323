import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainInterview = ({list}) => {
  return (
    <MainInterviewSection>
      <Header>
        <Link to={`/interview`}>
          <h2>인터뷰</h2>
        </Link>
      </Header>
      <ul>
        {list.map((row) => (
          <li key={row.id}>
            <Link to={`/interview/${row.id}`}>
              <img src={`//cdn.vanchosun.com/news/File_images_board/${row.imageFile}`} alt={row.title} loading='lazy'/>
              <h3>{row.title}</h3>
            </Link>
          </li>
        ))}
      </ul>
    </MainInterviewSection>
  )
};

const MainInterviewSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem 2rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    & li {
      margin: 0 auto;
      width: 48.5%;
      border-bottom: 0 solid #dddddd;
      & a {
        display: flex;
        flex-direction: column;

        color: #000;
        text-decoration: none;
        & img {
          width: inherit;
          height: 12rem;
          object-fit: cover;
          -webkit-user-drag: none;
        }
        & h3 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;

const Header = styled.div`
  margin: 0;
  padding: 2rem 0 1.6rem;
  & h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;