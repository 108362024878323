import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { elapsedText } from '../../../utils/utils';
import { CommunityBoards } from '../../../__mocks__/PostData';
import { CommunityApi } from '../api/CommunityApi';

export const MainCommunity = () => {
  const communityApi = new CommunityApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tabType, setTabType] = useState(searchParams.get('type')?searchParams.get('type'):'openBest');
  const [communityBestList, setCommunityBestList] = useState({list:[]});
  const [communityNewList, setCommunityNewList] = useState({list:[]});
  const [exportQnAList, setExportQnAList] = useState({list:[]});
  const [immigrantList, setImmigrantList] = useState({list:[]});
  const [meetingUpList, setMeetingUpList] = useState({list:[]});

  const isOpenBest = () => {
    if('openBest' === tabType) {
      return true;
    }
    return false;
  }
  const isOpenNew = () => {
    if('openNew' === tabType) {
      return true;
    }
    return false;
  }
  const isExportQnA = () => {
    if('exportQnA' === tabType) {
      return true;
    }
    return false;
  }
  const isImmigrant = () => {
    if('immigrant' === tabType) {
      return true;
    }
    return false;
  }
  const isMeetingUp = () => {
    if('meetingUp' === tabType) {
      return true;
    }
    return false;
  }
  const handleTabOnClick = (tabType) => {
    setTabType(tabType);
  }

  const handleSearch = () => {
    communityApi.fetchCommunityBestList({ page: 1, listSize: 5, isMain: true }, (data) => {
      setCommunityBestList(data);
    });
    communityApi.fetchCommunityNewList({ page: 1, listSize: 5, isMain: true }, (data) => {
      setCommunityNewList(data);
    });
    communityApi.fetchExportQnAList({ page: 1, listSize: 4, isMain: true }, (data) => {
      setExportQnAList(data);
    });
    communityApi.fetchImmigrantList({ page: 1, listSize: 4, isMain: true }, (data) => {
      setImmigrantList(data);
    });
    communityApi.fetchMeetingUpList({ page: 1, listSize: 4, isMain: true }, (data) => {
      setMeetingUpList(data);
    });
  };

  const handlerItemCardOnClick = (url) => {
    navigate(url);
  }

  const handleMoreButtonOnClick = () => {
    let typeValue = (isOpenBest()||isOpenNew())?'open': tabType;
    navigate("/community", {state: {type: typeValue}});
  }


  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainCommunityWrapper>
        <Tabs>
          <div className={`${(isOpenBest()||isOpenNew()) ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('openBest')}>자유게시판</div>
          <div className={`${isExportQnA() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('exportQnA')}>전문가Q&A</div>
          <div className={`${isImmigrant() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('immigrant')}>이민정착</div>
          <div className={`${isMeetingUp() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('meetingUp')}>만남의광장</div>
        </Tabs>
        {(isOpenBest()||isOpenNew()) && (
          <CommunityList>
            <div className='btnGroup'>
              <div className={`${isOpenBest() ? 'active' : ''}`} onClick={() => handleTabOnClick('openBest')}>BEST 인기글</div>
              <div className={`${isOpenNew() ? 'active' : ''}`} onClick={() => handleTabOnClick('openNew')}>NEW 최신글</div>
            </div>
            {isOpenBest() && (
              <ul>
                {communityBestList.list.map((row) => (
                  <li key={row.id}>
                    <div className='item' onClick={()=>handlerItemCardOnClick(`/community/${row.id}`)}>
                      <span>{row.rowNumber}</span>
                      <div className='title'>
                        {row.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {isOpenNew() && (
              <ul>
              {communityNewList.list.map((row) => (
                <li>
                  <div className='title' onClick={()=>handlerItemCardOnClick(`/community/${row.id}`)}>
                    {row.title}
                  </div>
                </li>
              ))}
            </ul>
            )}
          </CommunityList>
        )}
        {isExportQnA() && (
          <ExpertList>
            <ul>
              {exportQnAList.list.map((row) => (
                <li>
                  <div className='item' onClick={()=>handlerItemCardOnClick(`/community/${row.id}`)}>
                    <div className='type'>{CommunityBoards[row?.boardId]?.label ? CommunityBoards[row?.boardId]?.label : row?.boardId}</div>
                    <div className='title'>{row.title}</div>
                  </div>
                </li>
              ))}
            </ul>
          </ExpertList>
        )}
        {isImmigrant() && (
          <ImmigrantList>
            <ul>
              {immigrantList.list.map((row) => (
                <li>
                  <div className='item' onClick={()=>handlerItemCardOnClick(`/community/${row.id}`)}>
                    <div className='type'>{row.boardName}</div>
                    <div className='title'>{row.title}</div>
                  </div>
                </li>
              ))}
            </ul>
          </ImmigrantList>
        )}

        {isMeetingUp() && (
          <MeetingUpList>
            <ul>
              {meetingUpList.list.map((row) => (
                <li key={row.id} >
                  <div className='item' onClick={()=>handlerItemCardOnClick(`/community/${row.id}`)}>
                    <h3>
                      {row.title}
                    </h3>
                    <div className='info'>
                      <span className='name'>
                        {row.name}
                      </span>
                      <img class='period' src='/icon/period-icon.png' alt='period'/>
                      <span className='date'>
                        {elapsedText(row?.postDate)}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </MeetingUpList>
        )}
        <ButtonMore onClick={()=>handleMoreButtonOnClick()}>
          더보기 <img src='/icon/angle-right-black-icon.png' alt='더보기' />
        </ButtonMore>
      </MainCommunityWrapper>
    </>
  );
};

const MainCommunityWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 1.6rem 0;
  width: auto;
  background-color: #fff;
`;
const Tabs = styled.div`
  padding: 0 1.6rem;
  width: auto;
  height: 4.8rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 0.1rem solid #f7f7f7;
  & div.tab {
    width: 7.4rem;
    height: 4.4rem;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  & div.active {
    margin-bottom: -0.2rem;
    font-weight: 700;
    color: #de2b36;
    border-bottom: 0.2rem solid #de2b36;
  }
`;

const ButtonMore = styled.div`
  margin: 1.6rem 1.6rem 0 1.6rem;
  width: auto;
  height: 4.8rem;
  font-size: 14px;
  font-weight: 500;
  color: #616161;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  cursor: pointer;
  & img {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const CommunityList = styled.div`
  padding: 0 1.6rem;
  height: 26.6rem;
  & div.btnGroup {
    padding-top: 1.6rem;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    display: flex;
    justify-content: center;

    & div {
      width: 100%;
      height: 4.2rem;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #eee;
      border: 0.1rem solid #eee;
      cursor: pointer;
    }
    & div:nth-child(1) {
      border-right: 0;
      border-radius: 0.4rem 0 0 0.4rem;
    }
    & div:nth-child(2) {
      border-radius: 0 0.4rem 0.4rem 0;
    }
    & >div.active {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      color: #FD6258;
      background-color: #fff;
    }
  }
  & ul {
    padding-left: 0.7rem;
    list-style: none;
    & li {
      height: 4rem;

      display: flex;
      align-items: center;

      border-bottom: 0.1rem solid #f7f7f7;
      cursor: pointer;
      & div.item {
        width: 100%;

        font-size: 14px;
        font-weight: 400;

        display: flex;
        align-items: center;
        & div.title {
          padding-left: 1rem;
          display: inline-block;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      & div.title {
        width: 100%;

        font-size: 14px;
        font-weight: 400;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    & li > div >span {
      font-size: 14px;
      font-weight: 700;
      line-height: 2rem;
      color: #BDBDBD;
    }
    & li:nth-child(-n+3) > div >span {
      font-size: 14px;
      font-weight: 700;
      line-height: 2rem;
      color: #FD6258;
    }
  }
  & li:last-child {
    border-bottom: 0rem solid #f7f7f7;
  }
`;

const ExpertList = styled.div`
  padding: 0 1.6rem;
  height: 26.6rem;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      height: 6.6rem;
      border-bottom: 0.1rem solid #f4f4f4;
      cursor: pointer;
      & div.item {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & div.type {
          height: 2rem;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.7rem;
          letter-spacing: -0.04em;
          color: #FD6258;
        }
        & div.title {
          height: 2rem;

          font-size: 14px;
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: -0.04em;
          color: #000000;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    & li:last-child {
      border-bottom: 0rem solid #f7f7f7;
    }
  }
`;

const ImmigrantList = styled.div`
  padding: 0 1.6rem;
  height: 26.6rem;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      height: 6.6rem;
      border-bottom: 0.1rem solid #f4f4f4;
      cursor: pointer;
      & div.item {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & div.type {
          height: 2rem;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.7rem;
          letter-spacing: -0.04em;
          color: #FD6258;
        }
        & div.title {
          height: 2rem;

          font-size: 14px;
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: -0.04em;
          color: #000000;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    & li:last-child {
      border-bottom: 0rem solid #f7f7f7;
    }
  }
`;

const MeetingUpList = styled.div`
  padding: 0 1.6rem;
  height: 26.6rem;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      height: 6.6rem;
      border-bottom: 0.1rem solid #f7f7f7;
      cursor: pointer;
      & div.item {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        & h3 {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 2rem;

          font-size: 14px;
          font-weight: 400;
          line-height: 2rem;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & div.info {
          display: flex;
          align-items: center;
          & span {
            height: 2rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.738rem;
            letter-spacing: -0.04em;
            color: #bdbdbd;
          }
          & img.period {
            margin: 0 0.3rem;
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
    }
    & li:last-child {
      border-bottom: 0rem solid #f7f7f7;
    }
  }
`;
