import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MarketSearch } from '../component/MarketSearch';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import Pagination from '../../../common/Pagination';
import { CommunityPostListComponent } from './component/CommunityPostListComponent';
import { CommunityCategoryModal } from './component/CommunityCategoryModal';
import { CommunityNoticeListComponent } from './component/CommunityNoticeListComponent';
import { SortComponent } from '../component/SortComponent';
import { CommunityApi } from '../api/CommunityApi';
import { UserApi } from '../../user/api/UserApi';
import {
  CommunityExportQnACategory,
  CommunityImmigrantCategory,
  CommunityMeetingUpCategory,
  CommunityOpenCategory,
  CommunitySort
} from '../../../__mocks__/PostData';
import { Helmet } from 'react-helmet-async';

export const CommunityList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "community_search";
  const DEFAULT_VIEW_MODE = "text";
  const PER_VIEW_LIST = 18;
  const FIRST_LIST_POINT = 0;
  const LIST_SIZE = 18;

  const communityApi = new CommunityApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isNewButtonClick, setIsNewButtonClick] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/community/main/frame.php'; // 여기서 www.com URL로 변경
  }

  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: '',
          categoryName: '전체보기',
          title: null,
          type: location.state?.type?location.state.type:'open',
          sort: 'newest',
          scrollY: 0,
          viewMode: DEFAULT_VIEW_MODE,
        };
      }
    }
  });
  const [communityList, setCommunityList] = useState({list:[]});
  const [communityNoticeList, setCommunityNoticeList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeCategoryData = (code, name) => {
    changeSearchData('page', 1);
    changeSearchData('categoryCode', code);
    changeSearchData('categoryName', name);
  };
  const toggleCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal)
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    communityApi.fetchCommunityList(
      searchData,
      (data) => {
        setCommunityList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        setLoading(false);
        if(error) {
          if(error.response) {
            const {response: {data}} = error;
            setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
          } else if(error.message) {
            setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
          }
        }
      }
    );
  }

  const handleNoticeSearchClick = () => {
    communityApi.fetchCommunityNoticeList(
      searchData,
      (data) => setCommunityNoticeList(data)
    );
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  const handleNewOnClick = () => {
    setIsNewButtonClick(!isNewButtonClick);
  }

  const handleNewCommunityOnClick = (communityType) => {
    if(userApi.isSignIn()) {
      navigate("/community-edit", { state: { communityType: communityType }});
    } else {
      navigate("/signIn");
    }
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const isOpen = () => {
    if('open' === searchData.type) {
      return true;
    }
    return false;
  }
  const isExportQnA = () => {
    if('exportQnA' === searchData.type) {
      return true;
    }
    return false;
  }
  const isImmigrant = () => {
    if('immigrant' === searchData.type) {
      return true;
    }
    return false;
  }
  const isMeetingUp = () => {
    if('meetingUp' === searchData.type) {
      return true;
    }
    return false;
  }
  const handleTabOnClick = (tabType) => {
    changeSearchData('type', tabType);
    changeSearchData('page', 1);
    changeSearchData('categoryCode', '');
    changeSearchData('categoryName', '전체보기');
  }

  useEffect(() => {
    handleNoticeSearchClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.type]);

  useEffect(() => {
    handleSearchClick();

    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.sort, searchData.title, searchData.type, searchData.categoryCode]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`커뮤니티 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'어떤 정보를 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'community_top_ra'}
          listSize={5}
        />
        <Tabs>
          <div className={`${isOpen() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('open')}>자유게시판</div>
          <div className={`${isExportQnA() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('exportQnA')}>전문가Q&A</div>
          <div className={`${isImmigrant() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('immigrant')}>이민정착</div>
          <div className={`${isMeetingUp() ? 'tab active' : 'tab'}`} onClick={() => handleTabOnClick('meetingUp')}>만남의광장</div>
        </Tabs>
        <MarketLineAdList
          boardId={1}
          mobileAdOnClick={handlerItemCardOnClick}
        />
        <SearchFilter>
          <div></div>
          <div>
            <div className='mapPin' onClick={()=> toggleCategoryModal()}>
              {searchData?.categoryName}<img src='/icon/angle-down-black-sm-icon.png' alt=''/>
            </div>
            <CommunityCategoryModal
              categories={
                isOpen()
                ? CommunityOpenCategory
                : isExportQnA()
                  ? CommunityExportQnACategory
                  : isImmigrant()
                    ? CommunityImmigrantCategory
                    : isMeetingUp()
                      ? CommunityMeetingUpCategory
                      : []
              }
              showCategoryModal={showCategoryModal}
              toggleCategoryModal={toggleCategoryModal}
              categoryCode={searchData?.categoryCode}
              changeCategoryData={changeCategoryData}
            />
            <SortComponent
              sortDisplayList={CommunitySort}
              sortCode={searchData?.sort}
              changeSortData={changeSortData}
            />
          </div>
        </SearchFilter>
        <CommunityNoticeListComponent
          list={communityNoticeList.list}
          link={'community'}
          startPoint={FIRST_LIST_POINT}
          perView={PER_VIEW_LIST}
          postOnClick={handlerItemCardOnClick}
        />
        <div className='list'>
          <CommunityPostListComponent
            list={communityList.list}
            link={'community'}
            type={searchData.type}
            startPoint={FIRST_LIST_POINT}
            perView={PER_VIEW_LIST}
            isLoading={loading}
            postOnClick={handlerItemCardOnClick}
          />
        </div>
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}
        />
        {isNewButtonClick ? (
          <>
            <WriteButton bottom={27.5} right={1.6} width={'1.909rem'} height={'1.909rem'} onClick={()=>handleNewCommunityOnClick("자유게시판")}>
              <span>자유게시판</span>
              <img src='/icon/job-search-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton bottom={21.3} right={1.6} width={'1.909rem'} height={'1.909rem'} onClick={()=>handleNewCommunityOnClick("전문가Q&A")}>
              <span>전문가Q&A</span>
              <img src='/icon/job-offer-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton bottom={15.1} right={1.6} width={'1.909rem'} height={'1.909rem'} onClick={()=>handleNewCommunityOnClick("이민정착")}>
              <span>이민정착</span>
              <img src='/icon/job-search-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton bottom={8.9} right={1.6} width={'1.909rem'} height={'1.909rem'} onClick={()=>handleNewCommunityOnClick("만남의광장")}>
              <span>만남의광장</span>
              <img src='/icon/job-offer-write-icon.png' alt='Write button' />
            </WriteButton>
            <WriteButton className='active' bottom={2.7} right={1.6} width={'1.583rem'} height={'1.583rem'} onClick={()=>handleNewOnClick()}>
              <img src='/icon/job-new-write-clicked-icon.png' alt='등록 선택' />
            </WriteButton>
          </>
        ) : (
          <WriteButton bottom={2.7} right={1.6} width={'2.322rem'} height={'2.318rem'} onClick={()=>handleNewCommunityOnClick()}>
            <img src='/icon/job-new-write-icon.png' alt='등록' />
          </WriteButton>
        )}
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0.5rem;
  width: auto;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 0 1.6rem 1.6rem;
    min-height: 39vh;
  }
`;

const Tabs = styled.div`
  margin-bottom: 2rem;
  height: 4.4rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;

  border-bottom: 0.1rem solid #F5F5F5;
  & div.tab {
    padding-top: 1.6rem;
    width: 7.4rem;
    height: 2.75rem;
    font-style: normal;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;

    text-align: center;
    cursor: pointer;
  }
  & div.active {
    font-weight: 500;
    color: #FD6258;
    border-bottom: 0.1rem solid #FD6258;
  }
`;

const WriteButton = styled.div`
  position: fixed;
  bottom: ${props => props.bottom +'rem'};
  right: ${props => props.right +'rem'};
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fd6258;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width};
    height: ${props => props.height};

  }
  & span {
    position: fixed;
    bottom: ${props => props.bottom + 1.3 +'rem'};
    right: ${props => props.right + 5.5 +'rem'};
    width: 8.4rem;
    height: 2.1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.1rem;
    letter-spacing: -0.04em;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.4rem;
  }
  &.active {
    position: fixed;
    bottom: ${props => props.bottom+'rem'};
    right: ${props => props.right+'rem'};
    width: 4.4rem;
    height: 4.4rem;
    background-color: #fff;
    border: 0.1rem solid #ddd;
    border-radius: 50%;
    z-index: 10;
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props => props.width};
      height: ${props => props.height};
      cursor: pointer;
    }
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & img.cardList {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & img.imageList {
    width: 1.7rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.filter {
    padding-left: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    border-left: 1px solid #E0E0E0;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;
