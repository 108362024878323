import React, { useState } from 'react';
import styled from 'styled-components';
import { elapsedText, isBlankValue } from '../../../../utils/utils';
import { CommunityApi } from '../../api/CommunityApi';
import { CommunityReplyActionComponent } from './CommunityReplyActionComponent';

export const CommunityReplyDetailComponent = ({
  id, owner, boardId, bdId, name, comment, postDate,
  setLoading, setDialog, handleReplaySearchClick, showMoreOnClick }) => {
  const communityApi = new CommunityApi();
  const [isEditForm, setIsEditForm] = useState(false);
  const [editData, setEditData] = useState({
    id: id,
    owner: owner,
    boardId: boardId,
    bdId: bdId,
    name: name,
    comment: comment,
    postDate: postDate,
  });


  const changeEditData = (name, value) => {
    setEditData((data) => {
      const updated = {...data};
      updated[name] = value;
      return updated;
    });
  };

  const initEditDate = () => {
    setEditData({
      id: id,
      owner: owner,
      boardId: boardId,
      bdId: bdId,
      name: name,
      comment: comment,
      postDate: postDate,
    });
  }

  // handler ---------------------------------------------
  const handleWriteOnChange = (key, value) => {
    switch(key) {
      case 'name':
        changeEditData(key, removeSpecificCharactersForName(value));
        break;
      case 'comment':
        if(301 <= editData?.comment.length) {
          alert(`내용을 300자 이내로 기재해주세요.`);
          changeEditData(key, value.substring(0, 300));
        } else {
          changeEditData(key, value);
        }
        break;
      default:
        changeEditData(key, value);
        break;
    }
  };

  const handleCancelEditButton = () =>{
    initEditDate();
    toggleEditFormButton();
  }

  const toggleEditButton = () =>{
    if(editData.id) {
      setLoading(true);

      handleCheckReplyPermission(editData, (hasPermission) => {
        setLoading(false);
        if(hasPermission) {
          toggleEditFormButton();
        } else {
          setDialog({isOpen: true, message: "해당 댓글에 대한 사용 권한이 없습니다.", type: "alert"});
        }
      });
    }
  }

  const handleCheckReplyPermission = (data, callback) => {
    communityApi.checkReplyPermission(data.id,
      (data) => {
        callback(data.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const toggleEditFormButton = () =>{
    setIsEditForm(!isEditForm);
  }

  const handleSaveEditReplyFormOnClick = (data) => {
    if(validateEditReplyData(data)) {
      setLoading(true);
      const {id, boardId, bdId, comment} = data;
      const saveData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'bdId': bdId,
        'comment': comment,
      });

      communityApi.saveReplyComment(id, saveData,
        (data) => {
          setLoading(false);
          toggleEditFormButton();
          handleReplaySearchClick?.();
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { status, message, errors } = data;
          if(404 === status) {
            setDialog({isOpen: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const toggleDeleteButton = () =>{
    if(editData.id && window.confirm("삭제하시겠습니까?")) {
      setLoading(true);


      handleCheckReplyPermission(editData, (hasPermission) => {
        setLoading(false);
        if(hasPermission) {
          handleDeleteOnClick();
        } else {
          setDialog({isOpen: true, message: "해당 댓글에 대한 사용 권한이 없습니다.", type: "alert"});
        }
      });
    }
  }

  const handleDeleteOnClick = () => {
    if(editData.id) {
      setLoading(true);

      communityApi.deleteReplyComment(editData.id,
        (data) => {
          setLoading(false);

          handleReplaySearchClick?.();
        },
        (error) => {
          setLoading(false);
          const {response: {data}} = error;
          const { status, message, errors } = data;
          if(404 === status) {
            setDialog({isOpen: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        }
      );
    }
  }


  // ==== valid check ---------------------------------
  const removeSpecificCharactersForName = (value) => {
    const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|\s]/gi;	//정규식 구문
    if(value) {
      let notAllowedStr = value.replace(regExp , '');
      if(notAllowedStr) {
        alert(`허용하지 않는 문자 ${notAllowedStr} 는 입력하실수 없습니다.`);
        let notAllowedAry = Array.from(notAllowedStr);
        notAllowedAry.forEach(element=>{
          value = value.replace(element, '');
        });
        return value;
      }
    }
    return value;
  }

  const validateEditReplyData = ({id, boardId, bdId, comment}) => {
    if(isBlankValue(id)) {
      setDialog({isOpen: true, message: "댓글 ID는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(boardId)) {
      setDialog({isOpen: true, message: "게시판 ID는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(bdId)) {
      setDialog({isOpen: true, message: "게시물 ID는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(comment)) {
      setDialog({isOpen: true, message: "내용은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // render -------------------------------------------------

  return (
    <ReplyItem key={editData.id}>
      <div className="replyCard">
        <Info>
          <div className='infoHeader'>
            <span>{editData.name ? editData.name : '-'}</span>
            {editData.owner &&(
              <CommunityReplyActionComponent
                editOnClick={toggleEditButton}
                deleteOnClick={toggleDeleteButton}
                showMoreOnClick={showMoreOnClick}
              />
            )}
          </div>
          {(isEditForm) ? (
              <ReplayEdit>
                <div>
                  <textarea placeholder='댓글을 남겨주세요.' value={editData.comment} onChange={(e)=>handleWriteOnChange('comment', e.target.value)} />
                </div>
                <div className='replayWriteFooter'>
                  <span>{editData?.comment.length}/300</span>
                  <div>
                    <button type='button' onClick={()=>handleCancelEditButton()}>취소</button>
                    <button type='button' onClick={()=>handleSaveEditReplyFormOnClick(editData)}>수정</button>
                  </div>
                </div>
              </ReplayEdit>
            ) : (
              <>
                <div className='infoDescription'>
                  {editData.comment}
                </div>
                <div className='infoFooter'>
                  <span className='postedDate'>{elapsedText(editData?.postDate)}</span>
                </div>
              </>
            )
          }
        </Info>
      </div>
    </ReplyItem>
  );
};

const ReplyItem = styled.li`
  padding: 1.2rem 0;
  width: 100%;
  height: auto;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.replyCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
  }
  &:last-child {
    border-bottom: 0rem solid #F5F5F5;
  }
`;

const Info = styled.div`
  padding-top: 1rem;
  width: 100%;
  height: auto;
  & div.infoHeader {
    width: auto;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & span {
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
    }
    & img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  & div.infoDescription {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    white-space: break-spaces;
    color: #000000;
  }
  & div.infoFooter {
    display: flex;
    align-items: center;
    & span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
  }
  & div.infoButtonGroup {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border: 0.1rem solid #F5F5F5;
    border-radius: 0.4rem;
    & input {
      width: 83%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      border: 0;
    }
    & input:focus-visible {
      outline: none;
    }
    & button {
      margin-left: 0.5rem;
      width: 5.4rem;
      height: 3rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #FD6258;

      background: #FFEAE9;
      border: 0;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }
`;

const ReplayEdit = styled.div`
  width: 100%;
  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & input {
      margin: 1rem 0rem;
      padding: 0 1rem;
      width: 42%;
      height: 4rem;
      border: 0.1rem solid #F5F5F5;
      border-radius: 0.4rem;
    }
    & input:focus {
      outline: none;
    }
    & textarea {
      padding: 1rem;
      width: 100%;
      height: 14.4rem;
      outline: none;
      resize: none;
      border: 1px solid #F5F5F5;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
  }
  & div.replayWriteFooter {
    height: 4.6rem;
    display: flex;
    align-items: center;
    & span {
      margin-left: 1.5rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
    & div {
      width: 12.5rem;
      & button {
        width: 5.4rem;
        height: 3rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #616161;
        background-color: #F5F5F5;
        border: 0.1rem solid #F5F5F5;
        border-radius: 0.4rem;
        cursor: pointer;
      }
      & button:last-child {
        color: #FD6258;
        background-color: #FFEAE9;
        border: 0.1rem solid #FFEAE9;
      }
    }
  }
`;