import React from 'react';
import styled from 'styled-components';

export const HomestayCustomCheckbox = React.memo(({label, valuesString='', name, value, handleValueOnChange}) => {


  const isChecked = (str) => {
    if(!valuesString)
      return false;
    return (valuesString.indexOf(str)>=0)?true:false
  }

  const handleOnChange = () => {
    if(isChecked(value)) {
      handleValueOnChange?.(`${valuesString.replace(value,"")}`);
    } else {
      handleValueOnChange?.(`${valuesString}${value}`);
    }
  }

  return (
    <CustomCheckboxWrapper>
      <input
        className='hiddenCheckbox'
        type="checkbox"
        name={name}
        value={value}
        onChange={()=>handleOnChange()}
        checked={isChecked(value)}/>
      <span className="viewCheckbox"></span>
      <span className="viewLabel">{label}</span>
    </CustomCheckboxWrapper>
  );
});

const CustomCheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  & input[type=checkbox].hiddenCheckbox {
    visibility: hidden;
  }
  & input[type=checkbox].hiddenCheckbox:checked ~ span.viewCheckbox {
    background-color: #FD6258;
    border: 0.1rem solid #EEEEEE;
    border-radius: 0.3rem;
  }
  & input[type=checkbox].hiddenCheckbox:checked ~ span.viewCheckbox:after {
    display: block;
  }
  & span.viewCheckbox {
    position: absolute;
    top: 0;
    left: 0.4rem;
    height: 2rem;
    width: 2rem;
    background-color: #fff;
    border: 0.1rem solid #EEEEEE;
    border-radius: 0.2rem;
  }
  & span.viewCheckbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  & span.viewCheckbox:after {
    left: 0.6rem;
    bottom: 0.6rem;
    width: 0.5rem;
    height: 0.9rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  & span.viewLabel {
    position: absolute;
    top: 0;
    left: 3.4rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;