import React from 'react';
import styled from 'styled-components';
import { CommonFooter } from '../common/CommonFooter';
import { MarketHeaderForDetail } from '../domain/market/component/MarketHeaderForDetail';

export const MarketDetailLayout = ({
    title,
    children,
    cancelLink,
    showBackButton=false,
    showCancelButton=false,
  }) => {
  return (
    <>
      <MarketWrapper>
        <MarketHeaderForDetail
          title={title}
          cancelLink={cancelLink}
          showBackButton={showBackButton}
          showCancelButton={showCancelButton}
        />
        <main>
          {children}
        </main>
        <CommonFooter />
      </MarketWrapper>
    </>
  );
}

const MarketWrapper = styled.div`
  position: relative;
`;