import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInterval } from '../../hooks/useInterval';
import { AdsApi } from './api/AdsApi';

export const LeftMenuAd = ({ position, listSize=1 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});
  const [page, setPage] = useState(0);

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(
      position,
      listSize,
      (data)=> {
        setAds(data);
      },
      (error)=> {
        console.log(error);
      }
    )
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(!id) return;
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  const handleNextClick = () => {
    if(page + 1 < ads?.list.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  useInterval(()=> {
    handleNextClick();
  }, (1<ads?.list.length) ? 6000 : null);

  useEffect(() => {
    handleAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BannerWrapper>
      {(0 < ads?.list.length) && (
        <SlideList>
          {ads.list.map((row, index) => (
            <Item key={row.id} opacity={(index===page)? 1 : 0}>
              <img
                src={`${row.imageFile1}`}
                alt={`${row.title}`}
                onClick={()=>handleOpenDetail(row)}
                onError={(e)=>handleImageError(e)} />
            </Item>
          ))}
          <NextButton onClick={()=>handleNextClick()}>Next</NextButton>
        </SlideList>
      )}
    </BannerWrapper>
  )
}

const BannerWrapper = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: auto;

  background-color: #fff;
  box-sizing: border-box;
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 9.3rem;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
`;

const Item = styled.li`
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  /* opacity: ${props => props.opacity}; */
  /* transition: all 0.45s ease; */
  z-index: 3;
  & img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    cursor: pointer;
  }
`;

const NextButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.4rem;
  width: 4rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  text-align: center;
  color: #FFFFFF;
  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 0 0.4rem;
  cursor: pointer;
  z-index: 10;
`;