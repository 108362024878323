import qs from "qs";
import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";

export class RentApi {

  fetchRentList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/rent`,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      params: searchData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchRentById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/rent/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchRentEditById(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/rent/${id}/edit`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  checkRentPermission(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/rent/${id}/check-permission`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch(error => {
      errorCallback?.(error);
    })
  }

  createRentForStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/rent`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createRentForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/rent/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveRentForStep1(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/rent/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveRentForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/rent/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteRent(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/rent/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createRentImageFile(id, formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/rent/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteRentImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/rent/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveRentNewPassword(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/rent/${id}/password`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}