import React from 'react';
import styled from 'styled-components';
import { CommonFooter } from '../common/CommonFooter';
import { NewsHeader } from '../domain/news/component/NewsHeader';
import { NewsTopMenu } from '../domain/news/component/NewsTopMenu';

export const NewsListLayout = ({children}) => {
  return (
    <>
      <NewsTopHeader>
        <NewsHeader />
        <NewsTopMenu />
        {children}
        <CommonFooter />
      </NewsTopHeader>
    </>
  );
}

const NewsTopHeader = styled.div`
  position: relative;
`;