import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';

export const MainNewsList = React.memo(({newsList, title, showTitle, link}) => {
  return (
    <>
      <MainNewsSection>
        {0 < newsList.length ? (
          <NewsMain>
            <Header>
              {showTitle &&
                <Link to={`/${link}`}>
                  <h2>{title}</h2>
                </Link>
              }
            </Header>
            <ul>
              {newsList.map((row) => (
                <li key={row.id}>
                  <Link to={`/${link}/${row.id}`}>
                    <h3>{row.tagName ? (<span>[{row.tagName?.toUpperCase()}]</span>):''}{row.title}</h3>
                  </Link>
                </li>
              ))}
            </ul>
          </NewsMain>
        ) : (
          <SkeletonComponent>
            <SkeletonSection>
              <ul>
                <li><div></div></li>
                <li><div></div></li>
                <li><div></div></li>
                <li><div></div></li>
                <li><div></div></li>
                <li><div></div></li>
              </ul>
            </SkeletonSection>
          </SkeletonComponent>
        )}
      </MainNewsSection>
    </>
  )
});

const MainNewsSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const Header = styled.div`
  & h2 {
    margin: 0;
    padding: 2rem 0 1.6rem;

    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;

const NewsMain = styled.div`
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      padding: 1.3rem 0;
      border-bottom: 0.1rem solid #F5F5F5;
      & a {
        color: #000;
        text-decoration: none;
      }
      & h3 {
        margin: 0;
        width: inherit;

        font-size: 14px;
        font-weight: 400;
        line-height: 1.8rem;
        letter-spacing: -0.04em;
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & span {
        margin-right: 0.5rem;
        font-weight: 500;
        color: #de2b36;
      }
    }
    & li:last-child {
      border-bottom: 0 solid #F5F5F5;
    }
  }
`;

const SkeletonSection = styled.div`
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      padding: 1.3rem 0;
      border-bottom: 0.1rem solid #F5F5F5;
      & div {
        display: block;
        width: 90%;
        height: 1.8rem;
        background-color: #F5F5F5;
      }
    }
    & li:last-child {
      border-bottom: 0 solid #F5F5F5;
    }
  }
`;