import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { removeHtmlTags, elapsedText } from '../../../utils/utils';

export const NewsTextListComponent = memo(({ list, link, isLoading, itemOnClick }) =>{

  const handlerItemCardOnClick = (url) => {
    itemOnClick?.(url);
  }

  return (
    <NewsTextListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <TextItemSection>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
            <TextItem>
              <div style={{height: '2rem', backgroundColor: '#F5F5F5'}}/>
            </TextItem>
          </TextItemSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData></NoData>
          ):(
            <TextItemSection>
              {list.map((row) => (
                <TextItem key={row.id} onClick={()=>handlerItemCardOnClick(`/${link}/${row.id}`)}>
                  <div className='title'>
                  {row?.boardName && `[${row?.boardName}] `} {row.title}
                  </div>
                  <div className='description'>
                    {removeHtmlTags(row.description)}
                  </div>
                  <div className='updatedDate'>
                    {`${elapsedText(row.updatedDate)} | ${row.reporter}`}
                  </div>
                </TextItem>
              ))}
            </TextItemSection>
          )}
        </>
      )}
    </NewsTextListWrapper>
  );
});

const NewsTextListWrapper = styled.div`
  width: 100%;
`;

const TextItemSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const TextItem = styled.li`
  padding: 2rem 0;
  border-bottom: 0.1rem solid #eee;
  cursor: pointer;
  & div.title {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & div.description {
    padding: 1rem 0;
    width: 100%;
    height: 3rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #BDBDBD;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  & div.updatedDate {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
  }
  &:last-child {
    border-bottom: 0rem solid #eee;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;