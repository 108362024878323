import { jsonApi } from "../../../utils/AxiosApi";

export class ItemApi {

  fetchItemById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/item/${id}`,
    }).then((response) => {
      callback?.(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

}