import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../common/Loader';
import { JobApi } from '../api/JobApi';
import { addHyphenForPhoneNumber, elapsedText, priceToString } from '../../../utils/utils';
import {
  JobCareerType,
  JobCategories,
  JobEmploymentType,
  JobSalaryRate
} from '../../../__mocks__/PostData';


export const JobDetail = ({setDialog}) => {
  const jobApi = new JobApi();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewEditConformGroup, setViewEditConformGroup] = useState(false);
  const [viewDeleteConformGroup, setViewDeleteConformGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    id: '',
    boardId: 7,
    active: 0,
    type: '',
    name: '',
    phone: '',
    email: '',
    title: '',
    description: '',
    contactCode: '',
    categoryCode: '',
    employmentType: 'both',
    careerType: 'both',
    location: '1',
    salaryRate: 'deal',
    price: 0,
    endRecruitmentDate: '',
    isSoldOut: 0,
    isOwner: false,
    isPremium: false,
    premiumStart: '',
    premiumEnd: '',
    updateDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------

  // handler ------------------------------------------------
  const handleSearchClick = () => {
    jobApi.fetchJobById(id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        // setDialog({isOpen: true, message: "현재 등록된 게시물이 없습니다.", type: "alert"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate("/job", {state: {isBack: true}});
  }

  const handleViewEditConformOnClick = () => {
    setViewEditConformGroup((value) => !value);
    if(viewDeleteConformGroup)
      handleViewDeleteConformOnClick();
  }

  const handleEditOnClick = () => {
    if(article.id) {
      navigate(`/job-edit/${article.id}`,  {
        state: {
          id: article.id,
        },
      });
    }
  }

  const handleViewDeleteConformOnClick = () => {
    setViewDeleteConformGroup((value) => !value);
    if(viewEditConformGroup)
      handleViewEditConformOnClick();
  }

  const handleDeleteOnClick = () => {
    if(article.id && window.confirm("정말 삭제하시겠습니까?")) {
      setLoading(true);
      jobApi.deleteJob(article.id,
        (data) => {
          setLoading(false);
          setDialog({isOpen: true, message: "삭제 되었습니다.", type: "alert"});
          navigate('/job');
        },
        (error)=> {
          setLoading(false);
          console.log(error);
        }
      );
    }
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-job.png`;
  }


  // ==== valid check ---------------------------------
  const isSeekPerson = () => {
    return ('구인' === article.type) ? true : false;
  }
  const isSeekJob = () => {
    return ('구직' === article.type) ? true : false;
  }

  // render -------------------------------------------------
  const addImageBox = (index) => {
    const domain = article.images[index]['domain'];
    const filePath = article.images[index]['filePath'];
    const fileName = article.images[index]['fileName'];
    return (
      <div key={index}>
        {fileName && (
          <div className="preview-image-box">
            <img src={`${domain}${filePath}${fileName}`} alt={`이미지${index}`} onError={(e)=>handleImageError(e)} loading='lazy' />
          </div>
        )}
      </div>
    );
  }

  const addPremiumTag = ({isPremium}) => {
    if(isPremium) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addTypeTag = ({isPremium}) => {
    if(isPremium) return '';
    if(isSeekPerson()) {
      return (<img className='jobType' src='/icon/job-offer-icon.png' alt='미사용' />);
    } else if(isSeekJob()) {
      return (<img className='jobType' src='/icon/job-search-icon.png' alt='상태좋음' />);
    }
    return '';
  }

  const addJobSearchDoneTag  = ({isSoldOut}) => {
    if(isSoldOut)
      return (<img className='jobSearchDone' src='/icon/job-search-done-icon.png' alt='구직완료' />);
    return '';
  }

  const addLocationNContactTag = ({premium, hitCount, updateDate}) => {
    if("1" === premium) {
      return ''
    } else {
      return (<span>{elapsedText(updateDate)} · 조회수 {hitCount}</span>)
    }
  }

  const addContactInfoTag = ({contactCode, phone, email}) => {
    return (
      <>
        {('phone' === contactCode) ? (
          addHyphenForPhoneNumber(phone)
        ) : ('email' === contactCode) ? (
          email
        ) : ('both' === (contactCode||'both')) ? (
          <>
            {addHyphenForPhoneNumber(phone)}
            <br/>
            {email}
          </>
        )  : '-'
        }
      </>
    )
  }

  const addPriceTag = ({salaryRate, price}) => {
    const displayPrice = ('deal' === (salaryRate||'deal'))
      ? ''
      : (0<price)
        ? priceToString(price)
        : '';
    return (
      <>
        {`${JobSalaryRate[salaryRate||'deal']?.label} ${displayPrice}`}
      </>
    );

  }

  const renderCategory = ({categoryCode}) => {
    const label = categoryCode ? JobCategories[categoryCode]?.label : categoryCode;
    return (
      <>
        {(label) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='angle right'/>
            <span className='categoryLv1'>{label}</span>
          </>
        )}
      </>
    );
  }

  const renderDeleteEditButton = ({isOwner}) => {
    if(!isOwner) return '';
    return (
      <ConformGroup>
        <ButtonGroup>
          <ButtonStyle type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>수정하기</ButtonStyle>
          <ButtonStyle type='button' color="#fd6258" backgroundColor='#fff' onClick={() => handleDeleteOnClick()}>삭제하기</ButtonStyle>
        </ButtonGroup>
      </ConformGroup>
    );
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <ArticleTag>
            <div>
              {addPremiumTag(article)}
              {addTypeTag(article)}
              {addJobSearchDoneTag(article)}
            </div>
            <span>{`게시글번호 ${article.id}`}</span>
          </ArticleTag>
          <Title>{article.title}</Title>
          <UpdateInfo>
            {addLocationNContactTag(article)}
          </UpdateInfo>
          <Info>
            <table>
              <tbody>
                <tr>
                  <td className='head'>
                    {isSeekPerson()
                      ?`업체명`
                      :`${article.type}자`
                    }
                  </td>
                  <td className='col'>{article.name?article.name:'-'}</td>
                </tr>
                  <td className='head'>{`${article.type}문의`}</td>
                  <td className='col'>{addContactInfoTag(article)}</td>
                <tr>
                  <td className='head'>근무지역</td>
                  <td className='col'>{article.locationKrName?article.locationKrName:'-'}</td>
                </tr>
                <tr>
                  <td className='head'>카테고리</td>
                  <td className='col'>
                    <Category>
                      <span className='categoryLv0'>전체</span>
                      {renderCategory(article)}
                    </Category>
                  </td>
                </tr>
              </tbody>
            </table>
          </Info>
          <Title>한 눈에 보는 구인정보</Title>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/job-career-icon.png' alt='경력'/>
                      </div>
                      <div className='label'>경력</div>
                      <div className='value'>
                        {JobCareerType[article.careerType]?.label?JobCareerType[article.careerType]?.label:'-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/job-wage-icon.png' alt='학습인원'/>
                      </div>
                      <div className='label'>
                        {isSeekPerson()?'임금':'희망임금'}
                      </div>
                      <div className='value'>
                        {addPriceTag(article)}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/job-type-icon.png' alt='고용형태'/>
                      </div>
                      <div className='label'>고용형태</div>
                      <div className='value'>
                        {article.employmentType
                          ? JobEmploymentType[article.employmentType]?.label
                          : '협의 가능'
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img src='/icon/job-deadline-icon.png' alt='구직마감일'/>
                      </div>
                      <div className='label'>{`${article.type}마감일`}</div>
                      <div className='value'>
                        {article.endRecruitmentDate
                          ? article.endRecruitmentDate
                          : `상시${article.type}`
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <ContentTitle>
            {`${article.type}설명`}
          </ContentTitle>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
          <ImageWrapper>
            {Object.keys(article.images).map((key) => addImageBox(key))}
          </ImageWrapper>
        </ContentWrapper>
        <Separator height={'0.1rem'}/>
        { renderDeleteEditButton(article) }
        <Loader loading={loading} />
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0.5rem 0;
  width: auto;
  background-color: #fff;
  border-top: 0.1rem solid #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding: 2rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ArticleTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div {
    & img {
      display: inline-block;
      margin-right: 0.6rem;
      height: 1.8rem;
    }
    & img.premiumIcon {
      width: 5rem;
    }
    & img.jobType {
      width: 3.2rem;
      height: 1.8rem;
    }
    & img.jobSearchDone {
      width: 5rem;
      height: 1.8rem;
    }
    & img.purchaseIcon {
      height: 1.8rem;
    }
  }

  & span {
    margin-left: 1.5rem;
    padding: 0.2rem 0.8rem;
    display: inline-block;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 300px;
  }
`;

const Title = styled.h1`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  word-break: break-all;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;
const Info = styled.div`
  margin: 1.7rem 0;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    & td {
      font-size: 14px;
    }
    & td.head {
      width: 30%;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      vertical-align: top;
      color: #000;
    }
    & td.col {
      width: 70%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;
    }
    & td.left {
      text-align: left;
    }
    & td.right {
      text-align: right;
    }
  }
`;

const PreviewInfo = styled.div`
  margin: 0rem 0;
  border: 0rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 0rem 0rem;
    width: 100%;
    & tr {
      width: 100%;
      height: 1.1rem;
      & td {
        width: 8.3rem;
        & div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & div.icon {
            width: 4.2rem;
            height: 4.2rem;
            & img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
          & div.label {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #424242;
          }
          & div.value {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            display: flex;
            flex-direction: row;
            color: #9E9E9E;
            & span {
              margin: 0 0.3rem;
            }
          }
        }
      }
    }
  }
`;

const Category = styled.div`
  width: auto;
  height: 2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0rem solid #f5f5f5;
  & span.categoryLv0 {
    color: #424242;
  }
  & span.categoryLv1 {
    font-weight: 500;
    color: #FD6258;
  }
  & img {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const Content = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;

const ContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;

`;


const ImageWrapper = styled.section`
  margin-bottom: 0.5rem;
  border-bottom: 0.1 dashed #ddd;
  & p {
    padding: 0.75rem 1.25rem;
    color: #721c24;
    font-size: 14px;
    font-weight: 500;
    background-color: #F8D7DA;
  }
  & div {
    margin: 1rem 0;
    & label.form-label-button {
      padding: 0.6rem 2.5rem;
      height: 2.4rem;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      font-weight: 700;
      color: white;

      background-color: #b1b1b1;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    & div.preview-image-box {
      position: relative;
      margin-top: 1rem;
      width: 100%;
      /* height: 33.8rem; */
      /* border: 0.1rem solid #ddd; */
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & button {
        position: absolute;
        right: 0px;
        z-index: 100;
      }
    }
  }
`;

const ConformGroup = styled.div`
  padding: 1.6rem;
  border-top: 0.1rem solid #f5f5f5;
`;
const ButtonGroup = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  column-gap: 0.6rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;

`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #fd6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;
