import React, { useState } from 'react';
import styled from 'styled-components';

export const ImageSlideComponent = ({images, onClick, noImgUrl}) => {
  const [page, setPage] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(null);

  const handleImageError = (e) => {
    (noImgUrl)
      ? e.target.src=noImgUrl
      : e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = () => {
    onClick?.();
  }

  const handleNextClick = () => {
    if(page + 1 < Object.keys(images)?.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  const handlePreviousClick = () => {
    if(0 === page) {
      setPage(Object.keys(images)?.length-1);
    } else {
      setPage(page - 1);
    }
  }

  const handleOnTouchStart = (e) => {
    if(2>Object.keys(images)?.length) return;
    setSliderPosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    });
  }

  const handleOnTouchEnd = (e) => {
    if(2>Object.keys(images)?.length) return;
    moveSlide(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
  }

  const handleOnMouseDown = (e) => {
    if(2>Object.keys(images)?.length) return;
    setSliderPosition({
      x: e.clientX,
      y: e.clientY
    });
  }

  const handleOnMouseUp = (e) => {
    if(1<Object.keys(images)?.length) {
      moveSlide(e.clientX, e.clientY);
    } else {
      handleOpenDetail();
    }
  }

  const moveSlide = (clientX, clientY) => {
    const distanceX = Math.abs(sliderPosition.x - clientX);
    const distanceY = Math.abs(sliderPosition.y - clientY);

    if((distanceY + distanceX > 20) && (distanceX > distanceY)) {
      if(sliderPosition.x - clientX < 0) {
        handlePreviousClick();
      } else if(sliderPosition.x - clientX > 0) {
        handleNextClick();
      }
    } else {
      handleOpenDetail();
    }
  }

  return (
    <ImageSlideWrapper>
      {(images && 0 < Object.keys(images).length) ? (
        <>
          <SlideList translateX={page}
            onTouchStart={handleOnTouchStart}
            onTouchEnd={handleOnTouchEnd}
            onMouseDown={handleOnMouseDown}
            onMouseUp={handleOnMouseUp}
          >
            {Object.values(images)?.map((row) => (
              <li key={row.fileId}>
                <Item>
                  <img src={`${row.domain}${row.filePath}${row.fileName}`}
                    alt={`${row?.seq}`}
                    onError={(e)=>handleImageError(e)}
                  />
                </Item>
              </li>
            ))}
          </SlideList>
          <SlidePageList>
            { 1 < Object.keys(images).length && Object.keys(images).map((key, index) => (
              <li key={key}>
                <PageLabel backgroundColor={(page === index)? '#424242' : '#C4C4C4'}></PageLabel>
              </li>
            ))}
          </SlidePageList>
        </>
      ) : (
        <>
          <Item>
            <img src={noImgUrl ? noImgUrl : '/img/no-image.png'} alt='' />
          </Item>
        </>
      )}
    </ImageSlideWrapper>
  );
};

const ImageSlideWrapper = styled.section`
  position: relative;
  margin: 0;
  padding: 0;
  width: auto;
  min-height: 37.5rem;
  max-height: 41.4rem;
  overflow: hidden;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
  transform: ${props => `translateX(${props.translateX * -100}%)`};
  transition: all 0.45s ease;
  & li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Item = styled.div`
  position: relative;
  padding: 0;
  cursor: pointer;
  & img {
    width: 100%;
    height: 37.5rem;
    object-fit: cover;
    object-position: center;
    border: 0rem solid #f5f5f5;
    -webkit-user-drag: none;
  }
`;

const SlidePageList = styled.ul`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%, 0);

  margin: 0;
  padding: 0;
  text-align: center;
  & li {
    display: inline-block;
    vertical-align: middle;
  }
`;

const PageLabel = styled.label`
  margin: 0rem 0.4rem;
  padding: 0.3rem;
  display: block;
  background: ${props => props.backgroundColor};
  border-radius: 3rem;
  cursor: pointer;
`;