import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { addHyphenForPhoneNumber, elapsedText, priceToString } from '../../../utils/utils';

export const MarketPostListComponent = memo(({ list, link, isLoading=true, postOnClick}) => {

  const handlerPostOnClick = (url, extUrl) => {
    if(extUrl) {
      window.open(`${extUrl}`);
    }
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    if('buysell' === link) {
      e.target.src=`/img/no-image-buysell.png`;
    } else {
      e.target.src=`/img/no-image.png`;
    }
  }

  const isPremium = (premium) => {
    return ("1" === premium) ? true : false;
  }

  const addPremiumTag = ({premium}) => {
    if(isPremium(premium)) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addConditionTag = ({premium, conditionCode}) => {
    if(isPremium(premium)) return '';
    if("new" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/new-condition-icon.png' alt='미사용' style={{width: '4.2rem', height: '1.8rem'}}/>);
    } else if("good" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/good-condition-icon.png' alt='상태좋음' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("used" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/used-condition-icon.png' alt='사용감 있음' style={{width: '6rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addPurchaseTag = ({premium, purchaseStateCode}) => {
    if(isPremium(premium)) return '';
    if("sold-out" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/sold-out-status-icon.png' alt='판매완료' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("trading" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/reserved-status-icon.png' alt='거래중' style={{width: '4rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addTitleTag = ({premium, title}) => {
    if(isPremium(premium)) {
      return <h2 className='premiumTitle'>{title}</h2>;
    } else {
      return (<h2>{title}</h2>);
    }
  }

  const addLocationNContactTag = ({premium, phone, locationKrName, updatedDate}) => {
    if(isPremium(premium)) {
      return (
        <span>
          <img src='/icon/phone-icon.png' alt='Phone Number'/>
          {addHyphenForPhoneNumber(phone)}
        </span>);
    } else {
      return (<span>{locationKrName ? locationKrName+" · ":''}{elapsedText(updatedDate)}</span>);
    }
  }

  const addPriceTag = ({premium, price, type}) => {
    if(isPremium(premium)) {
      return '';
    } else if('무료나눔' === type) {
      return (
        <div className='price'>{type}</div>
      )
    } else {
      return (
        <div className='price'>
          {0 < price ? priceToString(price) : '가격문의'}
        </div>
      )
    }
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
            <PostItem>
              <div className="itemCard">
                <div style={{width: '15.2rem', height: '11.4rem', backgroundColor: '#F5F5F5', borderRadius: '0.4rem'}} />
                <InfoWrapper>
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                  <div style={{margin: '0.2rem', width: '100%', height: '20px', backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </PostItem>
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {0 < list.length ? (
            <PostSection>
              {list.map((row)=>(
                <PostItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`, row.link)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper>
                      {addPremiumTag(row)}
                      <div>
                        {addConditionTag(row)}
                        {addPurchaseTag(row)}
                      </div>
                      {addTitleTag(row)}
                      {addLocationNContactTag(row)}
                      {addPriceTag(row)}
                    </InfoWrapper>
                  </div>
                </PostItem>
              ))}
            </PostSection>
          ):(
            <NoData>데이터가 없습니다.</NoData>
          )}
        </>
      )}
    </PostListWrapper>
  );
});

const PostListWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PostItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
  border-bottom: 0rem solid #ddd;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 15.2rem;
  height: 11.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 16.7rem;
  right: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  word-spacing: 1pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & h2 {
    margin: 0;
    width: inherit;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & h2.premiumTitle {
    margin: 0;
    width: inherit;
    height: 3.7rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;

    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
  & div {
    display: block;

    font-size: 12px;
    font-weight: 400;
    line-height: 2rem;
    color: #9e9e9e;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & span {
    display: block;

    font-size: 12px;
    font-weight: 400;
    line-height: 2rem;
    color: #9e9e9e;

    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & img {
      margin-right: 0.3rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & span:first-child {
    font-weight: 500;
    color: #3eac3e;
  }
  & div.price {
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    color: #000;
  }
  & img.premiumIcon {
    width: 5rem;
    height: 1.8rem;
  }
  & img.conditionIcon {
    display: inline-block;
    margin-right: 0.4rem;
    height: 1.8rem;
  }
  & img.purchaseIcon {
    display: inline-block;
    height: 1.8rem;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;