import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { elapsedText, priceToString } from '../../../utils/utils';

export const MainCarsForNews = ({list}) => {
  const navigate = useNavigate();
  const [setSwiperRef] = useState(null);

  const handleButtonLinkOnClick = (e, url) => {
    navigate(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  return (
    <MainCarSection>
      <Header>
        <h2>자동차</h2>
        <span><Link to={`/car`}>전체보기</Link></span>
      </Header>
      <MySwiper
        onSwiper={setSwiperRef}
        slidesPerView={2}
        spaceBetween={16}
        freeMode={true}
        >
        {list.map((row) => (
          <SwiperSlide key={row.id}>
            <div className='item' onClick={(e)=>handleButtonLinkOnClick(e, `/car/${row.id}`)}>
              <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading='lazy' />
              <h3>{row.title}</h3>
              <div>
                <span className='location'>{row.locationKrName}</span>
                <img className='period' src='/icon/period-icon.png' alt='period'/>
                <span className='date'>{elapsedText(row?.updatedDate)}</span>
              </div>
              <div>
                <span className='price'>
                  {(0<row?.price)
                    ? priceToString(row?.price)
                    : '가격문의'
                  }
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </MySwiper>
    </MainCarSection>
  )
};

const MainCarSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 2rem 1.6rem;
  background-color: #fff;
  box-sizing: border-box;
`;

const MySwiper = styled(Swiper)`
  margin: 0;
  padding: 0;
  width: auto;
  & div.item {
    width: 100%;
    height: auto;
    cursor: pointer;
    & img {
      width: 100%;
      height: 10.4rem;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    & h3 {
      margin: 0;
      width: auto;
      height: 2rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    & div {
      display: flex;
      align-items: center;
      & img.period {
        margin: 0 0.3rem;
        width: 0.2rem;
        height: 0.2rem;
      }
      & span.location {
        width: auto;
        height: 2rem;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.7rem;
        color: #9e9e9e;
      }
      & span.date {
        width: auto;
        height: 2rem;
        font-size: 12px;
        line-height: 1.7rem;
        color: #8f8f8f;
      }
      & span.price {
        width: auto;
        height: 2rem;
        font-size: 16px;
        font-weight: 700;
        line-height: 2.3rem;
      }
    }
  }
`;

const Header = styled.div`
  padding: 2rem 0 1.6rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;