
export const openErrorModalForApi = (error, setDialog) => {
  const status = error?.response?.status;
  if(503 === status) {
    setDialog({isOpen: true, message: "일시적인 오류로 서비스 접속에 실패했습니다.", type: "nonHeader"});
  } else {
    if(error?.response) {
      const {response: {data}} = error;
      setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
    } else if(error.message) {
      setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
    }
  }
};