import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { elapsedText } from '../../../utils/utils';

export const MainJobs = ({list}) => {
  return (
    <>
      <MainJobSection>
        <Header>
          <h2>구인구직 정보를<br/>찾고 계신가요?</h2>
          <span><Link to={`/job`}>전체보기</Link></span>
        </Header>
        <ul>
          {list?.map((row) => (
            <li key={row.id}>
              <Link to={`/job/${row.id}`}>
                <ItemWrapper>
                  <div className='itemDetail'>
                    <h3>{row.name}</h3>
                    <div className='title'>{row.title}</div>
                    <div className='info'>
                      <span className='location'>{row.locationKrName}</span>
                      <img className='period' src='/icon/period-icon.png' alt='period'/>
                      <span className="postDate">{elapsedText(row.updateDate)}</span>
                    </div>
                  </div>
                </ItemWrapper>
                <span className="postType">구인</span>
              </Link>
            </li>
          ))}
        </ul>
      </MainJobSection>
    </>
  )
};

const MainJobSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem 2.4rem 1.6rem;
  width: auto;
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      position: relative;
      margin: 0;
      padding: 1.4rem 0 1.3rem 0;
      height: 6.2rem;
      font-size: 17px;
      border-bottom: 0.1rem solid #f5f5f5;
      & a {
        color: #000;
        text-decoration: none;
      }
      & span.postType {
        position: absolute;
        bottom: 1.4rem;
        right: 0;
        width: 5.6rem;
        height: 1.8rem;

        font-size: 12px;
        font-weight: 500;
        line-height: 1.8rem;
        color: #fd6258;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 0.1rem solid #ffb0ab;
        border-radius: 0.2rem;
      }
    }
    & li:last-child {
      border-bottom: 0 solid #ddd;
    }
  }
`;

const Header = styled.div`
  position: relative;
  padding: 2.4rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
  }
  & span {
    height: 1.7rem;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    & a {
      color: #9e9e9e;
      text-decoration: none;
    }
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div.itemDetail {
    position: relative;
    width: 78%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      margin: 0;
      padding: 0;
      width: inherit;
      height: 2rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #000000;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div.title {
      height: 2rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div.info {
      margin-top: 0.5rem;
      height: 1.7rem;
      display: flex;
      align-items: center;
      & span.location {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.7rem;
        letter-spacing: -0.04em;
        color: #9e9e9e;
      }
      & span.postDate {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.7rem;
        letter-spacing: -0.04em;
        color: #9e9e9e;
      }
      & img.period {
        margin: 0 0.4rem;
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }

`;
