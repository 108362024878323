import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdsApi } from './api/AdsApi';

export const NewsPopupModal = ({ show, toggle, position, savedName, listSize=1 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(position, listSize, (data)=> {
      setAds(data);
    })
  }

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerTodayCloseOnClick = () => {
    let expires = new Date();
    localStorage.setItem(savedName, expires.setHours(expires.getHours() + 24));
    handleToggleModal?.();
  }

  const handlerCloseOnClick = () => {
    handleToggleModal?.();
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-img-news.png`;
  }

  const openDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  useEffect(() => {
    handleAdsSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      {show && (0< ads?.list.length) ? (
        <Modal>
          <ModalBody width={'100%'}>
            <PopupWrapper>
                {ads?.list.map((row) => (
                  <Item key={row.id} onClick={()=>openDetail(row)}>
                    <img className={'popupImage'} src={`${row.imageFile1}`} alt={row.title} onError={(e) => handleImageError(e)} />
                  </Item>
                ))}
              <PopupBottom>
                <div onClick={handlerTodayCloseOnClick}>오늘 하루 보지않기</div>
                <div onClick={handlerCloseOnClick}>닫기</div>
              </PopupBottom>
            </PopupWrapper>
          </ModalBody>
        </Modal>
      ) : ''
      }
    </>
  );
}
const Modal = styled.div`
  z-index: 98;
  position: fixed;
  bottom: 0;
  left: 50%;
  max-width: 41.6rem;
  width: 100%;
  height: 9.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  bottom: 0rem;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
`;

const PopupWrapper = styled.div`
  position: relative;
`;

const Item = styled.div`
  position: relative;
  width: 100%;
  height: 7.5rem;
  cursor: pointer;
  & img.popupImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    -webkit-user-drag: none;
  }
`;

const PopupBottom = styled.div`
  padding: 0 1.6rem;
  width: auto;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  & div {
    font-weight: 400;
    font-size: 12px;
    line-height: 2rem;
    cursor: pointer;
  }
`;