import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";

export class JobApi {

  fetchJobList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/job`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchJobById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/job/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchJobEditById(id, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/job/${id}/edit`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  checkJobPermission(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/job/${id}/check-permission`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveJobNewPassword(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/job/${id}/password`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createJobForStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/job`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createJobForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/job/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveJobForStep1(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/job/${id}/step1`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveJobForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/job/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteJob(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/job/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createJobImageFile(id, formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/job/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteJobImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/job/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}