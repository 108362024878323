import React, { useState } from 'react';
import styled from 'styled-components';

export const RealtyAccordionComponent = ({children}) => {
  const [isMore, setIsMore] = useState(true);

  const toggleShowMore = () => {
    setIsMore(!isMore);
  }
  return (
    <>
      <RealtyAccordionComponentWrapper>
        <Content height={isMore ? '8.4rem': '100%'}>
          {children}
        </Content>
        {isMore ? (
          <MoreButton onClick={()=>toggleShowMore()}>
            더보기
            <img src='/icon/angle-down-black-m-icon.png' alt='더보기 버튼' />
          </MoreButton>
        ) : (
          <MoreButton onClick={()=>toggleShowMore()}>
            접기
            <img src='/icon/angle-up-black-m-icon.png' alt='접기 버튼' />
          </MoreButton>
        )}
      </RealtyAccordionComponentWrapper>
    </>
  );
}

const RealtyAccordionComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Content = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${({ height }) => height};
  overflow: hidden;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MoreButton = styled.div`
  margin: 3rem 0;
  padding: 0;
  width: 100%;
  height: 4.2rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;

  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: -0.04em;
  color: #424242;
  border: 1px solid #E0E0E0;
  border-radius: 30rem;
  & img {
    margin: 0.3rem 0 0 0.3rem;
    padding: 0;
    width: 1.2rem;
    height: 1.2rem;
  }
`;