import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const MarketHeaderForDetail = ({title, backLink, cancelLink, showBackButton=false, showCancelButton=false}) => {
  const navigate = useNavigate();
  const DEFAULT_LINK = '/market';

  const handleBackLinkOnClick = () => {
    if(backLink) {
      navigate(`${backLink}`, {replace : true});
      return;
    }
    if(1<window.history.length){
      navigate(-1, {replace : true});
      return;
    } else {
      navigate(`${DEFAULT_LINK}`, {replace : true});
      return;
    }
  }

  const handleCancelLinkOnClick = () => {
    if(window.confirm("정말로 나가시겠습니까?")) {
      if(cancelLink) {
        navigate(cancelLink, {replace : true});
      } else {
        navigate(`${DEFAULT_LINK}`, {replace : true});
      }
    }
  }

  return (
    <DetailHeader>
      {showBackButton && (
        <img className='btnBack' src='/icon/angle-back-black-icon.png' alt='뒤로가기 버튼'
          onClick={()=>handleBackLinkOnClick()}/>
      )}
      <div>{title}</div>
      {showCancelButton && (
        <img className='btnCancel' src='/icon/x-mark.png' alt='취소 버튼'
          onClick={()=>handleCancelLinkOnClick()}/>
      )}
    </DetailHeader>
  );
}

const DetailHeader = styled.div`
  position: relative;
  padding: 0 1.6rem;
  width: auto;
  height: 5.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #F5F5F5;
  & div {
    font-weight: 500;
    font-size: 18px;
    line-height: 5.4rem;
    letter-spacing: -0.04em;
    color: #000;
  }
  & img.btnBack {
    position: absolute;
    top: 1.9rem;
    left: 2rem;
    width: 1.2rem;
    height: 2rem;
    cursor: pointer;
  }
  & img.btnCancel {
    position: absolute;
    top: 1.9rem;
    right: 2rem;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
  }
`;
