import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeadLineNews } from '../component/HeadLineNews';
import { MainNewsList } from '../component/MainNewsList';
import { MainPhotoNews } from '../component/MainPhotoNews';
import { SpecialFeatureArticles } from '../component/SpecialFeatureArticles';
import { MainColumnist } from '../component/MainColumnist';
import { MainInterview } from '../component/MainInterview';
import { MainEducation } from '../component/MainEducation';
import { MainBuySells } from '../component/MainBuySells';
import { MainJobs } from '../component/MainJobs';
import { MainCarsForNews } from '../component/MainCarsForNews';
import { MainNewsTopView } from '../component/MainNewsTopView';
import { MobileNewsBanner } from '../../ads/MobileNewsBanner';
import { NewsPopupModal } from '../../ads/NewsPopupModal';
import { GoogleAdvertiseForInFeed } from '../../ads/GoogleAdvertiseForInFeed';
import { NewsApi } from '../api/NewsApi';
import { BuysellApi } from '../../market/api/BuysellApi';
import { JobApi } from '../../market/api/JobApi';
import { CarApi } from '../../market/api/CarApi';
import { Helmet } from 'react-helmet-async';

export const NewsMain = () => {
  const HAS_VISITED_BEFORE = "hasNewsVisitedBefore";
  const newsApi = new NewsApi();
  const buysellApi = new BuysellApi();
  const jobApi = new JobApi();
  const carApi = new CarApi();
  // const [showNewsPopupBanner, setShowNewsPopupBanner] = useState(true);
  const [showNewsPopupModal, setShowNewsPopupModal] = useState(() => {
    if(typeof window !== "undefined") {
      const hasVisitedBefore = window.localStorage.getItem(HAS_VISITED_BEFORE);
      if(hasVisitedBefore && hasVisitedBefore > new Date()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
  const [headLineNews, setHeadLineNews] = useState({list:[]});
  const [mainANews, setMainANews] = useState({list:[]});
  const [mainBNews, setMainBNews] = useState({list:[]});
  const [mainCNews, setMainCNews] = useState({list:[]});
  // const [mainAdNews, setMainAdNews] = useState({list:[]});
  const [mainPhotoNews, setMainPhotoNews] = useState({list:[]});
  const [specialFeatureArticles, setSpecialFeatureArticles] = useState({list:[]});
  const [koreanList, setKoreanList] = useState({list:[]});
  const [columnistList, setColumnistList] = useState({list:[]});
  const [immigrantList, setImmigrantList] = useState({list:[]});
  const [interviewList, setInterviewList] = useState({list:[]});
  const [koreaList, setKoreaList] = useState({list:[]});
  const [educationList, setEducationList] = useState({list:[]});
  const [buysellList, setBuysellList] = useState({list:[]});
  const [jobList, setJobList] = useState({list:[]});
  const [carList, setCarList] = useState({list:[]});
  const [NewsTabs, setNewsTabs] = useState({ "0": {name: '', list: []} });

  const updateNewsTabs = (key, value) => {
    setNewsTabs((newsTabs) => {
      const updated = {...newsTabs};
      updated[key] = value;
      return updated;
    });
  };

  const handleSearchMainNews = () => {
    newsApi.fetchMainNews2List(
      (data)=>{
        setHeadLineNews({list: data?.result?.newsHeadLine});
        setMainANews({list: data?.result?.newsAPage});
        setMainBNews({list: data?.result?.newsBPage});
        setMainCNews({list: data?.result?.newsCPage});
        // setMainAdNews({list: data?.result?.newsAdPage});
      }
    );
  }
  const handleSearchMainPhotoNews = () => {
    newsApi.fetchPhotoList({ page: 1, listSize: 2 }, (data) => {
      setMainPhotoNews(data);
    });
  }
  const handleSearchSpecialFeatureArticles = () => {
    newsApi.fetchSpecialFeatureArticleList({ page: 1, listSize: 2 }, (data) => {
      setSpecialFeatureArticles(data);
    });
  }
  const handleSearchKoreanList = () => {
    newsApi.fetchKoreanList({ page: 1, listSize: 7 }, (data) => {
      setKoreanList(data);
    });
  }
  const handleSearchColumnistList = () => {
    newsApi.fetchColumnistList({ page: 1, listSize: 5 }, (data) => {
      setColumnistList(data);
    });
  }
  const handleFetchImmigrantList = () => {
    newsApi.fetchImmigrantList({ page: 1, listSize: 5 }, (data) => {
      setImmigrantList(data);
    });
  }
  const handleFetchInterviewList = () => {
    newsApi.fetchInterviewList({ page: 1, listSize: 2 }, (data) => {
      setInterviewList(data);
    });
  }
  const handleSearchKoreaList = () => {
    newsApi.fetchKoreaList({ page: 1, listSize: 5 }, (data) => {
      setKoreaList(data);
    });
  }
  const handleSearchEducationList = () => {
    newsApi.fetchEducationList({ page: 1, listSize: 4 }, (data) => {
      setEducationList(data);
    });
  }
  const handleSearchMarketList = () => {
    buysellApi.fetchBuysellList({ page: 1, listSize: 5, displayPremium: false }, (data) => {
      setBuysellList(data);
    });
    jobApi.fetchJobList({ page: 1, listSize: 5, displayPremium: false }, (data) => {
      setJobList(data);
    });
    carApi.fetchCarList({ page: 1, listSize: 2 }, (data) => {
      setCarList(data);
    });
  }
  const handleSearchNewsTop10TabsList = () => {
    newsApi.fetchTopViewList({ page: 1, listSize: 11 }, (data) => {
      updateNewsTabs("0",{name: '많이 본 뉴스', list: data.list});
    });
    newsApi.fetchNewestList({ page: 1, listSize: 11 }, (data) => {
      updateNewsTabs("1",{name: '최신 뉴스', list: data.list});
    });
  }

  const toggleMarketPopupModal = () => {
    setShowNewsPopupModal(!showNewsPopupModal);
  }

  // const toggleMarketPopupBanner = () => {
  //   setShowNewsPopupBanner(!showNewsPopupBanner);
  // }

  useEffect(() => {
    handleSearchMainNews();
    handleSearchMainPhotoNews();
    handleSearchSpecialFeatureArticles();
    handleSearchKoreanList();
    handleSearchColumnistList();
    handleFetchImmigrantList();
    handleFetchInterviewList();
    handleSearchKoreaList();
    handleSearchEducationList();
    handleSearchMarketList();
    handleSearchNewsTop10TabsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NewsWrapper>
        <Helmet>
          <title>{`밴쿠버 조선일보`}</title>
        </Helmet>
        <main>
          <div>
            <HeadLineNews newsList={headLineNews.list} />
            <MainNewsList newsList={mainANews.list} link={"news"} />
            {/* <MainNewsList newsList={mainAdNews?.list} link={"news"} /> */}
          </div>
          <div>
            <MainPhotoNews list={mainPhotoNews.list} />
          </div>
          <div style={{padding: '0', boxSizing: 'border-box', backgroundColor: '#fff'}}>
            <MobileNewsBanner position={'news_top1'} listSize={5} />
          </div>
          <div>
            <MainNewsList newsList={mainBNews.list} title={"뉴스"} showTitle={true} link={"news"} />
          </div>
          <div style={{padding: '0', boxSizing: 'border-box', backgroundColor: '#fff'}}>
            <MobileNewsBanner position={'news_middle1'} listSize={5} />
          </div>
          <div>
            <MainNewsList newsList={mainCNews.list} link={"news"} />
          </div>
          <div style={{padding: '0', boxSizing: 'border-box', backgroundColor: '#fff'}}>
            <MobileNewsBanner position={'news_special'} listSize={2} />
          </div>
          <div>
            <SpecialFeatureArticles list={specialFeatureArticles.list} />
            <MainNewsList newsList={koreanList.list} title={"교민"} showTitle={true} link={"k-people"} />
          </div>
          <div>
            <MainColumnist list={columnistList.list} />
          </div>
          <div>
            <MainNewsList newsList={immigrantList.list} title={"이민"} showTitle={true} link={"immigrant"}/>
          </div>
          <div>
            <MainInterview list={interviewList.list} />
            <MainNewsList newsList={koreaList.list} title={"한국"} showTitle={true} link={"korea"} />
          </div>
          <div>
            <MainEducation list={educationList.list} />
          </div>
          <div>
            <GoogleAdvertiseForInFeed
              client="ca-pub-2380324855411184"
              slot="6610277828"
              format="fluid"
              responsive="true"
              layoutKey="-6m+ei+13-45+62"
            />
          </div>
          <div>
            <MainBuySells list={buysellList.list} />
          </div>
          <div>
            <MainJobs list={jobList.list} />
          </div>
          <div>
            <MainCarsForNews list={carList.list} />
          </div>
          <div>
            <MainNewsTopView tabs={NewsTabs} />
          </div>
          <div style={{padding: '0', boxSizing: 'border-box', backgroundColor: '#fff'}}>
            <MobileNewsBanner position={'news_column'} listSize={2} />
          </div>
        </main>
      </NewsWrapper>
      {/* <NewsPopupBanner
        position={'news_popup_banner'}
        show={showNewsPopupBanner}
        toggle={toggleMarketPopupBanner}
        listSize={1}
      /> */}
      <NewsPopupModal
        position={'news_bottom'}
        show={showNewsPopupModal}
        toggle={toggleMarketPopupModal}
        savedName={HAS_VISITED_BEFORE}
        listSize={1}
      />
    </>
  );
};

const NewsWrapper = styled.div`
  margin: auto;
  width: 100%;
  & main {
    width: 100%;
    text-align: center;
    & > div {
      position: relative;
      margin: 0;
      width: 100%;
      /* display: inline-block; */
      text-align: left;
    }
  }
`;

