import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../common/InputComponent';
import { SelectComponent } from '../../../common/SelectComponents';
import { RadioComponents } from '../../../common/RadioComponents';
import { RadioButtonComponents } from '../../../common/RadioButtonComponents';
import { JobEndDatepickerComponent } from './component/JobEndDatepickerComponent';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { MarketEditor } from '../component/MarketEditor';
import { Loader } from '../../../common/Loader';
import { JobApi } from '../api/JobApi';
import { UserApi } from '../../user/api/UserApi';
import { addHyphenForPhoneNumber, isBlankValue, isPositiveOrZero, isValidEmail, isValidSpecificCharacters } from '../../../utils/utils';
import {
  Cities,
  JobCareerType,
  JobCategories,
  JobContacts,
  JobEmploymentType,
  JobSalaryRate,
  JobSearchSoldOut,
  JobSoldOut
} from '../../../__mocks__/PostData';

export const JobDetailEdit = ({setDialog}) => {
  const jobApi = new JobApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const [showCalender, setShowCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [isNewPost] = useState(location?.state?.id?false:true);
  const [article, setArticle] = useState({
    id: location?.state?.id?location?.state?.id:'',
    boardId: 7,
    active: 1,
    type: location?.state?.jobType,
    name: '',
    phone: '',
    email: '',
    contactCode: 'phone',
    title: '',
    description: '',
    categoryCode: '',
    employmentType: 'full-time',
    careerType: 'entry-level',
    location: '5',
    payType: '8',
    salaryRate: 'hour',
    price: 0,
    endRecruitmentDate: '',
    isSoldOut: 0,
    updatedDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------
  const changeArticleData = (name, value) => {
    setArticle((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeArticleImages = (index, imageData) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setArticle((values) => {
      const updated = {...values};
      updated['images'][index] = {};
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleStepOnClick = (step, id='') => {
    const checkId = (id) ? id : article.id;
    if(2 === step && !checkId) {
      return;
    } else {
      setStep(step);
    }
  }

  const handleNameOnChange = (value) => {
    if(isValidSpecificCharacters(value)) {
      changeArticleData("name", value);
    }
  }

  const handleEmailOnChange = (value) => {
    changeArticleData("email", value);
  }

  const handleTitleOnChange = (value) => {
    if(isValidSpecificCharacters(value)) {
      changeArticleData("title", value);
    }
  }

  const handlePriceOnChange = (value) => {
    changeArticleData("price", value.replace(/[^.0-9]/g, ""));
    changeArticleData("price", value);
  }

  const handlePhoneNumberOnChange = (value) => {
    changeArticleData("phone", autoAddHyphenForPhoneNumber(value));
  };

  const handleSoldOutOnChange = (value) => {
    changeArticleData('isSoldOut', value);
  }

  const handleLocationOnChange = (e) => {
    changeArticleData('location', e.target.value);
  }

  const handleCategoryCodeOnChange = (e) => {
    changeArticleData("categoryCode", e.target.value);
  }

  const handleContactCodeOnChange = (e) => {
    changeArticleData('contactCode', e.target.value);
  };

  const handleEmploymentTypeOnChange = (value) => {
    changeArticleData('employmentType', value);
  };

  const handleSalaryRateOnChange = ({target}) => {
    changeArticleData('salaryRate', target.value);
    if('deal' === target.value) {
      handlePriceOnChange('0.00');
    }
  };

  const handleCareerTypeOnChange = (value) => {
    changeArticleData('careerType', value);
  };

  const handleEndRecruitmentDateOnChange = (value) => {
    changeArticleData("endRecruitmentDate", value);
  }

  const handlerToggleShowCalender = () => {
    setShowCalender(!showCalender);
  }

  const handleSearchClick = () => {
    if(article.id) {
      setLoading(true);

      jobApi.fetchJobEditById(article.id,
        (data) => {
          setLoading(false);
          const { result } = data;
          setArticle(result);
        },
        (error) => {
          setLoading(false);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1);
        }
      );
    } else {
      userApi.fetchUser(
        (data) => {
          const { result: {nickName, contactEmail, phone} } = data;
          changeArticleData("name", nickName);
          changeArticleData("phone", phone);
          changeArticleData("email", contactEmail);
        },
        (error) => {
          console.log(error);
          const { response, status, message, errors } = error;
          if(status) {
            if(403 === status) {
              // setDialog({hasError: true, message: `${message}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${message}`, type: "error"});
            }
          } else if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          } else if(response) {
            const {status, statusText} = response;
            if(403 === status) {
              // setDialog({hasError: true, message: `${statusText}`, type: "error"});
              alert(`로그인후 사용해주세요.`);
              navigate(-1);
            } else {
              setDialog({hasError: true, message: `${statusText}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const uploadImageFile = (file, index) => {
    if(article.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', article.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      jobApi.createJobImageFile(
        article.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(article.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': article.id,
      });

      jobApi.deleteJobImageFile(
        article.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteArticleImages(index);
        },
        () => {
          setLoading(false);
          setDialog({isOpen: true, message: "처리중 에러가 발생하였습니다.", type: "alert"});
        }
      );
    }
  }

  const handleSaveForStep1OnClick = () => {
    const id = article.id;
    if(id) {
      saveForStep1();
    } else {
      createForStep1();
    }
  }

  const saveForStep1 = () => {
    if(validateArticleDataForStep1ForSave() && window.confirm("Step1 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active, type, name,
        phone, email, contactCode,
        title, categoryCode, employmentType, salaryRate,
        price, careerType, endRecruitmentDate, location} = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'type': type,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'categoryCode': categoryCode,
        'employmentType': employmentType,
        'salaryRate': salaryRate,
        'price': price,
        'careerType': careerType,
        'endRecruitmentDate': endRecruitmentDate,
        'location': location,
      });

      jobApi.saveJobForStep1(id, updateData,
        (data) => {
          setLoading(false);
          handleStepOnClick(2);
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { message, errors } = data;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const createForStep1 = () => {
    if(validateArticleDataForStep1ForCreate()) {
      const {
        boardId, type, name, phone, email, contactCode,
        title, categoryCode, employmentType, salaryRate, price
        , careerType, endRecruitmentDate, location
      } = article;
      const createData = JSON.stringify({
        'boardId': boardId,
        'type': type,
        'name': name,
        'phone': phone,
        'email': email,
        'contactCode': contactCode,
        'title': title,
        'categoryCode': categoryCode,
        'employmentType': employmentType,
        'salaryRate': salaryRate,
        'price': price,
        'careerType': careerType,
        'endRecruitmentDate': endRecruitmentDate,
        'location': location,
      });
      setLoading(true);

      jobApi.createJobForStep1(
        createData,
        (data) => {
          setLoading(false);
          changeArticleData('id', data.id);
          handleStepOnClick(2, data.id);
        },
        (error) => {
          setLoading(false);
          const { message, errors } = error;
          if (errors) {
            const { field, defaultMessage } = errors[0];
            setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
          }
        }
      );
    }
  }

  const handleSaveForStep2OnClick = () => {
    if(window.confirm(isNewPost?"결제 하시겠습니까?":"Step2 정보를 수정 하시겠습니까?")) {
      setLoading(true);
      const { id, boardId, active, type, payType, isSoldOut } = article;
      const updateData = JSON.stringify({
        'id': id,
        'boardId': boardId,
        'active': active,
        'type': type,
        'description': editorRef.current.getContent(),
        'payType': payType,
        'isSoldOut': isSoldOut,
      });

      if(isNewPost) {
        jobApi.createJobForStep2(id, updateData,
          (data) => {
            setLoading(false);
            // setDialog({isOpen: true, message: "등록 하였습니다.", type: "alert"});
            if('8' === payType && "구인" === type) {
              handleExtendPostOnClick();
            } else {
              navigate(`/job`, {replace : true});
            }
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      } else {
        jobApi.saveJobForStep2(id, updateData,
          (data) => {
            setDialog({isOpen: true, message: "저장하였습니다.", type: "alert"});
            navigate(`/job/${id}`, {replace : true});
          },
          (error)=> {
            setLoading(false);
            const {response: {data}} = error;
            const { message, errors } = data;
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        );
      }
    }
  }

  const handleExtendPostOnClick = () => {
    const { id, boardId, payType } = article;
    navigate('/market-payment-step1',
      {state: {
        refType: 'job',
        refPostId: id,
        refBoardId: boardId,
        itemId: payType,
        backLink: `/job/${id}`,
        cancelLink: `/job`,
      }}
    );
  }

  // ==== valid check ------------------------------------------------
  const isStep1 = () => {
    return (step === 1) ? true : false;
  }

  const isStep2 = () => {
    return (step === 2) ? true : false;
  }

  const isJobSearch = (type) => {
    if("구직" === type)
      return true;
    return false;
  }

  const isPriceDisabled = (salaryRate) => {
    return ('deal'===salaryRate) ? true: false;
  }

  const autoAddHyphenForPhoneNumber = (value) => {
    const number = value.replace(/[^0-9]/g, "");
    let phone = "";

    if (number.length < 5) {
      return number;
    } else if (number.length < 8) {
      phone = `${number.substr(0, 3)}-${number.substr(3)}`;
    } else if (number.length < 10) {
      phone = `${number.substr(0, 2)}-${number.substr(2, 3)}-${number.substr(5)}`;
    } else if (number.length < 11) {
      phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
    } else if (number.length < 12) {
      phone = `${number.substr(0, 3)}-${number.substr(3, 4)}-${number.substr(7)}`;
    }
    return phone;
  }

  const validateArticleDataForStep1ForCreate = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "업체명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }

    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }

    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: `${isJobSearch(article.type)?'희망업무': '모집분야'}는 필수입니다.`, type: "alert"});
      return false;
    }
    if(isBlankValue(article.employmentType)) {
      setDialog({isOpen: true, message: "고용형태는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "임금은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.careerType)) {
      setDialog({isOpen: true, message: "경력은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "지역은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  const validateArticleDataForStep1ForSave = () => {
    if(isBlankValue(article.name)) {
      setDialog({isOpen: true, message: "업체명은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.phone)) {
      setDialog({isOpen: true, message: "전화번호는 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.email)) {
      setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
      return false;
    }
    if(!isValidEmail(article.email)) {
      setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
      return false;
    }

    if(isBlankValue(article.title)) {
      setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.categoryCode)) {
      setDialog({isOpen: true, message: `${isJobSearch(article.type)?'희망업무': '모집분야'}는 필수입니다.`, type: "alert"});
      return false;
    }
    if(isBlankValue(article.employmentType)) {
      setDialog({isOpen: true, message: "고용형태는 필수입니다.", type: "alert"});
      return false;
    }
    if(!isPositiveOrZero(article.price)) {
      setDialog({isOpen: true, message: "임금은 0 이하 일수 없습니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.careerType)) {
      setDialog({isOpen: true, message: "경력은 필수입니다.", type: "alert"});
      return false;
    }
    if(isBlankValue(article.location)) {
      setDialog({isOpen: true, message: "지역은 필수입니다.", type: "alert"});
      return false;
    }
    // if(isBlankValue(article.endRecruitmentDate)) {
    //   setDialog({isOpen: true, message: "모집종료일은 필수입니다.", type: "alert"});
    //   return false;
    // }
    return true;
  }

  // render ------------------------------------------------
  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <StepGroup>
        <div className={`${isStep1() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(1)}>Step 1</div>
        <div className={`${isStep2() ? 'step active' : 'step'}`} onClick={() => handleStepOnClick(2)}>Step 2</div>
      </StepGroup>
      {isStep1() && (
        <>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>{article.type}자 정보를 입력해주세요</div>
              <InputComponent
                label={isJobSearch(article.type)?'구직자': '업체명'}
                id={'name'}
                type={'text'}
                checkType={'text'}
                value={article.name}
                onChange={handleNameOnChange}
                placeholder={`${(isJobSearch(article.type)?'구직자를': '업체명을')} 입력하세요.`} />
              <InputComponent
                label={'전화번호'}
                id={'phone'}
                type={'text'}
                checkType={'text'}
                value={addHyphenForPhoneNumber(article.phone)}
                maxLength={13}
                placeholder={'00*-00*-0000'}
                onChange={handlePhoneNumberOnChange} />
              <InputComponent
                label={'이메일'}
                id={'email'}
                type={'email'}
                checkType={'email'}
                value={article.email}
                placeholder={'입력하세요.'}
                onChange={handleEmailOnChange} />
              <RadioComponents
                label={'연락망 선택'}
                id={'contactCode'}
                value={article.contactCode}
                objectData={JobContacts}
                infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
                onChange={handleContactCodeOnChange} />
            </ArticleForm>
          </ContentWrap>
          <Separator height={'1rem'}/>
          <ContentWrap>
            <ArticleForm>
              <div className='articleLabel'>{article.type} 정보를 입력해주세요</div>
              <InputComponent
                label={'제목'}
                id={'title'}
                type={'text'}
                checkType={'text'}
                value={article.title}
                placeholder={'입력하세요.'}
                onChange={handleTitleOnChange} />
              <SelectComponent
                label={isJobSearch(article.type)?'희망업무': '모집분야'}
                id={'categoryCode'}
                value={article.categoryCode}
                objectData={{'': { value: '', label: '선택'}, ...JobCategories}}
                placeholder={isJobSearch(article.type)?'희망 업무를 선택하세요': '담당 업무를 선택하세요.'}
                infoMessage={'여러 희망 업무는 대표 업무만 선택하세요.'}
                onChange={handleCategoryCodeOnChange} />
              <RadioButtonComponents
                label={'고용형태'}
                id={'employmentType'}
                value={article.employmentType}
                objectData={JobEmploymentType}
                width={'31%'}
                onChange={handleEmploymentTypeOnChange} />

              <InputGroup>
                <label htmlFor={'salaryRate'}>{'임금'}</label>
                <div>
                  <select className="form-select"
                    id={'salaryRate'}
                    value={article.salaryRate||''}
                    onChange={handleSalaryRateOnChange}
                  >
                    {Object.keys(JobSalaryRate).map((key) =>
                      <option key={key} value={JobSalaryRate[key].value}>{JobSalaryRate[key].label}</option>
                    )}
                  </select>
                  <input className={`form-text`}
                    type={'text'}
                    id={'price'}
                    value={article.price||0}
                    placeholder={'입력하세요.'}
                    onChange={(e) => handlePriceOnChange(e.target.value)}
                    autoComplete="off"
                    required
                    disabled={isPriceDisabled(article.salaryRate)}
                  />
                </div>
              </InputGroup>

              <RadioButtonComponents
                label={'경력'}
                id={'careerType'}
                value={article.careerType}
                objectData={JobCareerType}
                width={'31%'}
                onChange={handleCareerTypeOnChange} />

              <JobEndDatepickerComponent
                label={isJobSearch(article.type)?'구직마감일':'모집종료일'}
                chkLabel={isJobSearch(article.type)?'상시구직':'상시채용'}
                id={'endDate'}
                dateFormat={'yyyy-MM-dd'}
                placeholderText={'yyyy-MM-dd'}
                value={article.endRecruitmentDate}
                isShow={showCalender}
                onClick={handlerToggleShowCalender}
                onChange={handleEndRecruitmentDateOnChange}
              />
              <SelectComponent
                label={isJobSearch(article.type)?'근무희망지역': '근무지역'}
                id={'location'}
                value={article.location}
                objectData={Cities}
                placeholder={isJobSearch(article.type)?'근무희망지역 선택':'근무지역 선택'}
                onChange={handleLocationOnChange} />
            </ArticleForm>
            <NextButton
              type='button'
              color="#fff"
              backgroundColor='#fd6258'
              onClick={() => handleSaveForStep1OnClick()}>
              {isNewPost? '다음' : '수정하기'}
            </NextButton>
          </ContentWrap>
        </>
      )}
      {isStep2() && (
        <>
          <ContentWrap>
            <ImageUploadComponent
              images={article.images}
              uploadProcess={uploadImageFile}
              deleteProcess={deleteImageFile}
              setDialog={setDialog}
            />
            <Label htmlFor='description'>
              상세내용
              <span>(선택)</span>
            </Label>
            <MarketEditor
              id={'description'}
              editorRef={editorRef}
              placeholder={'업무 상세 내용을 입력해주세요. 예) 근무 시간, 자격요건, 근무 여건, 우대사항 등'}
              description={article.description}
              infoMessage={'* 구인/구직 광고글 외에 주제와 맞지 않는 내용을 기재하면 사전통지 없이 삭제됩니다.'}
              disabled={false}
            />
            <ArticleForm>
              <RadioButtonComponents
                label={'모집상태'}
                subLabel={'(선택)'}
                id={'isSoldOut'}
                value={article.isSoldOut}
                objectData={isJobSearch(article.type)?JobSearchSoldOut: JobSoldOut}
                width={'48%'}
                onChange={handleSoldOutOnChange} />
            </ArticleForm>
            <NextButton
              type='button'
              color="#fff"
              backgroundColor='#fd6258'
              onClick={() => handleSaveForStep2OnClick()}>
              {isNewPost? '결제하기' : '수정하기'}
            </NextButton>
            {(!isNewPost && "구인" === article.type) && (
              <PostExtendGroup>
                <input type='text'
                  id='PostExtend'
                  value={`${article?.premiumStart ? new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(new Date(article?.premiumStart)) : '시작일'} ~ ${article?.premiumEnd ? new Intl.DateTimeFormat("ko", { dateStyle: 'medium' }).format(new Date(article?.premiumEnd)) : '종료일'}`}
                  readOnly/>
                <button type='button'
                  onClick={() => handleExtendPostOnClick()}
                >광고 연장신청</button>
              </PostExtendGroup>
            )}
          </ContentWrap>
        </>
      )}
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;

const StepGroup = styled.div`
  padding: 1.3rem 1.6rem 0;
  display: flex;
  justify-content: center;
  & div.step {
    width: 50%;
    height: 4.2rem;

    font-size: 14px;
    font-weight: 700;
    line-height: 4.2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  & div.step:first-child {
    border: 0.1rem solid #eee;
    border-radius: 0.4rem 0rem 0rem 0.4rem;
  }
  & div.step:last-child {
    border: 0.1rem solid #eee;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
  }
  & div.step.active {
    color: #fd6258;
    background-color: #ffeae9;
    border-color: #ffeae9;
  }
`;

const ContentWrap = styled.div`
  padding: 1.4rem;
  height: 77%;
`;

const ArticleForm = styled.div`
  margin-bottom: 0.5rem;
  & div.articleLabel {
    margin: 3.2rem 0rem 2rem 0rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const NextButton = styled.button`
  margin: 3rem 0 2rem;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const Label = styled.label`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 2.3rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  & span {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #FD6258;
  }
`;

const InputGroup = styled.div`
  margin: 1.35rem 0;
  height: auto;
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & label {
    width: 100%;
    height: 2.3rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }
  & div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & select {
      margin-right: 0.8rem;
      padding-left: 1.6rem;
      width: 35%;
      height: 4.15rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }

    & input {
      padding-right: 0.8rem;
      min-width: 21rem;
      width: 65%;
      height: 3.8rem;

      font-size: 14px;
      font-weight: 400;
      line-height: 3.8rem;
      letter-spacing: -0.04em;
      color: #424242;
      text-align: right;

      background-image: url('/icon/dollar-icon.png');
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position: 1.6rem center;

      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
    }
    & input:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.success {
      color: ${({ theme }) => theme.colors.success};
      border-color: ${({ theme }) => theme.colors.borderSuccess};
    }
    & input.success:focus-visible {
      outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
    }
    & input.error {
      border-color: ${({ theme }) => theme.colors.errorBorder};
    }
    & input.error:focus-visible {
      outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
    }
  }
`;

const PostExtendGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    padding-right: 0.8rem;
    width: 60%;
    height: 4rem;

    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #616161;
    text-align: center;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }

  & button {
    width: 35%;
    height: 4.4rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    background: #F5F5F5;
    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
    cursor: pointer;
  }
`;