import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Pagination } from 'swiper';
import 'swiper/css';

export const MarketTopMenu = () => {
  SwiperCore.use([FreeMode, Pagination]);
  const location = useLocation();
  const navigate = useNavigate();
  const [swiperRef, setSwiperRef] = useState(null);

  const [list] = useState([
    {url:'/market', name: '마켓홈', position: 'market_bottom'},
    {url:'/buysell', name: '사고팔고', position: 'buysell_bottom'},
    {url:'/job', name: '구인구직', position: 'jobs_bottom'},
    {url:'/tutor', name: '학원튜터', position: 'tutor_bottom'},
    {url:'/rent-homestay', name: '렌트/민박', position: 'rent_bottom'},
    {url:'/realty-sale', name: '부동산', position: 'sale_bottom'},
    {url:'/car', name: '자동차', position: 'car_bottom'},
    {url:'/community', name: '커뮤니티', position: 'community_bottom'},
    {url:'/yellowpage', name: '업소찾기', position: 'yellow-page_bottom'},
  ]);

  const [currentPage, setCurrentPage] = useState(location?.pathname);

  const handleMenuOnClick = (url, slideTo) => {
    setCurrentPage(url);
    navigate(url);
    if(slideTo) {
      initSlideTo(url);
    }
  }

  const initHeader = (url) => {
    let index = list.map(x => x.url).indexOf(url);
    if(0 <= index && swiperRef != null) {
      swiperRef.slideTo(index - 1, 0, false);
    }
  };

  const initSlideTo = (url) => {
    initHeader(url);
  };

  const isActive = (url) => {
    const removedFistCharUrl = url.substring(1, url.length);

    if(0 < currentPage.search(removedFistCharUrl)) {
      return 'active'
    } else if(currentPage === url) {
      return 'active'
    } else {
      return '';
    }
  }

  const getCurrentPageNumber = () => {
    let index = list.map(x => x.url).indexOf(currentPage);
    return index;
  }

  useEffect(()=>{
    setCurrentPage(location?.pathname);
    initSlideTo(location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  return (
    <>
      <MySwiper
        onSwiper={setSwiperRef}
        slidesPerView={4.8}
        freeMode={true}
        pagination={false}
        initialSlide={getCurrentPageNumber()}
        >
        {list.map((list) => (
          <SwiperSlide key={list.url}>
            <div id={list.url} className={`item ${isActive(list.url)}`} onClick={()=>handleMenuOnClick(list.url, false)}>{list.name}</div>
          </SwiperSlide>
        ))}
      </MySwiper>
    </>
  );
}

const MySwiper = styled(Swiper)`
  width: auto;
  height: 4.3rem;
  background: #fff;
  & div.swiper-slide {
    position: relative;
    margin: 0 auto;
    height: 4.2rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    border-bottom: 0.1rem solid #eee;
  }
  & div.item {
      position: absolute;
      height: 4.2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #000000;
      cursor: pointer;
    }
    & div.item.active {
      font-size: 14px;
      font-weight: 700;
      color: #fd6258;
      border-bottom: 0.2rem solid #fd6258;
    }
`;