import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const NewsSearch = ({ placeholder, searchData, changeSearchData, handleSearchClick}) => {
  const inputRef = useRef(null);
  const [viewModeList] = useState(['text', 'list', 'grid', 'card']);
  const [viewMode, setViewMode] = useState(searchData.viewMode);
  const [newsMenulist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(searchData.filter);

  const handleSearchOnClick = () => {
    handleSearchClick?.();
  }

  const handleFilterToggleOnClick = () => {
    changeSearchData("filterToggle", !searchData.filterToggle);
  }

  const handleCategoriesOnChange = (value) => {
    if(hasCategory(value)) {
      setCategories((categories) => [...categories.filter((v)=> v !== value)]);
    } else {
      setCategories((categories) => [...categories, value]);
    }
  }

  const hasCategory = (value) => {
    return categories.includes(value);
  }

  const handleSearchOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      changeSearchData?.('title', inputRef.current.value);
      changeSearchData('page', 1);
    }
  }

  const handleSearchOnBlur = () => {
    changeSearchData?.('title', inputRef.current.value);
    changeSearchData('page', 1);
  }

  const handleSelectedFilter = (selectedFilter) => {
    if(filter === selectedFilter) {
      selectedFilter = null;
    }
    setFilter(selectedFilter);
    changeSearchData?.('filter', selectedFilter);
    changeSearchData?.('page', 1);
  }

  const handleSelectedViewMode = (selectedViewMode) => {
    if(viewMode === selectedViewMode) {
      selectedViewMode = null;
    }
    setViewMode(selectedViewMode);
    changeSearchData?.('viewMode', selectedViewMode);
  }

  useEffect(() => {
    inputRef.current.value = searchData.title;
  }, [searchData.title]);

  return (
    <div>
      <SearchSection>
        <div className={'searchGroup'}>
          <input type="text" ref={inputRef} title="검색어 입력" placeholder={placeholder} maxLength={"255"}
            onKeyUp={handleSearchOnKeyUp} onBlur={handleSearchOnBlur}
          />
          <button className='search' type="button" onClick={handleSearchOnClick}>검색</button>
          <button className='filter' type="button" onClick={handleFilterToggleOnClick}></button>
        </div>
      </SearchSection>
      { searchData.filterToggle && (
        <FilterSection>
          <Category>
            { newsMenulist.map((item) => (
              <li key={item.name} onClick={() => handleCategoriesOnChange(`${item.name}`)}>
                <div className={`${hasCategory(item.name) ? 'active' : ''}`}>{item.name}</div>
              </li>
            ))}
          </Category>
          <Period>
            <li className={`${filter === 'today' ? 'active' : ''}`} onClick={() => handleSelectedFilter("today")}>
              <div>오늘만</div>
            </li>
            <li className={`${filter === 'week' ? 'active' : ''}`} onClick={() => handleSelectedFilter("week")}>
              <div>일주일</div>
            </li>
            <li className={`${filter === 'month' ? 'active' : ''}`} onClick={() => handleSelectedFilter("month")}>
              <div>이번달</div>
            </li>
            <li className={`${filter === 'year' ? 'active' : ''}`} onClick={() => handleSelectedFilter("year")}>
              <div>올해</div>
            </li>
          </Period>
          <ViewType>
            { viewModeList.map((view) => (
              <li key={view} onClick={() => handleSelectedViewMode(view)}>
                <div className={`${viewMode === view ? 'active' : ''}`}>{view}</div>
              </li>
            ))}
          </ViewType>
        </FilterSection>
      )}
    </div>
  );
}

const SearchSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F0F0F0;
  border: 0.1rem solid #F0F0F0;
  border-radius: 0.8rem;
  & div.searchGroup {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    & input {
      padding-left: 1rem;
      height: 3rem;
      width: auto;
      min-width: 22rem;
      width: 80%;
      color: #5d5e60;
      border: 0.1rem solid #F0F0F0;
      border-radius: 0.8rem;
      outline-color: #ddd;
    }
    & button.search {
      margin-left: 0.5rem;
      height: 3rem;
      width: 5rem;
      color: #5d5e60;
      background-color: #ddd;
      border: 0.1rem solid #F0F0F0;
      border-radius: 0.8rem;
      cursor: pointer;
    }
    & button.filter {
      margin-left: 0.5rem;
      height: 3rem;
      width: 3.5rem;
      background-color: #ddd;
      border: 0.1rem solid #F0F0F0;
      border-radius: 0.8rem;
      cursor: pointer;
      background-image: url('/icon/rent-homestay-filter-icon.png');
      background-size: 1.6rem;
      background-repeat: no-repeat;
      background-position: 0.8rem 0.63rem;
    }
  }
`;

const FilterSection = styled.section`
  margin: 0.5rem 0;
  background-color: #F0F0F0;
  border: 0.1rem solid #F0F0F0;
  border-radius: 0.8rem;
`;

const Period = styled.ul`
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;

  list-style-type: disc;
  & li {
    margin: 0.5rem;
    width: 6.5rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    & div {
      width: 100%;
      height: 2.4rem;
      color: #747474;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  & li.active {
    & div {
      color: #000;
      font-weight: bold;
      background-color: #ddd;
      border: 0.1rem solid #ddd;
      border-radius: 0.8rem;
    }
  }
`;

const Category = styled.ul`
  margin: 0.5rem 1rem 0 1rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  & li {
    margin: 0.5rem 0.5rem 0 0.5rem;
    & div {
      padding: 0 0.4rem;
      height: 2rem;

      font-size: 14px;
      color: #747474;

      display: flex;
      align-items: center;

      border: 0 solid #ddd;
      border-radius: 0.6rem;
      background-color: #fff;
      cursor: pointer;
    }
    & div.active {
      color: #000;
      font-weight: bold;
      background-color: #ddd;
      border: 0.1rem solid #ddd;
      border-radius: 0.8rem;
    }
  }
`;
const ViewType = styled.ul`
  margin: 0 1rem 1rem 1rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style-type: none;
  & li {
    & div {
      padding: 0 0.4rem;
      height: 2rem;

      font-size: 14px;
      color: #747474;

      display: flex;
      align-items: center;

      border: 0 solid #ddd;
      border-radius: 0.6rem;
      background-color: #fff;
      cursor: pointer;
    }
    & div.active {
      font-weight: 700;
      color: #000;
      background-color: #ddd;
    }
  }
`;
