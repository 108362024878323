import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CommonModal } from '../../../../common/CommonModal';

export const TutorLearnerModal = ({ title, learnerList, learners, showLearnerModal, toggleLearnerModal, handlerLearnersOnChange }) => {

  const handleToggleModal = () => {
    toggleLearnerModal?.();
  }

  const handlerOnClick = () => {
    const checkedList = document.querySelectorAll('.custom:checked');
    let resultLearners = "";
    checkedList.forEach(element => {
      resultLearners += `${element?.value},`;
    });
    handlerLearnersOnChange(resultLearners);
    handleToggleModal?.();
  }

  // const isChecked = (values, key) => {
  //   return (-1<values?.indexOf(key))? true: false;
  // }

  useEffect(() => {
    if(showLearnerModal) {
      document.body.style= `overflow: hidden`;
    }
    return () => document.body.style = `overflow: auto`;
  }, [showLearnerModal]);

  return (
    <>
      {showLearnerModal&&(
        <CommonModal width={'100%'}>
          <LearnerModalWrapper>
            <div className='modalTitle'>
              {title}
              <img src='/icon/x-mark.png'
                alt='Modal Close'
                onClick={()=> handleToggleModal()}
              />
            </div>
            <LearnerList>
              <ul>
                {Object.keys(learnerList).map((key) => (
                  <li key={key}>
                    <input className='custom' type='checkbox' id={key} value={key} />
                    <label htmlFor={key}>
                      {learnerList[key].label}
                    </label>
                  </li>
                ))}
              </ul>
              <Button type='button'
                color="#fff"
                backgroundColor='#fd6258'
                onClick={() => handlerOnClick()}>완료</Button>
            </LearnerList>
          </LearnerModalWrapper>
        </CommonModal>
      )}
    </>
  );
};
const LearnerModalWrapper = styled.div`
  position: relative;
  & div.modalTitle {
    padding: 0rem 2rem;
    width: auto;
    height: 6.8rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const LearnerList = styled.div`
  display: flex;
  flex-direction: column;
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid #f5f5f5;
    & li {
      padding-left: 1.4rem;
      width: auto;
      height: 4.8rem;
      cursor: pointer;

      display: flex;
      align-items: center;

      & input {
        margin: 0;
        width: 2rem;
        height: 2rem;
        &.custom{
          accent-color: #f86258;
        }
      }
      & label {
        margin-left: 1.4rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4rem;
      }
    }
    & li:nth-child(odd) {
      border-top: 0rem solid #ddd;
      border-right: 0rem solid #ddd;
    }
    & li:nth-child(even) {
      border-top: 0rem solid #ddd;
    }
    & li:nth-last-child(1) {
      border-bottom: 0rem solid #ddd;
    }
    & li:nth-last-child(2) {
      border-bottom: 0rem solid #ddd;
    }
    & li.active {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #FD6258;
      background: #FFEAE9;
    }
  }
`;

const Button = styled.button`
  margin: 2.2rem 1.4rem;
  width: auto;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;