import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { CarApi } from '../api/CarApi';
import { MarketSearch } from '../component/MarketSearch';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { LocationModal } from '../component/LocationModal';
import { SortComponent } from '../component/SortComponent';
import { CarMainTopAd } from '../../ads/CarMainTopAd';
import { CarCardComponent } from './component/CarCardComponent';
import { CarSort } from '../../../__mocks__/PostData';
import { CarFilterModal } from './component/CarFilterModal';
import { Helmet } from 'react-helmet-async';

export const CarList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "car_search";
  const DEFAULT_VIEW_MODE = "list";
  const LIST_SIZE = 20;

  const carApi = new CarApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showCarFilter, setShowCarFilter] = useState(false);

  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: '',
          locationCode: '',
          locationName: '',
          locationKrName: '모든지역',

          brand: '',
          type: '',
          color: '',
          minYear: 2000,
          maxYear: 2023,
          gasType: '',
          transmission: '',
          minPrice: 0,
          maxPrice: 1000000,
          minMileage: 0,
          maxMileage: 400000,
          drivingSystem: '',
          includedOptions: '',
          sort: 'newest',
          title: null,
          scrollY: 0,
          viewMode: DEFAULT_VIEW_MODE,
        };
      }
    }
  });
  const [carList, setCarList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeLocationData = (code, name, krName) => {
    changeSearchData("page", 1);
    changeSearchData('locationCode', code);
    changeSearchData('locationName', name);
    changeSearchData('locationKrName', krName);
  };
  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal)
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = (scrollUp=false) => {
    setLoading(true);
    carApi.fetchCarList(
      searchData,
      (data) => {
        setCarList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        setLoading(false);
        if(error) {
          if(error.response) {
            const {response: {data}} = error;
            setDialog({isOpen: true, message: data?.message, type: "nonHeader"});
          } else if(error.message) {
            setDialog({isOpen: true, message: error?.message, type: "nonHeader"});
          }
        }
      }
    );
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 400);
  }

  const toggleShowCarFilter = () => {
    setShowCarFilter(!showCarFilter);
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.sort, searchData.title, searchData.categoryCode, searchData.locationCode]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`자동차 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'중고차를 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
      </PageWrapper>
      <CarMainTopAd
        position={'car_top_ra'}
        listSize={15}
        />
      <PageWrapper>
        <div style={{margin: '3rem 0 1rem'}}>
          <MarketListTopAds
            position={'car_da'}
            listSize={5}
          />
        </div>
        <MarketLineAdList
          boardId={7}
          mobileAdOnClick={handlerItemCardOnClick}
        />
        <SearchFilter>
          <div className={'btnFilter'} onClick={()=>toggleShowCarFilter()}>
            필터
            <img className={'imageList'} src='/icon/rent-homestay-filter-icon.png' alt=''/>

          </div>
          <div>
            <div className='mapPin' onClick={()=> toggleLocationModal()}>
              {searchData?.locationKrName}
              <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
            </div>
            <LocationModal
              showLocationModal={showLocationModal}
              toggleLocationModal={toggleLocationModal}
              locationCode={searchData?.locationCode}
              changeLocationData={changeLocationData}
            />
            <SortComponent
              sortDisplayList={CarSort}
              sortCode={searchData?.sort}
              changeSortData={changeSortData}
            />
          </div>
        </SearchFilter>

        <div className='list'>
          <CarCardComponent
            list={carList.list}
            link={'car'}
            isLoading={loading}
            postOnClick={handlerItemCardOnClick}
          />
        </div>
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}
        />
      </PageWrapper>
      <CarFilterModal
        show={showCarFilter}
        toggle={toggleShowCarFilter}
        searchData={searchData}
        changeSearchData={changeSearchData}
        handleSearchClick={handleSearchClick}
      />
    </>
  );
}
const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 0;
  width: auto;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 1.6rem;
    min-height: 39vh;
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & div.btnFilter {
    width: 4.8rem;
    height: 2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & div.filter {
    padding-left: 1rem;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    border-left: 1px solid #E0E0E0;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;