import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Parallax, Navigation } from "swiper";
import 'swiper/css';
import { AdsApi } from './api/AdsApi';


export const MarketLineAdList = React.memo(({ boardId, mobileAdOnClick }) => {
  SwiperCore.use([Autoplay, Pagination, Parallax, Navigation]);
  const adsApi = new AdsApi();
  const [ads, setAds] = useState({list:[]});

  const handleLineAdsSearch = () => {
    if(boardId) {
      adsApi.fetchLineAdsList(boardId, (data)=> {
        setAds(data);
      })
    }
  }

  const handlerAdOnClick = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      mobileAdOnClick?.(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  useEffect(() => {
    handleLineAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PostListWrapper>
      {ads.length === 0 ?
      (
        <NoData>데이터가 없습니다.</NoData>
      ):(
          <MySwiper
            direction={"vertical"}
            loop={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
          >
            {ads?.list.map((ad) => (
              <SwiperSlide key={ad.id}>
                <AdItem onClick={() => handlerAdOnClick(ad)}>
                  <div>AD</div>
                  <h2>{ad.title}</h2>
                </AdItem>
              </SwiperSlide>
            ))}
          </MySwiper>
      )}
    </PostListWrapper>
  );
});

const PostListWrapper = styled.div`
  width: 100%;
  height: 4.5rem;
  background: #F5F5F5;
`;

const MySwiper = styled(Swiper)`
  position: absolute;
  width: 100%;
  height: 4.5rem;
`;

const AdItem = styled.div`
  position: relative;
  padding: 1.2rem 1.2rem;
  height: 4.5rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  overflow: none;
  cursor: pointer;
  & div {
    width: 4rem;
    height: 2rem;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7;
    color: #9E9E9E;
    text-align: center;
    background-color: #FFFFFF;
    border: 0.1rem solid #bdbdbd;
    border-radius: 2.1rem;
  }
  & h2 {
    margin: 0;
    margin-left : 0.8rem;
    padding: 0;

    width: 100%;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const NoData = styled.p`
  height: 5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 1.3rem;
  color: #666;
`;