import React, { useRef } from 'react';
import styled from 'styled-components';
import { isEmptyObj, isValidImageFile } from '../utils/utils';

export const ImageUploadComponent = ({images, uploadProcess, deleteProcess, setDialog}) => {
  const photoInput = useRef();

  const handleUploadImageOnClick = () => {
    photoInput.current.click();
  }

  const handleUploadImage = (event) => {
    const files = event.target.files;
    if (files && 0 < files.length) {
      const file = files[0];
      let index = getImageIndex(images);
      if(index === 0 ) {
        setDialog({isOpen: true, message: "이미지는 10개이상 등록할 수 없습니다.", type: "alert"});
        return;
      }

      if(isValidImageFile(file, 10)) {
        uploadProcess(file, index);
      }
    }
  }

  const getImageIndex = (images) => {
    let index = 0;
    for (let i=1; i<11; i++) {
      if(isEmptyObj(images[i])) {
        return i;
      }
    }
    return index;
  };

  const handleDeleteOnclick = (index, fileId) => {
    if(window.confirm("이미지를 삭제하시겠습니까?")) {
      deleteProcess(index, fileId);
    }
  }

  // render -------------------------------------------------
  const addImageBoxes = (images) => {
    const result = [];
    if(images) {
      Object.keys(images).forEach((key) => {
        result.push(addImageBox(key, images[key]));
      })
    }
    return result;
  }

  const addImageBox = (index, image) => {
    const fileName = image?.fileName;
    const filePath = image?.filePath;
    const fileId = image?.fileId;
    const domain = image?.domain;
    return (
      <li key={fileId}>
        {fileName && (
          <PreviewImageBox>
            <img
              className={'preview-img'}
              src={`${domain}${filePath}${fileName}`}
              alt={`이미지 ${index}`} />
            {(index==="1") && (<span>대표</span>)}
            <button type="button" onClick={() => handleDeleteOnclick(index, fileId)} />
          </PreviewImageBox>
        )}
      </li>
    );
  }

  return (
    <ImageWrapper>
      <ul>
        <UploadImageBox key={'0'} onClick={handleUploadImageOnClick}>
          <label>
            <img src='/icon/camera-icon.png' alt='이미지 업로드 버튼'/>
            <span>0/10</span>
          </label>
          <input className="form-input-file"
            type="file"
            id='btnImageFile'
            accept="image/jpg, image/jpeg, image/png"
            ref={photoInput}
            onChange={(e) => handleUploadImage(e)}/>
        </UploadImageBox>
        {addImageBoxes(images)}
      </ul>
    </ImageWrapper>
  );
}

const ImageWrapper = styled.section`
  margin: 2.7rem 0;
  & ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    & li {
      margin: 0.5rem 0.8rem;
    }
  }
`;

const UploadImageBox = styled.li`
  position: relative;
  width: 7rem;
  height: 7rem;
  background-color: #f5f5f5;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  cursor: pointer;
  & input.form-input-file {
    display: none;
  }
  & img {
    position: absolute;
    top: 0.6rem;
    left: 1.1rem;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
  }
  & span {
    position: absolute;
    top: 4rem;
    left: 2.5rem;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
    cursor: pointer;
  }
`;

const PreviewImageBox = styled.div`
  position: relative;
  width: 7rem;
  height: 7rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  border: 0.1rem solid #f5f5f5;
  & > img.preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
  & span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 3.3rem;
    height: 1.6rem;

    font-size: 10px;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: -0.04em;
    text-align: center;
    color: #FFFFFF;

    background-color: #fd6258;
    opacity: 0.9;
    border-radius: 0 0.4rem;
  }
  & button {
    position: absolute;
    top: -0.7rem;
    right: -0.7rem;

    margin: 0;
    padding: 0;
    width: 1.8rem;
    height: 1.8rem;

    background-color: #FFFFFF;
    background-image: url('/icon/x-mark.png');
    background-size: 0.6rem;
    background-repeat: no-repeat;
    background-position: 0.5rem 0.5rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 1rem;
    z-index: 3;
    cursor: pointer;
  }
`;