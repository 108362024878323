import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CommonLeftMenu } from '../../../common/CommonLeftMenu';

export const NewsHeader = () => {
  const navigate = useNavigate();

  const handleMenuOnClick = (url) => {
    navigate(url);
  }

  return (
    <>
      <MyHeader>
        <Logo>
          <div onClick={()=>handleMenuOnClick('/')}>
            <img src="/img/logo-vanchosun-news.png" alt="밴쿠버 조선일보 로고" />
          </div>
        </Logo>
        <CommonLeftMenu />
      </MyHeader>
    </>
  );
}
const MyHeader = styled.div`
  margin: 0;
  padding: 1.9rem 1.6rem;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const Logo = styled.div`
  position: relative;
  & div {
    width: 11rem;
    height: 2rem;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;