import React, { useState } from 'react';
import styled from 'styled-components';

export const CommunityReplySortComponent = ({ sortCode, sortName, changeSortData }) => {
  const [show, setShow] = useState(false);

  const handleToggleSortModal = () => {
    setShow(!show);
  }

  const handlerOnClick = (code, codeName) => {
    changeSortData(code, codeName);
    handleToggleSortModal();
  }

  return (
    <SortWrapper>
      <SortTitle onClick={(e)=> handleToggleSortModal(e)}>
        {sortName}
        {show?
          (
            <img src='/icon/angle-up-gray-icon.png' alt='정렬선택 열림'/>
          ):(
            <img src='/icon/angle-down-black-sm-icon.png' alt='정렬선택 닫힘'/>
          )
        }
      </SortTitle>
      <SortList show={show}>
        <ul>
          <li className={`${sortCode === 'updated' ? 'active' : ''}`} onClick={() => handlerOnClick("updated", "최신순")}>
            <div>최신순</div>
          </li>
          <li className={`${sortCode === 'created' ? 'active' : ''}`} onClick={() => handlerOnClick("created", "등록순")}>
            <div>등록순</div>
          </li>
        </ul>
      </SortList>
    </SortWrapper>
  );
};

const SortWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const SortTitle = styled.div`
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  color: #9E9E9E;
  cursor: pointer;
  & img {
    margin-left: 1rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`;
const SortList = styled.div`
  display: ${props => props.show ? '':'none'};
  position: absolute;
  top: 3.4rem;
  right: 0;
  z-index: 100;
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    & li {
      margin: 0rem;

      display: flex;
      align-items: center;

      & div {
        width: 14rem;
        height: 4.2rem;
        color: #747474;
        font-size: 1.4rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #eee;
        cursor: pointer;
      }
    }
    & li.active {
      & div {
        color: #FD6258;
        font-weight: bold;
        background-color: #FFEAE9;
      }
    }
  }
`;