import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../common/SkeletonComponent';
import { MarketMainTopAdsModal } from './MarketMainTopAdsModal';
import { AdsApi } from './api/AdsApi';
import { useInterval } from '../../hooks/useInterval';

export const MarketMainTopAd = React.memo(({ position, listSize=5 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});
  const [slidePosition, setSlidePosition] = useState(null);
  const [page, setPage] = useState(0);
  const [showMarketMainTopAdsModal, setShowMarketMainTopAdsModal] = useState(false);

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(
      position,
      listSize,
      (data)=> {
        setAds(data);
      },
      (error)=> {
        console.log(error);
      }
    );
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const toggleMarketMainTopAdModal = () => {
    setShowMarketMainTopAdsModal(!showMarketMainTopAdsModal);
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  const handleNextClick = () => {
    if(page + 1 < ads?.list?.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  const handlePreviousClick = () => {
    if(0 === page) {
      setPage(ads?.list?.length-1);
    } else {
      setPage(page - 1);
    }
  }

  const handleOnTouchStart = (e) => {
    if(2>ads?.list?.length) return;
    setSlidePosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    });
  }

  const handleOnTouchEnd = (e) => {
    if(2>ads?.list?.length) return;
    moveSlide(e.changedTouches[0].pageX, e.changedTouches[0].pageY, null);
  }

  const handleOnMouseDown = (e) => {
    if(2>ads?.list?.length) return;
    setSlidePosition({
      x: e.clientX,
      y: e.clientY
    });
  }

  const handleOnMouseUp = (e, row) => {
    if(2>ads?.list?.length) {
      handleOpenDetail(row);
    } else {
      moveSlide(e.clientX, e.clientY, row);
    }
  }

  const moveSlide = (clientX, clientY, row) => {
    const distanceX = Math.abs(slidePosition.x - clientX);
    const distanceY = Math.abs(slidePosition.y - clientY);

    if((distanceY + distanceX > 30) && (distanceX > distanceY)) {
      if(slidePosition.x - clientX < 0) {
        handlePreviousClick();
      } else if(slidePosition.x - clientX > 0) {
        handleNextClick();
      }
    } else if(row) {
      handleOpenDetail(row);
    }
  }

  useInterval(()=> {
    handleNextClick();
  }, (1<ads?.list?.length) ? 8000 : null);

  useEffect(() => {
    handleAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MarketMainTopAdSection>
        {(0 < ads?.list?.length) ? (
          <>
            <SlideList translateX={page}>
              {ads?.list?.map((row) => (
                <li key={row.id}>
                  <Item
                    onTouchStart={(e)=>handleOnTouchStart(e)}
                    onTouchEnd={(e)=>handleOnTouchEnd(e)}
                    onMouseDown={(e)=>handleOnMouseDown(e)}
                    onMouseUp={(e)=>handleOnMouseUp(e, row)}
                  >
                    <img src={`${row.imageFile1}`}
                      alt={`${row.title}`}
                      onError={(e)=>handleImageError(e)}
                    />
                  </Item>
                </li>
              ))}
            </SlideList>
            <NextButton onClick={()=>handleNextClick()}>Next</NextButton>
            <PopupModalIcon onClick={toggleMarketMainTopAdModal}>
              <div>
                {page+1}/{ads?.list?.length}
              </div>
              <img src={`/icon/plus-top-banner-icon.png`} alt='광고 모아보기 버튼'/>
            </PopupModalIcon>
          </>
        ) : (
          <SkeletonComponent>
            <SkeletonSection />
          </SkeletonComponent>
        )}
      </MarketMainTopAdSection>
      <MarketMainTopAdsModal
        show={showMarketMainTopAdsModal}
        toggle={toggleMarketMainTopAdModal}
        list={ads?.list}
      />
    </>
  );
});

const MarketMainTopAdSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: auto;
  min-height: 32.7rem;
  height: 100%;
  overflow: hidden;

  background-color: #ffffff;
  box-sizing: border-box;
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 32.7rem;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
  transform: ${props => `translateX(${props.translateX * -100}%)`};
  transition: all 0.45s ease;
  & li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
  }
`;

const NextButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.4rem;
  width: 4rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  text-align: center;
  color: #FFFFFF;
  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 0 0.4rem;
  cursor: pointer;
  z-index: 10;
`;

const PopupModalIcon = styled.div`
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  width: 5.8rem;
  height: 2.1rem;

  font-size: 12px;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: -0.04em;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid #FFFFFF;
  border-radius: 30rem;
  cursor: pointer;
  z-index: 1;
  & img {
    padding-left: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const SkeletonSection = styled.div`
  width: 100%;
  height: 32.7rem;
  background-color: #F5F5F5;
`;
