import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CommonLeftMenu } from '../../../common/CommonLeftMenu';

export const MarketHeaderForList = () => {
  const navigate = useNavigate();

  const handleMenuOnClick = (url) => {
    navigate(url);
  }

  return (
    <>
      <MyHeader>
        <Logo>
          <div onClick={()=>handleMenuOnClick('/market')}>
            <img src='/img/logo-vanmarket.png' alt='밴마켓 로고' />
          </div>
        </Logo>
        <CommonLeftMenu />
      </MyHeader>
    </>
  );
}
const MyHeader = styled.div`
  margin: auto;
  width: auto;
  background-color: #ffffff;
  cursor: pointer;
`;
const Logo = styled.div`
  position: relative;
  height: 5.4rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  & div {
    padding-left: 1.6rem;
    & img {
      width: 10.8rem;
      height: 1.525rem;
      font-size: 16px;
      font-weight: 500;
      line-height: 2.3rem;
    }
  }
`;