import React from 'react'
import styled from 'styled-components';
import { CheckBoxComponent } from './CheckBoxComponent';
import { RadioButtonComponent } from './RadioButtonComponent';
import { SwitchComponent } from './SwitchComponent';
// import { RangeComponent } from './RangeComponent';
// import { priceToString } from '../../../../utils/utils';
import {
  DwellingFilterAdditionalFacilityList,
  DwellingFilterIncludedOptionList,
  DwellingTypeList,
} from '../../../../__mocks__/PostData';

export const DwellingFilterModal = ({ show, toggle, searchData, changeSearchData, handleSearchClick }) => {

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerOnClick = () => {
    handleToggleModal?.();
  }

  const handlerApplyOnClick = () => {
    handleSearchClick?.();
    handleToggleModal();
  }

  const handlerResetFilterDataOnClick = () => {
    changeSearchData("types", '');
    changeSearchData("bedRoomCount", 0);
    changeSearchData("bathroomCount", 0);
    changeSearchData("includedOptions", '');
    changeSearchData("additionalFacilities", '');
    changeSearchData("flagType", '');
    changeSearchData("minPrice", 0);
    changeSearchData("maxPrice", 3000000);
  }

  const handleTypeOnChange = (value) => {
    changeSearchData("types", value);
  }

  const handleBedRoomCountOnChange = (value) => {
    changeSearchData("bedRoomCount", value);
  }

  const handleBathroomCountOnChange = (value) => {
    changeSearchData("bathroomCount", value);
  }

  const handleIncludedOptionsOnChange = (value) => {
    changeSearchData("includedOptions", value);
  }

  const handleAdditionalFacilitiesOnChange = (value) => {
    changeSearchData("additionalFacilities", value);
  }

  const handleFlagTypeOnChange = (key, value) => {
    changeSearchData('flagType', value);
  }

  // const handlePriceOnChange = (minPrice, maxPrice) => {
  //   changeSearchData("minPrice", minPrice);
  //   changeSearchData("maxPrice", maxPrice);
  // }

  return (
    <>
      {show && (
        <Modal>
          <ModalBody>
            <FilterWrapper>
              <FilterTitle>
                <div>필터</div>
                <CloseBtn src="/icon/x-mark.png" alt='닫기 버튼' onClick={handlerOnClick}/>
              </FilterTitle>
              <Main>
                <Section>
                  <div className='title'>
                    건물타입
                  </div>
                  <CheckBoxComponent
                    id='types'
                    value={searchData?.types}
                    list={DwellingTypeList}
                    onChange={handleTypeOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    방 개수
                  </div>
                  <RadioButtonComponent
                    id='bedroomCount'
                    value={searchData?.bedRoomCount}
                    list={[{value:1,label:'1+'},{value:2,label:'2+'},{value:3,label:'3+'},{value:4,label:'4+'},{value:5,label:'5+'}]}
                    width={'18%'}
                    onChange={handleBedRoomCountOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    욕실 개수
                  </div>
                  <RadioButtonComponent
                    id='bathroomCount'
                    value={searchData?.bathroomCount}
                    list={[{value:1,label:'1+'},{value:2,label:'2+'},{value:3,label:'3+'},{value:4,label:'4+'},{value:5,label:'5+'}]}
                    width={'18%'}
                    onChange={handleBathroomCountOnChange}
                  />
                </Section>
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    매매 가격
                  </div>
                  <RangeTitle>{priceToString(searchData?.minPrice, 3)} ~ {priceToString(searchData?.maxPrice, 3)}</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <RangeComponent
                      id='price'
                      min={0}
                      max={30000000}
                      median={'$15,000,000'}
                      step={10000}
                      minValue={searchData?.minPrice}
                      maxValue={searchData?.maxPrice}
                      onChange={handlePriceOnChange}
                    />
                  </div>
                </Section> */}
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    추가옵션
                  </div>
                  <CheckBoxComponent
                    id='includedOptions'
                    value={searchData?.includedOptions}
                    list={DwellingFilterIncludedOptionList}
                    onChange={handleIncludedOptionsOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    부대시설
                  </div>
                  <CheckBoxComponent
                    id='additionalFacilities'
                    value={searchData?.additionalFacilities}
                    list={DwellingFilterAdditionalFacilityList}
                    onChange={handleAdditionalFacilitiesOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div style={{paddingBottom: '1rem'}}>
                    <SwitchComponent
                      title='급매 모아보기'
                      id='distressSale'
                      value={searchData?.flagType}
                      checkedValue='급매'
                      onChange={handleFlagTypeOnChange}
                    />
                  </div>
                  <div style={{paddingBottom: '1rem'}}>
                    <SwitchComponent
                      title='분양 모아보기'
                      id='preConstruction'
                      value={searchData?.flagType}
                      checkedValue='분양'
                      onChange={handleFlagTypeOnChange}
                    />
                  </div>
                  <div>
                    <SwitchComponent
                      title='전매 모아보기'
                      id='assignment'
                      value={searchData?.flagType}
                      checkedValue='전매'
                      onChange={handleFlagTypeOnChange}
                    />
                  </div>
                </Section>
              </Main>
              <ButtonGroup>
                  <ButtonStyle
                    type='button'
                    width='28%'
                    color='#BDBDBD'
                    backgroundColor='#F5F5F5'
                    borderColor='#F5F5F5'
                    onClick={handlerResetFilterDataOnClick}
                  >
                    초기화
                  </ButtonStyle>
                  <ButtonStyle
                    type='button'
                    width='68%'
                    color='#FFFFFF'
                    backgroundColor='#FD6258'
                    borderColor='#FD6258'
                    onClick={handlerApplyOnClick}
                  >
                    적용하기
                  </ButtonStyle>
                </ButtonGroup>
            </FilterWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;

const FilterWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const FilterTitle = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #f5f5f5;
  & div {
    margin: 0 auto;
    width: 8rem;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
  };
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.6rem;
  right: 1.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Main = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  height: 100%;
`;

const Section = styled.div`
  position: relative;
  margin: 0;
  padding: 2.4rem 0rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000;
  & div.title {
    margin-bottom: 2.4rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ButtonGroup = styled.div`
  margin: 0;
  padding: 1.3rem 1.6rem 2.7rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid #F5F5F5;
`;

const ButtonStyle = styled.button`
  width: ${props => props.width};
  height: 4.8rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.borderColor};
  border-radius: 0.4rem;
  cursor: pointer;
`;

// const RangeTitle = styled.div`
//   position: absolute;
//   top: 5.2rem;
//   left: 0;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 2.3rem;
//   letter-spacing: -0.04em;
//   color: #9E9E9E;
// `;
