import React, { useState } from 'react';
import styled from 'styled-components';
// import { RecaptchaComponent } from '../../../../common/RecaptchaComponent';
import { CommunityReplyDetailComponent } from './CommunityReplyDetailComponent';
import { CommunityApi } from '../../api/CommunityApi';
import { UserApi } from '../../../user/api/UserApi';
import { isBlankValue } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';

export const CommunityReplyListComponent = ({ boardId, bdId, list, startPoint, perView, postOnClick, setLoading, setDialog, handleReplaySearchClick}) => {
  const communityApi = new CommunityApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const [isMore, setIsMore] = useState(false);
  const [isWrite, setIsWrite] = useState(false);
  const [writeData, setWriteData] = useState({
    id: '',
    boardId: boardId,
    bdId: bdId,
    name: '',
    password: '',
    comment: '',
    recaptcha: '',
  });

  const initWriteDate = () => {
    setWriteData({
      id: '',
      boardId: boardId,
      bdId: bdId,
      name: '',
      password: '',
      comment: '',
    });
  }

  const changeWriteData = (name, value) => {
    setWriteData((writeData) => {
      const updated = {...writeData};
      updated[name] = value;
      return updated;
    });
  };

  // handler ---------------------------------------------
  const toggleShowMore = () => {
    setIsMore(!isMore);
  }

  const showMoreOnClick = () => {
    setIsMore(true);
  }

  const handleWriteOnChange = (key, value) => {
    switch(key) {
      case 'name':
        changeWriteData(key, removeSpecificCharactersForName(value));
        break;
      case 'comment':
        if(301 <= writeData?.comment.length) {
          alert(`내용을 300자 이내로 기재해주세요.`);
          changeWriteData(key, value.substring(0, 300));
        } else {
          changeWriteData(key, value);
        }
        break;
      default:
        changeWriteData(key, value);
        break;
    }
  };

  const handleCreateReplyOnClick = () => {
    if(validateReplyData()) {
      setLoading(true);
      const bdId = writeData.bdId;
      const saveData = JSON.stringify({
        'boardId': boardId,
        'bdId': bdId,
        'comment': writeData.comment,
        // 'recaptcha': writeData.recaptcha,
      });

      communityApi.createReplyComment(bdId, saveData,
        (data) => {
          setLoading(false);
          handleReplaySearchClick();
          toggleWriteButton();
        },
        (error)=> {
          setLoading(false);
          const {response: {data}} = error;
          const { status, message, errors } = data;
          if(404 === status) {
            setDialog({isOpen: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
        }
      );
    }
  }

  const toggleWriteButton = () =>{
    if(userApi.isSignIn()) {
      setIsWrite(!isWrite);
      if(!isWrite) {
        initWriteDate();
      }
    } else {
      navigate("/signIn");
    }
  }

  // ==== valid check ---------------------------------
  const removeSpecificCharactersForName = (value) => {
    const regExp = /[ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|\s]/gi;	//정규식 구문
    if(value) {
      let notAllowedStr = value.replace(regExp , '');
      if(notAllowedStr) {
        alert(`허용하지 않는 문자 ${notAllowedStr} 는 입력하실수 없습니다.`);
        let notAllowedAry = Array.from(notAllowedStr);
        notAllowedAry.forEach(element=>{
          value = value.replace(element, '');
        });
        return value;
      }
    }
    return value;
  }

  const validateReplyData = () => {
    if(isBlankValue(writeData.comment)) {
      setDialog({isOpen: true, message: "내용은 필수입니다.", type: "alert"});
      return false;
    }
    return true;
  }

  // render -------------------------------------------------
  const renderPostItems = (startPoint, perView, replyList, toggleShowMore, showMoreOnClick) => {
    let result = [];
    if(replyList.length > startPoint) {
      const loopStart = startPoint;
      const loopEnd = (replyList.length < (perView + startPoint))? replyList.length: perView + startPoint;
      for(let i=loopStart; i<loopEnd; i++) {
        let row = replyList[i];
        result.push (
          <CommunityReplyDetailComponent
            key={row.id}
            id={row.id}
            owner={row.owner}
            boardId={row.boardId}
            bdId={row.bdId}
            name={row.name}
            comment={row.description}
            postDate={row.postDate}
            setLoading={setLoading}
            setDialog={setDialog}
            handleReplaySearchClick={handleReplaySearchClick}
            showMoreOnClick={showMoreOnClick}
          />
        )
      }
    }
    return result;
  }

  return (
    <ReplyListWrapper>
      {isWrite ? (
        <ReplayWrite>
          <div>
            <textarea placeholder='댓글을 남겨주세요.' value={writeData.comment} onChange={(e)=>handleWriteOnChange('comment', e.target.value)} />
          </div>
          {/* <RecaptchaComponent
            onChange={handlerRecaptchaOnChange}
          /> */}
          <div className='replayWriteFooter'>
            <span>{writeData?.comment.length}/300</span>
            <div>
              <button type='button' onClick={()=>toggleWriteButton()}>취소</button>
              <button type='button' onClick={()=>handleCreateReplyOnClick()}>등록</button>
            </div>
          </div>
        </ReplayWrite>
      ) : (
        <ReplayWriteButton onClick={()=>toggleWriteButton()}>
          <img src='/icon/reply-icon.png' alt='댓글 쓰기' />
          댓글을 남겨주세요.
        </ReplayWriteButton>
      )}
      {0 < list?.length && (
        <>
          <ReplySection height={isMore ? '110%': '5.5rem'}>
            {renderPostItems(startPoint, perView, list, toggleShowMore, showMoreOnClick)}
          </ReplySection>
          {isMore ? (
            <MoreButton onClick={()=>toggleShowMore()}>
              댓글 접기
              <img src='/icon/angle-up-black-m-icon.png' alt='접기 버튼' />
            </MoreButton>
          ) : (
            <MoreButton onClick={()=>toggleShowMore()}>
              댓글 더보기
              <img src='/icon/angle-down-black-m-icon.png' alt='더보기 버튼' />
            </MoreButton>
          )}
        </>
      )}
    </ReplyListWrapper>
  );
};

const ReplyListWrapper = styled.div`
  padding-bottom: 1.3rem;
  width: 100%;
`;

const ReplayWrite = styled.div`
  margin: 0 1.6rem;
  width: auto;
  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & input {
      margin: 1rem 0rem;
      padding: 0 1rem;
      width: 42%;
      height: 4rem;
      border: 0.1rem solid #F5F5F5;
      border-radius: 0.4rem;
    }
    & input:focus {
      outline: none;
    }
    & textarea {
      padding: 1rem;
      width: 100%;
      height: 14.4rem;
      outline: none;
      resize: none;
      border: 1px solid #F5F5F5;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
  }
  & div.replayWriteFooter {
    height: 4.6rem;
    display: flex;
    align-items: center;
    & span {
      margin-left: 1.5rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #424242;
    }
    & div {
      width: 12.5rem;
      & button {
        width: 5.4rem;
        height: 3rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.04em;
        color: #616161;
        background-color: #F5F5F5;
        border: 0.1rem solid #F5F5F5;
        border-radius: 0.4rem;
        cursor: pointer;
      }
      & button:last-child {
        color: #FD6258;
        background-color: #FFEAE9;
        border: 0.1rem solid #FFEAE9;
      }
    }
  }
`;

const ReplayWriteButton = styled.div`
  margin: 0 1.6rem;
  padding: 0 1.3rem;
  width: auto;
  height: 4.6rem;

  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #424242;

  display: flex;
  align-items: center;
  border: 0.1rem solid #EEEEEE;
  border-radius: 0.4rem;
  cursor: pointer;
  & img {
    margin-right: 0.7rem;
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const ReplySection = styled.ul`
  margin: 0;
  padding: 0 1.6rem 6rem;
  width: auto;
  height: ${({ height }) => height};
  overflow: hidden;
  list-style: none;
`;

const MoreButton = styled.div`
  position: relative;
  margin-bottom: 3rem;
  width: 100%;
  height: 5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.027rem;

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.04em;
  color: #424242;
  border-top: 0.1rem solid #E0E0E0;
  border-bottom: 0.1rem solid #E0E0E0;
  cursor: pointer;
  & img {
    margin-left: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`;
