import qs from "qs";
import { jsonApi } from "../../../utils/AxiosApi";

export class CommercialApi {

  fetchCommercialList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/commercial`,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      params: searchData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommercialById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/commercial/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}