import React, { memo } from 'react';
import styled from 'styled-components';

export const MarketTextListComponent = memo(({ list, link, postOnClick }) =>{

  const handlerItemCardOnClick = (url, extUrl) => {
    if(extUrl) {
      window.open(`${extUrl}`);
    }
    postOnClick?.(url);
  }

  const addPremiumTag = (premium) => {
    if("1" === premium) {
      return (<span>[프리미엄]</span>);
    } else {
      return '';
    }
  }

  return (
    <MarketTextListWrapper>
      {list.length === 0 ? (
        <NoData>데이터가 없습니다.</NoData>
      ):(
        <TextItemSection>
          {list.map((row) => (
            <TextItem key={row.id}>
              <div onClick={()=>handlerItemCardOnClick(`/${link}/${row.id}`, row.link)} >
                {addPremiumTag(row.premium)}
                {row.title}
              </div>
            </TextItem>
          ))}
        </TextItemSection>
      )}
    </MarketTextListWrapper>
  );
});

const MarketTextListWrapper = styled.div`
  width: 100%;
`;

const TextItemSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const TextItem = styled.li`
  padding: 1.3rem 1rem;
  border-bottom: 0.1rem solid #dfdfdf;
  & div {
    width: 100%;
    text-decoration: none;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #000;

    display: inline-block;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    & span {
      color: #D6000F;
      margin-right: 1rem;
    }
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 1.3rem;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;