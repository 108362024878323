import React from 'react'
import styled from 'styled-components';
import { CheckBoxComponent } from './CheckBoxComponent';
// import { RangeComponent } from './RangeComponent';
// import { priceToString } from '../../../../utils/utils';
import {
  CommercialAdditionalFacilityList,
  CommercialIncludedOptionList,
  CommercialTypeList,
} from '../../../../__mocks__/PostData';


export const CommercialFilterModal = ({ show, toggle, searchData, changeSearchData, handleSearchClick }) => {

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerOnClick = () => {
    handleToggleModal?.();
  }

  const handlerApplyOnClick = () => {
    handleSearchClick?.();
    handleToggleModal();
  }

  const handlerResetFilterDataOnClick = () => {
    changeSearchData("types", '');
    changeSearchData("minPrice", 0);
    changeSearchData("maxPrice", 3000000);
    changeSearchData("minFloorNumber", 0);
    changeSearchData("maxFloorNumber", 30);
    changeSearchData("minNumberParkingSpaces", 0);
    changeSearchData("maxNumberParkingSpaces", 100);
    changeSearchData("minIndoorSpace", 0);
    changeSearchData("maxIndoorSpace", 0);
    changeSearchData("minLandSpace", 0);
    changeSearchData("maxLandSpace", 0);
  }

  const handleTypeOnChange = (value) => {
    changeSearchData("types", value);
  }

  // const handlePriceOnChange = (minPrice, maxPrice) => {
  //   changeSearchData("minPrice", minPrice);
  //   changeSearchData("maxPrice", maxPrice);
  // }

  // const handleFloorNumberOnChange = (minFloorNumber, maxFloorNumber) => {
  //   changeSearchData("minFloorNumber", minFloorNumber);
  //   changeSearchData("maxFloorNumber", maxFloorNumber);
  // }

  // const handleNumberParkingSpacesOnChange = (minNumberParkingSpaces, maxNumberParkingSpaces) => {
  //   changeSearchData("minNumberParkingSpaces", minNumberParkingSpaces);
  //   changeSearchData("maxNumberParkingSpaces", maxNumberParkingSpaces);
  // }

  // const handleMinIndoorSpaceOnChange = ({target}) => {
  //   changeSearchData("minIndoorSpace", target.value);
  // }

  // const handleMaxIndoorSpaceOnChange = ({target}) => {
  //   changeSearchData("maxIndoorSpace", target.value);
  // }

  // const handleMinLandSpaceOnChange = ({target}) => {
  //   changeSearchData("minLandSpace", target.value);
  // }

  // const handleMaxLandSpaceOnChange = ({target}) => {
  //   changeSearchData("maxLandSpace", target.value);
  // }

  const handleIncludedOptionsOnChange = (value) => {
    changeSearchData("includedOptions", value);
  }

  const handleAdditionalFacilitiesOnChange = (value) => {
    changeSearchData("additionalFacilities", value);
  }

  return (
    <>
      {show && (
        <Modal>
          <ModalBody>
            <FilterWrapper>
              <FilterTitle>
                <div>필터</div>
                <CloseBtn src="/icon/x-mark.png" alt='닫기 버튼' onClick={handlerOnClick}/>
              </FilterTitle>
              <Main>
                <Section>
                  <div className='title'>
                    건물타입
                  </div>
                  <CheckBoxComponent
                    id='types'
                    value={searchData?.types}
                    list={CommercialTypeList}
                    onChange={handleTypeOnChange}
                  />
                </Section>
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    매매 가격
                  </div>
                  <RangeTitle>{priceToString(searchData?.minPrice, 3)} ~ {priceToString(searchData?.maxPrice, 3)}</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <RangeComponent
                      id='price'
                      min={0}
                      max={30000000}
                      median={'$15,000,000'}
                      step={10000}
                      minValue={searchData?.minPrice}
                      maxValue={searchData?.maxPrice}
                      onChange={handlePriceOnChange}
                    />
                  </div>
                </Section> */}
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    실내 면적
                  </div>
                  <IndoorSpaceGroup style={{display: 'flex', alignItems: 'center',}}>
                    <input type='number'
                      id='minIndoorSpace'
                      min='0'
                      value={searchData?.minIndoorSpace}
                      onChange={handleMinIndoorSpaceOnChange} />
                    ~
                    <input type='number'
                      id='maxIndoorSpace'
                      min='0'
                      value={searchData?.maxIndoorSpace}
                      onChange={handleMaxIndoorSpaceOnChange} />
                  </IndoorSpaceGroup>
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    대지 면적
                  </div>
                  <IndoorSpaceGroup style={{display: 'flex', alignItems: 'center',}}>
                    <input type='number'
                      id='minLandSpace'
                      min='0'
                      value={searchData?.minLandSpace}
                      onChange={handleMinLandSpaceOnChange} />
                    ~
                    <input type='number'
                      id='maxLandSpace'
                      min='0'
                      value={searchData?.maxLandSpace}
                      onChange={handleMaxLandSpaceOnChange} />
                  </IndoorSpaceGroup>
                </Section> */}
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    층수
                  </div>
                  <RangeTitle>{searchData?.minFloorNumber} ~ {searchData?.maxFloorNumber}층</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <RangeComponent
                      id='floorNumber'
                      min={0}
                      max={10}
                      median={'15층'}
                      step={1}
                      minValue={searchData?.minFloorNumber}
                      maxValue={searchData?.maxFloorNumber}
                      onChange={handleFloorNumberOnChange}
                    />
                  </div>
                </Section> */}
                {/* <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    주차대수
                  </div>
                  <RangeTitle>{searchData?.minNumberParkingSpaces} ~ {searchData?.maxNumberParkingSpaces}대 이상</RangeTitle>
                  <div style={{marginTop: '3.8rem'}}>
                    <RangeComponent
                      id='numberParkingSpaces'
                      min={0}
                      max={100}
                      median={'50대'}
                      step={1}
                      minValue={searchData?.minNumberParkingSpaces}
                      maxValue={searchData?.maxNumberParkingSpaces}
                      onChange={handleNumberParkingSpacesOnChange}
                    />
                  </div>
                </Section> */}
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    추가옵션
                  </div>
                  <CheckBoxComponent
                    id='includedOptions'
                    value={searchData?.includedOptions}
                    list={CommercialIncludedOptionList}
                    onChange={handleIncludedOptionsOnChange}
                  />
                </Section>
                <Separator height='0.1rem' />
                <Section>
                  <div className='title'>
                    특이사항
                  </div>
                  <CheckBoxComponent
                    id='additionalFacilities'
                    value={searchData?.additionalFacilities}
                    list={CommercialAdditionalFacilityList}
                    onChange={handleAdditionalFacilitiesOnChange}
                  />
                </Section>
              </Main>
              <ButtonGroup>
                <ButtonStyle
                  type='button'
                  width='28%'
                  color='#BDBDBD'
                  backgroundColor='#F5F5F5'
                  borderColor='#F5F5F5'
                  onClick={handlerResetFilterDataOnClick}
                >
                  초기화
                </ButtonStyle>
                <ButtonStyle
                  type='button'
                  width='68%'
                  color='#FFFFFF'
                  backgroundColor='#FD6258'
                  borderColor='#FD6258'
                  onClick={handlerApplyOnClick}
                >
                  적용하기
                </ButtonStyle>
              </ButtonGroup>
            </FilterWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;

const FilterWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const FilterTitle = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #f5f5f5;
  & div {
    margin: 0 auto;
    width: 8rem;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
  };
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.6rem;
  right: 1.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Main = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  height: 100%;
`;

const Section = styled.div`
  position: relative;
  margin: 0;
  padding: 2.4rem 0rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000;
  & div.title {
    margin-bottom: 2.4rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const ButtonGroup = styled.div`
  margin: 0;
  padding: 1.3rem 1.6rem 2.7rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid #F5F5F5;
`;

const ButtonStyle = styled.button`
  width: ${props => props.width};
  height: 4.8rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.borderColor};
  border-radius: 0.4rem;
  cursor: pointer;
`;

// const RangeTitle = styled.div`
//   position: absolute;
//   top: 5.2rem;
//   left: 0;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 2.3rem;
//   letter-spacing: -0.04em;
//   color: #9E9E9E;
// `;

// const IndoorSpaceGroup = styled.div`
//   display: 'flex';
//   align-items: 'center';
//   justify-content: space-between;

//   font-size: 16px;
//   font-weight: 400;
//   line-height: 2.3rem;
//   letter-spacing: -0.04em;
//   color: #000000;

//   & input {
//     padding: 0 1rem;
//     width: 40%;
//     height: 4.6rem;

//     font-size: 16px;
//     font-weight: 400;
//     line-height: 2.3rem;
//     letter-spacing: -0.04em;
//     color: #000000;
//     text-align: right;

//     background: #FFFFFF;
//     border: 0.1rem solid #E0E0E0;
//     border-radius: 0.4rem;
//     outline: none;
//   }
// `;
