import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ImageGalleryModal } from '../component/ImageGalleryModal';
import { ImageSlideComponent } from '../component/ImageSlideComponent';
import { GoogleAdvertiseForInFeed } from '../../ads/GoogleAdvertiseForInFeed';
import { openErrorModalForApi } from '../../../utils/ErrorHandler';
import { BuysellApi } from '../api/BuysellApi';
import { addHyphenForPhoneNumber, elapsedText, priceToString } from '../../../utils/utils';
import {
  BuysellCategoriesWithFree
} from '../../../__mocks__/PostData';
// import CopyToClipboard from 'react-copy-to-clipboard';

export const BuysellDetail = ({setDialog}) => {
  const buysellApi = new BuysellApi();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewEditConformGroup, setViewEditConformGroup] = useState(false);
  const [viewDeleteConformGroup, setViewDeleteConformGroup] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [article, setArticle] = useState({
    id: '',
    boardId: 1,
    active: '0',
    name: '',
    password: null,
    phone: '',
    phoneExt: '',
    email: '',
    contactCode: '',
    title: '',
    categoryCode: '',
    employmentType: '',
    salaryRate: '',
    price: 0,
    careerType: '',
    endRecruitmentDate: '',
    location: '',
    description: '',
    payType: '',
    owner: false,
    premium: false,
    updatedDate: '',
    images: {},
  });

  // == state 변경 ------------------------------------------------

  // handler ---------------------------------------------
  const isPremium = () => {
    return (1 === article?.premium) ? true : false;
  }

  const handleSearchClick = () => {
    buysellApi.fetchBuysellById(
      id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        openErrorModalForApi(error, setDialog);
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate(-1, {state: {isBack: true}});
  }

  const handleViewEditConformOnClick = () => {
    setViewEditConformGroup((value) => !value);
    if(viewDeleteConformGroup)
      handleViewDeleteConformOnClick();
  }

  const handleEditOnClick = () => {
    if(article.id) {
      navigate(`/buysell-edit/${article.id}`,  {
        state: {
          id: article.id,
        },
      });
    }
  }

  const handleViewDeleteConformOnClick = () => {
    setViewDeleteConformGroup((value) => !value);
    if(viewEditConformGroup)
      handleViewEditConformOnClick();
  }

  const handleDeleteOnClick = () => {
    if(article.id && window.confirm("삭제하시겠습니까?")) {
      buysellApi.deleteBuysell(article.id,
        (data) => {
          setDialog({isOpen: true, message: "삭제 되었습니다.", type: "alert"});
          handleHistoryBackClick();
        },
        (error)=> {
          console.log(error);
        }
      );
    }
  }

  const handlerToggleShowModal = () => {
    setShowImageModal(!showImageModal);
  }

  // render -------------------------------------------------
  const addPremiumTag = () => {
    if(isPremium()) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addConditionTag = ({conditionCode}) => {
    if(isPremium()) return '';
    if("new" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/new-condition-icon.png' alt='미사용' style={{width: '4.2rem', height: '1.8rem'}}/>);
    } else if("good" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/good-condition-icon.png' alt='상태좋음' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("used" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/used-condition-icon.png' alt='사용감 있음' style={{width: '6rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addPurchaseTag = ({purchaseStateCode}) => {
    if(isPremium()) return '';
    if("sold-out" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/sold-out-status-icon.png' alt='판매완료' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("trading" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/reserved-status-icon.png' alt='거래중' style={{width: '4rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  // const addTypeTag = ({type}) => {
  //   if(isPremium()) return '';
  //   if("무빙세일" === type) {
  //     return (<span className='typeIcon'>무빙세일</span>);
  //   } else if("무료나눔" === type) {
  //     return (<span className='typeIcon'>무료나눔</span>);
  //   }
  //   return '';
  // }

  const addPrice = ({price, type}) => {
    if(0 < price) {
      return priceToString(article?.price)
    } else if("무료나눔" === type) {
      return type;
    } else {
      return '가격문의';
    }
  }

  const addLocationNContactTag = ({updatedDate, hitCount}) => {
    if(isPremium()) return '';
    return (<span>{elapsedText(updatedDate)} · 조회수 {hitCount}</span>)
  }

  const addContactTag = ({contactCode, phone, email}) => {
    if(contactCode==="phone") {
      return (
        <>
          {phone && (
            <div>
              {addHyphenForPhoneNumber(phone)}
              {/* <CopyToClipboard text={addHyphenForPhoneNumber(phone)} onCopy={()=>alert('Copied!')}>
                <CopyButton/>
              </CopyToClipboard> */}
            </div>
          )}
        </>
      );
    } else if(contactCode==="email") {
      return (
        <>
          {email && (
            <div>
              {email}
              {/* <CopyToClipboard text={email} onCopy={()=>alert('Copied!')}>
                <CopyButton/>
              </CopyToClipboard> */}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {phone && (
            <div>
              {addHyphenForPhoneNumber(phone)}
              {/* <CopyToClipboard text={addHyphenForPhoneNumber(phone)} onCopy={()=>alert('Copied!')}>
                <CopyButton/>
              </CopyToClipboard> */}
            </div>
          )}
          {email && (
            <div>
              {email}
              {/* <CopyToClipboard text={email} onCopy={()=>alert('Copied!')}>
                <CopyButton/>
              </CopyToClipboard> */}
            </div>
          )}
        </>
      );
    }
  }

  const renderCategoryTag = ({categoryCode}) => {
    const label = categoryCode ? BuysellCategoriesWithFree[categoryCode]?.label : categoryCode;
    return (
      <>
        {(label) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span className='categoryLv1'>{label}</span>
          </>
        )}
      </>
    );
  }

  const renderDeleteEditButton = ({owner}) => {
    if(!owner) return '';
    return (
      <ConformGroup>
        <ButtonGroup>
          <ButtonStyle type='button' color="#fff" backgroundColor='#fd6258' onClick={() => handleEditOnClick()}>수정하기</ButtonStyle>
          <ButtonStyle type='button' color="#fd6258" backgroundColor='#fff' onClick={() => handleDeleteOnClick()}>삭제하기</ButtonStyle>
        </ButtonGroup>
      </ConformGroup>
    );
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ImageSlideComponent
        images={article.images}
        noImgUrl={`/img/no-image-buysell.png`}
        onClick={()=>handlerToggleShowModal()}
      />
      <Category>
        <span className='categoryLv0'>전체</span>
        {renderCategoryTag(article)}
        <span className='borderNumber'>{`게시글번호 ${article.id}`}</span>
      </Category>
      <ContentWrapper>
        <ArticleTag>
          {addPremiumTag()}
          {addConditionTag(article)}
          {addPurchaseTag(article)}
        </ArticleTag>
        <Title>{article.title}</Title>
        <UpdateInfo>
          {addLocationNContactTag(article)}
        </UpdateInfo>
        <PriceInfo>
          {addPrice(article)}
        </PriceInfo>
        <Info>
          <table>
            <tbody>
              <tr>
                <td className='head'>판매자</td>
                <td className='col'>{article.name}</td>
              </tr>
              <tr>
                <td className='head'>거래지역</td>
                <td className='col'>{article.locationKrName}</td>
              </tr>
              <tr>
                <td className='head'>거래문의</td>
                <td className='col'>
                  {addContactTag(article)}
                </td>
              </tr>
            </tbody>
          </table>
        </Info>
      </ContentWrapper>
      <ContentLabel>
        상품설명
      </ContentLabel>
      <Content dangerouslySetInnerHTML={{__html: article.description}} />
      {!isPremium() && (
        <GoogleAdvertiseForInFeed
          client="ca-pub-2380324855411184"
          slot="9838258151"
          format="fluid"
          responsive="true"
          layoutKey="-fb+5q+5e-d4+4m"
        />
      )}
      { renderDeleteEditButton(article) }
      <ImageGalleryModal
        title='상품사진'
        showModal={showImageModal}
        toggleShowModal={()=>handlerToggleShowModal()}
        imageList={article.images} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Category = styled.div`
  position: relative;
  padding-left: 1.4rem;
  width: auto;
  height: 4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #f5f5f5;
  & span.borderNumber {
    position: absolute;
    top: 0.8rem;
    right: 1.6rem;
    padding: 0.2rem 0.8rem;
    display: inline-block;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 30rem;
  }
  & span.categoryLv0 {
    color: #424242;
  }
  & span.categoryLv1 {
    font-weight: 500;
    color: #FD6258;
  }
  & img {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const ArticleTag = styled.div`
  margin-top: 1.3rem;
  display: flex;
  & img {
    display: inline-block;
    margin-right: 0.6rem;
    height: 1.8rem;
  }
  & img.premiumIcon {
    width: 5rem;
  }
  & img.conditionIcon {
    height: 1.8rem;
  }
  & img.purchaseIcon {
    height: 1.8rem;
  }
  & span.typeIcon {
    padding: 0 0.8rem;
    height: 1.8rem;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.06em;
    color: #FD6258;
    background: #FFFFFF;
    border: 0.1rem solid #FD6258;
    border-radius: 0.2rem;
    box-sizing: border-box;
  }
`;
const Title = styled.h1`
  margin: 0;
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  word-break: break-all;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;
const PriceInfo = styled.div`
  margin-top: 0.8rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 2rem;
  color: #000;
`;
const Info = styled.div`
  margin: 1.7rem 0;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    & td {
      font-size: 14px;
    }
    & td.head {
      width: 20%;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      vertical-align: top;
      color: #000;
    }
    & td.col {
      width: 70%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;
    }
    & td.left {
      text-align: left;
    }
    & td.right {
      text-align: right;
    }
  }
`;

const ContentLabel = styled.div`
  border-top: 1rem solid #f7f7f7;
  padding: 2rem 1.6rem 0 1.6rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 2rem;
  letter-spacing: -0.04em;
`;

const Content = styled.div`
  padding: 1rem 1.6rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: -0.04em;
  color: #000;
`;

const ConformGroup = styled.div`
  padding: 1.6rem;
  border-top: 0.1rem solid #f5f5f5;
`;
const ButtonGroup = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  column-gap: 0.6rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: ${props => props.color};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid #fd6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

// const CopyButton = styled.button`
//   margin-left: 0.4rem;
//   width: 1.6rem;
//   height: 1.6rem;
//   background-image: url( "/icon/chat-light-icon.png" );
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 1.6rem;
//   border: 0.1rem solid #ddd;
//   border-radius: 0.2rem;
//   cursor: pointer;
// `;