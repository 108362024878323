import React from 'react';
import styled from 'styled-components';

export const AdvertisingInformation = () => {
  return (
    <Wrapper>
      <img src='/img/advertising-information.jpg' alt='광고 안내'/>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin: auto;
  padding: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & img {
    width: 100%;
  }
`;