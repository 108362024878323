import { jsonApi } from '../../../utils/AxiosApi';

export class YellowpageApi {

  fetchYellowpageList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/yellowpage`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchYellowpageById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/yellowpage/${id}`,
    }).then((response) => {
      callback?.(response.data);
    })
    .catch((error) => {
      errorCallback?.(error);
    })
  }

}