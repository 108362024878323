import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

const Pagination = ({ currentPage, totalPage, perPageSize, handlePageChange, color='#fd6258' }) => {
  const [startPageNum, setStartPageNum] = useState(0);

  const calculatePageSize = () => {
    if (totalPage <= startPageNum + perPageSize-1) {
      return totalPage;
    } else {
      return startPageNum + perPageSize-1;
    }
  };

  const pageRendering = () => {
    const result = [];
    for(let i=startPageNum; i<=calculatePageSize(); i++) {
      result.push(
        <Page key={i}
          className={`${i === currentPage? 'active' : ''}`}
          color={color}
          onClick={()=>handlePageOnClick(i)}>{i}
        </Page>);
    }
    return result;
  };

  const handlePageOnClick = (pageNum) => {
    handlePageChange?.(pageNum);
  };

  const handleLeftArrowOnClick = () => {
    if(0 < startPageNum - perPageSize) {
      handlePageChange?.(startPageNum - perPageSize);
    }
  };

  const handleRightArrowOnClick = () => {
    if(currentPage < totalPage) {
      handlePageChange?.(startPageNum + perPageSize);
    }
  };

  const hasNextPage = () => {
    if((currentPage + perPageSize-1) < totalPage)
      return true;
    return false;
  }

  useEffect(() => {
    const startPageNum = (parseInt((currentPage-1) / perPageSize)*perPageSize)+1;

    setStartPageNum(startPageNum);
  }, [currentPage, totalPage, perPageSize]);

  return (
    <PaginationWrapper>
      <PaginationBox>
        {(5<currentPage) ? (
          <PageBack onClick={()=>handleLeftArrowOnClick()}>
            <img src='/icon/angle-back-gray-icon.png' alt='이전으로' />
          </PageBack>) : ''
        }

        {pageRendering()}
        {hasNextPage() && (
          <PageForward onClick={()=>handleRightArrowOnClick()}>
            <img src='/icon/angle-forward-gray-icon.png' alt='다음으로' />
          </PageForward>
        )}
      </PaginationBox>
    </PaginationWrapper>
  );
}

const PaginationWrapper = styled.div`
  padding: 1rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
const PaginationBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
const Page = styled.div`
  margin: 0 0.3rem;
  width: 3rem;
  height: 3rem;

  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 0.1rem solid #eee;
  border-radius: 0.7rem;
  cursor: pointer;
  &.active {
    color: #fff;
    background-color: ${props => props.color};
  }
  & img {
    width: 0.4rem;
    height: 0.8rem;
  }
`;

const PageBack = styled.div`
  margin: 0 0.3rem;
  width: 4rem;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 0.1rem solid #eee;
  border-radius: 0.7rem;
  cursor: pointer;

  & img {
    width: 0.4rem;
    height: 0.8rem;
  }
`;

const PageForward = styled.div`
  margin: 0 0.3rem;
  width: 4rem;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 0.1rem solid #eee;
  border-radius: 0.7rem;
  cursor: pointer;

  & img {
    width: 0.4rem;
    height: 0.8rem;
  }
`;

export default memo(Pagination);