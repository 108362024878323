import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { addHyphenForPhoneNumber, elapsedText, priceToString } from '../../../utils/utils';

export const MarketGridComponent = memo(({ list, link, isLoading=true, postOnClick}) => {

  const handlerPostOnClick = (url, extUrl) => {
    if(extUrl) {
      window.open(`${extUrl}`);
    }
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    if('buysell' === link) {
      e.target.src=`/img/no-image-buysell.png`;
    } else {
      e.target.src=`/img/no-image.png`;
    }
  }

  const addPremiumTag = (premium) => {
    if(isPremium(premium)) {
      return (<img className='premiumIcon' src='/icon/premium-icon.png' alt='프리미엄'/>);
    }
    return '';
  }

  const addConditionTag = ({premium, conditionCode}) => {
    if(isPremium(premium)) return '';
    if("new" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/new-condition-icon.png' alt='미사용' style={{width: '4.2rem', height: '1.8rem'}}/>);
    } else if("good" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/good-condition-icon.png' alt='상태좋음' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("used" === conditionCode) {
      return (<img className='conditionIcon' src='/icon/used-condition-icon.png' alt='사용감 있음' style={{width: '6rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addPurchaseTag = ({premium, purchaseStateCode}) => {
    if(isPremium(premium)) return '';
    if("sold-out" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/sold-out-status-icon.png' alt='판매완료' style={{width: '5rem', height: '1.8rem'}}/>);
    } else if("trading" === purchaseStateCode) {
      return (<img className='purchaseIcon' src='/icon/reserved-status-icon.png' alt='거래중' style={{width: '4rem', height: '1.8rem'}}/>);
    }
    return '';
  }

  const addTitleTag = ({premium, title}) => {
    if(isPremium(premium)) {
      return <h2 className='premiumTitle'>{title}</h2>;
    } else {
      return (<h2>{title}</h2>);
    }
  }

  const addLocationNContactTag = ({premium, phone, locationKrName, updatedDate}) => {
    if(isPremium(premium)) {
      return (
        <span>
          <img src='/icon/phone-icon.png' alt='Phone Number'/>
          {addHyphenForPhoneNumber(phone)}
        </span>
      );
    } else {
      return (<span>{locationKrName ? `${locationKrName} · ` : ''}{elapsedText(updatedDate)}</span>);
    }
  }

  const addPriceTag = ({premium, price, type}) => {
    if(isPremium(premium)) {
      return '';
    } else if('무료나눔' === type) {
      return (
        <div className='price'>{type}</div>
      )
    } else {
      return (
        <div className='price'>
          {(0 < price) ? priceToString(price) : '가격문의'}
        </div>
      );
    }
  }

  const isPremium = (premium) => {
    return ("1" === premium) ? true : false;
  }

  return (
    <GridListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <GridSection>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <h2 style={{height: '2.4rem', width: '11rem', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{marginTop: '0.4rem', width: '16rem', height: '1.8rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{marginTop: '0.4rem', width: '6rem', height: '2.3rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <h2 style={{height: '2.4rem', width: '11rem', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{marginTop: '0.4rem', width: '16rem', height: '1.8rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{marginTop: '0.4rem', width: '6rem', height: '2.3rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <h2 style={{height: '2.4rem', width: '11rem', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{marginTop: '0.4rem', width: '16rem', height: '1.8rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{marginTop: '0.4rem', width: '6rem', height: '2.3rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <h2 style={{height: '2.4rem', width: '11rem', backgroundColor: '#F5F5F5'}}> </h2>
                  <div style={{marginTop: '0.4rem', width: '16rem', height: '1.8rem', backgroundColor: '#F5F5F5'}}></div>
                  <div style={{marginTop: '0.4rem', width: '6rem', height: '2.3rem', backgroundColor: '#F5F5F5'}}></div>
                </InfoWrapper>
              </div>
            </GridItem>
          </GridSection>
        </SkeletonComponent>
      ) : (
        <>
          {0 < list.length ? (
            <GridSection>
              {list.map((row)=>(
                <GridItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`, row.link)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper>
                      {addPremiumTag(row)}
                      <div>
                        {addConditionTag(row)}
                        {addPurchaseTag(row)}
                      </div>
                      {addTitleTag(row)}
                      {addLocationNContactTag(row)}
                      {addPriceTag(row)}
                    </InfoWrapper>
                  </div>
                </GridItem>
              ))}
            </GridSection>
          ):(
            <NoData>데이터가 없습니다.</NoData>
          )}
        </>
      )}
    </GridListWrapper>
  );
});

const GridListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const GridSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
`;

const GridItem = styled.li`
  margin-top: 1.9rem;
  width: 16.6rem;
  height: 21.8rem;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  @media (max-width: 370px) {
    width: 16rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 12.4rem;
  background-color: #F5F5F5;
  border-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    background-color: #fff;
    border: 0.1rem solid #f5f5f5;
    border-radius: 0.4rem;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 1rem;
  height: 4rem;
  color: #666;
  & h2 {
    margin: 0;
    padding: 0;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0.4rem;
  }
  & h2.premiumTitle {
    margin: 0;
    width: inherit;
    height: 3.7rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;

    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
  & span {
    display: block;

    font-size: 12px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & img {
      margin-right: 0.3rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  & span:first-child {
    font-weight: 500;
    color: #3eac3e;
  }
  & div {
    border-radius: 0.4rem;
  }
  & div.price {
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    color: #000;
  }
  & img.premiumIcon {
    width: 5rem;
    height: 1.8rem;
  }
  & img.conditionIcon {
    display: inline-block;
    margin-right: 0.4rem;
    height: 1.8rem;
  }
  & img.purchaseIcon {
    display: inline-block;
    height: 1.8rem;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;