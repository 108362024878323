import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getRememberMe, removeRememberMe, saveRememberMe } from '../../utils/TokenManager';
// import { isValidEmail } from '../../utils/utils';
import { UserApi } from './api/UserApi';

export const SignIn = ({setDialog}) => {
  const userApi = new UserApi();
  const navigate = useNavigate();
  const rememberMe = useRef();
  const [userInfo, setUserInfo] = useState({
    email: getRememberMe(),
    password: '',
  });

  // == state 변경 ------------------------------------------------
  const changeUserInfo = (name, value) => {
    setUserInfo((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleEmailOnChange = (value) => {
    changeUserInfo("email", value);
  }

  const handlePasswordOnChange = (value) => {
    changeUserInfo("password", value);
  }

  const handleOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      handleLoginOnClick();
    }
  }

  const handleLoginOnClick = () => {
    userApi.signIn(
      userInfo,
      (data) => {
        if(rememberMe.current.checked) {
          saveRememberMe(userInfo.email);
        } else {
          removeRememberMe();
        }
        handleHistoryBackClick();
      },
      (error) => {
        const {response: {status, statusText}} = error;
        if(401 === status) {
          setDialog({isOpen: true, message: "이메일 또는 비밀번호가 일치하지 않습니다.", type: "nonHeader"});
        } else {
          setDialog({isOpen: true, message: statusText, type: "nonHeader"});
        }
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate('/market', {replace : true});
  }

  const handlerSignUpOnClick = () => {
    navigate('/signUp');
  }

  const handlerResetPasswordOnClick = () => {
    navigate('/resetPassword');
  }

  useEffect(()=>{
    rememberMe.current.checked = getRememberMe()? true: false;
  });

  return (
    <PageWrapper>
      <Title>
        <span>밴마켓</span>에 로그인 하고<br/>
        원하는 서비스를 시작해보세요!
      </Title>
      <ContentWrap>
        <InputGroup>
          <input
            type='email'
            id='email'
            value={userInfo.email}
            onChange={(e) => handleEmailOnChange(e.target.value)}
            placeholder="이메일"
            autoComplete="off"
            required
          />
          <input
            type='password'
            id='password'
            value={userInfo.password}
            onChange={(e) => handlePasswordOnChange(e.target.value)}
            onKeyUp={(e) => handleOnKeyUp(e)}
            placeholder="비밀번호"
            autoComplete="off"
            required
          />
        </InputGroup>
        <ButtonStyle type='button' onClick={handleLoginOnClick}>
          로그인하기
        </ButtonStyle>
        <InfoGroup>
          <KeepId>
            <input type='checkbox'
              id={`rememberMe`}
              name={'rememberMe'}
              ref={rememberMe}
            />
            <label htmlFor={`rememberMe`}></label>
            <label className='message' htmlFor={`rememberMe`}>
              아이디 저장
            </label>
          </KeepId>
          <LinkGroup>
            <UserServiceLink onClick={()=>handlerResetPasswordOnClick()}>비밀번호 찾기</UserServiceLink>
            <Separator />
            <SignUpGroup onClick={()=>handlerSignUpOnClick()}>회원가입</SignUpGroup>
          </LinkGroup>
        </InfoGroup>
      </ContentWrap>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  margin: auto;
  width: 100%;
  min-height: 50rem;
  height: 74.5vh;
  background-color: #fff;
  box-sizing : border-box;
`;

const Title = styled.div`
  margin: 0;
  padding: 5rem 1.6rem 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.04em;
  & span {
    color: #FD6258;
  }
`;

const ContentWrap = styled.div`
  padding: 3rem 1.6rem;
`;

const InputGroup = styled.div`
  width: 100%;
  height: auto;
  & input {
    margin: 1.6rem 0;
    display: block;
    padding-left: 1.5rem;
    width: 100%;
    height: 4.8rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    border: 0.1rem solid #eee;
    border-radius: 0.4rem;
    box-sizing : border-box;
    outline: none;
  }
`;

const InfoGroup = styled.div`
  margin: 1.6rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const KeepId = styled.div`
  display: flex;
  align-items: center;
  & input + label {
    display: inline-block;
    margin: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  & input:checked + label {
    background-color: #f86258;
    border: 0.1rem solid #f86258;
    background-image: url('/icon/input-checkbox-checked-icon.png');
    background-size: 1.12rem;
    background-repeat: no-repeat;
    background-position: center;
  }
  & input {
    display: none;
  }
  & label.message {
    margin-left: 1rem;
    padding: 0rem;
    height: 2rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
    cursor: pointer;
  }
`;

const LinkGroup = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

const UserServiceLink = styled.div`
    height: 1.8rem;
    padding: 0 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
    cursor: pointer;
`;

const Separator = styled.div`
  height: 1.2rem;
  border-left: 0.1rem solid #ddd;
`;

const SignUpGroup = styled.div`
    height: 1.8rem;
    padding: 0 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
    cursor: pointer;
`;

const ButtonStyle = styled.button`
  height: 5.2rem;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;