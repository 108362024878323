import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../common/Loader';
import { priceToStringWithDecimal } from '../../../utils/utils';
import { OrderApi } from '../api/OrderApi';

export const PaymentStep3 = ({setDialog}) => {
  const orderApi = new OrderApi();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState({
    orderId: state?.orderId,
    refType: state?.refType,
    refPostId: state?.refPostId,
    refBoardId: state?.refBoardId,
    paymentType: state?.paymentType,
    backLink: state?.backLink,
    cancelLink: state?.cancelLink,

    grandTotalAmount: 0,
    totalItemAmount: 0,
    totalGstAmount: 0,
    orderDateTime: '',
  });

  // == state 변경 ------------------------------------------------
  const changePaymentInfo = (name, value) => {
    setPaymentInfo((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const isPhonePayment =() =>{
    return ('phone' === paymentInfo.paymentMethod) ? true: false;
  }

  const handleSearchClick = () => {
    setLoading(true);

    const { orderId, refPostId, refBoardId } = paymentInfo;

    if(orderId && refPostId && refBoardId) {
      orderApi.fetchOrderById(
        paymentInfo?.orderId,
        (data) => {
          setLoading(false);
          const { result } = data;
          changePaymentInfo("grandTotalAmount", result?.grandTotalAmount);
          changePaymentInfo("totalItemAmount", result?.totalItemAmount);
          changePaymentInfo("totalGstAmount", result?.totalGstAmount);
          changePaymentInfo("orderDateTime", result?.orderDateTime);
          changePaymentInfo("orderStatus", result?.orderStatus);
        },
        (error) => {
          setLoading(false);
          const { status, message, errors } = error;
          if(401 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else if(404 === status) {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          } else {
            if (errors) {
              const { field, defaultMessage } = errors[0];
              setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
            }
          }
          navigate(-1);
        }
      );
    } else {
      alert("잘못된 접근 입니다.");
      navigate('/market', {replace : true});
    }
  }

  const handleNextButtonOnClick = () => {
    if(isPhonePayment()) {
      navigate(paymentInfo.cancelLink, {replace : true});
    } else {
      navigate('/market', {replace : true});
    }
  }

  useEffect(()=> {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ContentTitle>
          <img src='/icon/payment-done-icon.png' alt='결제완료'/>
          {(isPhonePayment()) ? (
            <>
              <div>
                전화 결제 요청 되었습니다.
              </div>
              <div style={{textAlgin:'center'}}>
                웹담당자에게 문의 부탁드립니다.<br/>
                (604-877-1178) or web@vanchosun.com
              </div>
            </>
          ): (
            <>
              {paymentInfo?.orderStatus === '실패' ? (
                <>
                  <div>
                    결제 실패 또는 거부 되었습니다.
                  </div>
                  <div>
                    거래를 완료할 수 없습니다. 다른 결제 방법을 사용해 주세요.
                  </div>
                </>
              ) : (
                <>
                  <div>
                    결제 완료 되었습니다.
                  </div>
                  <div>
                    작성하신 광고는 포스팅 되었습니다.
                  </div>
                </>
              )}
            </>
          )}
        </ContentTitle>
        <Separator />
        <SubTotal>
          <label>주문금액</label>
          <div>{priceToStringWithDecimal(paymentInfo?.totalItemAmount)}</div>
        </SubTotal>
        <EstimatedTex>
          <label>GST(%5)</label>
          <div>{priceToStringWithDecimal(paymentInfo?.totalGstAmount)}</div>
        </EstimatedTex>
        <GrandTotal>
          <label>총 결제금액</label>
          <div>{priceToStringWithDecimal(paymentInfo?.grandTotalAmount)}</div>
        </GrandTotal>
        <Separator />
        <NormalText>
          <label>주문일시</label>
          <div>{paymentInfo?.orderDateTime}</div>
        </NormalText>
        <NormalText>
          <label>주문번호</label>
          <div>{paymentInfo?.orderId}</div>
        </NormalText>
        <NormalText>
          <label>게시판번호</label>
          <div>{`${paymentInfo?.refBoardId||''} (${paymentInfo?.refType||''})`}</div>
        </NormalText>
        <NormalText>
          <label>게시글번호</label>
          <div>{paymentInfo.refPostId}</div>
        </NormalText>
        {/* <NormalText>
          <label>광고시작일</label>
          <div>2022-08-22</div>
        </NormalText>
        <NormalText>
          <label>광고종료일</label>
          <div>2022-09-22</div>
        </NormalText> */}
        <Separator />
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          문의: 웹 담당자 (604-877-1178) (web@vanchosun.com)
        </InfoMessage>
        <NextButton
          type='button'
          color="#fff"
          backgroundColor='#fd6258'
          onClick={() => handleNextButtonOnClick()}>확인</NextButton>
      </ContentWrapper>
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & img {
    margin-top: 5rem;
    width: 4.781rem;
    height: 4.781rem;
    margin-bottom: 1.6rem;
  }
  & div:nth-child(2) {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #fd6258;
  }
  & div:nth-child(3) {
    margin-bottom: 4rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #000000;
    text-align: center;
  }
`;

const SubTotal = styled.div`
  margin: 0.8rem 0;
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div {
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;
const EstimatedTex = styled.div`
  margin: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div {
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;
const GrandTotal = styled.div`
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #fd6258;
  }
  & div {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
    color: #fd6258;
  }
`;

const NormalText = styled.div`
  margin: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;
  }
  & div {
    width: 20rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    text-align: right;
    letter-spacing: -0.04em;
    color: #424242;
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const NextButton = styled.button`
  margin: 3rem 0;
  width: 100%;
  height: 5.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 5.4rem;
  letter-spacing: -0.04em;
  color: #fff;
  background-color: #FD6258;
  border: 0.1rem solid #FD6258;
  border-radius: 0.4rem;
  cursor: pointer;
`;

const Separator = styled.div`
  margin: 1rem 0;
  border: 0.1rem solid #eee;
`;