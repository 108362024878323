import React from 'react'
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const MainRestaurantCategory = () => {
  const navigate = useNavigate();
  const handleButtonLinkOnClick = (keyword) => {
    navigate('/yellowpage', {
      state: {
        marketHomeShow: 1,
        marketHomeKeyword: keyword,
      }
    });
  }

  return (
    <>
      <MainRestaurantSection>
        <Header>
          <h2>맛집을 찾으시나요?</h2>
        </Header>
        <MySwiper
          slidesPerView={3.5}
          spaceBetween={10}
          freeMode={true}
          >
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('한식')}>
              <img src='/icon/category-korean-foods.png' alt=''/>
              <div>한식</div>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('일식')}>
              <img src='/icon/category-japanese-foods.png' alt=''/>
              <div>일식</div>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('중식')}>
              <img src='/icon/category-chinese-foods.png' alt=''/>
              <div>중식</div>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('양식')}>
              <img src='/icon/category-western-foods.png' alt=''/>
              <div>양식</div>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('카페/디저트')}>
              <img src='/icon/category-dessert.png' alt=''/>
              <div>카페/디저트</div>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item onClick={()=>handleButtonLinkOnClick('기타')}>
              <img src='/icon/category-etc-foods.png' alt=''/>
              <div>기타</div>
            </Item>
          </SwiperSlide>
        </MySwiper>
      </MainRestaurantSection>
    </>
  );
};

const MainRestaurantSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding-bottom: 2.4rem;
  width: auto;
  height: auto;
  background-color: #ffffff;
`;

const Header = styled.div`
  padding: 2.4rem 1.6rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
  }
`;

const MySwiper = styled(Swiper)`
  padding-left: 1.6rem;
`;

const Item = styled.div`
  width: 9.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & img {
    width:  9.2rem;
    height: 9.2rem;
    background-color: #f4f4f4;
    border-radius: 50%;
  }
  & div {
    margin-top: 0.7rem;
    height: 2rem;

    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;
