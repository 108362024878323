import React, { useState, useEffect } from 'react';
import { useParams, } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../common/Loader';
import { addHyphenForPhoneNumber, elapsedText } from '../../utils/utils';
import { AdsApi } from './api/AdsApi';

export const MobileAdDetail = () => {
  const adsApi = new AdsApi();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [params] = useState({});
  const [article, setArticle] = useState({
    id: '',
    boardId: '',
    active: 0,
    name: '',
    phone: '',
    email: '',
    title: '',
    description: '',
    hit: 0,
    imageFile1: '',
    imageFile2: '',
    updatedDate: '',
  });

  // const changeArticleData = (name, value) => {
  //   setArticle((values) => {
  //     const updated = {...values};
  //     updated[name] = value;
  //     return updated;
  //   });
  // };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    adsApi.fetchAdById(
      id,
      params,
      (data) => {
        setArticle(data.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        // const {response: {data}} = error;
        // setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        // setDialog({isOpen: true, message: "현재 등록된 게시물이 없습니다.", type: "alert"});
        // handleHistoryBackClick();
      },
    );
  }

  // render -------------------------------------------------
  const addLocationNContactTag = ({updatedDate, hit}) => {
    return (<span>{elapsedText(updatedDate)} · 조회수 {hit}</span>)
  }

  const addConnectTag = ({phone, email}) => {
    return (
      <>
        {(phone && email) ? (
          <>
            {addHyphenForPhoneNumber(phone)}
            <br/>
            {email}
          </>
        ) : (phone) ? (
          <>
            {phone}
          </>
        ) : (email) ? (
          <>
            {email}
          </>
        ) : ''
        }
      </>
    )
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Title>{article.title}</Title>
        <UpdateInfo>
          {addLocationNContactTag(article)}
        </UpdateInfo>
        <Info>
          <table>
            <tbody>
              <tr>
                <td className='head'>광고주</td>
                <td className='col'>{article.name}</td>
              </tr>
              <tr>
                <td className='head'>연락처</td>
                <td className='col'>{addConnectTag(article)}</td>
              </tr>
            </tbody>
          </table>
        </Info>
      </ContentWrapper>
      <ContentWrapper>
        <ContentLabel>
          광고설명
        </ContentLabel>
        <Content dangerouslySetInnerHTML={{__html: article.description}} />
      </ContentWrapper>
      <Loader loading={loading} />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0.5rem 0;
  width: auto;
  background-color: #fff;
  border-top: 0.1rem solid #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding: 0 1.6rem;
`;

const Title = styled.h1`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
`;

const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;

const Info = styled.div`
  margin-top: 1.7rem;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & table {
    padding: 1rem 1.6rem;
    width: 100%;
    & td {
      font-size: 14px;
    }
    & td.head {
      width: 30%;
      font-size: 14px;
      font-weight: 500;
      line-height: 2rem;
      text-align: left;
      vertical-align: top;
      color: #000;
    }
    & td.col {
      width: 70%;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      color: #424242;
    }
    & td.left {
      text-align: left;
    }
    & td.right {
      text-align: right;
    }
  }
`;

const ContentLabel = styled.div`
  margin: 0;
  padding: 2rem 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
`;

const Content = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;
