import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NewsApi } from '../api/NewsApi';
import { GoogleAdvertiseForInFeed } from '../../ads/GoogleAdvertiseForInFeed';
import { Helmet } from 'react-helmet-async';
import { removeHtmlTags } from '../../../utils/utils';

export const NewsDetail = () => {
  const newsApi = new NewsApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [article, setArticle] = useState({
    title: '',
    subTitle: '',
    description: '',
    imageFile: '',
    reporter: '',
    updateDate: '',
    hitCount: 0,
    youtubeCode: null,
    isMovie: 0,
    boardId: '17',
    boardName: '',
    email: '',
  });

  const moveToErrorPage = () => {
    navigate('/error', {replace : true});
  }

  const handleSearchClick = () => {
    newsApi.fetchNewsById(id,
      (data) => {
        if(data?.result) {
          setArticle(data.result);
        } else {
          moveToErrorPage();
        }
      },
      (error) => {
        moveToErrorPage();
      }
    );
  }

  const handleSendFacebook = () => {
    alert("공유하기가 지원되지 않는 환경입니다.");
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${article?.title.substring(0,20)} - 밴쿠버 조선일보`}</title>
        <meta property="description" content={`${removeHtmlTags(article?.description).substring(0,80)}`} />
        <meta property="author" content={`${article?.reporter}`} />
        <meta property="og:site_name" content="밴쿠버 조선일보" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://m.vanchosun.com${location.pathname}`} />
        <meta property="og:title" content={`${article?.title.substring(0,20)}`} />
        <meta property="og:description" content={`${removeHtmlTags(article?.description).substring(0,80)}`} />
        {article?.imageFile && (
          <meta property="og:image" content={`https://cdn.vanchosun.com/news/File_images_board/${article?.imageFile}`}/>
        )}
      </Helmet>
      <Wrapper>
        <ContentWrap>
          <Title>{article?.title}</Title>
          {article?.subTitle && (
            <SubTitle dangerouslySetInnerHTML={{__html: article?.subTitle}}></SubTitle>
          )}
          <Info>
            <div>입력: {article?.updatedDate}</div>
            <div>{article?.reporter}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{article?.email}</div>
            <FaceBookButton src="/icon/facebook-share-icon.png" alt="Facebook 공유" onClick={()=>handleSendFacebook()}/>
          </Info>
          <Content dangerouslySetInnerHTML={{__html: article?.description}} />
          <GoogleAdvertiseForInFeed
            client="ca-pub-2380324855411184"
            slot="7933315397"
            format="fluid"
            responsive="true"
            layoutKey="-73+ed+2i-1n-4w"
          />
          {/* <Subscription /> */}
        </ContentWrap>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  margin: auto;
  padding: 0 1.6rem 0.6rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
`;

const ContentWrap = styled.div`
  margin: 0;
  padding: 0;
  height: 77%;
`;
const Title = styled.h1`
  margin: 0;
  padding: 2rem 0 0;
  min-height: 3.2rem;
  font-size: 22px;
  font-weight: 500;
  line-height: 3.2rem;
  letter-spacing: -0.04em;
  color: #000000;
`;
const SubTitle = styled.h2`
  margin: 0;
  padding: 1.6rem 0 0;
  min-height: 2rem;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #616161;
`;
const Info = styled.div`
  margin: 0;
  padding: 4rem 0 0;
  & div {
    font-size: 12px;
    color: #666;
  }
`;
const FaceBookButton = styled.img`
  margin-left: -0.3rem;
  height: 2.7rem;
  cursor: pointer;
`;

const Content = styled.div`
  margin: 0;
  padding: 2.5rem 0 3rem;
  font-size: 16px;
  overflow: hidden;
  clear: both;
  & a {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: -0.04em;
    color: #000000;
    text-decoration: none;
  }
  & div.newsDetailImageBox {
    font-size: 12px;
    text-align: center;
    color: #808080;
  }
`;