import qs from "qs";
import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";

export class HomestayApi {

  fetchHomestayList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/homestay`,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      params: searchData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchHomestayById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/homestay/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchHomestayEditById(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/homestay/${id}/edit`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  checkHomestayPermission(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/homestay/${id}/check-permission`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createHomestayForStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/homestay`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createHomestayForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/homestay/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveHomestayForStep1(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/homestay/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveHomestayForStep2(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/homestay/${id}/step2`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteHomestay(id, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/homestay/${id}`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createHomestayImageFile(id, formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/homestay/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteHomestayImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/homestay/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveHomestayNewPassword(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/homestay/${id}/password`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}