import { jsonNoAuthApi } from "../../../utils/AxiosApi";

export class NewsApi {

  fetchMainNewsList(part, searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/main/${part}`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
  fetchMainNews2List(callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/main`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchNewsList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchNewsById(id, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchNewsSearchCount(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/search-count`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchNewsSearchList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/search`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchTopViewList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/topView`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchNewestList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/newest`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchInterviewList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/interview`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchOpinionList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/opinion`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchPhotoList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/photo`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchSpecialFeatureArticleList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/special-feature-articles`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchColumnistList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/columnist`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchImmigrantList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/immigrant`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchKoreaList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/korea`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchEducationList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/education`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchKoreanList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/korean`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchRealtyList(searchData, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/news/realty`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}