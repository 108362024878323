import React, { useState } from 'react';
import styled from 'styled-components';
import { isPositiveOrZero } from '../utils/utils';

export const InputPriceComponent = ({
  label, subLabel, infoMessage
  , id, value, maxLength, placeholder
  , onChange, onBlur, onKeyUp
  , disabled, style
}) => {
  const [validation, setValidation] = useState('');

  const handleOnChange = (value) => {
    isPositiveOrZero(value) ? setValidation("success") : setValidation("error");
    onChange?.(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
  }

  const handleOnBlur = (value) => {
    onBlur?.(value);
  }

  const handleOnKeyUp = (event) => {
    onKeyUp?.(event);
  }

  return (
    <InputGroup>
      <div>
        <label htmlFor={id}>{label}</label>
        {subLabel && (
          <span>{subLabel}</span>
        )}
      </div>
      <input className={`form-text ${validation}`}
        type='text'
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        onKeyUp={(e) => handleOnKeyUp(e)}
        maxLength={maxLength}
        autoComplete="off"
        required
        disabled={disabled}
        style={style}/>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </InputGroup>
  );
}

const InputGroup = styled.div`
  margin: 1.35rem 0;
  width: 100%;
  height: auto;
  min-height: 5.6rem;
  & div {
    padding: 0;
    height: 2.3rem;
    & label {
      padding: 0;
      width: auto;
      height: auto;

      font-size: 14px;
      font-weight: 500;
      line-height: 2.3rem;
      letter-spacing: -0.04em;
    }
    & span {
      margin-left: 0.4rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.04em;
      color: #FD6258;
    }
  }
  & input {
    padding-left: 1.8rem;
    width: 100%;
    height: 3rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    background-image: url('/icon/dollar-icon.png');
    background-repeat: no-repeat;
    background-size: 0.7rem;
    background-position: 0.6rem center;

    border: 0rem solid #ddd;
    border-bottom: 0.1rem solid #ddd;
    box-sizing : border-box;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;