import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainEducation = ({list}) => {
  return (
    <MainEducationSection>
      <Header>
        <Link to={`/education`}>
          <h2>에듀영어</h2>
        </Link>
      </Header>
      <ul>
        {list.map((row) => (
          <li key={row.id}>
            <Link to={`/education/${row.id}`}>
              <img src={`//cdn.vanchosun.com/news/File_images_board/${row.imageFile}`} alt={row.title} loading='lazy'/>
              <h3>{row.title}</h3>
            </Link>
          </li>
        ))}
      </ul>
    </MainEducationSection>
  )
};

const MainEducationSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 1.6rem 2rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      margin: 0 auto;
      padding: 0.8rem 0;
      border-top: 0.1rem solid #ddd;
      & a {
        color: #000;
        text-decoration: none;
        display: flex;
        align-items: center;
        & img {
          display: none;
          -webkit-user-drag: none;
        }
        & h3 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    & li:nth-child(1) {
      margin: 0;
      padding-top: 0.5rem;
      border-top: 0;
      & a {
        flex-direction: column;
        align-items: flex-start;
        border-top: 0 solid #ddd;
        color: #000;
        text-decoration: none;
        & img {
          display: block;
          width: 100%;
          height: 20rem;
          object-fit: cover;
          -webkit-user-drag: none;
        }
        & h3 {
          margin: 0;
          padding-top: 0.8rem;
          width: 100%;
          font-size: 14px;
          font-weight: 400;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;

const Header = styled.div`
  margin: 0;
  padding: 2rem 0 1.6rem;
  & h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;