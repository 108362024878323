import React from 'react';
import { TutorListGridComponent } from './TutorListGridComponent';
import { TutorListPostListComponent } from './TutorListPostListComponent';

export const TutorListComponent = ({ viewMode, list, link, isLoading=true, postOnClick}) => {
  return (
    <>
      {'grid' === viewMode ? (
        <TutorListGridComponent
          list={list}
          link={link}
          isLoading={isLoading}
          postOnClick={postOnClick}
        />
      ) : (
        <TutorListPostListComponent
          list={list}
          link={link}
          isLoading={isLoading}
          postOnClick={postOnClick}
        />
      )}
    </>
  );
}