import React from 'react';
import styled from 'styled-components';

export const SwitchComponent = ({label, infoMessage, id, value, defaultValue, onChange}) => {

  const handleOnChange = ({target}) => {
    onChange?.(target.checked);
  }

  const isChecked = (value) => {
    return defaultValue === value ? true : false;
  }

  return (
    <Wrapper>
      <Title>{label}</Title>
      <Switch>
        <input
          id={`switch-${id}`}
          type="checkbox"
          onChange={handleOnChange}
          checked={isChecked(value)}
        />
        <span className="slider">
          {(isChecked(value))
            ? (<span className={`sliderOn`}>ON</span>)
            : (<span className={'sliderOff'}>OFF</span>)
          }
        </span>
      </Switch>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 8.2rem;
  height: 3.4rem;

  & input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  & span.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EEEEEE;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 3.4rem;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    & span.sliderOn {
      position: absolute;
      top: 0.5rem;
      left: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
    & span.sliderOff {
      position: absolute;
      top: 0.5rem;
      right: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
  }
  & input:checked + span.slider {
    background-color: #FD6258;
  }

  & input:focus + span.slider {
    box-shadow: 0 0 0.1rem #FD6258;
  }
  & input:checked + span.slider::before {
    -webkit-transform: translateX(4.8rem);
    -ms-transform: translateX(4.8rem);
    transform: translateX(4.8rem);
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;
