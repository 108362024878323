import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../common/SkeletonComponent';
import { MarketBannersModal } from './MarketBannersModal';
import { AdsApi } from './api/AdsApi';
import { useInterval } from '../../hooks/useInterval';

export const MobileBanner = ({ position, listSize=1 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});
  const [sliderPosition, setSliderPosition] = useState(null);
  const [page, setPage] = useState(0);
  const [showMarketBannersModal, setShowMarketBannersModal] = useState(false);

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(
      position,
      listSize,
      (data)=> {
        setAds(data);
      },
      (error)=> {
        console.log(error);
      }
    )
  }

  const handleOpenBannersModal = () => {
    toggleMarketBannersModal?.();
  }

  const toggleMarketBannersModal = () => {
    setShowMarketBannersModal(!showMarketBannersModal);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  const handleNextClick = () => {
    if(page + 1 < ads?.list.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  const handlePreviousClick = () => {
    if(0 === page) {
      setPage(ads?.list.length-1);
    } else {
      setPage(page - 1);
    }
  }

  const handleOnTouchStart = (e) => {
    if(2>ads?.list.length) return;
    setSliderPosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    });
  }

  const handleOnTouchEnd = (e) => {
    if(2>ads?.list.length) return;
    moveSlide(e.changedTouches[0].pageX, e.changedTouches[0].pageY, null);
  }

  const handleOnMouseDown = (e) => {
    if(2>ads?.list.length) return;
    setSliderPosition({
      x: e.clientX,
      y: e.clientY
    });
  }

  const handleOnMouseUp = (e, row) => {
    if(2>ads?.list.length) {
      handleOpenDetail(row);
    } else {
      moveSlide(e.clientX, e.clientY, row);
    }
  }

  const moveSlide = (clientX, clientY, row) => {
    const distanceX = Math.abs(sliderPosition.x - clientX);
    const distanceY = Math.abs(sliderPosition.y - clientY);

    if((distanceY + distanceX > 30) && (distanceX > distanceY)) {
      if(sliderPosition.x - clientX < 0) {
        handlePreviousClick();
      } else if(sliderPosition.x - clientX > 0) {
        handleNextClick();
      }
    } else if(row) {
      handleOpenDetail(row);
    }
  }

  useInterval(()=> {
    handleNextClick();
  }, (1<ads?.list.length) ? 6000 : null);

  useEffect(() => {
    handleAdsSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BannerWrapper>
      {(0 < ads?.list.length) ? (
        <SlideList translateX={page}>
          {ads?.list.map((row) => (
            <li key={row.id}>
              <Item
                onTouchStart={(e)=>handleOnTouchStart(e)}
                onTouchEnd={(e)=>handleOnTouchEnd(e)}
                onMouseDown={(e)=>handleOnMouseDown(e)}
                onMouseUp={(e)=>handleOnMouseUp(e, row)}
              >
                <img className={'bannerImage'}
                  src={`${row.imageFile1}`}
                  alt={row?.title}
                  onError={(e)=>handleImageError(e)} />
              </Item>
            </li>
          ))}
        </SlideList>
      ) : (
        <SkeletonComponent>
          <SkeletonSection />
        </SkeletonComponent>
      )}
        <BannerCountInfo onClick={()=>handleOpenBannersModal()}>
          <div>
            {page+1}
            <span> /{ads?.list.length}</span>
          </div>
          <img src={`/icon/mobile-banner-plus.png`} alt='광고 모아보기 버튼'/>
        </BannerCountInfo>
        <NextButton onClick={()=>handleNextClick()}>Next</NextButton>
      </BannerWrapper>
      <MarketBannersModal
        show={showMarketBannersModal}
        toggle={toggleMarketBannersModal}
        list={ads?.list}
      />
    </>
  )
}

const BannerWrapper = styled.section`
  position: relative;
  margin: 0 1.6rem;
  padding: 2.4rem 0rem;
  width: auto;
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: auto;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
  transform: ${props => `translateX(${props.translateX * -100}%)`};
  transition: all 0.45s linear;
  & li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Item = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 8.2rem;
  text-align: center;
  border: 0rem solid #f7f7f7;
  border-radius: 0.4rem;
  & img.bannerImage {
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0rem solid #f7f7f7;
    border-radius: 0.4rem;
    box-sizing: border-box;
    -webkit-user-drag: none;
    z-index: 10;
    cursor: pointer;
  }

`;

const BannerCountInfo = styled.div`
  position: absolute;
  right: 0;
  bottom: 2.4rem;
  margin: 0;
  padding: 0.4rem;
  width: 5.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0.4rem 0;
  z-index: 10;
  cursor: pointer;
  & div {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    & span {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.04em;
      color: #9e9e9e;
    }
  }
  & img {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const NextButton = styled.div`
  display: none;
  position: absolute;
  right: 1.6rem;
  top: 0;
  margin: 0;
  padding: 0.4rem;
  width: 5.8rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0.4rem;
  z-index: 10;
  cursor: pointer;
`;

const SkeletonSection = styled.div`
  width: 100%;
  height: 8.2rem;
  background-color: #F5F5F5;
  border-radius: 0.4rem;
`;