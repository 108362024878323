import { jsonApi } from "../../../utils/AxiosApi";

export class OrderApi {

  fetchOrderById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/order/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  paymentStep1(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/order/step1`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  paymentStep2(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/order/step2`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}