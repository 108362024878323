import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainNewsTopView = ({tabs}) => {
  const [currentTab, setCurrentTab] = useState("0");

  const handlerSelectTab = (index) => {
    setCurrentTab(index);
  };

  return (
    <MainNewsTopViewSection>
      <Tabs>
        {Object.keys(tabs).map((key) => (
        <li key={key} className={ currentTab === key ? 'active': ''}
          onClick={() => handlerSelectTab(key)}>{tabs[key].name}</li>
        ))}
      </Tabs>
      <MainNewsSection>
        {tabs[currentTab].list.map((row, index) => (
          <li key={row.id}>
            <Link to={`/news/${row.id}`}>
              <span>{index+1}</span>
              <h3> {row.title}</h3>
            </Link>
          </li>
        ))}
      </MainNewsSection>
    </MainNewsTopViewSection>
  )
};

const MainNewsTopViewSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 2rem 1.6rem;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const Tabs = styled.ul`
  margin: 0;
  padding: 0;
  height: 5rem;
  list-style: none;

  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: flex-end;
  justify-content: first-start;
  border-bottom: 0.1rem solid #dee2e6;
  & li {
    padding: 0.6rem 1.2rem;
    margin-bottom: -0.2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;

    border: 0.1rem solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    display: flex;
    align-items: center;
    cursor: pointer;
  }
  & li.active {
    font-weight: 500;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }

`;

const MainNewsSection = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      margin: 0;
      padding: 1.3rem 0;
      border-bottom: 0.1rem solid #f5f5f5;
      & a {
        display: flex;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;

        color: #000;
        text-decoration: none;
        & span {
          width: 3rem;
          font-size: 14px;
          font-weight: 500;
          line-height: 2rem;
          letter-spacing: -0.04em;
          color: #000000;
        }
        & h3 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: -0.04em;
          color: #000000;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    & li:last-child {
      border-bottom: 0 solid #f5f5f5;
    }
`;