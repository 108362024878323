import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdsApi } from './api/AdsApi';
import { CommonModal } from '../../common/CommonModal';
import { useInterval } from '../../hooks/useInterval';

export const MarketPopupModal = ({ show, toggle, position, savedName, listSize=1 }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();
  const [ads, setAds] = useState({list:[]});
  const [slidePosition, setSlidePosition] = useState(null);
  const [page, setPage] = useState(0);

  const handleAdsSearch = () => {
    adsApi.fetchBannerAdsList(
      position,
      listSize,
      (data)=> {
        setAds(data);
      },
      (error)=> {
        console.log(error);
      }
    )
  }

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerTodayCloseOnClick = () => {
    let expires = new Date();
    localStorage.setItem(savedName, expires.setHours(expires.getHours() + 24));
    handleToggleModal?.();
  }

  const handlerCloseOnClick = () => {
    handleToggleModal?.();
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  const handleNextClick = () => {
    if(page + 1 < ads?.list.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }

  const handlePreviousClick = () => {
    if(0 === page) {
      setPage(ads?.list.length-1);
    } else {
      setPage(page - 1);
    }
  }

  const handleOnTouchStart = (e) => {
    if(2>ads?.list.length) return;
    setSlidePosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    });
  }

  const handleOnTouchEnd = (e) => {
    if(2>ads?.list.length) return;
    moveSlide(e.changedTouches[0].pageX, e.changedTouches[0].pageY, null);
  }

  const handleOnMouseDown = (e) => {
    if(2>ads?.list.length) return;
    setSlidePosition({
      x: e.clientX,
      y: e.clientY
    });
  }

  const handleOnMouseUp = (e, row) => {
    if(2>ads?.list?.length) {
      handleOpenDetail(row);
    } else {
      moveSlide(e.clientX, e.clientY, row);
    }
  }

  const moveSlide = (clientX, clientY, row) => {
    const distanceX = Math.abs(slidePosition.x - clientX);
    const distanceY = Math.abs(slidePosition.y - clientY);

    if((distanceY + distanceX > 30) && (distanceX > distanceY)) {
      if(slidePosition.x - clientX < 0) {
        handlePreviousClick();
      } else if(slidePosition.x - clientX > 0) {
        handleNextClick();
      }
    } else if(row) {
      handleOpenDetail(row);
    }
  }

  useInterval(()=> {
    handleNextClick();
  }, (1<ads?.list.length) ? 9000 : null);

  useEffect(() => {
    handleAdsSearch()
    if(show) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      {(show && (0 < ads?.list.length)) && (
        <>
          <CommonModal
            width={'100%'}
            height={'27.3rem'}
            toggle={()=>handleToggleModal()}>
            <PopupWrapper>
              <SlideList translateX={page}>
                {ads?.list.map((row) => (
                  <li key={row.id}>
                    <Item
                      onTouchStart={(e)=>handleOnTouchStart(e)}
                      onTouchEnd={(e)=>handleOnTouchEnd(e)}
                      onMouseDown={(e)=>handleOnMouseDown(e)}
                      onMouseUp={(e)=>handleOnMouseUp(e, row)}
                    >
                      <div>
                        <img src={`${row.imageFile1}`} alt={row.title} onError={(e)=>handleImageError(e)} />
                      </div>
                    </Item>
                  </li>
                ))}
              </SlideList>
              <NextButton>
                <span>{page+1} / {ads?.list.length}</span>
              </NextButton>
              <PopupBottom>
                <div onClick={handlerTodayCloseOnClick}>오늘 하루 보지않기</div>
                <div onClick={handlerCloseOnClick}>닫기</div>
              </PopupBottom>
            </PopupWrapper>
          </CommonModal>
        </>
      )}
    </>
  );
}

const PopupWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  overflow: hidden;

  box-sizing: border-box;
`;

const SlideList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 22.5rem;
  white-space: nowrap;
  font-size: 0;
  list-style: none;
  transform: ${props => `translateX(${props.translateX * -100}%)`};
  & li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Item = styled.div`
  position: relative;
  width: 100%;
  height: 22.5rem;
  text-align: center;
  border-radius: 1.6rem 1.6rem 0 0;
  cursor: pointer;
  & img {
    display: block;
    width: 100% ;
    height: 22.5rem;
    object-fit: cover;
    border-radius: 1.6rem 1.6rem 0 0;
    -webkit-user-drag: none;
  }
`;

const NextButton = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.6rem;
  width: 4.9rem;
  height: 2rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7rem;
  color: #fff;
  text-align: center;

  background-color: #000;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10rem;
  z-index: 10;
  cursor: pointer;
`;

const PopupBottom = styled.div`
  padding: 0 1.6rem;
  width: auto;
  height: 4.6rem;
  display: flex;
  justify-content: space-between;
  & div {
    font-weight: 400;
    font-size: 12px;
    line-height: 4.6rem;
    cursor: pointer;
  }
`;