import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Pagination } from 'swiper';
import 'swiper/css';

export const NewsTopMenu = () => {
  SwiperCore.use([FreeMode, Pagination]);
  const location = useLocation();
  const navigate = useNavigate();
  const [swiperRef, setSwiperRef] = useState(null);

  const [list] = useState([
    {url:'/', name: '홈', position: 'news_bottom_home'},
    {url:'/market', name: '마켓', position: 'market_bottom'},
    {url:'/news', name: '뉴스', position: 'news_bottom'},
    {url:'/realty', name: '부동산', position: 'realty_bottom'},
    {url:'/immigrant', name: '이민', position: 'immigrant_bottom'},
    {url:'/education', name: '교육', position: 'education_bottom'},
    {url:'/k-people', name: '교민', position: 'korean_bottom'},
    {url:'/korea', name: '한국', position: 'korea_bottom'},
    {url:'/photo', name: '포토', position: 'photo_bottom'},
    {url:'/issuu', name: '신문보기', position: 'newspaper_bottom'},
    // {url:'/interview', name: '인터뷰', position: 'interview_bottom'},
    // {url:'/opinion', name: '칼럼', position: 'opinion_bottom'},
    // {url:'/special-feature-article', name: '기획특집', position: 'special-feature-article_bottom'},
  ]);

  const [currentPage, setCurrentPage] = useState(location?.pathname);

  const handleMenuOnClick = (url, slideTo) => {
    if('/issuu' === url) {
      window.open('//issuu.com/vanchosun.com');
    } else {
      setCurrentPage(url);
      navigate(url);
      if(slideTo) {
        initSlideTo(url);
      }
    }
  }

  const initHeader = (url) => {
    let index = list.map(x => x.url).indexOf(url);
    if(0 <= index && swiperRef != null) {
      swiperRef.slideTo(index - 1, 0, false);
    }
  };

  const initSlideTo = (url) => {
    initHeader(url);
  };

  const isActive = (url) => {
    const removedFistCharUrl = url.substring(1, url.length);

    if(0 < currentPage.search(removedFistCharUrl)) {
      return 'active'
    } else if(currentPage === url) {
      return 'active'
    } else {
      return '';
    }
  }

  const getCurrentPageNumber = () => {
    let index = list.map(x => x.url).indexOf(currentPage);
    return index;
  }

  useEffect(()=>{
    setCurrentPage(location?.pathname);
    initSlideTo(location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  return (
    <>
      <MySwiper
        onSwiper={setSwiperRef}
        // ref={setSwiperRef}
        slidesPerView={5.4}
        freeMode={true}
        pagination={false}
        initialSlide={getCurrentPageNumber()}
        >
        {list.map((list) => (
          <SwiperSlide key={list.url}>
            <MenuItem id={list.url}  onClick={()=>handleMenuOnClick(list.url, false)}>
              <span className={isActive(list.url)}>
                {list.name}
              </span>
            </MenuItem>
          </SwiperSlide>
        ))}
      </MySwiper>
    </>
  );
}

const MySwiper = styled(Swiper)`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3.5rem;
  background-color: #fff;
`;

const MenuItem = styled.div`
  height: 3.3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  & span {
    padding: 0 0.85rem;
    height: 3.7rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
  }
  & span.active {
    font-size: 14px;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #DE2B36;
    border-bottom: 0.2rem solid #DE2B36;
  }
`;