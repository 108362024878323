import React, { memo } from 'react';
import { MarketTextListComponent } from '../component/MarketTextListComponent';
import { MarketPostListComponent } from '../component/MarketPostListComponent';
import { MarketGridComponent } from '../component/MarketGridComponent';

export const MarketListComponent = memo(({ viewMode, list, link, isLoading=true, postOnClick}) => {
  return (
    <>
      { 'text' === viewMode ? (
          <MarketTextListComponent
            list={list}
            link={link}
            isLoading={isLoading}
            postOnClick={postOnClick}
          />
        ) : 'grid' === viewMode ? (
          <MarketGridComponent
            list={list}
            link={link}
            isLoading={isLoading}
            postOnClick={postOnClick}
          />
        ) : (
          <MarketPostListComponent
            list={list}
            link={link}
            isLoading={isLoading}
            postOnClick={postOnClick}
          />
        )
      }
    </>
  );
});