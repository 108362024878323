import React from 'react';
import styled from 'styled-components';

export const SelectComponent = (({ id, value, objectData, onChange, width='auto'}) => {

  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <SelectWrapper width={width}>
      <select
        id={id}
        value={value||''}
        onChange={handleOnChange}
      >
        {Object.keys(objectData).map((key) =>
          <option key={key} value={objectData[key].value}>
            {objectData[key].label}
          </option>
        )}
      </select>
    </SelectWrapper>
  );
});

const SelectWrapper = styled.div`
  margin: 0;
  padding: 0 0.4rem;
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #FFFFFF;
  border: 0.1rem solid #E0E0E0;
  border-radius: 0.4rem;
  & select {
    display: block;
    padding: 0;
    width: 100%;
    height: 4.6rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #424242;

    background-color: #FFFFFF;
    border: 0 solid #E0E0E0;
    outline: none;
  }
`;
