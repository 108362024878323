import qs from "qs";
import { jsonNoAuthApi } from "../../../utils/AxiosApi";

export class AdsApi {
  fetchBannerAdsList(position, listSize=1, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/ads/banner`,
      params: { 'position': position, 'page': 1, 'listSize': listSize },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  };

  fetchRandomAdsList(position, listSize=1, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/ads/random`,
      params: { 'position': position, 'page': 1, 'listSize': listSize },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  };

  fetchLineAdsList(boardId, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/ads/line`,
      params: { 'boardId': boardId },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  };

  fetchAdById(id, params, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/ads/${id}`,
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params)
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchMarketMainTopAds(position, listSize=1, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'get',
      url: `/api/ads/market-main-top`,
      params: { 'position': position, 'page': 1, 'listSize': listSize },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  };

  clickExtAd(id, callback, errorCallback) {
    jsonNoAuthApi({
      method: 'patch',
      url: `/api/ads/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}
