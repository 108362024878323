import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdsApi } from './api/AdsApi';

export const MarketMainTopAdsModal = ({ show, toggle, list }) => {
  const adsApi = new AdsApi();
  const navigate = useNavigate();

  const handleToggleModal = () => {
    toggle?.();
  }

  const handlerOnClick = () => {
    handleToggleModal?.();
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image.png`;
  }

  const handleOpenDetail = ({id, openDetail, linkUrl}) => {
    if(openDetail) {
      navigate(`/ads/${id}`);
      if(linkUrl) {
        window.open(`${linkUrl}`);
      }
    } else {
      if(linkUrl) {
        adsApi.clickExtAd(id);
        window.open(`${linkUrl}`);
      }
    }
  }

  useEffect(() => {
    if(show) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [show])

  return (
    <>
      {(0<list?.length && show) && (
        <Modal>
          <ModalBody>
            <MarketMainTopAdsWrapper>
              <Title>
                <div>전체보기</div>
                <CloseBtn src="/icon/x-mark.png" alt='닫기 버튼' onClick={handlerOnClick}/>
              </Title>
              <Main>
                <ul>
                  {list?.map((row) => (
                    <li key={row.id} onClick={()=>handleOpenDetail(row)}>
                      <img src={`${row.imageFile1}`} alt={row?.title} onError={(e)=>handleImageError(e)} loading='lazy' />
                    </li>
                  ))}
                </ul>
              </Main>
            </MarketMainTopAdsWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 93vh;
  background-color: rgb(255, 255, 255);
  border-radius: 1.6rem 1.6rem 0 0;
`;

const MarketMainTopAdsWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.1rem solid #f5f5f5;
  border-radius: 1.6rem 1.6rem 0 0;
  & div {
    margin: 0 auto;
    width: 8rem;
    height: 2.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
  };
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.6rem;
  right: 1.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Main = styled.div`
  height: 100%;
  & ul {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;
    list-style: none;
    & li {
      margin-bottom: 1rem;
      cursor: pointer;
      & img {
        width: 100%;
        height: 32.7rem;
        object-fit: cover;
        -webkit-user-drag: none;
      }
    }
  }
`;