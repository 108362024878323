import React, { useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

export const MarketSearch = React.memo(({placeholder, searchData, changeSearchData, handleSearchClick}) => {
  const inputRef = useRef(null);
  const [showClearButton, setShowClearButton] = useState(false);

  const handleCleanOnClick = () => {
    inputRef.current.value = "";
    changeSearchData?.('title', inputRef.current.value);
    changeSearchData('page', 1);
    isNeedClean();
  }

  const handleSearchOnClick = () => {
    handleSearchClick?.();
    isNeedClean();
  }

  const handleSearchOnKeyUp = (event) => {
    const {keyCode} = event;
    if(13 === keyCode) {
      changeSearchData?.('title', inputRef.current.value);
      changeSearchData('page', 1);
      inputRef.current.blur();
    }
    isNeedClean();
  }

  const handleSearchOnBlur = () => {
    changeSearchData?.('title', inputRef.current.value);
    changeSearchData('page', 1);
  }

  const isNeedClean = () => {
    const str = inputRef?.current?.value||'';
    setShowClearButton(0<str.length);
  }

  useEffect(() => {
    inputRef.current.value = searchData.title;
  }, [searchData.title]);

  return (
    <div>
      <SearchSection>
        <div className={'searchGroup'}>
          <input type="text" ref={inputRef} title="검색어 입력" placeholder={placeholder} maxLength={"255"}
            onKeyUp={handleSearchOnKeyUp} onBlur={handleSearchOnBlur}
          />
          <CleanButton show={showClearButton} src='/icon/ph_x-circle-fill-icon.png' alt="Clean Button" onClick={handleCleanOnClick} />
          <img className='search' src='/icon/search-gray-button.png' alt="Search Button" onClick={handleSearchOnClick} />
        </div>
      </SearchSection>
    </div>
  );
});

const SearchSection = styled.div`
  height: 7rem;
  border: 0rem solid #F0F0F0;
  & div.searchGroup {
    position: relative;
    & input {
      position: absolute;
      top: 1.4rem;
      left: 1.6rem;
      right: 1.5rem;
      padding-left: 1rem;
      min-width: 23rem;
      height: 3.8rem;

      font-size: 16px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #5d5e60;
      border: 0.1rem solid #BDBDBD;
      border-radius: 0.4rem;
      outline-color: #ddd;
    }
    & input::placeholder {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
    & img.search {
      position: absolute;
      top: 2.7rem;
      right: 3.2rem;
      width: 1.4rem;
      height: 1.4rem;
      color: #ddd;
      cursor: pointer;
    }
  }
`;

const CleanButton = styled.img`
  display: ${props => (props.show)?'':'none'};
  position: absolute;
  top: 2.6rem;
  right: 5.6rem;
  width: 1.8rem;
  height: 1.8rem;
  color: #ddd;
  cursor: pointer;
`;