import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SpecialFeatureArticles = ({list}) => {
  return (
    <SpecialFeatureArticlesSection>
      <Header>
        <Link to={`/special-feature-article`}>
          <h2>기획특집</h2>
        </Link>
      </Header>
      <ul>
        {list.map((row) => (
          <li key={row.id}>
            <Link to={`/special-feature-article/${row.id}`}>
              <img src={`//cdn.vanchosun.com/news/File_images_board/${row.imageFile}`} alt="" loading='lazy'/>
              <h3>{row.title}</h3>
            </Link>
          </li>
        ))}
      </ul>
    </SpecialFeatureArticlesSection>
  )
}

const Header = styled.div`
  & h2 {
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
`;

const SpecialFeatureArticlesSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 1.1rem 1.6rem;
  max-width: 43.9rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgb(0 0 0 / 6%);
  background-color: #343a40;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #343a40;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    & li {
      padding: 0.5rem;
      border-bottom: 0.1rem solid #ddd;
      & a {
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        & img {
          width: 17rem;
          height: 11rem;
          object-fit: cover;
          -webkit-user-drag: none;
        }
        & h3 {
          margin: 0;
          padding-left: 0.4rem;
          font-size: 14px;
          font-weight: 400;

          overflow: hidden;
        }
      }
    }
    & li:last-child {
      border-bottom: 0 solid #ddd;
    }
  }
`;