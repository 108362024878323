import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '../../../common/Pagination';
import { MarketSearch } from '../component/MarketSearch';
import { MarketListComponent } from '../component/MarketListComponent';
import { MarketListTopAds } from '../../ads/MarketListTopAds';
import { MarketListCategory } from '../component/MarketListCategory';
import { MarketLineAdList } from '../../ads/MarketLineAdList';
import { LocationModal } from '../component/LocationModal';
import { SortComponent } from '../component/SortComponent';
import { openErrorModalForApi } from '../../../utils/ErrorHandler';
import { BuysellApi } from '../api/BuysellApi';
import { UserApi } from '../../user/api/UserApi';
import {
  BuysellCategoriesWithFree,
  BuysellSort
} from '../../../__mocks__/PostData';
import { Helmet } from 'react-helmet-async';

export const BuysellList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "buysell_search";
  const DEFAULT_VIEW_MODE = "list";
  const LIST_SIZE = 20;

  const buysellApi = new BuysellApi();
  const userApi = new UserApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  if (!isMobileDevice) {
    window.location.href = 'https://www.vanchosun.com/market/main/frame.php?main=buysell'; // 여기서 www.com URL로 변경
  }

  const [searchData, setSearchData] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          page: 1,
          listSize: LIST_SIZE,
          totalCount: 0,
          totalPageCount: 0,
          perPageSize: 5,
          categoryCode: '',
          locationCode: '',
          locationName: 'All',
          locationKrName: '모든지역',
          sort: 'newest',
          title: null,
          scrollY: 0,
          viewMode: DEFAULT_VIEW_MODE,
        };
      }
    }
  });
  const [buysellList, setBuysellList] = useState({list:[]});

  const changeSearchData = (name, value) => {
    setSearchData((searchData) => {
      const updated = {...searchData};
      updated[name] = value;
      return updated;
    });
  };

  const changeCategoryData = (id) => {
    changeSearchData("page", 1);
    changeSearchData("categoryCode", id);
    if("free" === id) {
      changeSearchData("type", "무료나눔");
    } else if("moving-sale" === id) {
      changeSearchData("type", "무빙세일");
    } else {
      changeSearchData("type", "");
    }
  };

  const changeLocationData = (code, name, krName) => {
    changeSearchData("page", 1);
    changeSearchData('locationCode', code);
    changeSearchData('locationName', name);
    changeSearchData('locationKrName', krName);
  };
  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal);
  }
  const changeSortData = (code) => {
    changeSearchData('sort', code);
  };

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    setLoading(true);
    buysellApi.fetchBuysellList(
      searchData,
      (data) => {
        setBuysellList(data);
        changeSearchData('totalCount', data.totalCount);
        changeSearchData('totalPageCount', data.totalPageCount);
        setLoading(false);
        handleMoveScroll();
      },
      (error) => {
        setLoading(false);
        openErrorModalForApi(error, setDialog);
      }
    );
  }

  const handlePageChange = (pageNum) => {
    changeSearchData('page', pageNum);
    changeSearchData('scrollY', 0);
  }

  const handlerItemCardOnClick = (url) => {
    saveSearchData();
    navigate(url);
  }

  const handleNewOnClick = () => {
    if(userApi.isSignIn()) {
      navigate("/buysell-term-Service");
    } else {
      navigate("/signIn");
    }
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchData));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  // 스크롤 자동 이동 처리
  const handleFollow  = () => {
    changeSearchData("scrollY", window.pageYOffset);
  }
  const handleMoveScroll = () => {
    setTimeout(()=>{
      window.scrollTo({
        top: searchData.scrollY,
        behavior: "smooth"
      });
    }, 0);
  }

  const handleChangeViewMode = () => {
    if(isGridMode()) {
      changeSearchData('viewMode', 'list');
    } else {
      changeSearchData('viewMode', 'grid');
    }
  }

  const isGridMode = () => {
    return searchData.viewMode==='grid'?true:false;
  }

  useEffect(() => {
    handleSearchClick();
    removeSearchData();

    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.page, searchData.sort, searchData.title, searchData.categoryCode, searchData.locationCode]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{`사고팔고 - 밴마켓`}</title>
        </Helmet>
        <MarketSearch
          placeholder={'어떤 물건을 찾고 계신가요?'}
          searchData={searchData}
          changeSearchData={changeSearchData}
          handleSearchClick={handleSearchClick}
        />
        <MarketListTopAds
          position={'buysell_top_ra'}
          listSize={5}
        />
        <MarketListCategory
          categories={{'': { value: '', label: '전체'}, ...BuysellCategoriesWithFree}}
          searchData={searchData}
          changeSearchData={changeCategoryData}
          handleSearchClick={handleSearchClick}
        />
        <MarketLineAdList
          boardId={1}
          mobileAdOnClick={handlerItemCardOnClick}
        />
        <SearchFilter>
          <div onClick={()=>handleChangeViewMode()}>
            {isGridMode()
              ?<img className={'imageList'} src='/icon/image-list.png' alt=''/>
              :<img className={'cardList'} src='/icon/grid-2.png' alt=''/>
            }
          </div>
          <div>
            <div className='mapPin' onClick={()=> toggleLocationModal()}>
              {searchData?.locationKrName}
              <img src='/icon/location-map-pin-icon.png' alt='Location Name'/>
            </div>
            <LocationModal
              showLocationModal={showLocationModal}
              toggleLocationModal={toggleLocationModal}
              locationCode={searchData?.locationCode}
              changeLocationData={changeLocationData}
            />
            <SortComponent
              sortDisplayList={BuysellSort}
              sortCode={searchData?.sort}
              changeSortData={changeSortData}
            />
          </div>
        </SearchFilter>

        <div className='list'>
          <MarketListComponent
            viewMode={searchData.viewMode}
            list={buysellList.list}
            link={'buysell'}
            isLoading={loading}
            postOnClick={handlerItemCardOnClick}/>
        </div>
        <Pagination
          currentPage={searchData.page}
          totalPage={searchData.totalPageCount}
          perPageSize={searchData.perPageSize}
          handlePageChange={handlePageChange}
        />
        <WriteButton className='sticky'>
          <img src='/icon/write-button-icon.png' alt='Write button' onClick={()=>handleNewOnClick()}/>
        </WriteButton>
      </PageWrapper >
    </>
  );
}

const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 0;
  width: auto;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  & div.list {
    padding: 0 1.6rem;
    /* min-height: 39vh; */
  }
`;

const WriteButton = styled.div`
  position: fixed;
  bottom: 2.7rem;
  right: 1.6rem;
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fd6258;
  border-radius: 50%;
  z-index: 10;
  & img {
    position: absolute;
    top: 24%;
    left: 24%;
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
  }
`;

const SearchFilter = styled.div`
  padding: 0 1.6rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #F5F5F5;
  & img.cardList {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & img.imageList {
    width: 1.7rem;
    height: 1.5rem;
    cursor: pointer;
  }
  & div.mapPin {
    padding-right: 1rem;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    color: #424242;
    cursor: pointer;
    & img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;
