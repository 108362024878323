import { formWithMultipartApi, jsonApi } from "../../../utils/AxiosApi";
import {
  hasAccessToken,
  remoteToken,
  setToken
} from "../../../utils/TokenManager";

export class UserApi {

  signIn(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/account/signIn`,
      data: formData,
    }).then((response) => {
      setToken(response.data.access_token);
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  signOut(callback, errorCallback) {
    remoteToken();
    jsonApi({
      method: 'post',
      url: `/api/account/signOut`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  isSignIn() {
    return hasAccessToken();
  }

  signUp(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/account`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveUserInfo(formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/account`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deregisterUser(callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/account`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  uploadUserImage(formData, callback, errorCallback) {
    formWithMultipartApi({
      method: 'post',
      url: `/api/account/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteUserImage(callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/account/imageFile`,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  resetPassword(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/account/reset-password`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  savePassword(formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/account/reset-password`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deactivate(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/account/deactivate`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchUser(callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/user`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchUserPostList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/user/post`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchUserReplyList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/user/reply`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}