import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { elapsedText, isEmptyObj, priceToString } from '../../../utils/utils';
import { CommercialApi } from '../api/CommercialApi';
import { CommercialAdditionalFacilities, CommercialIncludedOptions, CommercialTypes, CommercialUnits, RealEstateCities, RealEstateProvinces } from '../../../__mocks__/PostData';
import { ImageGalleryModal } from '../component/ImageGalleryModal';
import { RealtyAccordionComponent } from '../realty/component/RealtyAccordionComponent';
import { ImageSlideComponent } from '../component/ImageSlideComponent';

export const CommercialDetail = ({setDialog}) => {
  const NO_IMAGE = '/img/no-image-rent-homestay.png';
  const commercialApi = new CommercialApi();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showImageModal, setShowImageModal] = useState(false);
  const [article, setArticle] = useState({
    id: '',
    boardId: '',
    active: '',
    email: '',
    name: '',
    phone: '',
    price: '',
    title: '',
    description: '',
    updateDate: '',
    images: {},
  });

  // handler ---------------------------------------------
  const handleSearchClick = () => {
    commercialApi.fetchCommercialById(id,
      (data) => {
        setArticle(data.result);
      },
      (error) => {
        const {response: {data}} = error;
        setDialog({isOpen: true, message: data.message, type: "nonHeader"});
        // setDialog({isOpen: true, message: "현재 등록된 게시물이 없습니다.", type: "alert"});
        handleHistoryBackClick();
      },
    );
  }

  const handleHistoryBackClick = () => {
    navigate("/realty-sale", {state: {isBack: true, type: 'commercial'}});
  }

  const handleStoreOwnerImageError = (e) => {
    e.target.src=`/img/no-store-owner-image.png`;
  }

  const handlerToggleShowModal = () => {
    if(isEmptyObj(article.images)) return;
    setShowImageModal(!showImageModal);
  }

  const isIncludedOptionsChecked = (value) => {
    return 0<=article.includedOptions?.indexOf(value);
  }

  const isAdditionalFacilitiesChecked = (value) => {
    return 0<=article.additionalFacilities?.indexOf(value);
  }

  // render -------------------------------------------------
  const renderCategory = ({type}) => {
    const typeLabel = type ? CommercialTypes[type]?.label : type;
    return (
      <>
        <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
        <span className='categoryLv1'>비즈매매</span>
        {(typeLabel) && (
          <>
            <img src='/icon/separation-angle-right-icon.png' alt='arrow right'/>
            <span className='categoryLv2'>{typeLabel}</span>
          </>
        )}
      </>
    );
  }

  const addCommercialFlagTypeTag = ({flagType, isSoldOut}) => {
    if(!flagType && !isSoldOut) return;
    let backgroundColor = '';
    if("급매" === flagType) {
      backgroundColor = '#F77B21';
    } else if("전매" === flagType) {
      backgroundColor = '#8440BA';
    } else if("분양" === flagType) {
      backgroundColor = '#329476';
    }
    return (
      <ArticleTag>
        {isSoldOut ? (
          <span style={{background: '#DE2B36'}}>SOLD</span>
        ) : (
          <span style={{background: backgroundColor}}>{flagType}</span>
        )}
      </ArticleTag>
    );
  }

  const addLocationNContactTag = ({updatedDate, hitCount}) => {
    return (<span>{elapsedText(updatedDate)} · 조회수 {hitCount}</span>);
  }

  const addIncludedOptionsTag = (id, label) => {
    if(isIncludedOptionsChecked(id)) {
      return (
        <div className='checkbox-checked' >
          <img src='/icon/checkbox-checked-icon.png' alt='checked' />
          <label>{label}</label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-unchecked'>
          <img src='/icon/checkbox-unchecked-icon.png' alt='unchecked' />
          <label>{label}</label>
        </div>
      );
    }
  }

  const addAdditionalFacilitiesTag = (id, label) => {
    if(isAdditionalFacilitiesChecked(id)) {
      return (
        <div className='checkbox-checked' >
          <img src='/icon/checkbox-checked-icon.png' alt='checked' />
          <label>{label}</label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-unchecked'>
          <img src='/icon/checkbox-unchecked-icon.png' alt='unchecked' />
          <label>{label}</label>
        </div>
      );
    }
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <ImageSlideComponent
          images={article.images}
          noImgUrl={NO_IMAGE}
          onClick={()=>handlerToggleShowModal()}
        />
        <CategoryGroup>
          <div className='category'>
            <span className='categoryLv0'>전체</span>
            {renderCategory(article)}
          </div>
          <span className='postId'>{`게시글번호 ${article.id}`}</span>
        </CategoryGroup>
        <ContentWrapper>
          {addCommercialFlagTypeTag(article)}
          <PageTitle>{article.title}</PageTitle>
          <PriceInfo>
            {0 < article?.price ? priceToString(article?.price) : `가격문의`}
          </PriceInfo>
          <UpdateInfo>
            {addLocationNContactTag(article)}
          </UpdateInfo>
          <LocationInfo>
            <img src='/icon/location-map-pin-black-icon.png' alt='위치 표시'/>
            {article?.address && `${article.address},`}
            {article?.locationSubId && ` ${RealEstateCities[article.locationSubId]?.enName},`}
            {article?.locationId && ` ${RealEstateProvinces[article.locationId]?.label}`}
            {article?.postal && ` (${article.postal})`}
          </LocationInfo>
          {article.storeId
            ? (
              <StoreInfo>
                <StoreTitle>
                  <span>AGENT INFO</span>
                  {article.storeTitle}
                </StoreTitle>
                <StoreDetail>
                  <img src={`https://www.vanchosun.com/stores/File_images_stores/${article.storeImageFile}`} onError={(e) => handleStoreOwnerImageError(e)} alt={article.storeTitle} />
                  <div className='storeDetails'>
                    <div className='storeOwner'>{article.storeName}</div>
                    <div>{article.storePhone}</div>
                    <div>{article.storeEmail}</div>
                  </div>
                </StoreDetail>
              </StoreInfo>
            )
            : (
              <StoreInfo>
                <StoreTitle>
                  <span>AGENT INFO</span>
                </StoreTitle>
                <StoreDetail>
                  <img src={`/img/no-store-owner-image.png`} onError={(e) => handleStoreOwnerImageError(e)} alt={'개인셀러'} />
                  <div className='storeDetails'>
                    <div className='storeOwner'>{article.name}</div>
                    <div>{article.phone}</div>
                    <div>{article.email}</div>
                  </div>
                </StoreDetail>
              </StoreInfo>
            )
          }
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>한 눈에 보는 매물</Title>
          <PreviewInfo>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='floorIcon' src='/icon/floor-icon.png' alt='floor'/>
                      </div>
                      <div className='label'>층(수)</div>
                      <div className='value'>
                        {(article.floorNumber) ? `${article.floorNumber}층` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='indoorSpaceIcon' src='/icon/indoor-space-icon.png' alt='indoorSpace'/>
                      </div>
                      <div className='label'>실내면적</div>
                      <div className='value'>
                        {(article.indoorSpace) ? `${article.indoorSpace} ${CommercialUnits[article.indoorSpaceUnit]?.label}` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='landSpaceIcon' src='/icon/land-space-icon.png' alt='landSpace'/>
                      </div>
                      <div className='label'>대지면적</div>
                      <div className='value'>
                        {(article.landSpace) ? `${article.landSpace} ${CommercialUnits[article.landSpaceUnit]?.label}` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='parkingLotIcon' src='/icon/parking-lot-icon.png' alt='parkingLot'/>
                      </div>
                      <div className='label'>주차대수</div>
                      <div className='value'>
                        {(article.numberParkingSpaces) ? `${article.numberParkingSpaces}대` : '-'}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
          <Separator height={'0.2rem'}/>
          <PreviewInfo>
            <table>
              <tbody>
                <tr className='icon'>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='builtInIcon' src='/icon/built-in-icon.png' alt='builtIn'/>
                      </div>
                      <div className='label'>운영기간</div>
                      <div className='value'>
                        {(0<article.operationPeriod) ? `${article?.operationPeriod}개월` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='moveInDateIcon' src='/icon/move-in-date-icon.png' alt='moveIn Date'/>
                      </div>
                      <div className='label'>리스기간</div>
                      <div className='value'>
                        {(0<article?.leasePeriod) ? `${article?.leasePeriod}개월` : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='coinsIcon' src='/icon/coins-icon.png' alt='coins'/>
                      </div>
                      <div className='label'>월매출</div>
                      <div className='value'>
                        {(0<article?.monthlySales) ? priceToString(article?.monthlySales) : '-'}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='icon'>
                        <img className='depositIcon' src='/icon/deposit-icon.png' alt='deposit'/>
                      </div>
                      <div className='label'>렌트비</div>
                      <div className='value'>
                        {(0<article?.rentalPrice) ? priceToString(article?.rentalPrice) : '-'}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PreviewInfo>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>포함 사항</Title>
          <RealtyAccordionComponent>
            <AdditionalInfo>
              {Object.keys(CommercialIncludedOptions).map((key)=>
                <li key={key}>
                  {addIncludedOptionsTag(key, CommercialIncludedOptions[key]?.label)}
                </li>
              )}
            </AdditionalInfo>
          </RealtyAccordionComponent>
          <Separator height={'0.2rem'}/>
          <Title>특이 사항</Title>
          <div style={{marginBottom: '3.5rem'}}>
            <AdditionalInfo>
              {Object.keys(CommercialAdditionalFacilities).map((key)=>
                <li key={key}>
                  {addAdditionalFacilitiesTag(key, CommercialAdditionalFacilities[key].label)}
                </li>
              )}
            </AdditionalInfo>
          </div>
        </ContentWrapper>
        <Separator height={'1rem'}/>
        <ContentWrapper>
          <Title>매물 설명</Title>
          <Content dangerouslySetInnerHTML={{__html: article.description}} />
        </ContentWrapper>
        <ImageGalleryModal
          title='매물 사진'
          showModal={showImageModal}
          toggleShowModal={()=>handlerToggleShowModal()}
          imageList={article.images} />
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  padding: 0;
  width: auto;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 0rem 1.6rem;
`;

const Separator = styled.div`
  width: auto;
  height: ${props => props.height};
  background-color: #f7f7f7;
`;

const CategoryGroup = styled.div`
  position: relative;
  padding-left: 1.4rem;
  & div.category {
    width: auto;
    height: 4rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #f5f5f5;
    & span.categoryLv0 {
      color: #424242;
    }
    & span.categoryLv1 {
      /* font-weight: 500; */
      color: #424242;
      /* color: #FD6258; */
    }
    & span.categoryLv2 {
      font-weight: 500;
      color: #FD6258;
    }
    & img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  & span.postId {
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    padding: 0.2rem 0.8rem;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
    color: #616161;
    border: 0.1rem solid #eee;
    border-radius: 30rem;
  }
`;

const ArticleTag = styled.div`
  margin: 2.1rem 0 0 0;
  display: flex;
  align-items: center;
  & span {
    margin-right: 0.5rem;
    padding: 0 1.1rem;
    width: auto;
    height: 1.8rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
  }
`;
const PageTitle = styled.h1`
  margin: 0;
  padding: 1rem 0 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  word-break: break-all;
`;
const Title = styled.h1`
  margin: 2.8rem 0 2.4rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
`;
const PriceInfo = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 2.9rem;
  color: #000;
`;
const UpdateInfo = styled.div`
  margin-top: 0.4rem;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;
  }
`;
const LocationInfo = styled.div`
  margin-top: 0.4rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #424242;
  display: flex;
  align-items: center;
  & img {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const StoreInfo = styled.div`
  margin: 3.7rem 0;
  width: auto;
  height: auto;
  background-color: #F7F7F7;
  border-radius: 0.4rem;
`;
const StoreTitle = styled.div`
  padding: 1.6rem;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;
  border-bottom: 0.2rem solid #fff;
  & span {
    padding: 0 0.4rem;
    width: 6.6rem;
    height: 1.8rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #FD6258;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #FD6258;
    border-radius: 0.2rem;
  }
`;
const StoreDetail = styled.div`
  padding: 1.6rem;
  height: auto;
  display: flex;
  align-items: center;
  & img {
    width: 7.6rem;
    height: 7.6rem;
    object-fit: cover;
    background: #FFFFFF;
    border-radius: 4px;
  }
  & div.storeDetails {
    margin-left: 2.1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;
    & div.storeOwner {
      margin-bottom: 0.6rem;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
  }
`;

const PreviewInfo = styled.div`
  margin: 0;
  padding: 0;
  border-radius: 0.4rem;
  & table {
    padding: 0rem 0rem;
    width: 100%;
    & tr {
      width: 100%;
      height: 12rem;
      & td {
        width: 8.3rem;
        & div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & div.icon {
            width: 4.2rem;
            height: 4.2rem;
            & img {
              width: 2rem;
              height: 2rem;
            }
            & img.parkingLotIcon {
              width: 2.4rem;
              height: 1.8rem;
            }
            & img.builtInIcon {
              width: 2.4rem;
              height: 2.1rem;
            }
            & img.coinsIcon {
              width: 2.4rem;
              height: 1.745rem;
            }
          }
          & div.label {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #424242;
          }
          & div.value {
            width: auto;
            height: 1.7rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.7rem;
            letter-spacing: -0.04em;
            color: #9E9E9E;
          }
        }
      }
    }
  }
`;

const AdditionalInfo = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & li {
    margin: 0.4rem;
    width: 47.6%;
    height: 3.2rem;
    & div.checkbox-checked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #fd6258;
      }
    }
    & div.checkbox-unchecked {
      display: flex;
      align-items: center;
      border: 0.1rem solid #eee;
      border-radius: 0.4rem;
      & img {
        margin: 0 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      & label {
        font-size: 12px;
        font-weight: 400;
        line-height: 3.2rem;
        letter-spacing: -0.04em;
        color: #9E9E9E;
      }
    }
  }
`;

const Content = styled.div`
  padding: 0.5rem 0;
  width: auto;
  height: 100%;
  overflow: auto;
  clear: both;
`;
