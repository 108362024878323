import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Pagination } from 'swiper';
import 'swiper/css';

export const MarketListCategory = React.memo(({ categories, searchData, changeSearchData }) => {
  SwiperCore.use([FreeMode, Pagination]);

  const handleCategoryOnClick = (id) => {
    changeSearchData?.(id);
  }

  const isActive = (id) => {
    return searchData.categoryCode === id ? 'active' : '';
  }

  return (
    <>
      <MySwiper
        slidesPerView={3.7}
        freeMode={true}
        pagination={false}
      >
        {Object.keys(categories).map((key) => (
          <SwiperSlide key={key}>
            <CategoryItem id={key}
              className={isActive(key)}
              onClick={()=>handleCategoryOnClick(categories[key].value)}
            >
              {categories[key].label}
            </CategoryItem>
          </SwiperSlide>
        ))}
      </MySwiper>
    </>
  );
});

const MySwiper = styled(Swiper)`
  margin-bottom: 1.4rem;
  width: 100%;
  height: 3.5rem;
  background: #fff;
`;

const CategoryItem = styled.div`
  margin-left: 1.6rem;
  height: 3rem;
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  background: #FFFFFF;

  border: 0.1rem solid #9E9E9E;
  border-radius: 4px;

  cursor: pointer;
  &.active {
    font-size: 14px;
    font-weight: 700;
    color: #fd6258;
    border: 0.1rem solid #fd6258;
  }
`;