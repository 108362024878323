import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { elapsedText } from '../../../utils/utils';

export function UserReplyListComponent({ list, isLoading=true, postOnClick}) {

  const navigate = useNavigate();
  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleMarketButtonOnClick = () => {
    navigate('/community', {replace : true});
  }

  return (
    <PostListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <PostSection>
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
            <div style={{margin: '1rem 0.2rem', width: '100%', height: '30px', backgroundColor: '#F5F5F5'}} />
          </PostSection>
        </SkeletonComponent>
      ) : (
        <>
          {list?.length === 0 ? (
            <NoData>
              <img src='/icon/no-data-icon.png' alt='알림 표시' />
              <p>
                아직 작성한 댓글이 없네요!<br/>
                다른 사람들과 의견을 마음껏 나눠보세요
              </p>
              <div className='marketButton' onClick={()=>handleMarketButtonOnClick()}>
                밴마켓 커뮤니티 바로가기 <img src='/icon/angle-right-gray2-icon.png' alt='더보기' />
              </div>
            </NoData>
          ):(
            <PostSection>
              {list?.map((row)=>(
                <ReplyItem key={row.postId} onClick={() => handlerPostOnClick(`/community/${row.postId}`)}>
                  <ReplyCard>
                    <h2>{row.replyMessage}</h2>
                    <span>
                      {elapsedText(row.updatedDate)}
                    </span>
                  </ReplyCard>
                  <PostInfo>
                    <PostBoardName>
                      {row.boardName}
                    </PostBoardName>
                    <PostTitle>
                      {row.postTitle}
                    </PostTitle>
                    <PostReplyCount>
                      <img src='/icon/chat-light-icon.png' alt='댓글' />
                      <span>{row.postReplyCount}</span>
                    </PostReplyCount>
                  </PostInfo>
                </ReplyItem>
              ))}
            </PostSection>
          )}
        </>
      )}
    </PostListWrapper>
  );
}

const PostListWrapper = styled.div`
  width: 100%;
`;

const PostSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ReplyItem = styled.li`
  margin: 0.8rem 0;
  width: 100%;
  height: 12rem;
`;

const ReplyCard = styled.div`
  width: 100%;
  height: auto;
  & h2 {
    margin: 0;
    padding: 1.5rem 0 0.5rem;
    width: inherit;
    height: 2rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & span {
    margin: 0;
    padding: 0;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9e9e9e;

    display: flex;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const PostInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PostBoardName = styled.span`
  margin: 0;
  padding: 0 0.6rem;

  height: 1.8rem;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: -0.06em;
  color: #FD6258;

  display: flex;
  align-items: center;

  background: #FFEAE9;
  border-radius: 2px;
`;

const PostTitle = styled.h2`
  margin: 0 0 0 0.6rem;
  width: auto;
  max-width: 21rem;
  height: 1.8rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PostReplyCount = styled.div`
  margin-left: 1.1rem;
  display: flex;
  align-items: center;
  & img {
    width: 1.6rem;
    height: 1.6rem;
  }
  & span {
    padding-left: 0.4rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
  }
`;

const NoData = styled.p`
  margin: 0;
  padding: 5.9rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & img {
    width: 6rem;
    height: 6rem;
  }
  & p {
    margin-bottom: 2.9rem;
    width: 25.2rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 2.3rem;
    text-align: center;
    letter-spacing: -0.04em;
    word-break: keep-all;
    color: #000000;
  }
  & div.marketButton {
    width: 20.6rem;
    height: 3.6rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #616161;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0.1rem solid #e0e0e0;
    border-radius: 30rem;
    cursor: pointer;
    & img {
      padding: 0.2rem 0 0 0.8rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;