import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const MarketTermOfServiceAgreement = ({ name, setDialog }) => {
  const navigate = useNavigate();
  const [isAll, setIsAll] = useState(false);
  const [isTermsPrivacy, setIsTermsPrivacy] = useState(false);
  const [isPersonalTransaction, setIsPersonalTransaction] = useState(false);

  const handleAllChange = () => {
    setIsAll(current => !current);
    handleTermsPrivacyChange(!isAll);
    handlePersonalTransactionChange(!isAll);
  };
  const handleTermsPrivacyChange = (value) => {
    if(value) {
      setIsTermsPrivacy(value);
    } else {
      setIsTermsPrivacy(current => !current);
    }
  };
  const handlePersonalTransactionChange = (value) => {
    if(value)
      setIsPersonalTransaction(value);
    else
      setIsPersonalTransaction(current => !current);
  }

  const handleNextButtonOnClick = () => {
    navigate("/buysell-edit", {replace : true});
  }

  const handlePrivacyPolicyButtonOnClick = () => {
    navigate("/market-privacy-policy");
  }

  const handleTermOfServiceButtonOnClick = () => {
    navigate("/market-term-of-service");
  }

  const handleProhibitGoodsButtonOnClick = () => {
    navigate("/market-prohibit-goods");
  }


  useEffect(() => {
    console.log(isTermsPrivacy, isPersonalTransaction);
    if(isTermsPrivacy && isPersonalTransaction) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTermsPrivacy, isPersonalTransaction]);

  return (
    <PageWrapper>
      <PageTitle>
        아래의 약관에 동의하신 후<br/>
        {name} 서비스를 이용해주세요!
      </PageTitle>
      <CheckAllBox value={isAll}>
        <label htmlFor={'all'}></label>
        <input id={'all'} name={'all'} type={'checkbox'} value={isAll} onChange={()=>handleAllChange()} />
        <div className='titleForCheckbox'>
          <div>약관 전체 동의</div>
        </div>
      </CheckAllBox>
      <CheckBox value={isTermsPrivacy}>
        <label htmlFor={'termsPrivacy'}></label>
        <input id={'termsPrivacy'} name={'termsPrivacy'} type={'checkbox'} value={isTermsPrivacy} onChange={()=>handleTermsPrivacyChange()} />
        <div className='titleForCheckbox'>
          <div>개인정보 수집 및 이용동의 (필수)</div>
          <div className='forEnglish'>Agree to Terms of Service and Privacy Policy.</div>
        </div>
      </CheckBox>
      <DescriptionBox>
        <div>개인정보 취급/수집 및 사용 동의(Privacy Policy)</div>
        <img src='/icon/termOfService-arrow-right-icon.png' alt='arrow right' onClick={()=>handlePrivacyPolicyButtonOnClick()}/>
      </DescriptionBox>
      <DescriptionBox>
        <div>이용약관동의(Terms of Service)</div>
        <img src='/icon/termOfService-arrow-right-icon.png' alt='arrow right' onClick={()=>handleTermOfServiceButtonOnClick()}/>
      </DescriptionBox>
      <CheckBox value={isPersonalTransaction}>
        <label htmlFor={'personalTransaction'}></label>
        <input id={'personalTransaction'} name={'personalTransaction'} type={'checkbox'} value={isPersonalTransaction} onChange={()=>handlePersonalTransactionChange()} />
        <div className='titleForCheckbox'>
          <div>
            전자상거래 개인 거래 및 소비자 보호 지침<br/>
            (거래금지 품목 및 위반 시 처벌) 동의 (필수)
          </div>
        </div>
      </CheckBox>
      <DescriptionBox>
        <div>거래 금지 항목 안내(Terms of Service)</div>
        <img src='/icon/termOfService-arrow-right-icon.png' alt='arrow right' onClick={()=>handleProhibitGoodsButtonOnClick()}/>
      </DescriptionBox>
      <ButtonGroup>
        <button type='button'
        onClick={() => handleNextButtonOnClick()}
        disabled={!isAll}>다음</button>
      </ButtonGroup>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0.5rem 0;
  width: auto;
  background-color: #fff;
`;

const PageTitle = styled.h1`
  margin: 2.5rem auto;
  padding: 0 1.6rem;
`;

const CheckAllBox = styled.div`
  margin: 0;
  padding: 1.5rem 3.2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #eee;
  & label {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    background: ${props => props.value? 'url("/icon/checkbox-active-icon.png") no-repeat center': 'url("/icon/checkbox-inactive-icon.png") no-repeat center'};
    background-size: cover;
  }
  & input {
    display: none;
  }
  & div.titleForCheckbox {
    margin-left: 1.5rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const CheckBox = styled.div`
  margin: 1.6rem;
  padding: 1.5rem 1.6rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  & label {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    background: ${props => props.value? 'url("/icon/checkbox-active-icon.png") no-repeat center': 'url("/icon/checkbox-inactive-icon.png") no-repeat center'};
    background-size: cover;
  }
  & input {
    display: none;
  }
  & div.titleForCheckbox {
    margin-left: 1.5rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #000;
    & div.forEnglish {
      margin-top: 0.2rem;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: -0.04em;
      color: #616161;
    }
  }
`;

const DescriptionBox = styled.div`
  position: relative;
  margin: 1.2rem 3.3rem;
  padding: 0;
  display: flex;
  align-items: center;
  & div {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
  & img {
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    width: 0.6rem;
    cursor: pointer;
  }
`;

const ButtonGroup = styled.div`
  margin: 3rem 1.6rem;
  display: flex;
  justify-content: center;
  & button {
    width: 100%;
    height: 5.4rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 5.4rem;
    letter-spacing: -0.04em;
    color: #fff;
    background-color: #fd6258;
    border: 0.1rem solid #fd6258;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  & button:disabled {
    background: #E0E0E0;
    border: 0.1rem solid #e0e0e0;
  }
`;
