import React from 'react';
import styled, { keyframes } from 'styled-components';

export const SkeletonComponent = ({children}) => {
  return (
    <SkeletonWrapper>
      {children}
    </SkeletonWrapper>
  );
}

const loading = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

const SkeletonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  animation: ${loading} 2.5s infinite;
`;