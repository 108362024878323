import React, { useEffect, useState } from "react";
// import { RecoilRoot } from 'recoil';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import styled, { ThemeProvider } from "styled-components";
import { NewsMain } from "./domain/news/main/NewsMain";
import { NewsList } from "./domain/news/news/NewsList";
import { NewsDetail } from "./domain/news/news/NewsDetail";
import { InterviewList } from "./domain/news/interview/InterviewList";
import { OpinionList } from "./domain/news/opinion/OpinionList";
import { PhotoList } from "./domain/news/photo/PhotoList";
import { ImmigrantList } from "./domain/news/immigrant/ImmigrantList";
import { KoreaList } from "./domain/news/korea/KoreaList";
import { EducationList } from "./domain/news/education/EducationList";
import { KoreanList } from "./domain/news/korean/KoreanList";
import { SpecialFeatureArticleList } from "./domain/news/specialFeatureArticle/SpecialFeatureArticleList";
import { RealtyList } from "./domain/news/realty/RealtyList";
import { MarketMain } from "./domain/market/main/MarketMain";
import { BuysellList } from "./domain/market/buysell/BuysellList";
import { BuysellDetail } from "./domain/market/buysell/BuysellDetail";
import { BuysellDetailEdit } from "./domain/market/buysell/BuysellDetailEdit";
import { JobList } from "./domain/market/job/JobList";
import { JobDetail } from "./domain/market/job/JobDetail";
import { JobDetailEdit } from "./domain/market/job/JobDetailEdit";
import { TutorList } from "./domain/market/tutor/TutorList";
import { TutorDetail } from "./domain/market/tutor/TutorDetail";
import { TutorDetailEdit } from "./domain/market/tutor/TutorDetailEdit";
import { CarList } from "./domain/market/car/CarList";
import { CarDetail } from "./domain/market/car/CarDetail";
import { RentHomestayList } from "./domain/market/rent-homestay/RentHomestayList";
import { RentDetail } from "./domain/market/rent/RentDetail";
import { RentDetailEdit } from "./domain/market/rent/RentDetailEdit";
import { HomestayDetail } from "./domain/market/homestay/HomestayDetail";
import { HomestayDetailEdit } from "./domain/market/homestay/HomestayDetailEdit";
import { RealtySaleList } from "./domain/market/realty/RealtySaleList";
import { DwellingDetail } from "./domain/market/dwelling/DwellingDetail";
import { CommercialDetail } from "./domain/market/commercial/CommercialDetail";
import { CommunityList } from "./domain/market/community/CommunityList";
import { CommunityDetail } from "./domain/market/community/CommunityDetail";
import { CommunityDetailEdit } from "./domain/market/community/CommunityDetailEdit";
import { YellowpageList } from "./domain/market/yellowpage/YellowpageList";
import { YellowpageDetail } from "./domain/market/yellowpage/YellowpageDetail";
import { MobileAdDetail } from "./domain/ads/MobileAdDetail";
import { NewsListLayout } from "./routes/NewsListLayout";
import { MarketListLayout } from "./routes/MarketListLayout";
import { NewsDetailLayout } from "./routes/NewsDetailLayout";
import { MarketDetailLayout } from "./routes/MarketDetailLayout";
import { MarketPaymentLayout } from "./routes/MarketPaymentLayout";
import { MarketTermOfServiceAgreement } from "./domain/market/component/MarketTermOfServiceAgreement";
import { MarketPrivacyPolicy } from "./domain/market/component/MarketPrivacyPolicy";
import Modal from "react-modal";
import { Dialog } from "./common/Dialog";
import { theme } from "./theme";
import { MarketTermOfService } from "./domain/market/component/MarketTermOfService";
import { MarketProhibitGoods } from "./domain/market/component/MarketProhibitGoods";
import { PaymentStep1 } from "./domain/market/payment/PaymentStep1";
import { PaymentStep2 } from "./domain/market/payment/PaymentStep2";
import { PaymentStep3 } from "./domain/market/payment/PaymentStep3";
import { UserDetailLayout } from "./routes/UserDetailLayout";
import { SignIn } from "./domain/user/SignIn";
import { SignUp } from "./domain/user/SignUp";
import { ResetPassword } from "./domain/user/ResetPassword";
import { Profile } from "./domain/user/Profile";
import { ProfileEdit } from "./domain/user/ProfileEdit";
import { UserPostList } from "./domain/user/UserPostList";
import { NewsSearchList } from "./domain/news/search/NewsSearchList";
import { NewsReport } from "./domain/news/news/NewsReport";
import { AdvertisingInformation } from "./domain/ads/AdInformation";
import { UserPrivacyPolicy } from "./domain/user/UserPrivacyPolicy";
import { UserTermOfService } from "./domain/user/UserTermOfService";
import { ErrorPage } from "./common/ErrorPage";
import TagManager from "react-gtm-module";
Modal.setAppElement('#root');

function App() {
  const [dialog, setDialog] = useState({
    isOpen: false, message: "", type: "alert"
  });

  const handleDialogClose = () => {
    setDialog((values) => {
      const updated = {...values};
      updated['isOpen'] = false;
      return updated;
    });
    return 0;
  }



  useEffect(() => {

    const tagManagerArgs = {
      gtmId: 'GTM-W2MQD98'
    };

    TagManager.initialize(tagManagerArgs);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  const currentURL = window.location.href;

// 모바일 장치가 아닌 경우만 처리
if (!isMobileDevice) {
  // 현재 URL을 가져옵니다.
  const currentURL = window.location.href;

  // m.vanchosun.com/news/ 뒤의 bdid 값을 추출
  const newsRegex = /m\.vanchosun\.com\/news\/(\d+)/;
  const newsMatch = currentURL.match(newsRegex);

  // m.vanchosun.com/job인 경우 처리
  if (currentURL === 'https://m.vanchosun.com/job') {
    window.location.href = 'https://www.vanchosun.com/market/main/frame.php?main=job';
  } else if (newsMatch && newsMatch[1]) {
    const bdid = newsMatch[1]; // 정규 표현식에서 추출한 bdid 값
    const redirectURL = `https://www.vanchosun.com/news/main/frame.php?main=1&boardId=17&bdId=${bdid}`;

    // 해당 URL로 리다이렉트
    window.location.href = redirectURL;
  } else {
    // bdid 값이 없을 경우 m.vanchosun.com으로 리다이렉트
    window.location.href = 'https://www.vanchosun.com';
  }
}


  }, []);

  return (
    // <RecoilRoot>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RootWrapper>
          <RootContainer>
            <Dialog
              isOpen={dialog.isOpen}
              handleClose={handleDialogClose}
              message={dialog.message}
              type={dialog.type} />
            <Routes>
              <Route path="/" element={
                  <NewsListLayout><NewsMain/></NewsListLayout>
                } restricted={false} exact
              />
              <Route path="/search" element={
                <NewsDetailLayout title={'검색'} cancelLink={'/'} showBackButton={true}>
                  <NewsSearchList/>
                </NewsDetailLayout>
              }/>
              <Route path="/news"
                element={
                  <NewsListLayout><NewsList/></NewsListLayout>
                } exact
              />
              <Route path="/news/:id" element={
                <NewsDetailLayout title={'뉴스'} cancelLink={'/news'} showBackButton={true}>
                  <NewsDetail type={'news'} />
                </NewsDetailLayout>
              } />
              <Route path="/news-report" element={
                <NewsDetailLayout showBackButton={true}>
                  <NewsReport />
                </NewsDetailLayout>
              } />
              <Route path="/interview" element={<NewsListLayout><InterviewList/></NewsListLayout>} exact/>
              <Route path="/interview/:id" element={
                <NewsDetailLayout title={'인터뷰'} cancelLink={'/interview'} showBackButton={true}>
                  <NewsDetail type={'interview'} />
                </NewsDetailLayout>}
              />
              <Route path="/opinion" element={<NewsListLayout><OpinionList /></NewsListLayout>} exact/>
              <Route path="/opinion/:id" element={
                <NewsDetailLayout title={'컬럼'} cancelLink={'/opinion'} showBackButton={true}>
                  <NewsDetail type={'opinion'} />
                </NewsDetailLayout>
              } />
              <Route path="/photo" element={<NewsListLayout><PhotoList /></NewsListLayout>} exact/>
              <Route path="/photo/:id" element={
                <NewsDetailLayout title={'포토'} cancelLink={'/photo'} showBackButton={true}>
                  <NewsDetail type={'photo'} />
                </NewsDetailLayout>
              } />
              <Route path="/immigrant" element={<NewsListLayout><ImmigrantList /></NewsListLayout>} exact/>
              <Route path="/immigrant/:id" element={
                <NewsDetailLayout title={'이민'} cancelLink={'/immigrant'} showBackButton={true}>
                  <NewsDetail type={'immigrant'} />
                </NewsDetailLayout>
              } />
              <Route path="/korea" element={<NewsListLayout><KoreaList /></NewsListLayout>} exact/>
              <Route path="/korea/:id" element={
                <NewsDetailLayout title={'한국'} cancelLink={'/korea'} showBackButton={true}>
                  <NewsDetail type={'korea'} />
                </NewsDetailLayout>
              } />
              <Route path="/education" element={<NewsListLayout><EducationList /></NewsListLayout>} exact/>
              <Route path="/education/:id" element={
                <NewsDetailLayout title={'교육'} cancelLink={'/education'} showBackButton={true}>
                  <NewsDetail type={'education'} />
                </NewsDetailLayout>
              } />
              <Route path="/k-people" element={<NewsListLayout><KoreanList /></NewsListLayout>} exact/>
              <Route path="/k-people/:id" element={
                <NewsDetailLayout title={'교민'} cancelLink={'/k-people'} showBackButton={true}>
                  <NewsDetail type={'k-people'} />
                </NewsDetailLayout>
              } />
              <Route path="/special-feature-article" element={<NewsListLayout><SpecialFeatureArticleList /></NewsListLayout>} exact/>
              <Route path="/special-feature-article/:id" element={
                <NewsDetailLayout title={'기획특집'} cancelLink={'/special-feature-article'} showBackButton={true}>
                  <NewsDetail type={'special-feature-article'} />
                </NewsDetailLayout>
              } />
              <Route path="/realty" element={<NewsListLayout><RealtyList /></NewsListLayout>} exact/>
              <Route path="/realty/:id" element={
                <NewsDetailLayout title={'부동산'} cancelLink={'/realty'} showBackButton={true}>
                  <NewsDetail type={'realty'} />
                </NewsDetailLayout>
              } />

              <Route path="/market" element={<MarketListLayout><MarketMain setDialog={setDialog} /></MarketListLayout>} />

              <Route path="/buysell" element={
                <MarketListLayout>
                  <BuysellList setDialog={setDialog} />
                </MarketListLayout>
              } exact/>
              <Route path="/buysell/:id" element={
                <MarketDetailLayout showBackButton={true}>
                  <BuysellDetail setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/buysell-term-Service" element={
                <MarketDetailLayout title={'약관동의'} cancelLink={'/buysell'} showBackButton={false} showCancelButton={true}>
                  <MarketTermOfServiceAgreement name={'사고팔고'} setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/buysell-edit" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/buysell'} showBackButton={false} showCancelButton={true}>
                  <BuysellDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/buysell-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/buysell'} showBackButton={true} showCancelButton={true}>
                  <BuysellDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } exact/>

              <Route path="/job" element={<MarketListLayout><JobList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/job/:id" element={<MarketDetailLayout cancelLink={'/job'} showBackButton={true} showCancelButton={false}><JobDetail setDialog={setDialog} /></MarketDetailLayout>} />
              <Route path="/job-edit" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/job'} showBackButton={false} showCancelButton={true}>
                  <JobDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/job-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/job'} showBackButton={true} showCancelButton={true}>
                  <JobDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } exact/>

              <Route path="/tutor" element={<MarketListLayout><TutorList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/tutor/:id" element={
                <MarketDetailLayout showBackButton={true}>
                  <TutorDetail setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/tutor-new" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/tutor'} showBackButton={false} showCancelButton={true}>
                  <TutorDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/tutor-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/tutor'} showBackButton={true} showCancelButton={true}>
                  <TutorDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } exact/>

              <Route path="/car" element={<MarketListLayout><CarList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/car/:id" element={<MarketDetailLayout showBackButton={true}><CarDetail setDialog={setDialog} /></MarketDetailLayout>} />

              <Route path="/rent-homestay" element={<MarketListLayout><RentHomestayList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/rent/:id" element={<MarketDetailLayout showBackButton={true}><RentDetail setDialog={setDialog} /></MarketDetailLayout>} />
              <Route path="/rent-new" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/rent-homestay'} showBackButton={false} showCancelButton={true}>
                  <RentDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/rent-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/rent-homestay'} showBackButton={true} showCancelButton={true}>
                  <RentDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />

              <Route path="/homestay/:id" element={
                <MarketDetailLayout showBackButton={true}>
                  <HomestayDetail setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/homestay-new" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/rent-homestay'} showBackButton={false} showCancelButton={true}>
                  <HomestayDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/homestay-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/rent-homestay'} showBackButton={true} showCancelButton={true}>
                  <HomestayDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } exact/>
              <Route path="/dwelling-sale/:id" element={<MarketDetailLayout showBackButton={true}><DwellingDetail setDialog={setDialog} /></MarketDetailLayout>} />
              <Route path="/commercial-sale/:id" element={<MarketDetailLayout showBackButton={true}><CommercialDetail setDialog={setDialog} /></MarketDetailLayout>} />
              <Route path="/realty-sale" element={<MarketListLayout><RealtySaleList setDialog={setDialog} /></MarketListLayout>} />

              <Route path="/community" element={<MarketListLayout><CommunityList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/community/:id" element={<MarketDetailLayout showBackButton={true}><CommunityDetail setDialog={setDialog} /></MarketDetailLayout>} />
              <Route path="/community-edit" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/community'} showBackButton={false} showCancelButton={true}>
                  <CommunityDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/community-edit/:id" element={
                <MarketDetailLayout title={'글쓰기'} cancelLink={'/community'} showBackButton={true} showCancelButton={true}>
                  <CommunityDetailEdit setDialog={setDialog} />
                </MarketDetailLayout>
              } />

              <Route path="/yellowpage" element={<MarketListLayout><YellowpageList setDialog={setDialog} /></MarketListLayout>} />
              <Route path="/yellowpage/:id" element={<MarketDetailLayout showBackButton={true}><YellowpageDetail setDialog={setDialog} /></MarketDetailLayout>} />

              <Route path="/ads/:id" element={
                <MarketDetailLayout title={'광고'} showBackButton={true}>
                  <MobileAdDetail setDialog={setDialog} />
                </MarketDetailLayout>
              } />
              <Route path="/advertising-information" element={
                <MarketDetailLayout showBackButton={true}>
                  <AdvertisingInformation />
                </MarketDetailLayout>
              } />

              <Route path="/market-privacy-policy" element={
                <MarketDetailLayout title={'개인정보 동의'} showBackButton={true}>
                  <MarketPrivacyPolicy />
                </MarketDetailLayout>
              } />
              <Route path="/market-term-of-service" element={
                <MarketDetailLayout title={'이용약관 동의'} showBackButton={true}>
                  <MarketTermOfService />
                </MarketDetailLayout>
              } />
              <Route path="/market-prohibit-goods" element={
                <MarketDetailLayout title={'거래 금지 항목'} showBackButton={true}>
                  <MarketProhibitGoods />
                </MarketDetailLayout>
              } />
              <Route path="/market-payment-step1" element={
                <MarketPaymentLayout title={'결제'} showBackButton={true} showCancelButton={true}>
                  <PaymentStep1 setDialog={setDialog} />
                </MarketPaymentLayout>
              }/>
              <Route path="/market-payment-step2" element={
                <MarketPaymentLayout title={'결제'} showBackButton={true} showCancelButton={true}>
                  <PaymentStep2 setDialog={setDialog} />
                </MarketPaymentLayout>
              }/>
              <Route path="/market-payment-step3" element={
                <MarketPaymentLayout showCancelButton={true}>
                  <PaymentStep3 setDialog={setDialog} />
                </MarketPaymentLayout>
              }/>

              <Route path="/news-report" element={
                <MarketPaymentLayout showCancelButton={true}>
                  <PaymentStep3 />
                </MarketPaymentLayout>
              } />

              newsReport
              <Route path="/signIn" element={
                <UserDetailLayout title={'로그인'} showBackButton={true} showCancelButton={false}>
                  <SignIn setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/signUp" element={
                <UserDetailLayout title={'회원가입'} showBackButton={true} showCancelButton={false}>
                  <SignUp setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/resetPassword" element={
                <UserDetailLayout showBackButton={true} showCancelButton={false}>
                  <ResetPassword setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/profile" element={
                <UserDetailLayout title={'프로필'} showBackButton={false} showCancelButton={true}>
                  <Profile setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/profile-edit" element={
                <UserDetailLayout title={'프로필 편집'} cancelLink={'/profile'} showBackButton={true} showCancelButton={false}>
                  <ProfileEdit setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/user-post" element={
                <UserDetailLayout title={'게시글'} cancelLink={'/profile'} showBackButton={true} showCancelButton={false}>
                  <UserPostList setDialog={setDialog} />
                </UserDetailLayout>
              } />
              <Route path="/user-privacy-policy" element={
                <UserDetailLayout title={'개인정보 처리방침 동의'} showBackButton={true} showCancelButton={false}>
                  <UserPrivacyPolicy />
                </UserDetailLayout>
              } />
              <Route path="/user-term-of-service" element={
                <UserDetailLayout title={'이용약관 동의'} showBackButton={true} showCancelButton={false}>
                  <UserTermOfService />
                </UserDetailLayout>
              } />
              <Route path="*" element={
                <UserDetailLayout showBackButton={true} showCancelButton={false}>
                  <ErrorPage/>
                </UserDetailLayout >
              } />
            </Routes>
          </RootContainer>
        </RootWrapper>
      </BrowserRouter>
    </ThemeProvider>
    // </RecoilRoot >
  );
}

const RootWrapper = styled.div`
  background-color: #f4f4f4;
`;

const RootContainer = styled.div`
  margin: 0 auto;
  position: relative;
  min-width: 36.0rem;
  max-width: 41.4rem;
  background-color: rgb(0 0 0 / 6%);
`;

export default App;
