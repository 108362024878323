import React from 'react';
import styled from 'styled-components';

export const UserPrivacyPolicy = () => {

  return (
    <PageWrapper>
      <PageTitle>
        Privacy Policy for Vancouver Korean Press Ltd.
      </PageTitle>

      <DescriptionTitle>1. General</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          This privacy policy describes the policy of Vancouver Korean Press Ltd. (“Vanchosun”) regarding the collection, use, storage, sharing and protection of your personal information ("Privacy Policy"). This Privacy Policy applies to the www.vanchosun.com and m.vanchosun.com websites (together, "Vanchosun" or "Vanmarket") and all related websites, mobile web, services and tools where reference is made to this policy ("Services"), regardless of how you access the Services, including access through mobile devices. Vancouver Korean Press Ltd. is the data controller.<br/><br/>

          <span className='bold'>Scope and consent</span>: By using Vanchosun and related Services, you give explicit consent to Vanchosun for the collection, use, disclosure and retention of your personal information by us, as described in this Privacy Policy and our Terms of Use.<br/><br/>

          Vanchosun may change this Privacy Policy from time to time. We advise you to read it regularly. Substantial changes to our Privacy Policy will be announced on our Website and Mobile Web. The amended Privacy Policy will be effective immediately after it is first posted on our Website and Mobile Web. This Privacy Policy is effective as of January 26, 2023.
        </p>
      </DescriptionBox>

      <DescriptionTitle>2. What personal information do we collect</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          You can visit our Website and Mobile Web without registering for an account. When you decide to provide us with your personal information, you agree that such information is sent to and stored on our servers. We collect the following types of personal information:<br/><br/>
          <span className='bold'>Information we collect automatically</span>: When you visit our Website and Mobile Web, use our Services and or posting ads or other content, we automatically collect the information sent to us by your computer, mobile device or other equipment that provides access. This information includes, but is not limited to:
        </p>
        <ul>
          <li>
            <div>
              information from your interaction with our Website and Services, including, but not limited to, device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from <span className='underline' style={{color: 'blue'}}>www.vanchosun.com</span> or m.vanchosun.com, referring URL, ad data, IP address and standard web log information; and
            </div>
          </li>
          <li>
            <div>
              information we collect through cookies, web beacons and similar technologies.
            </div>
          </li>
        </ul>

        <p className='singleDescription'>
          <span>Information you provide to us</span>: We collect and store any information you enter on our Website and Mobile Web or that you provide to us when you use our Services. This information includes, but is not limited to:
        </p>
        <ul>
          <li>
            <div>
              information that you provide to us when you register for an account or for the Services that you use;
            </div>
          </li>
          <li>
            <div>
              additional information that you may provide to us through social media sites or third party Services;
            </div>
          </li>
          <li>
            <div>
              information provided in the context of dispute resolution, correspondence through our Website or correspondence that is sent to us;
            </div>
          </li>
          <li>
            <div>
              information about your location and the location of your device, including your device’s unique identifier information if you have enabled this service on your mobile device, and
            </div>
          </li>
        </ul>

        <p className='singleDescription'>
          <span>Information from other sources</span>: We may receive or collect additional information about you from third parties and add this to our account information. This information includes, but is not limited to: demographic data, navigation data, additional contact data and additional data about you from other sources, such as public authorities, to the extent permitted by law.
        </p>
      </DescriptionBox>

      <DescriptionTitle>3. How we use your personal information</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          You agree that we may use your personal information for the following purposes:
        </p>
        <ul>
          <li>
            <div>
              to provide you access to our Services and Customer Support by means of e-mail or telephone;
            </div>
          </li>
          <li>
            <div>
              to prevent, detect and investigate potentially prohibited or illegal activities, fraud and security breaches and to enforce our Terms of Use;
            </div>
          </li>
          <li>
            <div>
              to personalize, measure and improve our Services, content and ads;
            </div>
          </li>
          <li>
            <div>
              to provide you with other services that you have requested, as described when we collected the information, and
            </div>
          </li>
        </ul>
        <p className='singleDescription'>
          <span>Sharing information with and registration on Email</span>: We may offer sign-on Services that enable you to access the Website or related websites with your login credentials.<br/><br/>
          If you provide us access to personal information stored on third party websites, the scope of access to this personal information shall vary per website and will be determined by both your own browser settings and your consent. If you wish to connect your third party account to your Vanchosun account and you consent to us accessing the information in these third party accounts, you agree that we can collect, use and store information from this third party website in accordance with this Privacy Policy.
        </p>

        <p className='singleDescription'>
          <span>Transfer of your personal information to third parties</span>: We may disclose your personal information to third parties in accordance with this Privacy Policy and applicable legislation and regulations. We will not disclose your personal information to third parties for their marketing purposes without your explicit consent. We may share your personal information with:
        </p>
        <ul>
          <li>
            <div>
              <span className='underline'>companies of the Classifieds Group</span> to provide joint content and services (such as registration and customer support), to prevent, detect and investigate possible illegal activities, violations of our policy, fraud and/or data security breaches, and to support decision making on products, websites, applications, services, tools and communication. The Classifieds Group companies will only use this information to send you marketing messages when you yourself have requested their services, and they will use your personal information in accordance with their respective privacy policy.
            </div>
          </li>
          <li>
            <div>
              <span className='underline'>service providers</span> with whom we have entered into an agreement to help us provide our Services on the website, such as financial service providers, marketing agencies and technical support. In such cases, the personal information shall remain under the control of Vancouver Korean Press Ltd.
            </div>
          </li>
          <li>
            <div>
              <span className='underline'>certain third parties</span> (such as intellectual property rights holders, supervisory authorities, tax authorities, police and other regulatory authorities) if we are required to do so by law, or in accordance with our Privacy Policy. We may share your personal information:<br/><br/>
              - to comply with legal obligations or a court order; or<br/><br/>
              - if this is necessary for the prevention, detection or prosecution of criminal offenses, such as fraud, deceit or prosecution, or<br/><br/>
              - if it is necessary to maintain our policies or to protect the rights and freedoms of others.<br/><br/>
            </div>
          </li>
          <li>
            <div>
              <span className='underline'>other third parties</span> to whom you have given consent to share your information via Vanchosun, e.g. in the framework of a cooperation;
            </div>
          </li>
          <li>
            <div>
              <span className='underline'>an intellectual property owner</span> if the owner of an intellectual property right or an intermediary in good faith believes that an advertisement violates the rights of the owner. Before personal information is provided, the IP owner will enter into an agreement that, inter alia, stipulates that information is only provided on the strict condition that it may only be used in the context of legal proceedings and/or obtaining legal advice and/or to answer questions from the relevant advertiser
            </div>
          </li>
        </ul>
        <p className='singleDescription'>
          Without limitation to the foregoing, we shall moreover - in our efforts to respect your privacy and to keep the website free of malicious persons or parties – not disclose your personal information to third parties without a court order or formal request from the government in accordance with applicable law, unless we believe in good faith that such disclosure is necessary to prevent impending injury or financial damages or to report alleged illegal activities.
        </p>
        <p className='singleDescription'>
          <span className='bold'>Information you share on <span className={'underline'} style={{color:'blue'}}>www.vanchosun.com</span> or m.vanchosun.com</span>: Our Website allows users to share advertisements and other information with other users, thereby making this shared information accessible to other users. Since our Website also enables you to directly contact a buyer or seller, we recommend that you consider how you share your personal information with others. You are solely responsible for the personal information you share through our Website and therefore we cannot guarantee the privacy or security of the information shared by you with other users.<br/><br/>
          In case you visit our Website from a shared computer or a computer in an internet café, we strongly recommend that you log off after each session. If you do not want the shared computer to remember you and/or your credentials, you will need to remove cookies and/or the history of your website visits.<br/><br/>
          Your data controller may transfer data to other members of our corporate family as described in this Privacy Policy who may process and keep your personal information on servers in the Canada and our data centers in other parts of the world.
        </p>
      </DescriptionBox>

      <DescriptionTitle>4. Marketing Purposes</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          You agree that we may use the information collected by us to send you offers, whether personalized or not, or to contact you by telephone regarding products or Services offered by Vanchosun or companies of the Classifieds Group.<br/><br/>
          We will not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalize our Services and functionalities.
        </p>
      </DescriptionBox>

      <DescriptionTitle>5. Cookies</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          When you use our Services, we and our service providers may place cookies (data files on your phone or mobile device’s drive) or web beacons (electronic images that are placed in a web page’s code) or similar technologies. We use cookies to help us identify you as a user, to provide you a better experience on our Website, to measure promotional effectiveness and to ensure trust and safety on our Website.
        </p>
      </DescriptionBox>

      <DescriptionTitle>6. Accessing, Reviewing and Changing Your Personal Information</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          We cannot modify your personal information or account information.
          You can modify your own information by logging into your Vanchosun or Vanmarket account.
          If you want to close your account (where applicable), we offer a service that you can close your account by yourself.
          Then we will retain your information, that you provide us to make your account on Vanchosun or Vanmarket, within a reasonable period of time.
          However, once you close your account, it will be hard to remake new account by using already enrolled email.
          If you want to remake your account again by using previous enrolled email, you should ask and contact us, <span className={'underline'} style={{color:'blue'}}>web@vanchosun.com</span> or +1 604-866-1178.
          Also If your information is factually incorrect or incomplete or irrelevant for the purposes for which we process your information, you can request us to modify or delete your information.
        </p>
      </DescriptionBox>

      <DescriptionTitle>7. Protection and retention of your personal information</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          We protect your information by using technical and administrative security measures (such as firewalls, data encryption, and physical and administrative access controls to the data and servers) that limit the risk of loss, abuse, unauthorized access, disclosure, and alteration. Nevertheless, if you believe your account has been abused, please contact us by email (web@vanchosun.com) or phone (+1 604-877-1178).<br/>
          We retain personal information no longer than is legally permissible and delete personal information when it is no longer necessary for the purposes as described above.
        </p>
      </DescriptionBox>

      <DescriptionTitle>8. Other information</DescriptionTitle>
      <DescriptionBox>
        <p className='singleDescription'>
          <span className={'bold'}>Abuse and unsolicited commercial ("spam")</span>: We do not tolerate abuse of our Website. You do not have permission to add other Vanchosun users to your mailing list (e-mail or postal) for commercial purposes, even if a user has purchased something from you, unless the user has given his explicit consent. If you notice that someone is abusing our Website (spam or spoof emails), please notify us.
        </p>
        <p className='singleDescription'>
          <span className={'bold'}>Third Parties</span>: Unless explicitly provided otherwise in this Privacy Policy, this Privacy Policy applies only to the use and transfer of information we collect from you. Vanchosun has no control over the privacy policies of third parties that may apply to you. When we work with third parties or use third party tools to provide certain services, we will explicitly indicate which privacy policy applies to you.
        </p>
        <p className='singleDescription'>
          <span className={'bold'}>Contact</span>: If you have any questions about Vanchosun and our Website please contact us by email (web@vanchosun.com) or phone (+1 604-877-1178).
        </p>
        <br/><br/>
      </DescriptionBox>

    </PageWrapper>
  );

}

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1.6rem;
  width: auto;
  position: relative;
  background-color: #fff;
`;

const PageTitle = styled.h1`
  margin: 2rem auto;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: -0.04em;
  color: #000000 ;
`;

const DescriptionTitle = styled.div`
  margin-top: 3rem;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #000000;
`;

const DescriptionBox = styled.div`
  padding: 0;
  margin: 0;
  & ul {
    margin: 0;
    padding-left: 2.1rem;
    list-style-type: disc;
    & li {
      font-size: 7px;
      color: #424242;
      & div {
        margin: 1rem 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 1.7rem;
        color: #424242;
      };
    }
  }
  & p.singleDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
  & span.bold {
    font-weight: 600;
  }
  & span.underline {
    text-decoration: underline;
  }
`;

