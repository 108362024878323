import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Separator } from '../../common/Separator';
import { toStringByFormatting } from '../../utils/utils';
import { UserApi } from './api/UserApi';

export const Profile = ({setDialog}) => {
  const userApi = new UserApi();
  const navigate = useNavigate();
  const DEFAULT_LINK = '/market';

  const [userInfo, setUserInfo] = useState({
    nickName: '',
    phone: '',
    email: '',
    myPostCount: 0,
    myReplyCount: 0,
    registrationDate: '',
  });

  // == handler ------------------------------------------------
  const handleSignOutOnClick = () => {
    if(window.confirm("로그아웃 하시겠습니까?")) {
      userApi.signOut();
      navigate(`${DEFAULT_LINK}`, {replace : true});
    }
  }

  const handlerUserPostOnClick = () => {
    navigate('/user-post', {state: {type: 'post'}});
  }

  const handlerUserReplyOnClick = () => {
    navigate('/user-post', {state: {type: 'reply'}});
  }

  const handleProfileEditOnClick = () => {
    navigate('/profile-edit');
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-profile.png`;
  }

  const handleUserSearch = () => {
    userApi.fetchUser(
      (data) => {
        setUserInfo(data.result);
      },
      (error) => {
        console.log(error);
        const { response, status, message, errors } = error;
        if(status) {
          if(403 === status) {
            setDialog({hasError: true, message: `로그인후 사용해주세요. ${message}`, type: "error"});
            alert(`로그인후 사용해주세요.`);
            navigate(`${DEFAULT_LINK}`, {replace : true});
          } else {
            setDialog({hasError: true, message: `${message}`, type: "error"});
          }
        } else if (errors) {
          const { field, defaultMessage } = errors[0];
          setDialog({isOpen: true, message: `[ ${message} ][ ${field} ] ${defaultMessage}`, type: "error"});
        } else if(response) {
          const {status, statusText} = response;
          if(403 === status) {
            // setDialog({hasError: true, message: `${statusText}`, type: "error"});
            alert(`로그인후 사용해주세요.`);
            navigate(`${DEFAULT_LINK}`, {replace : true});
          } else {
            setDialog({hasError: true, message: `${statusText}`, type: "error"});
          }
        }
      }
    );
  }

  useEffect(()=> {
    handleUserSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper>
        <MyCard>
          <MyInfo>
            <div className='myNickName'>{userInfo.nickName}</div>
            <div className='myEmail'>{userInfo.email}</div>
            <div className='myImage'>
              <img src={`${userInfo.userImage}`} alt={`${userInfo.nickName}`} onError={(e) => handleImageError(e)}/>
            </div>
          </MyInfo>
          <MyOtherInfo>
            {userInfo?.registrationDate && (
              <span>{toStringByFormatting(userInfo.registrationDate,'/')} 가입</span>
            )}
          </MyOtherInfo>
        </MyCard>
        <CardBackGroup>
          <div></div>
        </CardBackGroup>
        <MyActivityHistory>
          <h3>
            내 활동 내역
          </h3>
          <MyActivityGroup>
            <div onClick={()=>handlerUserPostOnClick()}>
              <img src='/icon/my-activity-post-icon.png' alt='게시글 건수'/>
              게시글 {userInfo.myPostCount}
            </div>
            <MyActivitySeparator/>
            <div onClick={()=>handlerUserReplyOnClick()}>
              <img src='/icon/my-activity-reply-icon.png' alt='댓글 건수'/>
              댓글 {userInfo.myReplyCount}
            </div>
          </MyActivityGroup>
        </MyActivityHistory>
        <Separator height={'1rem'} color={'#f5f5f5'}/>
        <EditProfile onClick={()=>handleProfileEditOnClick()}>
          프로필 편집
        </EditProfile>
        <SingOutButton onClick={() => handleSignOutOnClick()}>
          로그아웃
        </SingOutButton>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  min-height: 60rem;
  height: 80vh;
  background-color: #fff;
`;

const CardBackGroup = styled.div`
  width: 100%;
  height: 24.4rem;
  background-color: #FFF;
  & div {
    width: 100%;
    height: 14.9rem;
    background-image: url('/img/profile_background.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const MyActivityHistory = styled.div`
  margin: 0;
  padding: 0rem 1.6rem 3rem;
  background-color: #FFF;
  & h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;
const MyActivityGroup = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 9.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  & div {
    width: 17rem;
    height: 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & img {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;

const MyActivitySeparator = styled.span`
  width: 0;
  height: 5.5rem;
  border-right: 0.1rem solid #EEEEEE;
`;

const EditProfile = styled.div`
  margin: 0;
  padding: 1.5rem 1.6rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #424242;
  background-color: #FFF;
  border-bottom: 0.1rem solid #f5f5f5;
  box-sizing: border-box;
  cursor: pointer;
`;

const SingOutButton = styled.div`
  margin: 0;
  padding: 1.5rem 1.6rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #424242;
  background-color: #FFF;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #f5f5f5;
  cursor: pointer;
`;


const MyCard = styled.div`
  position: absolute;
  top: 5.2rem;
  left: 50%;
  margin: 0;
  padding: 2rem;
  width: 90vw;
  max-width: 38rem;
  height: 15.6rem;

  background: #FFFFFF;
  filter: drop-shadow(0 0.4rem 1.4rem rgba(0, 0, 0, 0.09));
  transform: translate(-50%, 0);
  border-radius: 1.6rem;
  box-sizing: border-box;
`;

const MyInfo = styled.div`
  margin: 0;
  padding: 0 0 2.35rem;
  & div.myNickName {
    font-size: 20px;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
  & div.myEmail {
    font-size: 14px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.04em;
    color: #9E9E9E;
  }
  & div.myImage {
    position: absolute;
    top: 2rem;
    right: 2.1rem;
    width: 5.8rem;
    height: 5.8rem;
    border-radius: 50%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

const MyOtherInfo = styled.div`
  padding: 1.7rem 0;
  width: 100%;
  height: 2.6rem;
  display: flex;
  border-top: 0.1rem solid #F5F5F5;
  & span {
    padding: 0.35rem 1.25rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.06em;
    color: #FD6258;
    background: #FFEAE9;
    border-radius: 300px;
  }
`;