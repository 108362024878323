import React from 'react';
import styled from 'styled-components';
import { CommonFooter } from '../common/CommonFooter';
import { MarketHeaderForList } from '../domain/market/component/MarketHeaderForList';
import { MarketTopMenu } from '../domain/market/component/MarketTopMenu';

export const MarketListLayout = ({children}) => {
  return (
    <>
      <MarketWrapper>
        <MarketHeaderForList />
        <MarketTopMenu />
        <main>
          {children}
        </main>
        <CommonFooter />
      </MarketWrapper>
    </>
  );
}

const MarketWrapper = styled.div`
  position: relative;
  width: auto;
`;