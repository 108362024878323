import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
// import Geocode from 'react-geocode';
import { YellowpageApi } from '../api/YellowpageApi';
// import { GoogleMapApi } from '../api/GoogleMapApi';


export const YellowpageDetail = () => {
  const yellowpageApi = new YellowpageApi();
  const { id } = useParams();
  const [article, setArticle] = useState({
    id: '',
    title: '',
    description: '',
    phone: '',
    fax: '',
    website: '',
    email: '',
    imageFile1: '',
    locationSubId: '',
    address: '',
    cityName: '',
    provinceName: '',
    postal: '',
    card1: '',
    card2: '',
    card3: '',
    hours: '',
    delivery: '',
    reservation: '',
    active: '',
    postDate: '',
    images: {
      1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}, 10: {},
    },
  });
  // Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)
  // Geocode.setLanguage('en')
  // Geocode.setRegion('es')
  // Geocode.enableDebug()

  // const [currentAddr, setCurrentAddr] = React.useState(null);
  // const [center, setCenter] = React.useState({
  //   lat: 0,
  //   lng: 0,
  // });


  const handleSearchClick = () => {
    yellowpageApi.fetchYellowpageById(id, (data) => {
      setArticle(data.result);
      // setCurrentAddr(`${data.result.address} ${data.result.cityName}`);
    });
  }

  // const GoogleMap = async (currentAddr) => {
  //   return Geocode.fromAddress(currentAddr)
  //     .then( response => {
  //       const { lat, lng } = response.results[0].geometry.location;
  //       return {lat, lng}
  //     })
  //     .catch(err => console.log(err))
  // }

  // const handleButton = async() => {
  //   console.log('currentAddr = '+currentAddr)
  //   if (currentAddr){
  //     const {lat, lng} = await GoogleMap(currentAddr)
  //     console.log({lat:lat,lng:lng})
  //     setCenter({lat:lat,lng:lng});
  //   }
  // }

  // useEffect(() => {
  //   handleButton();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentAddr]);

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-yellowpage.png`;
  }

  // render -------------------------------------------------
  const addImageBox = (index) => {
    const imgUrl = article.images[index]['fileName'];
    const imgPath = article.images[index]['filePath'];
    const domain = article.images[index]['domain'];
    return (
      <div key={index}>
        {imgUrl && (
          <div className="preview-image-box">
            <img src={`${domain}${imgPath}${imgUrl}`} alt={`이미지${index}`} onError={(e)=>handleImageError(e)} loading='lazy' />
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    handleSearchClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContentWrap>
        <Title>{article.title}</Title>
        <Info>
          <div>주소: {article.address} {article.cityName} {article.postal}</div>
          <div>전화번호: {`${article.phone ? article.phone : 'N/A'}`}</div>
          <div>이메일: {`${article.email ? article.email : 'N/A'}`}</div>
          {(article.website) &&
            (<div>홈페이지: {article.website}</div>)
          }
          {(article.card1 === '1' || article.card2 === '1' || article.card3 === '1') &&
            (<div>결제수단: {`${article.card1 === '1' ? 'Credit card /' : ''} ${article.card2 === '1' ? 'Debit card /' : ''} ${article.card3 === '1' ? 'Cash' : ''}`}</div>)
          }
          {(article.delivery === '1') &&
            <div>배달여부: 가능</div>
          }
          {(article.reservation === '1') &&
            <div>예약여부: 가능</div>
          }
        </Info>
        <Content dangerouslySetInnerHTML={{__html: article.description}} />
        {/* <GoogleMapApi latValue={center.lat} lngValue={center.lng} /> */}
        <ImageWrapper>
          {Object.keys(article.images).map((key) => addImageBox(key))}
        </ImageWrapper>
      </ContentWrap>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: auto;
  min-width: 36rem;
  max-width: 100%;
  background-color: #fff;
`;

const ContentWrap = styled.div`
  padding: 0.5rem 1.4rem;
`;
const Title = styled.h1`
  display: block;
  min-height: 3.2rem;
  font-size: 22px;
  font-weight: 700;
  word-break: break-all;
`;
const Info = styled.div`
  padding: 1rem 0;
  border-bottom: 0.1rem solid #d2d2d2;
  & div {
    font-size: 14px;
    color: #666;
  }
`;
const Content = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.4rem;
  color: #333;
  overflow: hidden;
  clear: both;
`;
const ImageWrapper = styled.section`
  margin-bottom: 0.5rem;
  border-bottom: 0rem dashed #ddd;
  & p {
    padding: 0.75rem 1.25rem;
    color: #721c24;
    font-size: 14px;
    font-weight: 500;
    background-color: #F8D7DA;
  }
  & div {
    margin: 1rem 0;
    & label.form-label-button {
      padding: 0.6rem 2.5rem;
      height: 2.4rem;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      font-weight: 700;
      color: white;

      background-color: #b1b1b1;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    & div.preview-image-box {
      position: relative;
      margin-top: 1rem;
      width: 100%;
      height: 33.8rem;
      border: 0.1rem solid #ddd;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & button {
        position: absolute;
        right: 0;
        z-index: 100;
      }
    }
  }
`;

