import React, { memo } from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../common/SkeletonComponent';
import { elapsedText, removeHtmlTags } from '../../../utils/utils';

export const NewsCardComponent = memo(({ list, link, isLoading, itemOnClick}) => {

  const handlerPostOnClick = (url) => {
    itemOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-img-news.png`;
  }

  const getImgAbsoluteUrl = () => {
    let absoluteUrl = "";
    switch (link) {
      case "community":
        absoluteUrl = process.env.REACT_APP_COMMUNITY_URL;
        break;
      default:
        absoluteUrl = process.env.REACT_APP_NEWS_URL;
        break;
    }

    return absoluteUrl;
  }

  return (
    <CardListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <CardSection>
            <CardItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper />
              </div>
            </CardItem>
            <CardItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper />
              </div>
            </CardItem>
          </CardSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <CardSection>
              {list.map((row) => (
                <CardItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                    <ImageWrapper>
                      <img src={`${getImgAbsoluteUrl()}${row.imageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                    </ImageWrapper>
                    <InfoWrapper>
                      <h2>{row.title}</h2>
                      <div className='description'>{`${removeHtmlTags(row.description)}`}</div>
                      <div className='reporter'>{`${row.reporter} · ${elapsedText(row.updatedDate)}`}</div>
                    </InfoWrapper>
                  </div>
                </CardItem>
              ))}
            </CardSection>
          )}
        </>
      )}

    </CardListWrapper>
  );
});

const CardListWrapper = styled.div`
  width: 100%;
`;

const CardSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const CardItem = styled.li`
  position: relative;
  margin-top: 0.5rem;
  width: 100%;
  height: 40rem;
  & div.itemCard {
    position: relative;
    color: black;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 40rem;
  background-color: #F5F5F5;
  background-image: url('/icon/no-img-news-icon.png');
  background-size: 9rem 7.8rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 0.8rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.8rem;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 16rem;
  background-color: rgb(32 33 36 / 70%);
  border-radius: 0 0 1.2rem 1.2rem;
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 2rem;
    color: #de2b36;
  }
  & h2 {
    margin: 0;
    padding: 1rem 1.4rem 0.5rem 1.4rem;
    height: 2.5rem;

    font-size: 16px;
    font-weight: 500;
    line-height: 2rem;
    color: #fff;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & div.description {
    padding: 0 1.4rem;
    height: 8.7rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #9e9e9e;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  & div.reporter {
    padding: 0 1.4rem;
    height: 3rem;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    color: #e0e0e0;
    text-align: right;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const NoData = styled.p`
  height: 5rem;

  font-size: 16px;
  color: #666;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;