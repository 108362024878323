import React from 'react';
import styled from 'styled-components';
import { SkeletonComponent } from '../../../../common/SkeletonComponent';
import { elapsedText, priceToString } from '../../../../utils/utils';
import {
  HomestayDetailedOptionForCard,
  HomestayTypes,
  RentDetailedOptionForCard,
  RentTypes,
} from '../../../../__mocks__/PostData';

export const RentHomestayCardComponent = React.memo(({ type, list, link, isLoading, postOnClick}) => {

  const handlerPostOnClick = (url) => {
    postOnClick?.(url);
  }

  const handleImageError = (e) => {
    e.target.src=`/img/no-image-rent-homestay.png`;
  }

  const isRent = () => {
    return (type==='rent') ? true : false;
  }

  const addPriceTag = ({paymentFrequency, price}) => {
    if(0<price && paymentFrequency) {
      return (
        <>
          {priceToString(price)}
          <span>
            {paymentFrequency
              && ` / ${paymentFrequency}`
            }
          </span>
        </>
      );
    } else {
      return (
        <>
          가격문의
        </>
      );
    }
  }

  const addDetailedOptionsTag = ({detailedOptions}) => {
    let etcCount = 0;
    let optionWithLabelList = [];
    if(!detailedOptions) return;
    if(isRent()) {
      for(let key in RentDetailedOptionForCard) {
        if(-1<detailedOptions?.indexOf(key)) {
          if(4>optionWithLabelList.length) {
            optionWithLabelList.push(RentDetailedOptionForCard[key]?.label);
          } else {
            etcCount++;
          }
        }
      }
    } else {
      for(let key in HomestayDetailedOptionForCard) {
        if(-1<detailedOptions?.indexOf(key)) {
          if(4>optionWithLabelList.length) {
            optionWithLabelList.push(HomestayDetailedOptionForCard[key]?.label);
          } else {
            etcCount++;
          }
        }
      }
    }

    if(0<etcCount) {
      optionWithLabelList.push(`+${etcCount}`);
    }

    return (
      <>
        {optionWithLabelList?.map(value =>
          <span key={value}>
            {value}
          </span>
        )}
      </>
    );
  }

  return (
    <GridListWrapper>
      {(isLoading) ? (
        <SkeletonComponent>
          <GridSection>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <LocationRoomBathInfo>
                    <div style={{width: '100%', backgroundColor: '#F5F5F5'}} />
                  </LocationRoomBathInfo>
                  <DetailedOptions style={{backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <LocationRoomBathInfo style={{backgroundColor: '#F5F5F5'}} />
                  <DetailedOptions style={{backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </GridItem>
            <GridItem>
              <div className="itemCard">
                <ImageWrapper />
                <InfoWrapper>
                  <Title style={{backgroundColor: '#F5F5F5'}}></Title>
                  <Updated style={{backgroundColor: '#F5F5F5'}}></Updated>
                  <Price style={{backgroundColor: '#F5F5F5'}}></Price>
                  <LocationRoomBathInfo style={{backgroundColor: '#F5F5F5'}} />
                  <DetailedOptions style={{backgroundColor: '#F5F5F5'}} />
                </InfoWrapper>
              </div>
            </GridItem>
          </GridSection>
        </SkeletonComponent>
      ) : (
        <>
          {list.length === 0 ? (
            <NoData>데이터가 없습니다.</NoData>
          ):(
            <GridSection>
              {list.map((row)=>(
                <GridItem key={row.id}>
                  <div className="itemCard" onClick={() => handlerPostOnClick(`/${link}/${row.id}`)}>
                    <ImageWrapper>
                      <img src={`${row.thumbnailImageFile}`} alt={row.title} onError={(e) => handleImageError(e)} loading="lazy" />
                      {( isRent() && row.moveInType === 'immediate-moveIn') &&
                        <div className='immediate-moveIn-icon' />
                      }
                      {(!isRent() && row.checkInType === 'immediate-checkIn') &&
                        <div className='immediate-checkIn-icon' />
                      }
                    </ImageWrapper>
                    <InfoWrapper>
                      <Title>{row.title}</Title>
                      <Updated>{elapsedText(row.updatedDate)}</Updated>
                      <Price>
                        {addPriceTag(row)}
                      </Price>
                      <LocationRoomBathInfo>
                        <div className='location'>
                          {row.locationName?row.locationName:' - '}
                        </div>
                        <div className='type'>
                          {isRent()
                            ? RentTypes[row.type].label
                            : HomestayTypes[row.type].label
                          }
                        </div>
                        <div className='room'>
                          {`방${row.bedroomCount}·화${row.bathroomCount}`}
                        </div>
                      </LocationRoomBathInfo>
                      {(row.detailedOptions) && (
                        <DetailedOptions>
                          {addDetailedOptionsTag(row)}
                        </DetailedOptions>
                      )}
                    </InfoWrapper>
                  </div>
                </GridItem>
              ))}
            </GridSection>
          )}
        </>
      )}
    </GridListWrapper>
  );
});

const GridListWrapper = styled.div`
  padding: 0;
  width: 100%;
`;

const GridSection = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GridItem = styled.li`
  margin-bottom: 3.4rem;
  width: 100%;
  min-height: 29.8rem;
  height: auto;
  & div.itemCard {
    position: relative;
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    border: 0.1rem solid #F5F5F5;
    border-radius: 0.4rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 18rem;
  background-color: #F5F5F5;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    background-color: #fff;
  }
  & div.immediate-moveIn-icon {
    position: absolute;
    top: 1rem;
    left: 1.1rem;
    width: 4.6rem;
    height: 4.6rem;
    background-image: url('/icon/rent-immediate-moveIn-icon.png');
    background-size: cover;
  }
  & div.immediate-checkIn-icon {
    position: absolute;
    top: 1rem;
    left: 1.1rem;
    width: 4.6rem;
    height: 4.6rem;
    background-image: url('/icon/homestay-immediate-checkIn-icon.png');
    background-size: cover;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 1.4rem 1.6rem 0.6rem;
  min-height: 10rem;
  color: #666;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  width: 80%;
  height: 1.8rem;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.8rem;
  letter-spacing: -0.04em;
  color: #000000;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Updated = styled.div`
  position: absolute;
  top: 1.4rem;
  right: 1.6rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;
`;

const Price = styled.div`
  margin: 0.3rem 0 1.2rem 0;
  height: 2.9rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 2.9rem;
  letter-spacing: -0.04em;
  color: #000000;
  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #000000;
  }
`;

const LocationRoomBathInfo = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-top: 0.1rem solid #E0E0E0;
  & div.location {
    margin-right: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #424242;
  }
  & div.type {
    padding: 0 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
  }
  & div.room {
    padding: 0 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
    color: #424242;
    border-left: 0.1rem solid #E0E0E0;
  }
`;

const DetailedOptions = styled.div`
  padding-bottom: 0.8rem;
  height: 1.8rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & span {
    margin-right: 0.6rem;
    padding: 0 0.7rem;
    height: 1.4rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.04em;
    color: #FD6258;
    border: 0.1rem solid #FD6258;
    border-radius: 2px;
  }
`;

const NoData = styled.p`
  height: 5rem;
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

`;