import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LeftMenuAd } from '../domain/ads/LeftMenuAd';
import { UserApi } from '../domain/user/api/UserApi';

export const CommonLeftMenu = () => {
  const userApi = new UserApi();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuObj] = useState({
    '/': {url:'/', name: '홈', tabType: 'news'},
    '/news': {url:'/news', name: '뉴스', tabType: 'news'},
    '/realty': {url:'/realty', name: '부동산', tabType: 'news'},
    '/immigrant': {url:'/immigrant', name: '이민', tabType: 'news'},
    '/education': {url:'/education', name: '교육', tabType: 'news'},
    '/k-people': {url:'/k-people', name: '교민', tabType: 'news'},
    '/korea': {url:'/korea', name: '한국', tabType: 'news'},
    '/photo': {url:'/photo', name: '포토', tabType: 'news'},
    '/issuu': {url:'/issuu', name: '신문보기', tabType: 'news'},
    '/opinion': {url:'/opinion', name: '기고∙칼럼', tabType: 'news'},
    '/interview': {url:'/interview', name: '인터뷰', tabType: 'news'},
    '/special-feature-article': {url:'/special-feature-article', name: '기획특집', tabType: 'news'},
    '/market': {url:'/market', name: '마켓홈', tabType: 'market'},
    '/buysell': {url:'/buysell', name: '사고팔고', tabType: 'market'},
    '/job': {url:'/job', name: '구인구직', tabType: 'market'},
    '/tutor': {url:'/tutor', name: '학원튜터', tabType: 'market'},
    '/rent-homestay': {url:'/rent-homestay', name: '렌트/민박', tabType: 'market'},
    '/realty-sale': {url:'/realty-sale', name: '부동산', tabType: 'market'},
    '/car': {url:'/car', name: '자동차', tabType: 'market'},
    '/community': {url:'/community', name: '커뮤니티', tabType: 'market'},
    '/yellowpage': {url:'/yellowpage', name: '업소찾기', tabType: 'market'},
  });

  const [tabType, setTabType] = useState(menuObj[location?.pathname]?.tabType);

  const handleSearchButtonOnClick = () => {
    navigate('/search');
  }

  const toggleUserOnClick = () => {
    if(userApi.isSignIn()) {
      handlePageMoveOnClick('/profile')
    } else {
      handlePageMoveOnClick('/signIn')
    }
  }

  const toggleMenuButton = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const isNews = () => {
    if('news' === tabType) {
      return true;
    }
    return false;
  }

  const isMarket = () => {
    if('market' === tabType) {
      return true;
    }
    return false;
  }

  const handleTabOnClick = (tabType) => {
    setTabType(tabType);
  }

  const handlePageMoveOnClick = (url) => {
    navigate(url);
    toggleMenuButton();
  }

  // const handleSignOutOnClick = () => {
  //   if(window.confirm("로그아웃 하시겠습니까?")) {
  //     userApi.signOut();
  //     toggleMenuButton();
  //   }
  // }

  const addNewTag = () => {
    return (<img className='new' src='/icon/new-icon.png' alt='new' />);
  }

  return (
    <>
      {isNews() && (
        <SearchBtn
          onClick={()=> handleSearchButtonOnClick()}>
          <div>
            <img src='/icon/search-button.png' alt='Top Search Button'/>
          </div>
        </SearchBtn>
      )}
      {isMarket() && (
        <UserBtn
          onClick={()=> toggleUserOnClick()}
        >
          <div>
            <img src='/icon/user-icon.png' alt='Signin Signout Button'/>
          </div>
        </UserBtn>
      )}
      <MenuBtn
        className={`${isMenuOpen ? "closer" : null}`}
        onClick={()=> toggleMenuButton()}
      >
        <div>
          <img src={`/icon/hamburger-button.png?v=1`} alt='Top Menu Button'/>
        </div>
      </MenuBtn>
      {isMenuOpen ? (
        <>
          <NavWrapper className={`${isMenuOpen ? "show" : null}`}>
            <NavSection>
              <div className={'nav-title'}>
                <Logo>
                  <div>
                    <img src="/img/logo-vanchosun-news.png" alt="밴쿠버 조선일보 로고" />
                  </div>
                  {/* {userApi.isSignIn() ? (
                    <div onClick={() => handleSignOutOnClick()}>로그아웃</div>
                  ): (
                    <div onClick={() => handlePageMoveOnClick('/signIn')}>로그인</div>
                  )} */}
                </Logo>
                <img className='btnClose' src='/icon/close-x-mark-icon.png' alt='닫기 버튼' onClick={()=> toggleMenuButton()}/>
              </div>
              <Tabs>
                <ul>
                  <li><div className={`${isNews() ? 'selected' : ''}`} onClick={() => handleTabOnClick('news')}>뉴스</div></li>
                  <li><div className={`${isMarket() ? 'selected' : ''}`} onClick={() => handleTabOnClick('market')}>마켓</div></li>
                </ul>
              </Tabs>
              {isNews() && (
                <>
                  <NavCategory className={'nav-category'}>
                    <ul>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/')}>
                          <img src='/icon/lf-menu-news-home-icon.png' alt='뉴스홈' />
                          뉴스홈 {addNewTag()}
                        </div>
                        </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/news')}>
                          <img src='/icon/lf-menu-news-news-icon.png' alt='최신뉴스' />
                          뉴스 {addNewTag()}
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/realty')}>
                          <img src='/icon/lf-menu-news-realty-icon.png' alt='부동산' />
                          부동산
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/immigrant')}>
                          <img src='/icon/lf-menu-news-immigrant-icon.png' alt='이민' />
                          이민
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/education')}>
                          <img src='/icon/lf-menu-news-education-icon.png' alt='교육' />
                          교육
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/k-people')}>
                          <img src='/icon/lf-menu-news-k-people-icon.png' alt='교민' />
                          교민
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/opinion')}>
                          <img src='/icon/lf-menu-news-opinion-icon.png' alt='기고∙칼럼' />
                          기고∙칼럼
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/interview')}>
                          <img src='/icon/lf-menu-news-interview-icon.png' alt='인터뷰' />
                          인터뷰
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/special-feature-article')}>
                          <img src='/icon/lf-menu-news-special-feature-article-icon.png' alt='기획특집' />
                          기획특집
                        </div>
                      </li>
                    </ul>
                  </NavCategory>
                  <LeftMenuAd position={'news_right_menu_da'} listSize={1} />
                </>
              )}
              {isMarket() && (
                <>
                  <NavCategory className={'nav-category'}>
                    <ul>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/market')}>
                          <img src='/icon/lf-menu-market-home-icon.png' alt='마켓홈' />
                          마켓홈
                          {addNewTag()}
                        </div>
                        </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/buysell')}>
                          <img src='/icon/lf-menu-market-buysell-icon.png' alt='사고팔고' />
                          사고팔고
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/job')}>
                          <img src='/icon/lf-menu-market-job-icon.png' alt='구인구직' />
                          구인구직
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/tutor')}>
                          <img src='/icon/lf-menu-market-tutor-icon.png' alt='학원튜터' />
                          학원튜터
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/rent-homestay')}>
                          <img src='/icon/lf-menu-market-rent-homestay-icon.png' alt='렌트/민박' />
                          렌트민박
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/realty-sale')}>
                          <img src='/icon/lf-menu-market-realty-sale-icon.png' alt='부동산' />
                          부동산
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/car')}>
                          <img src='/icon/lf-menu-market-car-icon.png' alt='자동차' />
                          자동차
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/community')}>
                          <img src='/icon/lf-menu-market-community-icon.png' alt='커뮤니티' />
                          커뮤니티
                        </div>
                      </li>
                      <li>
                        <div onClick={() => handlePageMoveOnClick('/yellowpage')}>
                          <img src='/icon/lf-menu-market-yellowpage-icon.png' alt='업소록' />
                          업소록
                        </div>
                      </li>
                    </ul>
                  </NavCategory>
                  <LeftMenuAd position={'market_right_menu_da'} listSize={1} />
                </>
              )}
            </NavSection>
          </NavWrapper>
        </>
      ) :''}
    </>
  );
}

const SearchBtn = styled.div`
  position: absolute;
  top: 1.8rem;
  right: 5.6rem;
  cursor: pointer;
  transition: all 450ms ease-in-out;
  display: flex;
  cursor: pointer;
  & div {
    & img {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const UserBtn = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 4.5rem;
  cursor: pointer;
  transition: all 450ms ease-in-out;

  display: flex;
  & div {
    & img {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
`;

const MenuBtn = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 0.2rem;
  cursor: pointer;
  transition: all 450ms ease-in-out;

  display: flex;
  & div {
    & img {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
`;

const NavWrapper = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  &.show {
    background: rgba(0, 0, 0, 0.5);
    cursor: default;
  }
`;

const Tabs = styled.div`
  background-color: #fff;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    & li {
      width: 5.6rem;
      height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;
      & div {
        width: auto;
        height: 3.3rem;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #9e9e9e;
      }
      & div.selected {
        margin-bottom: -0.2rem;
        color: #000;
        border-bottom: 0.2rem solid #000;
      }
    }

  }
`;

const NavSection = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 100;
  & div.nav-title {
    height: 5.4rem;

    font-weight: 500;
    font-size: 18px;
    line-height: 5.4rem;
    text-align: center;

    background-color: #fff;
    border: 0.1rem solid #F5F5F5;
    & img.btnClose {
      z-index: 101;
      position: absolute;
      top: 0.3rem;
      right: 0.4rem;
      width: 4.8rem;
      height: 4.8rem;
      cursor: pointer;
    }
  }
`;

const NavCategory = styled.div`
  height: auto;
  background-color: #fff;
  & ul {
    margin: auto;
    padding: 1rem 0.5rem;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    background: #fff;
    & li {
      margin: 0;
      width: 100%;
      & div {
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        & img {
          height: 4.8rem;
          width: 4.8rem;
        }
        & img.new {
          margin-left: 0.4rem;
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }
  }
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & div {
    margin-left: 1.6rem;
    width: 11rem;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;