import React from 'react';
import styled from 'styled-components';

export const CheckBoxComponent = React.memo(({ id, value, list, onChange }) => {

  const handleOnChange = (sourceValue, targetValue) => {
    if(isSame(sourceValue, targetValue)) {
      onChange?.(sourceValue.replace(`${targetValue},`, ''));
    } else {
      onChange?.(`${sourceValue}${targetValue},`);
    }
  }

  const isSame = (sourceValue, targetValue) => {
    return sourceValue?.split(',').some(element=>element === targetValue ? true : false);
  }

  return (
    <CheckboxWrapper>
      <CarTypeList>
        {list.map((item) => (
          <li key={`${id}-${item.value}`}>
            <input type='checkbox'
              id={`${id}-${item.value}`}
              name={id}
              value={item.value}
              onChange={()=>handleOnChange(value, item.value)}
              checked={isSame(value, item.value)}
            />
            <label htmlFor={`${id}-${item.value}`}></label>
            <label className='message' htmlFor={`${id}-${item.value}`}>
              {item.label}
              {(item?.buttonUrl) && (
                <img src={item?.buttonUrl} alt='이상' />
              )}
            </label>
          </li>
        ))}
      </CarTypeList>
    </CheckboxWrapper>
  );
});

const CheckboxWrapper = styled.div`
  margin: 0.7rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const CarTypeList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & li {
    width: 50%;
    height: 4.8rem;
    display: flex;
    align-items: center;

    & input + label {
      display: inline-block;
      margin: 0;
      width: 1.6rem;
      height: 1.6rem;
      border: 0.1rem solid #E0E0E0;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    & input:checked + label {
      background-color: #f86258;
      border: 0.1rem solid #f86258;
      background-image: url('/icon/input-checkbox-checked-icon.png');
      background-size: 1.12rem;
      background-repeat: no-repeat;
      background-position: center;
    }
    & input {
      display: none;
    }
    & label.message {
      margin-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;

      display: flex;
      align-items: center;
      cursor: pointer;
      & img {
        margin-left: 0.4rem;
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
`;